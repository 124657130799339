import { Box } from "../../../layouts/Box"
import { ArrowLink } from "../../../ui-parts/ocnk"

const OcnkTeikiUseStartLinkElement = () => {
	return(
		<div>
			<h2 className="mt36 mb20">ご利用の手引き</h2>
			<Box mt={10} />
			<ArrowLink
				link={"https://www.cardservice.co.jp/zmc/manual/admin.html"}
				href
			>
				ゼウス決済サービス管理画面メニュー
			</ArrowLink>
			<Box mt={10} />
			<ArrowLink
				link={"https://support.cardservice.co.jp/hc/ja"}
				href
			>
				ご利用のQ&A（クレジットカード）
			</ArrowLink>
		</div>
	)
}

export default OcnkTeikiUseStartLinkElement