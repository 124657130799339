import { FormWrap } from '../../../layouts/FormWrap';
import { Box } from '../../../layouts/Box';
import { TextField, Button, ErrorMessage } from '../../../ui-parts/ocnk';
import { ChangeEventHandler, MouseEventHandler } from 'react';

const OcnkTeikiEmailSettingElement = ({
  setEmailHandler,
  sendEmailHandler,
  ErrMsg,
  ApiErrMsg,
}: {
  setEmailHandler: ChangeEventHandler<HTMLInputElement>,
  sendEmailHandler: MouseEventHandler<HTMLButtonElement>,
  ErrMsg: string,
  ApiErrMsg?: string,
}) => {
  return (
    <>
      <FormWrap>
        <div className='align_center'>
          <h1 className='mt0 mb0'>メールアドレス設定</h1>
          <p className="mt16 align_left">リクエストのメールアドレスはゼウス決済Web申込システムにすでに登録がありますのでご使用できません。以下より他のメールアドレスを設定してください。</p>
        </div>
        <ErrorMessage ApiErrMsg={ApiErrMsg} />
        <TextField
          mt={12}
          onChange={setEmailHandler}
          iserror={ErrMsg}
          id='textfield1'
          label='メールアドレス'
          placeholder='user@cardservice.co.jp'
          fullwidth
        />
        <Box mt={32}>
          <Button variant='primary' fullwidth onClick={sendEmailHandler}>メールアドレスを設定する</Button>
        </Box>
      </FormWrap>
    </>
  )
}

export default OcnkTeikiEmailSettingElement
