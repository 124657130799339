import styled from 'styled-components'
import { ContentsWrapper } from './ContentsWrapper';
import { InquiryNavElement } from '../ui_elements';
import { Header } from './Header';
import { Footer } from './Footer';
import useInquiryNavHooks from '../../hooks/useInquiryNavHooks';
import {InquiryModalElement} from '../ui_elements';
import InquirySlideInElement from '../ui_elements/ocnk/Inquiry/InquirySlideInElement';
import useContainerHooks from '../../hooks/useContainerHooks';
import { Loading } from '../ui-parts/ocnk/Loading';
import { ReactNode } from 'react';
import useNavigateByAgent from '../../hooks/useNavigateByAgentHooks';
import CloseDatePopUp from '../ui_elements/ocnk/Container/CloseDatePopUp';
import EntryGuide from '../ui-parts/ocnk/EntryGuide';

const ContainerWrap = styled.div`
  display:flex;
  flex-direction:column;
  min-height: 100%;
`

export const Container = ({
  isLogin = false,
  isInquiry = false,
  background = 'default',
  align = "center",
  fullwidth = false,
  children = <></>,
  redirect
}: {
  isLogin?: boolean,
  isInquiry?: boolean,
  background?: string,
  align?: string,
  fullwidth?: boolean,
  children: ReactNode,
  closingNoticeText?: string,
  redirect?: boolean
}) => {
  const {navigateByAgent} = useNavigateByAgent()
  const {isLoading, canceledFlag, modalOpenHandler, inquiryRegisterHandler, setInquiryRegisterHandler, ModalSendFlag, registerApiErrMsg, InquiryRegisterErrMsg, selectTitle, ModalFlag, closeState, closingNoticeText, agentName} = useContainerHooks(isLogin,navigateByAgent)
  const {InquiryData, InquiryCommentData,accordionNum,path,serchInquiryData,nonClosedCount,Toggle,toggleChangeHandler,inquiryCount,naviToggleChangeHandler,NaviFlag,slideInShowFlag,EntryGuideShowFlag} = useInquiryNavHooks(isLogin,navigateByAgent)
  return (
    <ContainerWrap>
      {/* ローディング画面 */}
      <Loading isLoading={isLoading} />
      {/* ヘッダー */}
      <Header 
        isLogin={isLogin} 
        ModalFlag={ModalFlag} 
        modalOpenHandler={modalOpenHandler} 
        canceledFlag={canceledFlag} 
        navigateByAgent={navigateByAgent} 
        closeState={closeState} 
        redirect={redirect}
        agentName={agentName}
        path={path}
      />
      {(isInquiry && InquiryData.length !== 0)  &&
        <InquiryNavElement 
          InquiryData={InquiryData} 
          Toggle={Toggle} 
          toggleChangeHandler={toggleChangeHandler} 
          nonClosedCount={nonClosedCount}
        />
      }
      {/* 画面クローズ通知 */}
      {(closeState.confirm_flag && isLogin) &&
        <CloseDatePopUp
          closeText={closingNoticeText}
        />
      }
      {/* 画面中身 */}
      <ContentsWrapper background={background} align={align} fullwidth={fullwidth}>
        {children}
      </ContentsWrapper>
      {/* 不備スライドイン */}
      {(isLogin && slideInShowFlag && inquiryCount.active !== 0) && 
        <InquirySlideInElement
          inquiryCount={inquiryCount}
          naviToggleChangeHandler={naviToggleChangeHandler}
          NaviFlag={NaviFlag}
          InquiryCommentData={InquiryCommentData}
          serchInquiryData={serchInquiryData}
          accordionNum={accordionNum}
        />
      }
      {/* お問い合わせモーダル */}
      {ModalFlag && 
          <InquiryModalElement
            modalOpenHandler={modalOpenHandler}
            inquiryHandler={inquiryRegisterHandler}
            setInquiryRegisterHandler={setInquiryRegisterHandler}
            modalSendFlag={ModalSendFlag}
            apiErrMsg={registerApiErrMsg}
            errMsg={InquiryRegisterErrMsg}
            selectOption={selectTitle}
          />
      }
      {/* 申し込みガイド */}
      {EntryGuideShowFlag &&
        <EntryGuide path={path}/>
      }
      <Footer/>
    </ContainerWrap>
  );
};
