import { ChangeEventHandler, ReactNode, useState } from 'react';
import styled from 'styled-components'
import { colors } from '../../../css/variables/colors';
import { fonts } from '../../../css/variables/fonts';
import imgPasswordIconOn from '../../../images/icon_password_on.svg';
import imgPasswordIconOff from '../../../images/icon_password_off.svg';
import { ToolChip } from './ToolChip';
import { FlexBox } from '../../layouts/FlexBox';
import { mixins } from '../../../css/variables/mixins';

type textFieldWrapType = {
  fullwidth?: boolean,
  mt?: number,
  maxWidth?: number
}

const TextFieldWrap = styled.div<textFieldWrapType>`
  display:${props => props.fullwidth ? 'flex' : 'inline-flex'};
  margin-top:16px;
  flex-direction: column;
  margin-top: ${props => props.mt}px;
  ${props => props.maxWidth ? "max-width:"+props.maxWidth+"px;":""}
  label{
    display: block;
    margin-bottom: 4px;
    font-size:${fonts.fontSizeRem('s')};
    overflow: hidden;
    ${mixins.screenOver()}{
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`

type textFieldStyleType = {
  fullwidth?: boolean,
  width?: number,
  iserror?: string | boolean
}

const TextFieldStyle = styled.div<textFieldStyleType>`
  display:${props => props.fullwidth ? 'flex' : 'inline-flex'};
  position: relative;
  .textfield{
    background: ${colors.themeColor('background_input')};
    padding: 12px 16px;
    border-radius: 4px;
    width:${props => props.width ? props.width+'px' : (props.fullwidth ? '100%' : 'auto')};
    border:1px solid ${props => props.iserror ? colors.themeColor('border_error') + ' !important' : colors.themeColor('border_input')};
    outline: none;
    &::placeholder{
      color: ${colors.themeColor('text_placeholder')};
    }
    &:focus,&:active{
      border: 1px solid ${colors.themeColor('primary_light')}; ;
    }
    &:disabled{
      background: ${colors.themeColor('background_disabled')};
      color:${colors.themeColor('text-disabled')};
      border:1px solid ${colors.themeColor('border_disabled')};
    }
    &.password{
      padding-right: 48px;
      &::-ms-reveal{
        visibility: hidden;
      }
    }
  }
  .password_toggle{
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    border: none;
    background-color: transparent;
    &_on{
      background-image: url(${imgPasswordIconOff});
    }
    &_off{
      background-image: url(${imgPasswordIconOn});
    }
  }
`
const Error = styled.span`
  display: block;
  color: ${colors.themeColor('text_error')};
  font-size: ${fonts.fontSizeRem('s')};
  margin-top: 4px;
  white-space: pre-line;
  text-align:left;
`
const ToolChipWrapStyle = styled.span`
  display: inline-block;
  margin-left: 8px;
  vertical-align: text-bottom;
  font-size:${fonts.fontSizeRem('xs')};
`;

export const TextField = ({
  required,
  id,
  name,
  label,
  placeholder,
  disabled,
  width,
  title,
  defaultValue,
  onChange,
  toolChip,
  toolChipWidth,
  explain,
  value,
  ref,
  maxWidth,
  toolChipPlacement,
  type = 'text',
  iserror = '',
  fullwidth = false,
  mt = 16,
  labelMt = 16
}: {
  required?: boolean,
  id?: string,
  name?: string,
  label?: string,
  placeholder?: string,
  disabled?: boolean,
  iserror?: string | boolean,
  fullwidth?: boolean,
  width?: number,
  mt?: number,
  type?: string,
  title?: string,
  defaultValue?: string,
  onChange: ChangeEventHandler<HTMLInputElement>,
  toolChip?: string | ReactNode,
  toolChipWidth?: number,
  explain?: string | ReactNode,
  value?: string,
  ref?: string,
  maxWidth?: number,
  toolChipPlacement?: string,
  labelMt?: number
}) => {  
  const [currentType, setinputType] = useState(type);
  const togglePassword = () => {
    currentType === 'text' ? setinputType('password') : setinputType('text');
  }
  return (
    <TextFieldWrap fullwidth={fullwidth} mt={mt} maxWidth={maxWidth}>
      {(label || toolChip)&&
        <FlexBox gap={0} mt={labelMt}>
          {label ? (<label htmlFor={id}>{label}</label>) : (<></>)}
          {toolChip ? (
            <ToolChipWrapStyle>
              <ToolChip content={toolChip} placement={toolChipPlacement} width={toolChipWidth} />
            </ToolChipWrapStyle>
          ) : (<></>)}
        </FlexBox>
      }
      <TextFieldStyle iserror={iserror} fullwidth={fullwidth} width={width}>
        <input required={required} ref={ref} onChange={onChange} type={currentType} className={`${type === 'password' ? 'password' : ''} textfield`} id={id} placeholder={placeholder} defaultValue={defaultValue} disabled={disabled ? true : false} value={value}/>
        {type === 'password' ? (<input type='button' onClick={() => togglePassword()} className={`${currentType === 'password' ? 'password_toggle_on' : 'password_toggle_off'} password_toggle`} name={name} title={title} />) : (<></>)}
      </TextFieldStyle>
      {iserror  !== '' ? (<Error>{iserror}</Error>) : (<></>)}
      {explain ? (<ToolChipWrapStyle>{explain}</ToolChipWrapStyle>):(<></>)}
    </TextFieldWrap>
  );
};
