import { Container } from "../../../components/layouts/Container";
import { BackButton } from "../../../components/ui-parts/ocnk/BackButton";
import FinalAgreementElement from "../../../components/ui_elements/ocnk/FinalAgreement/FinalAgreementElement";
import useNavigateByAgent from "../../../hooks/useNavigateByAgentHooks";

const FinalAgreement = () => {

	const {navigateByAgent} = useNavigateByAgent()
	return (
		<Container isLogin={true} background={'default'} align={'top'}>
			<div className="mb4 align_right">
				<BackButton onClick={()=>navigateByAgent('/dashboard')}>ダッシュボードに戻る</BackButton>
			</div>
			<FinalAgreementElement navigateByAgent={navigateByAgent}/>
		</Container>
	)
}

export default FinalAgreement