import { BackGround } from "../../layouts/BackGround";

const PdfExplainBox = () => (
        <BackGround widthPercent={75}>
          <h2 className="mt0 mb20">お申し込み内容の確認について</h2>
          <p className='mb0'>ダッシュボードにある「お申し込み情報ダウンロード」ボタンからダウンロードができます。</p>
          <p className='mb0'>ご利用開始後に、Web申込システムをクローズいたしますので、お申込み内容の控えとしてご活用ください。</p>
        </BackGround>
)

export default PdfExplainBox