import styled from 'styled-components'
import { colors } from '../../css/variables/colors';
import { mixins } from '../../css/variables/mixins';
import { ReactNode, RefObject } from 'react';

type wrapperType = {
  width?: number,
  center?: boolean,
  margin_tb?: number,
  padding?: number,
}
const Wrapper = styled.div<wrapperType>`
${props => props.width ? 'max-width:' + props.width + 'px' : 'max-width:580px'};
${props => props.center ? 'text-align:center' : ''};
${props => props.margin_tb ? 'margin:' + props.margin_tb+ 'px 0px ' : 'margin:auto'};
background: ${colors.themeColor('background_main')};
border-radius: 8px;
border: 1px solid ${colors.themeColor('border_box')};
${props => props.padding ? 'padding:' + props.padding + 'px ' : 'padding:40px 48px;'};
${mixins.screenUnder()}{
  padding: 28px 16px;
}

`

export const FormWrap = ({
  width = 580,
  center = false,
  children = <></>,
  refProp = null,
  margin_tb,
  padding,
}: {
  children?: ReactNode,
  width?: number,
  margin_tb?: number,
  padding?: number,
  center?: boolean,
  refProp?: RefObject<HTMLInputElement> | null
}) => (
  <Wrapper width={width} margin_tb={margin_tb} padding={padding} center={center} ref={refProp}>
    {children}
  </Wrapper>
);
