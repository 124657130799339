import { useMemo, useState, useContext } from "react"
import { Context } from "../stores/Provider";
import { useLocation } from "react-router-dom"
import { PostMethod } from "../api/Api"
import { initApiResponse } from "../constance/constance"
import { initApiModelType, mainentryDevRedirectRequestType } from "../constance/typeModel"

const useUserRegisterAuthenticationHooks = (navigateByAgent: Function) => {
    const { state } = useContext(Context);
    const location = useLocation()
    const agentPath = state.agentPath
    const path = location.pathname.replace(agentPath, '')
    const [ clickFlag, setClickFlag ] = useState<boolean>(false)
    const paramsArray = location.search.substring(1).split('&')

    //emailパラメータ
    const emailParam = useMemo(()=>{
        let result = ""
        paramsArray.forEach((param)=>{
            if(param.includes('user_name=')) result = param.split('user_name=')[1]
        })
        return result
    },[paramsArray])
    //認証コードパラメータ
    const confirmationCodeParam = useMemo(()=>{
        let result = ""
        paramsArray.forEach((param)=>{
            if(param.includes('confirmation_code=')) result = param.split('confirmation_code=')[1]
        })
        return result
    },[paramsArray])
    //認証ボタン押下時
    const authButtonHandler = async() => {
        let result: initApiModelType<undefined> = initApiResponse
        const postData: mainentryDevRedirectRequestType = {
            user_name : emailParam,
            confirmation_code : confirmationCodeParam,
        }
        // ダブルクリック時はAPIを実行しない
        if(!clickFlag){
            setClickFlag(true)
            result = await PostMethod<mainentryDevRedirectRequestType, undefined>(postData, '/mainentry_dev_redirect')
            return result
        }
        return result
    }
    //認証成功時実行関数
    const normal = () => {
        return navigateByAgent('/login')
    }
    //400番台実行関数
    const abnormal = () => {
        return navigateByAgent(path + location.search)
    }
    return { authButtonHandler, normal, abnormal }
}

export default useUserRegisterAuthenticationHooks