import styled from "styled-components";
import { ErrorHandler } from "../../../api/ErrorHandler";
import { Container } from "../../../components/layouts/Container"
import { Button, EntryIcons } from "../../../components/ui-parts/ocnk";
import { BackButton } from "../../../components/ui-parts/ocnk/BackButton";
import { Snackbar } from "../../../components/ui-parts/ocnk/Snackbar";
import OcnkTeikiEntryConfirmElement from "../../../components/ui_elements/ocnkTeiki/EntryConfirm/EntryConfirmElement";
import { colors } from "../../../css/variables/colors";
import useEntryConfirmHooks from "../../../hooks/useEntryConfirmHooks";
import { mixins } from "../../../css/variables/mixins";
import { initApiModelType, entryInfoPostResponseDataType } from "../../../constance/typeModel";
import useNavigateByAgent from "../../../hooks/useNavigateByAgentHooks";
import LinkByAgent from "../../../components/ui-parts/ocnk/LinkByAgent";
import OcnkBankExplainBox from "../../../components/ui_elements/ocnkTeiki/EntryConfirm/OcnkBankExplainBox";

const TitleWrapper = styled.div`
    p {
        color:${colors.themeColor('text-main')};
        font-size:26px;
        font-weight:600;
        margin-bottom:0px;
	white-space: nowrap;
    }
    display:flex;
    
`
const WholeWrapper = styled.div`
    ${mixins.screenOver()}{
        padding:0px 90px 0px;
    }
    ${mixins.screenUnder()}{
        padding:0px 16px 0px;
    }
    
`
const FlexWrpper = styled.div`
    display:flex;
    justify-content:space-between;
    margin-top:28px;
    margin-bottom:20px;
    ${mixins.screenUnder()}{
        flex-direction:column;
    }
`
const ButtonWrapper = styled.div`
    margin-top:48px;
    ${mixins.screenUnder()}{
        text-align:center;
    }
    
`

const OcnkTeikiEntryConfirm = ({
    redirect
}:{
    redirect?: boolean
}) => {
    const navigatePath = redirect ? '/redirect' : '/entry'
    const {navigateByAgent} = useNavigateByAgent()
    const { isOpenModal, entryComplete, normal, abnormal, userInfoData } = useEntryConfirmHooks(navigateByAgent, redirect);
    const { ErrorHandle } = ErrorHandler(navigateByAgent);

    const entryCompleteHandler = async () => {
        const response = await entryComplete();
        ErrorHandle<initApiModelType<entryInfoPostResponseDataType>>(response, normal, abnormal, false);
    }

    return(
        <Container isLogin={true} background={'default'} align={'top'} redirect={redirect} fullwidth>
            <Snackbar open={isOpenModal.flag} message={isOpenModal.message} type={isOpenModal.type}/>
            <WholeWrapper>
                {!redirect &&
                    <div className="align_right">
                        <BackButton><LinkByAgent to={'/dashboard'}>ダッシュボードへ戻る</LinkByAgent></BackButton>
                    </div>
                }
                <h1>お申し込み情報をご確認ください</h1>
                <hr/>
                <FlexWrpper>
                    <TitleWrapper>
                        <EntryIcons icon={'shop'} /><p>お客様情報</p>
                    </TitleWrapper>
                    <BackButton><LinkByAgent to={navigatePath+'/merchant'}  confirm_flag={true}>お客様情報を修正</LinkByAgent></BackButton>
                </FlexWrpper>
                <OcnkTeikiEntryConfirmElement title={'merchant'} check={false}/>
                {!userInfoData.ip_flag ?
                    <>
                        <FlexWrpper>
                            <TitleWrapper>
                                <EntryIcons icon={'bank'} /><p>口座情報</p>
                            </TitleWrapper>
                            <BackButton><LinkByAgent to={navigatePath+'/bank_account'}  confirm_flag={true}>口座情報を修正</LinkByAgent></BackButton>
                        </FlexWrpper>
                        <OcnkTeikiEntryConfirmElement title={'bank'} check={false}/>
                    </>
                    :
                    <>
                        <FlexWrpper>
                            <TitleWrapper>
                                <EntryIcons icon={'bank'} /><p>口座情報</p>
                            </TitleWrapper>
                        </FlexWrpper>
                        <OcnkBankExplainBox/>
                    </>
                }
                <FlexWrpper>
                    <TitleWrapper>
                        <EntryIcons icon={'shopinfo'} /><p>ショップ情報</p>
                    </TitleWrapper>
                    <BackButton><LinkByAgent to={navigatePath+'/shop'}  confirm_flag={true}>ショップ情報を修正</LinkByAgent></BackButton>
                </FlexWrpper>
                <OcnkTeikiEntryConfirmElement title={'shop'} check={false}/>
                <FlexWrpper>
                    <TitleWrapper>
                        <EntryIcons icon={'setting'} /><p>お見積内容の確認</p>
                    </TitleWrapper>
                </FlexWrpper>
                <OcnkTeikiEntryConfirmElement title={'economic'} check={false}/>
                {redirect &&
                    <>
                        <FlexWrpper>
                            <TitleWrapper>
                                <EntryIcons icon={'setting'} /><p>Web申込システム ログイン情報</p>
                            </TitleWrapper>
                        </FlexWrpper>
                        <OcnkTeikiEntryConfirmElement title={'login'} check={false}/>
                    </>
                }
                <ButtonWrapper>
                    <Button onClick={entryCompleteHandler}>ゼウス決済サービスに申し込む</Button>
                </ButtonWrapper>
            </WholeWrapper>
            
        </Container>
    )
}
export default OcnkTeikiEntryConfirm
