import { useContext, useMemo, useCallback } from "react"
import { useLocation } from "react-router-dom"
import { PostMethod } from "../api/Api"
import { itemValidators } from "../validators/validationPattern"
import { getSession, setBankAccountInfoData, setEntryCheckInfoData, setMerchantInfoData, setOperationInfoData, setRepresentativeInfoData, setShopInfoData, setUserInfoData } from "../sessionStorage/sessionStorageMethod"
import { Context } from "../stores/Provider"
import { bankNameBranchValidator, bankNameValidator, blankValidate, comparePriceValidator, fileValidator } from "../validators/publicValidator"
import { setIsLoading } from "../function/setIsLoading"
import { logoutAbnormalFunction } from "../function/logoutAbnormalFunction"
import { entryInfoPostRequestDataType, initApiModelType, entryInfoPostResponseDataType, initBankAccountSessionDataType,  initReviewContentsDivisionSessionDataType, initShopSessionDataType, initMerchantSessionDataType, initRepresentativeSessionDataType } from "../constance/typeModel"
import { initShopSessionData } from "../constance/sessionData"
import { agentMasterLists, initApiResponse } from "../constance/constance"
import { validateMessageBankInsertDataType, validateMessageMerchantInsertDataType, validateMessageShopInsertDataType } from "../constance/providerTypeModel"

const useEntryAPIHooks = (navigateByAgent: Function, redirect?: boolean) => {
	const {state, dispatch} = useContext(Context)
	const location = useLocation()

	//セッションデータ抽出
	const userInfoData = getSession('user')
	const agentMasterInfoData = getSession('agent_master')
	const merchantSessionData = getSession('merchant')
	const merchantInfoData = state.entry.merchant
	const bankAccountInfoData = state.entry.bank_account
	const representativeInfoData = state.entry.representative
	const operationInfoData = state.entry.operation
	const economicInfoData = getSession('economic_condition')
	const fileInfoData = state.entry.files
	const shopInfoData = state.entry.shop
	const entryCheckInfoData = getSession('entry_check')
	const ObjToDevide = state.entry.review_contents_division

	//おちゃ定期用IPフラグ 
	const ipFlag = userInfoData.ip_flag

	//画面のパス
	const path = useMemo(()=>{
		const pathArray = location.pathname.split('/')
		return pathArray[pathArray.length-1]
	},[location.pathname])

	//確認画面からの遷移か確認するフラグ
	const fromConfirmFlag = useMemo(()=>{
		let confirmFlag = false
		if(location.state){
			confirmFlag = location.state.confirm_flag
		}
		return confirmFlag
	},[location.state]) 
	

	//データをAPI用に成型
	//店舗情報_郵便番号
	const merchantInsert = useMemo((): initMerchantSessionDataType => {
		// 郵便番号が上下両方入っていない時は空で送る
		const merchantPostCode = (merchantInfoData.merchant_register_post_code3 !== "" && merchantInfoData.merchant_register_post_code4 !== "") ? merchantInfoData.merchant_register_post_code3 + '-' + merchantInfoData.merchant_register_post_code4 : "" 
		// 年収を数字化
		const merchantAnnualBusiness: string | number | null = merchantInfoData.merchant_annual_business !== null && merchantInfoData.merchant_annual_business !== "" ? Number(merchantInfoData.merchant_annual_business) : merchantInfoData.merchant_annual_business
		// コンビニが選択されていない時、merchant_website_urlにブランクを挿入
		const merchantWebsiteUrl = economicInfoData.cvs.system_setting.select_flag ? merchantInfoData.merchant_website_url : ""
		const merchantInsert: initMerchantSessionDataType = {
			...merchantInfoData,
			merchant_register_post_code: merchantPostCode,	
			merchant_annual_business: merchantAnnualBusiness,
			merchant_website_url: merchantWebsiteUrl 		
		}
		return merchantInsert
	}, [merchantInfoData,economicInfoData])

	//代表者情報をAPI用に成型
	const representativeInsert = useMemo((): initRepresentativeSessionDataType => {
		let representationBirthday = representativeInfoData.representative_birthday_CE + '-' + representativeInfoData.representative_birthday_month + '-' + representativeInfoData.representative_birthday_day
		representationBirthday = representationBirthday === "--" ? "" : representationBirthday
		const representativeInsert: initRepresentativeSessionDataType = {
			...representativeInfoData,
			representative_birthday: representationBirthday,
		}
		return representativeInsert
	}, [representativeInfoData])

	//全角英数字から半角英数字への変換
    const alphabetHalfWidthChangeHandler = useCallback((data: string) => {
        return data.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
			return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
		});
    },[])

	//店舗情報用リコイルデータまとめる（空欄バリデーション用）
	const arrangeMerchantData = useMemo(() => {
		const unionData = {
			...merchantInsert,
			...representativeInsert,
			...operationInfoData,
			identity_doc_images:fileInfoData.identity_doc_images,
		}
		return unionData
	}, [merchantInsert, representativeInsert, operationInfoData, fileInfoData])

	//口座情報用リコイルデータをまとめる（空欄バリデーション用）
	const arrangeBankAccountData = useMemo(() => {
		const unionData = {
			...bankAccountInfoData,
			passbook_images:fileInfoData.passbook_images,
		}
		return unionData
	}, [bankAccountInfoData, fileInfoData])

	//ショップ情報用リコイルデータをまとめる（空欄バリデーション用）
	const arrangeShopData = useMemo(() => {
		const unionData = {
			...shopInfoData,
			antique:fileInfoData.antique, 
			license:fileInfoData.license,
			examination_document:fileInfoData.examination_document
		}
		return unionData
	}, [shopInfoData, fileInfoData])

	//ページごとにリコイルのデータを変更（空欄バリデーション用）
	const devideRecoilByPages = (page: string) => {
		switch (page) {
			case 'merchant':
				return arrangeMerchantData
			case 'bank_account':
				return arrangeBankAccountData
			case 'shop':
				return arrangeShopData
			default:
				return {}
		}
	}

	//商品コンテンツの出し分けで表示されない項目にnullをセット
	const setReviewContentsNullHandler = useCallback((shopData: initShopSessionDataType, deivdeData: initReviewContentsDivisionSessionDataType, merchantData: initMerchantSessionDataType) => {
		const setNameObj: {
			[key: string]: string[],
			antique_flag: ["shop_addition_antique_flag"],
			btob_flag: ["shop_addition_btob_code"],
			estimate_flag: ["shop_addition_estimated_product_flag"],
			online_local_flag: ["shop_addition_online","shop_addition_offline"],
			prepaid_continuous_flag: ["shop_addition_prepaid_flag"],
			purchase_points_flag: ["shop_addition_point_purchase_flag"],
			real_store_flag: ["shop_addition_physical_store_flag","shop_addition_physical_store_url_flag","shop_addition_physical_store_url"],
			rental_flag: ["shop_addition_rental_flag","shop_addition_rental_product_url"],
			reserve_item_flag: ["shop_addition_reserved_product_flag","shop_addition_reservation_period_flag"],
			subscription_flag: ["shop_addition_subscription_flag","shop_addition_subscription_code"],
		} = {
			antique_flag: ["shop_addition_antique_flag"],
			btob_flag: ["shop_addition_btob_code"],
			estimate_flag: ["shop_addition_estimated_product_flag"],
			online_local_flag: ["shop_addition_online","shop_addition_offline"],
			prepaid_continuous_flag: ["shop_addition_prepaid_flag"],
			purchase_points_flag: ["shop_addition_point_purchase_flag"],
			real_store_flag: ["shop_addition_physical_store_flag","shop_addition_physical_store_url_flag","shop_addition_physical_store_url"],
			rental_flag: ["shop_addition_rental_flag","shop_addition_rental_product_url"],
			reserve_item_flag: ["shop_addition_reserved_product_flag","shop_addition_reservation_period_flag"],
			subscription_flag: ["shop_addition_subscription_flag","shop_addition_subscription_code"],
		}
		let resultObj: {[key: string]:string | number | boolean | null} = {}
		for(let flagName in setNameObj){
			const shopItemArray = setNameObj[flagName]
			//出し分けフラグがtrueの時、ショップ情報に入っているデータを挿入
			if(flagName === 'real_store_flag') continue
			if(deivdeData[flagName]){
				shopItemArray.forEach((itemName: string)=>{
					const shopItemValue = shopData[itemName]
					resultObj[itemName] = shopItemValue	
				})
			//falseの場合、nullを挿入
			} else {
				shopItemArray.forEach((itemName: string)=>{
					resultObj[itemName] = null	
				})
			}	
		}
		//恒久対応の必要あり
		//実店舗のみ条件が多いため別処理
		if(userInfoData.agent_master_id === 1 && deivdeData.real_store_flag && merchantData.merchant_type === '2'){
			setNameObj.real_store_flag.forEach((itemName)=>{
				const shopItemValue = shopData[itemName]
				resultObj[itemName] = shopItemValue
			})
		} else {
			setNameObj.real_store_flag.forEach((itemName)=>{
				resultObj[itemName] = null
			})
		}
		//恒久対応の必要あり
		//おちゃ定期の場合にサブスクフラグをtrueにする
		const ocnk_agent_master_id = [3,4,5]
		if(ocnk_agent_master_id.includes(userInfoData.agent_master_id)){
			resultObj.shop_addition_subscription_flag = true
		}
		// ライセンスフラグは出し分けフラグをそのまま連携
		resultObj.shop_addition_license_flag = deivdeData.license_flag
		return resultObj
	},[])

	//バリデートされていないデータのメッセージを空欄にする
	const noValidateMessage = <T extends object,>(validateData: T, originValidateData: T) => {
		const validateProps = Object.keys(validateData)
		const originProps = Object.keys(originValidateData)
		const comparisonProps = originProps.filter(index => validateProps.indexOf(index) === -1)
		let noValidateData = {}
		comparisonProps.forEach((key) => {
			noValidateData = { ...noValidateData, [key]: { isValidate: '', message: '' } }
		})
		const validateResult = {
			...validateData,
			...noValidateData
		}
		return validateResult
	}
	//店舗情報用バリデーション
	const merchantValidator = () => {
		const merchantValidateData = state.validateMessages.merchant
		let validateData: validateMessageMerchantInsertDataType = {}
		const merchantArray = [
			"merchant_name","merchant_name","merchant_name_kana","merchant_last_name","merchant_first_name","merchant_last_name_kana","merchant_first_name_kana",
			"merchant_phone_number_before","merchant_phone_number_middle","merchant_phone_number_after","merchant_register_post_code3","merchant_register_post_code4",
			"merchant_address_municipality","merchant_address_municipality_kana","merchant_address_streetbunch","merchant_address_streetbunch_kana","merchant_address_building",
			"merchant_address_building_kana","merchant_website_url","merchant_annual_business","merchant_shop_category","merchant_corporate_number",
		]
		const representativeArray = [
			"representative_last_name","representative_first_name","representative_last_name_kana","representative_first_name_kana",
		]
		const oparatorArray = [
			"operator_last_name","operator_first_name","operator_last_name_kana","operator_first_name_kana","operator_email","operator_phone_number_before",
			"operator_phone_number_middle","operator_phone_number_after","support_operator_email","support_operator_phone_number_before","support_operator_phone_number_middle",
			"support_operator_phone_number_after",
		]
		merchantArray.forEach((item)=>{
			validateData[item] = itemValidators<string | number | null>("merchant",item,merchantInfoData[item])
		})
		representativeArray.forEach((item)=>{
			validateData[item] = itemValidators<string | null>("merchant",item,representativeInfoData[item])
		})
		oparatorArray.forEach((item)=>{
			validateData[item] = itemValidators<string>("merchant",item,operationInfoData[item])
		})
		validateData.identity_doc_images = fileValidator(fileInfoData.identity_doc_images)
		const validateResult = noValidateMessage<validateMessageMerchantInsertDataType>(validateData, merchantValidateData)

		dispatch({
			type: 'set-validate-merchant',
			payload: {
				...validateResult
			}
		})
		return validateResult
	}

	//口座情報用バリデーション
	const bankAccountValidator = () => {
		const bankAccountValidateData = state.validateMessages.bank_account
		const bankArray = ["bank_account_number","bank_account_name"]
		let validateData: validateMessageBankInsertDataType = {}
		bankArray.forEach((item)=>{
			validateData[item] = itemValidators<string | number | null>('bank_account', item, bankAccountInfoData[item])
		})
		validateData.bank_account_bank_name = bankNameValidator(bankAccountInfoData.bank_account_bank_name,state.entry_bank_pulldown)
		validateData.bank_account_bank_branch = bankNameBranchValidator(bankAccountInfoData,state.entry_bank_pulldown)
		validateData.passbook_images = fileValidator(fileInfoData.passbook_images)
		const validateResult = noValidateMessage<validateMessageBankInsertDataType>(validateData, bankAccountValidateData)
		dispatch({
			type: 'set-validate-bank_account',
			payload: {
				...validateResult
			}
		})
		return validateResult
	}

	//ショップ情報用バリデーション
	const shopValidator = () => {
		const shopValidateData = state.validateMessages.shop
		const shopArray = [
			"account_id","shop_name","shop_name_kana","shop_name_alphabet","shop_addition_shop_website_check_url","shop_addition_business_deal_url","shop_cvs_website_name",
			"shop_cvs_website_name_kana","shop_website_url","shop_addition_price_range_min","shop_addition_price_range_max","shop_addition_product_content_freetext",
			"shop_addition_rental_product_url","shop_addition_physical_store_url","shop_addition_business_deal_url","shop_addition_subscription_code",
			"shop_atobarai_customer_notice_store_name","shop_addition_shop_website_password","shop_addition_shop_website_id"
		]
		const fileArray = ["antique","examination_document","rental","license"]
		let validateData: validateMessageShopInsertDataType = {}
		shopArray.forEach((item)=>{
			validateData[item] = itemValidators<string | number | boolean | null>('shop', item, shopInfoData[item])
		})
		fileArray.forEach((item)=>{
			validateData[item] = fileValidator(fileInfoData[item])
		})
		validateData.shop_addition_price_validate = comparePriceValidator(shopInfoData)
		const validateResult = noValidateMessage<validateMessageShopInsertDataType>(validateData, shopValidateData)
		dispatch({
			type: 'set-validate-shop',
			payload: {
				...validateResult
			}
		})
		return validateResult
	}
	//バリデーション成功判定(おちゃ上位)
	const validateSuccessJudge = <T,>(validateData: validateMessageMerchantInsertDataType | validateMessageBankInsertDataType | validateMessageShopInsertDataType, page: T) => {
		let validateFlag =false
		const devision = page !== null ? page : path
		//対象外のバリデーションを無視
		let checkData: string[] = [];

		if(devision === 'merchant'){
			checkData.push(
                'merchant_type','merchant_phone_number_before','merchant_phone_number_middle','merchant_phone_number_after','merchant_register_post_code3','merchant_register_post_code4',
				'merchant_address_prefectures','merchant_address_municipality','merchant_address_municipality_kana','merchant_address_streetbunch','merchant_address_streetbunch_kana',
				'merchant_address_building','merchant_address_building_kana','merchant_annual_business','merchant_shop_category','operator_last_name','operator_last_name_kana',
				'operator_first_name','operator_first_name_kana','operator_email','operator_phone_number_before','operator_phone_number_middle','operator_phone_number_after',
				'support_operator_email','support_operator_phone_number_before','support_operator_phone_number_middle','support_operator_phone_number_after',
            )
			if(merchantInfoData.merchant_type === '1'){
				checkData.push(
					'merchant_name','merchant_name_kana','merchant_corporate_number','representative_last_name','representative_first_name','representative_last_name_kana',
					'representative_first_name_kana','representative_sex','representative_birthday_CE','representative_birthday_month','representative_birthday_day',
				)
			} else if (merchantInfoData.merchant_type === '2'){
				checkData.push(
					'identity_doc_images','merchant_last_name','merchant_first_name','merchant_last_name_kana','merchant_first_name_kana','representative_sex',
					'representative_birthday_CE','representative_birthday_month','representative_birthday_day'
				)
			}
			if(economicInfoData.cvs.system_setting.select_flag === true){
				checkData.push('merchant_website_url')
			}
		}
		if(devision === 'bank_account'){
			checkData.push(
				'passbook_images','bank_account_bank_name','bank_account_bank_branch','bank_account_category','bank_account_number','bank_account_name',
			)
		}
		if(devision === 'shop'){
			checkData.push(
				'shop_name','shop_name_kana','shop_name_alphabet','shop_website_url','shop_addition_site_status_code','shop_addition_conduct_start_yyyy','shop_addition_conduct_start_mm',
				'shop_addition_product_content_code','shop_addition_major_content_code','shop_addition_medium_content_code','shop_addition_product_content_freetext',
				'shop_addition_available_product_flag','shop_addition_non_infringing_product_presence_absence_flag','shop_addition_non_administrative_penalties_five_year_flag',
				'shop_addition_non_losing_judgment_flag',
			)
			if(agentMasterInfoData.account_id_flag){
				checkData.push('account_id')
			}
			if(shopInfoData.shop_addition_site_status_code === 0){
				checkData.push('shop_addition_site_status_method')
				if(shopInfoData.shop_addition_site_status_method === 0){
					checkData.push('shop_addition_business_deal_url','shop_addition_shop_website_check_url')
				}else if(shopInfoData.shop_addition_site_status_method === 1){
					checkData.push('examination_document')
				}
			}
			if(economicInfoData.cvs.system_setting.select_flag){
				checkData.push('shop_cvs_website_name','shop_cvs_website_name_kana','shop_addition_price_range_min','shop_addition_price_range_max','shop_addition_price_validate')
			}
			if(economicInfoData.atobarai.system_setting.select_flag){
				checkData.push('shop_atobarai_customer_notice_store_name')
			}
			if(ObjToDevide.antique_flag){
				checkData.push('shop_addition_antique_flag',)
				if(shopInfoData.shop_addition_antique_flag){
					checkData.push('antique',)
				}
			}
			if(ObjToDevide.subscription_flag){
				checkData.push('shop_addition_subscription_flag',)
				if(shopInfoData.shop_addition_subscription_flag){
					checkData.push('shop_addition_subscription_code')
				}
			}
			if(ObjToDevide.purchase_points_flag){
				checkData.push('shop_addition_point_purchase_flag',)
			}
			if(ObjToDevide.prepaid_continuous_flag){
				checkData.push('shop_addition_prepaid_flag',)
			}
			if(ObjToDevide.reserve_item_flag){
				checkData.push('shop_addition_reserved_product_flag',)
				if(shopInfoData.shop_addition_reserved_product_flag){
					checkData.push('shop_addition_reservation_period_flag')
				}
			}
			if(ObjToDevide.estimate_flag){
				checkData.push('shop_addition_estimated_product_flag',)
			}
			if(ObjToDevide.rental_flag){
				checkData.push('shop_addition_rental_flag')
				if(shopInfoData.shop_addition_rental_flag){
					//shop_addition_rental_product_urlはAPIHooksのみ（EntrySideHooksには追加なし）
					checkData.push('shop_addition_rental_validate','shop_addition_rental_product_url')
				}
			}
			if(ObjToDevide.online_local_flag){
				checkData.push('shop_addition_offer_place')
			}
			if(ObjToDevide.btob_flag){
				checkData.push('shop_addition_btob_code',)
			}
			if(ObjToDevide.real_store_flag && merchantInfoData.merchant_type === "2"){
				checkData.push('shop_addition_physical_store_flag')
				if(shopInfoData.shop_addition_physical_store_flag){
					checkData.push('shop_addition_physical_store_url_flag')
					if(shopInfoData.shop_addition_physical_store_url_flag){
						checkData.push('shop_addition_physical_store_url')
					}
				}
			}
			if(ObjToDevide.license_flag){
				checkData.push('license')
			}
		}

		checkData.forEach((key) => {
			if(validateData[key]!.message !== '') validateFlag = true
		})
		
		if(validateFlag) return false
		return true
	}

	//バリデーション成功判定(おちゃ定期)
	const ocnkTeikiValidateSuccessJudge = <T,>(validateData: validateMessageMerchantInsertDataType | validateMessageBankInsertDataType | validateMessageShopInsertDataType, page: T) => {
		let validateFlag =false
		const devision = page !== null ? page : path
		//対象外のバリデーションを無視
		let checkData: string[] = [];
		const merchantIpDeleteMerchantData = [
			'merchant_phone_number_before','merchant_phone_number_middle','merchant_phone_number_after','merchant_register_post_code3','merchant_register_post_code4',
			'merchant_address_prefectures','merchant_address_municipality','merchant_address_streetbunch',
			'merchant_address_municipality_kana','merchant_address_streetbunch_kana','merchant_annual_business','merchant_shop_category',
			'support_operator_email','support_operator_phone_number_before','support_operator_phone_number_middle','support_operator_phone_number_after','identity_doc_images'
		]
		const personalIpDeleteMerchantData = [
			'merchant_phone_number_before','merchant_phone_number_middle','merchant_phone_number_after','merchant_register_post_code3','merchant_register_post_code4',
			'merchant_address_prefectures','merchant_address_municipality','merchant_address_streetbunch',
			'merchant_address_municipality_kana','merchant_address_streetbunch_kana','merchant_annual_business','merchant_shop_category',
			'representative_sex','representative_birthday_CE','representative_birthday_month','representative_birthday_day',
			'support_operator_email','support_operator_phone_number_before','support_operator_phone_number_middle','support_operator_phone_number_after','identity_doc_images'
		]
		const ipDeleteBankData = [
			'passbook_images','bank_account_bank_name','bank_account_bank_branch','bank_account_category','bank_account_number','bank_account_name',
		]
		const ipDeleteShopData = [
			'shop_name','shop_name_kana','shop_name_alphabet','shop_addition_physical_store_flag','shop_addition_physical_store_url_flag','shop_addition_physical_store_url',
		]

		if(devision === 'merchant'){
			checkData.push(
                'merchant_type','merchant_phone_number_before','merchant_phone_number_middle','merchant_phone_number_after','merchant_register_post_code3','merchant_register_post_code4',
				'merchant_address_prefectures','merchant_address_municipality','merchant_address_municipality_kana','merchant_address_streetbunch','merchant_address_streetbunch_kana',
				'merchant_address_building','merchant_address_building_kana','merchant_annual_business','merchant_shop_category','operator_last_name','operator_last_name_kana',
				'operator_first_name','operator_first_name_kana','operator_email','operator_phone_number_before','operator_phone_number_middle','operator_phone_number_after',
				'support_operator_email','support_operator_phone_number_before','support_operator_phone_number_middle','support_operator_phone_number_after',
            )
			if(merchantInfoData.merchant_type === '1'){
				checkData.push(
					'merchant_name','merchant_name_kana','merchant_corporate_number','representative_last_name','representative_first_name','representative_last_name_kana',
					'representative_first_name_kana','representative_sex','representative_birthday_CE','representative_birthday_month','representative_birthday_day',
				)
			} else if (merchantInfoData.merchant_type === '2'){
				checkData.push(
					'identity_doc_images','merchant_last_name','merchant_first_name','merchant_last_name_kana','merchant_first_name_kana','representative_sex',
					'representative_birthday_CE','representative_birthday_month','representative_birthday_day'
				)
			}
			if(economicInfoData.cvs.system_setting.select_flag === true){
				checkData.push('merchant_website_url')
			}
		}
		if(devision === 'bank_account'){
			checkData.push(
				'passbook_images','bank_account_bank_name','bank_account_bank_branch','bank_account_category','bank_account_number','bank_account_name',
			)
		}
		if(devision === 'shop'){
			checkData.push(
				'shop_name','shop_name_kana','shop_name_alphabet','shop_website_url',
				'shop_addition_product_content_code','shop_addition_major_content_code','shop_addition_medium_content_code','shop_addition_product_content_freetext',
				'shop_addition_available_product_flag','shop_addition_non_infringing_product_presence_absence_flag','shop_addition_non_administrative_penalties_five_year_flag',
				'shop_addition_non_losing_judgment_flag',
			)
			if(shopInfoData.shop_addition_site_status === 0){
				checkData.push('shop_addition_site_status_method')
				if(shopInfoData.shop_addition_site_status_method === 0){
					checkData.push('shop_addition_business_deal_url','shop_addition_shop_website_check_url')
				}else if(shopInfoData.shop_addition_site_status_method === 1){
					checkData.push('examination_document')
				}
			}
			if(ObjToDevide.antique_flag){
				checkData.push('shop_addition_antique_flag',)
				if(shopInfoData.shop_addition_antique_flag){
					checkData.push('antique',)
				}
			}
			if(ObjToDevide.subscription_flag){
				checkData.push('shop_addition_subscription_flag',)
				if(shopInfoData.shop_addition_subscription_flag){
					checkData.push('shop_addition_subscription_code')
				}
			}
			if(ObjToDevide.purchase_points_flag){
				checkData.push('shop_addition_point_purchase_flag',)
			}
			if(ObjToDevide.prepaid_continuous_flag){
				checkData.push('shop_addition_prepaid_flag',)
			}
			if(ObjToDevide.reserve_item_flag){
				checkData.push('shop_addition_reserved_product_flag',)
				if(shopInfoData.shop_addition_reserved_product_flag){
					checkData.push('shop_addition_reservation_period_flag')
				}
			}
			if(ObjToDevide.estimate_flag){
				checkData.push('shop_addition_estimated_product_flag',)
			}
			if(ObjToDevide.rental_flag){
				checkData.push('shop_addition_rental_flag')
				if(shopInfoData.shop_addition_rental_flag){
					//shop_addition_rental_product_urlはAPIHooksのみ（EntrySideHooksには追加なし）
					checkData.push('shop_addition_rental_validate','shop_addition_rental_product_url')
				}
			}
			if(ObjToDevide.online_local_flag){
				checkData.push('shop_addition_offer_place')
			}
			if(ObjToDevide.btob_flag){
				checkData.push('shop_addition_btob_code',)
			}
			if(ObjToDevide.license_flag){
				checkData.push('license')
			}
		}
		if(userInfoData.ip_flag){
			if(merchantInfoData.merchant_type === '1'){
				checkData = checkData.filter((key)=>{
					return !merchantIpDeleteMerchantData.includes(key)
				})
			} else if(merchantInfoData.merchant_type === '2'){
				checkData = checkData.filter((key)=>{
					return !personalIpDeleteMerchantData.includes(key)
				})
			}
			checkData = checkData.filter((key)=>{
				return !ipDeleteBankData.includes(key)
			})
			checkData = checkData.filter((key)=>{
				return !ipDeleteShopData.includes(key)
			})
		}
		checkData.forEach((key) => {
			if(validateData[key]!.message !== '') validateFlag = true
		})
		if(validateFlag) return false
		return true
	}

	//申込情報項目の先頭のスペースを削除する関数(stringの場合分けで型ガードできなかったためanyを配置)
	const topSpaceDeleteHandler = <T extends {[key: string]: any}>(data: T) => {
		let newObj: {[key: string]:T} = {}
		for(let item in data){
			let newData = data[item]
			if(typeof(data[item]) === "string" && data[item].indexOf(' ') === 0){
				newData = data[item].replace(' ','')
			} else {
				newObj[item] = newData
				continue
			}
			newObj[item] = newData
		}
		return newObj
	}

	//空欄バリデート
	const blankValidator = <T extends string | null>(validateData: validateMessageMerchantInsertDataType | validateMessageBankInsertDataType | validateMessageShopInsertDataType, page: T) => {
		const devision = page !== null ? page : path
		const recoilByPages = devideRecoilByPages(devision);
		const { blankData } = blankValidate(recoilByPages, validateData,devision)
		dispatch({
			type: `set-validate-${devision}`,
			payload: {
				...blankData
			}
		})
		return { blankData }
	}
	//項目バリデート
	const pageValidator = <T,>(page: T) => {
		const devision = page !== null ? page : path
		switch (devision) {
			case 'merchant':
				return merchantValidator();
			case 'bank_account':
				return bankAccountValidator();
			case 'shop':
				return shopValidator();
			default:
				return {}
		}
	}

	// おちゃ定期加盟店情報データ成形
	const ocnkTeikiMerchantInsert = useMemo(()=>{
		const result = {
			merchant_annual_business: null
		}
		return result
	},[])

	// おちゃ定期加盟店情報データ成形（個人用）
	const ocnkTeikiPersonalMerchantInsert = useMemo(()=>{
		const result = {
			representative_sex: null,
			representative_birthday: null
		}
		return result
	},[])

	// おちゃ定期口座情報データ成形
	const ocnkTeikibankInsert = useMemo(()=>{
		const result = {
			bank_account_category: null
		}
		return result
	},[])

	// 申し込みショップデータ成型
	const shopInsert = useMemo(()=>{
		const shopNameAlphabet = shopInfoData.shop_name_alphabet !== null ? alphabetHalfWidthChangeHandler(shopInfoData.shop_name_alphabet) : null
		const deivdeItemObj = setReviewContentsNullHandler(shopInfoData,ObjToDevide,merchantSessionData)
		const shopNameAtobarai = economicInfoData.atobarai.system_setting.select_flag ? (shopInfoData.shop_atobarai_customer_notice_store_name !== "" ? shopInfoData.shop_atobarai_customer_notice_store_name.replace(/‐/g,'－') : "") : ""
		const shopCvsWebsiteName = economicInfoData.cvs.system_setting.select_flag ? shopInfoData.shop_cvs_website_name : ""
		const shopCvsWebsiteNameKana = economicInfoData.cvs.system_setting.select_flag ? shopInfoData.shop_cvs_website_name_kana : ""
		const shopAdditionPriceRangeMax = economicInfoData.cvs.system_setting.select_flag ? shopInfoData.shop_addition_price_range_max : null
		const shopAdditionPriceRangeMin = economicInfoData.cvs.system_setting.select_flag ? shopInfoData.shop_addition_price_range_min : null
		const shopInsert = {
			...shopInfoData,
			...deivdeItemObj,
			shop_name_alphabet:shopNameAlphabet,
			shop_atobarai_customer_notice_store_name:shopNameAtobarai,
			shop_cvs_website_name:shopCvsWebsiteName,
			shop_cvs_website_name_kana:shopCvsWebsiteNameKana,
			shop_addition_price_range_max:shopAdditionPriceRangeMax,
			shop_addition_price_range_min:shopAdditionPriceRangeMin,
		}

		return shopInsert
	},[shopInfoData,setReviewContentsNullHandler,alphabetHalfWidthChangeHandler,merchantSessionData,ObjToDevide,economicInfoData])

	// おちゃ定期申し込みショップデータ成型
	const ocnkTeikiShopInsert = useMemo(()=>{
		const shopNameAlphabet = shopInfoData.shop_name_alphabet !== null ? alphabetHalfWidthChangeHandler(shopInfoData.shop_name_alphabet) : null
		const deivdeItemObj = setReviewContentsNullHandler(shopInfoData,ObjToDevide,merchantSessionData)
		const shopInsert = {
			...shopInfoData,
			...deivdeItemObj,
			shop_name_alphabet:shopNameAlphabet,
		}

		return shopInsert
	},[shopInfoData,setReviewContentsNullHandler,alphabetHalfWidthChangeHandler,merchantSessionData,ObjToDevide])

	//申し込みAPI送信用データをまとめる
	const setMainData = () => {
		let mainData = {
			...userInfoData,	
			status:state.user_status.status,
		};
		switch(path){
			case 'merchant':
				let preInsertMerchantData = {
					...merchantInsert,
					...representativeInsert,
					...operationInfoData,
				}
				if(agentMasterLists.ocnk_teiki.includes(userInfoData.agent_master_id) && userInfoData.ip_flag){
					preInsertMerchantData = {
						...preInsertMerchantData,
						...ocnkTeikiMerchantInsert,
					}
				}
				if(agentMasterLists.ocnk_teiki.includes(userInfoData.agent_master_id) && userInfoData.ip_flag && merchantInfoData.merchant_type === '2'){
					preInsertMerchantData = {
						...preInsertMerchantData,
						...ocnkTeikiMerchantInsert,
						...ocnkTeikiPersonalMerchantInsert,
					}
				}
				let insertMerchantData = topSpaceDeleteHandler<initMerchantSessionDataType>(preInsertMerchantData)
				mainData = {
					...mainData,
					merchant:{
						merchant:insertMerchantData
					},
				}
				break;
			case 'bank_account':
				let preInsertBankData = bankAccountInfoData
				if(agentMasterLists.ocnk_teiki.includes(userInfoData.agent_master_id) && userInfoData.ip_flag){
					//IPフラグが立っている時、IPcodeから情報を取得してセット
					preInsertBankData = {
						...preInsertBankData,
						...ocnkTeikibankInsert
					}
				}
				let insertBankData = topSpaceDeleteHandler<initBankAccountSessionDataType>(preInsertBankData)
				mainData = {
					...mainData,
					merchant:{
						bank:insertBankData
					},
				}
				
				break;
			case 'shop':
				let preInsertShopData = initShopSessionData
				if(agentMasterLists.ocnk_teiki.includes(userInfoData.agent_master_id) && userInfoData.ip_flag){
					preInsertShopData = {
						...preInsertShopData,
						...ocnkTeikiShopInsert
					}
				} else {
					preInsertShopData = {
						...preInsertShopData,
						...shopInsert
					}
				}
				let insertShopData = topSpaceDeleteHandler<initShopSessionDataType>(preInsertShopData)
				mainData = {
					...mainData,
					shop:insertShopData,
				}
				break;
			default: return mainData
		}

		return mainData
	}
	// 加盟店レスポンスデータをセッションにセットするために加工
	const merchantResponseDataMolder = (responseData: entryInfoPostResponseDataType) => {
		let merchantObj = {}
		let representativeObj = {}
		let operatorObj = {}
		let representative_birthday_CE = ""
		let representative_birthday_month = ""
		let representative_birthday_day = ""
		for(let merchantItem in responseData.merchant){
			if(merchantItem.split('_')[0] === "merchant"){
				merchantObj = {...merchantObj,[merchantItem]:responseData.merchant[merchantItem]}
			}
			if(merchantItem.split('_')[0] === "representative"){
				if(merchantItem === "representative_birthday" && responseData.merchant.representative_birthday !== "" && responseData.merchant.representative_birthday !== null){
					representative_birthday_CE = responseData.merchant.representative_birthday.split('-')[0]
					representative_birthday_month = responseData.merchant.representative_birthday.split('-')[1]
					representative_birthday_day = responseData.merchant.representative_birthday.split('-')[2]
				}
				representativeObj = {...representativeObj,[merchantItem]:responseData.merchant[merchantItem], representative_birthday_CE:representative_birthday_CE,representative_birthday_month:representative_birthday_month, representative_birthday_day:representative_birthday_day}
			}
			if(merchantItem.split('_')[0] === "operator" || merchantItem.split('_')[0] === "support"){
				operatorObj = {...operatorObj,[merchantItem]:responseData.merchant[merchantItem]}
			}
		}
		return {merchantObj, representativeObj, operatorObj} 
	}

	// 銀行レスポンスデータをセッションにセットするために加工
	const bankResponseDataMolder = (responseData: entryInfoPostResponseDataType) => {
		let bankObj = {}
		for(let merchantItem in responseData.merchant){
			if(merchantItem.split('_')[0] === "bank"){
				bankObj = {...bankObj,[merchantItem]:responseData.merchant[merchantItem]}
			}
		}
		return {bankObj}
	}

	// 申込レスポンスデータを加盟店、銀行、ショップ情報にセット
	const setResponseDataHandler = (response: initApiModelType<entryInfoPostResponseDataType>) => {		
		const responseData = response.response_data

		// 型ガード用条件式
		if(responseData === undefined) return
		
		switch(path){
			case 'merchant':
				const {merchantObj, representativeObj, operatorObj} = merchantResponseDataMolder(responseData)
				setMerchantInfoData({...merchantInfoData,...merchantObj})
				setRepresentativeInfoData({...representativeInfoData,...representativeObj})
				setOperationInfoData({...operationInfoData,...operatorObj})
				dispatch({
					type: 'set-entry-data',
					payload: {
						merchant: {...merchantInfoData,...merchantObj},
						representative: {...representativeInfoData,...representativeObj},
						operation: {...operationInfoData,...operatorObj}
					}
				})
				break;
			case 'bank_account':
				const {bankObj} = bankResponseDataMolder(responseData)
				setBankAccountInfoData({...bankAccountInfoData,...bankObj})
				dispatch({
					type: 'set-entry-bank-account',
					payload: {...bankAccountInfoData,...bankObj}
				})
				break;
			case 'shop':
				setShopInfoData({...shopInfoData,...responseData.shop})
				dispatch({
					type: 'set-entry-shop',
					payload: {...shopInfoData,...responseData.shop}
				})
				break;
			default: return
		}
		const insertUserData = {...userInfoData}
		if(responseData.entry_id !== undefined) insertUserData.entry_id = responseData.entry_id
		setUserInfoData(insertUserData)
	}

	//申込必要項目バリデーション 
	const validateSuccessJudgeByAgent = <T,>(blankData: validateMessageMerchantInsertDataType | validateMessageBankInsertDataType | validateMessageShopInsertDataType, page: T, agentId: number) => {
		switch(agentId){
			case 1:
				return validateSuccessJudge<T>(blankData, page)
			case 2:
			case 3:
			case 4:
			case 5:
			case 7:
				return ocnkTeikiValidateSuccessJudge<T>(blankData, page)
			default : 
				return false
		}
	}

	// バリデーションをかけて、結果をモーダルメッセージを表示
	const validateAndSetMessageHandler = (): {merchant:boolean, bank_account:boolean, shop:boolean} => {
		const pagesArray = ["merchant", "bank_account", "shop"]
		let validateSuccessObj: {
			[key: string]: boolean,
			merchant:boolean,
			bank_account:boolean,
			shop:boolean,
		} = {
			merchant:false,
			bank_account:false,
			shop:false,
		};
		//全ページにバリデーション
		pagesArray.forEach((page)=>{
			let validateData = pageValidator<string>(page);
			const { blankData } = blankValidator<string>(validateData, page);
			if (validateSuccessJudgeByAgent<string>(blankData, page, userInfoData.agent_master_id)) validateSuccessObj = {...validateSuccessObj, [page]:true}
		})

		//エラーメッセージ表示
		let errMsg = ["に入力エラーがあります。"];
		for(let key in validateSuccessObj){
			if(!validateSuccessObj[key]){
				switch(key){
					case 'shop':
						errMsg.unshift('ショップ情報')
						break;
					case 'bank_account':
						errMsg.unshift('口座情報')
						break;
					case 'merchant':
						errMsg.unshift('お客様情報')
						break;
					default:
				}
			} 
		}
		let count = errMsg.length
		if(count > 1){
			if(count === 3) errMsg.splice(1,0,'・')
			if(count === 4){ 
				errMsg.splice(1,0,'・')
				errMsg.splice(3,0,'・')
			}
			let insertErrMsg = errMsg.join('')
			//コンテキストに保存
			dispatch({
				type : 'set-modal-flag',
				payload : {
						path : path,
						type : 'error',
						flag : true,
						message :insertErrMsg
					}
			})
		}
		return validateSuccessObj
	}

	//登録時のモーダル表示ハンドラ
	const setModalFlagHandler = (messageType: string,path: string,type: string) => {
		let message = ""
		if(messageType === 'entry'){
			if(path === 'merchant' && type === 'success') message = "お客様情報を保存しました"
			if(path === 'merchant' && type === 'error') message = "お客様情報の保存に失敗しました"
			if(path === 'bank_account' && type === 'success') message = "口座情報を保存しました"
			if(path === 'bank_account' && type === 'error') message = "口座情報の保存に失敗しました"
			if(path === 'shop' && type === 'success') message = "ショップ情報を保存しました"
			if(path === 'shop' && type === 'error') message = "ショップ情報の保存に失敗しました"
		} else if(messageType === 'validate'){
			if(path === 'merchant') message = 'お客様情報に入力エラーがあります。'
			if(path === 'bank_account') message = '口座情報に入力エラーがあります。'
			if(path === 'shop') message = 'ショップ情報に入力エラーがあります。'
		}
		dispatch({
			type : 'set-modal-flag',
			payload : {
					path : path,
					type : type,
					flag : true,
					message :message
				}
		})
	}
	//申し込み完了チェックマーク
	const setEntryCheckHandler = (path: string,status: string) => {
		if(path === "shop"){
			setEntryCheckInfoData({...entryCheckInfoData, merchant:status, bank_account:status, shop:status})
		} else {
			setEntryCheckInfoData({...entryCheckInfoData, [path]:status})
		}
	}

	//エントリデータ一時保存
	const EntryTemporaryPostMethod = async () => {
		let response: initApiModelType<entryInfoPostResponseDataType> = initApiResponse
		//全体バリデーションエラーコメント消去
		dispatch({
			type: 'set-entry-validate',
			payload: {
				entryValidateMessage:""
			}
		})
		//一時保存用バリデーションをかける
		let itemValidateData = pageValidator<null>(null);
		//バリデーションの判定
		if (!validateSuccessJudgeByAgent<null>(itemValidateData, null, userInfoData.agent_master_id)){
			return setModalFlagHandler('validate',path,'error')
		} 
		const mainData = setMainData();
		//ローディング画面開始
        setIsLoading(true, dispatch)
		response = await PostMethod<entryInfoPostRequestDataType, entryInfoPostResponseDataType>(mainData, '/entry_info')
		if(response.code !== 200){
			if(response.code === 401){
				logoutAbnormalFunction(response,dispatch,navigateByAgent,state.agentPath)
			} else {
				setModalFlagHandler('entry',path,'error')
			}
			//ローディング画面を非表示
			return setIsLoading(false,dispatch)	
		} 
		setModalFlagHandler('entry',path,'success')
		setResponseDataHandler(response)
		//ローディング画面を非表示
        setIsLoading(false,dispatch)
	}

	const navigateToPath = (path: string) => {
		let navigateTo = ""
		switch (path) {
			case 'merchant':
				navigateTo = '/entry/bank_account'
				break;
			case 'bank_account':
				navigateTo = '/entry/shop'
				break;
			case 'shop':
				//遷移先は確認画面
				navigateTo = '/entry_confirm'
				break;
			default:
				break
		}
		if(location.state && location.state.confirm_flag) navigateTo = '/entry_confirm'
		const navigateState = navigateTo.substring(1)
		return {navigateTo, navigateState}
	}

	const ocnkTeikiNavigateToPath = (path: string) => {
		let navigateTo = ""
		const navigatePath = redirect ? '/redirect' : '/entry'
		const confirmPath = redirect ? '/redirect_password' : '/entry_confirm'
		const redirectPath = redirect ? '/redirect_confirm' : '/entry_confirm'
		const merchantPath = '/bank_account'
		switch (path) {
			case 'merchant':
				navigateTo = navigatePath+merchantPath
				break;
			case 'bank_account':
				navigateTo = navigatePath+'/shop'
				break;
			case 'shop':
				//遷移先は確認画面
				navigateTo = confirmPath
				break;
			default:
				break
		}
		if(location.state && location.state.confirm_flag) navigateTo = redirectPath
		const navigateState = navigateTo.substring(1)
		return {navigateTo, navigateState}
	}
	const changeNavigateToPath = () => {
		let navigateTo = ''
		switch(state.agentPath){
			// おちゃ定期用
			case '/ocnk-teiki': 
				navigateTo = ocnkTeikiNavigateToPath(path).navigateTo
				break
			// おちゃ上位用
			default : 
				navigateTo = navigateToPath(path).navigateTo
		}
		return navigateTo
	}

	//エントリデータ保存
	const EntryPostMethod = async () => {
		let response: initApiModelType<entryInfoPostResponseDataType> = initApiResponse
		//全体バリデーションエラーコメント消去
		dispatch({
			type: 'set-entry-validate',
			payload: {
				entryValidateMessage:""
			}
		})
		// ショップ画面の時、全画面のバリデーションをかけ,それ以外は該当画面のみバリデーションを実行
		if(path === 'shop'){
			let validateSuccessObj = validateAndSetMessageHandler()
			//バリデーション判定
			if(!validateSuccessObj.merchant || !validateSuccessObj.bank_account || !validateSuccessObj.shop){
				return
			} 
		} else {
			//本保存用バリデーションをかける
			let validateData = pageValidator<null>(null);
			const { blankData } = blankValidator<null>(validateData, null);
			//バリデーションの判定
			if (!validateSuccessJudgeByAgent<null>(blankData, null, userInfoData.agent_master_id)){ 
				return setModalFlagHandler('validate',path,'error')
			}
		}
		const mainData = setMainData();

		//ローディング画面開始
        setIsLoading(true, dispatch)
		// API実行
		response = await PostMethod<entryInfoPostRequestDataType, entryInfoPostResponseDataType>(mainData, '/entry_info')
		if(response.code !== 200){
			if(response.code === 401){
				logoutAbnormalFunction(response,dispatch,navigateByAgent,state.agentPath)
			} else {
				setModalFlagHandler('entry',path,'error')
			}
			//ローディング画面を非表示
			return setIsLoading(false,dispatch)	
		} 
		//成功時、成功モーダルを出す
		setModalFlagHandler('entry',path,'success')
		//申し込み完了チェックマーク表示
		setEntryCheckHandler(path,'checked')
		//データをセットするハンドラ
		setResponseDataHandler(response)
		// 遷移先変更
		const navigateTo = changeNavigateToPath()
		
		//ローディング画面を非表示
        setIsLoading(false,dispatch)
		return navigateByAgent(navigateTo)
	}
	
	return { EntryPostMethod, EntryTemporaryPostMethod, fromConfirmFlag, path, ipFlag }

}
export default useEntryAPIHooks
