import { ReactNode } from 'react';
import { Box } from '../../../layouts/Box';
import { MessageBox } from '../../../style/DashboardStyle';

const DashboardMessageElement = ({
  mainMessage = <></>,
  step = 1
}: {
  mainMessage : ReactNode,
  step : number
}) => {

  return (
    <section>
      <Box contentsWidth>
        <h1 className='align_center'>Web申込システム</h1>
        <p className='align_center'>下記の手順に沿って必要な情報を入力の上、お申し込みください。</p>
      </Box>
      <Box mt_desktop={32} mt_mobile={16} contentsWidth>
        <MessageBox data-stepcount={step}>
          <div className='inner'>
            {mainMessage}
          </div>
        </MessageBox>
      </Box>
    </section>
  )
}

export default DashboardMessageElement