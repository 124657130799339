import { useMemo } from "react"
import styled from "styled-components"
import { FlexBox } from "../components/layouts/FlexBox"
import { colors } from "../css/variables/colors"
import { fonts } from "../css/variables/fonts"
import { ChangeTag } from "../components/ui-parts/ocnk/FinalAgreement/ChangeTag"
import { mixins } from "../css/variables/mixins"
import { feeArrayType, finalAgreementCompareDataType } from "../constance/typeModel"
import { finalAgreementDiffExtractionTablesResponseDataType } from "../constance/providerTypeModel"

const FeeNameStyle = styled.p`
    max-width:280px;
    font-size:${fonts.fontSizeRem('s')};
    word-break: break-all;
`
const FeeStyle =styled.p`
    white-space: nowrap;
`
const CancelWrapper = styled.div`
    background-color:${colors.themeColor('background_pink')};
    padding:5px;
`
const AddWrapper = styled.div`
    background-color:${colors.themeColor('background_input')};
    padding:5px;
`
const FeeSpan = styled.span`
    font-size: ${fonts.fontSizeRem('xl')};
`
const FeeP = styled.p`
    font-size: ${fonts.fontSizeRem('ms')};
    ${mixins.screenUnder()}{
        font-size: ${fonts.fontSizeRem('s')};
    }
`

const useFinalAgreementTableHooks = ({
    options,
    compareData,
    responseData,
    originFeeArrayHandler,
}: {
    options: feeArrayType[],
    compareData: finalAgreementCompareDataType,
    responseData: finalAgreementDiffExtractionTablesResponseDataType,
    originFeeArrayHandler:Function,
}) => {
    // オプション費用リスト
    const finalAgreementSystemOptionFee = useMemo(()=>{
        if(!options || !compareData || !responseData) return (<></>)
        const finalAgreementOptions = [...options] 
        const creditItems = responseData
        const creditItemsData = originFeeArrayHandler("credit")
        if("subscription" in creditItems && creditItems.subscription) finalAgreementOptions.push({name:creditItemsData.subscription.name, fee:creditItemsData.subscription.fee, key:"subscription"})
        if("threed_secure" in creditItems &&creditItems.threed_secure) finalAgreementOptions.push({name:creditItemsData.threed_secure.name, fee:creditItemsData.threed_secure.fee, key:"threed_secure"})
        if("cvv" in creditItems &&creditItems.cvv) finalAgreementOptions.push({name:creditItemsData.cvv.name, fee:creditItemsData.cvv.fee, key:"cvv"})
        if("div" in creditItems &&creditItems.div) finalAgreementOptions.push({name:creditItemsData.div.name, fee:creditItemsData.div.fee, key:"div"})
        const finalAgreementSystemOptionFee = finalAgreementOptions.map((value: feeArrayType,index: number)=>{
            let nonChangeFlag = !(value.key in compareData.credit) || !(compareData.credit[value.key])
            let changeFlag = value.key in compareData.credit && compareData.credit[value.key]
            return(
                <div key={'finalAgreement'+value.key+index}>
                    {/* 最終合意で変更がなかった場合 */}
                    {nonChangeFlag &&
                        <FlexBox spaceBetween gap={8} responsiveColumn>
                            <FeeNameStyle className="opacity">{value.name}</FeeNameStyle>
                            <FeeStyle className="opacity blue">￥<FeeSpan>{value.fee}</FeeSpan> /月</FeeStyle>
                        </FlexBox>
                    }
                    {/* 最終合意画面で変更有の場合 */}
                    {changeFlag && 
                        <>
                            {/* 変更後の値がfalseの場合 */}
                            {!(creditItems[value.key]) &&
                                <CancelWrapper>
                                    <ChangeTag afterFlag={true} changeDetail={'disabled'} />
                                    <FeeP className="red_text">{value.name.replace('利用料','')}はご利用いただけません</FeeP>
                                </CancelWrapper>
                            }
                            {/* 変更後の値がtrueの場合 */}
                            {creditItems[value.key] && 
                                <AddWrapper>
                                    <ChangeTag afterFlag={true} changeDetail={'usabled'} />
                                    <FlexBox spaceBetween gap={8} responsiveColumn>
                                        <FeeNameStyle>{value.name}</FeeNameStyle>
                                        <FeeStyle className="blue font-s">￥<FeeSpan>{value.fee}</FeeSpan> /月</FeeStyle>
                                    </FlexBox>
                                    <FeeP className="blue">新しく追加されました</FeeP>
                                </AddWrapper>
                            }
                        </>
                    }
                        
                    <hr className="mt0 mb8" />
                </div>
            )
        })
        return finalAgreementSystemOptionFee
    },[options,compareData,responseData,originFeeArrayHandler])

    return {finalAgreementSystemOptionFee}
}
export default useFinalAgreementTableHooks