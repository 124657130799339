import styled from 'styled-components'
import loadingBase from '../../../images/loading_base.svg';
import loadingBar from '../../../images/loading_bar.svg';

type loadingWrapType = {
  isLoading: boolean
}

const LoadingWrap = styled.div`
  position:fixed;
  z-index: 9999;
  top:0;
  left:0;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.25);
  display: ${(props: loadingWrapType) => props.isLoading?'block':'none'};
`;
const LoadingImage = styled.div`
  width: 50px;
  height: 50px;
  position: absolute;
  display: block;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  background: url(${loadingBase});
  &:after{
    content: '';
    position: absolute;
    width: 50px;
    height: 50px;
    background: url(${loadingBar});
    animation: loadingRotate 1s linear infinite;
    @keyframes loadingRotate {
      0%{
        transform: rotate(0deg);
      }
      100%{
        transform:rotate(360deg)
      }
    }
  }
`;

export const Loading = ({
  isLoading = false
}: {
  isLoading: boolean
}) => {
  return (
    <LoadingWrap isLoading={isLoading}>
      <LoadingImage />
    </LoadingWrap>
  );
};
