import { FormWrap } from "../../../components/layouts/FormWrap"
import { Button } from "../../../components/ui-parts/ocnk"
import systemErrorImg from "../../../images/systemError.svg"
import { ContentsWrapper } from "../../../components/layouts/ContentsWrapper"
import { useContext } from "react"
import { Context } from "../../../stores/Provider"
import { logoutAbnormalFunction } from "../../../function/logoutAbnormalFunction"
import useNavigateByAgent from "../../../hooks/useNavigateByAgentHooks"
import { Container } from "../../../components/layouts/Container"

const SystemError = () => {
    const {navigateByAgent} = useNavigateByAgent()
    const {state,dispatch} = useContext(Context)
    const response = {code:500, message: ""}
    return(
        <Container>
            <ContentsWrapper >
                <FormWrap center>
                    <img src={systemErrorImg} alt="systemErrorImg" />
                    <h3>システムエラーが発生しました</h3>
                    <div className="mb32">
                        <p className="font-m">ご迷惑をおかけしております。</p>
                        <p className="font-m">しばらく時間をおいてからアクセスしてください</p>
                    </div>
                    <Button variant='secondary' onClick={()=>logoutAbnormalFunction(response,dispatch,navigateByAgent,state.agentPath)}>ログイン画面へ</Button>
                </FormWrap>
            </ContentsWrapper>
        </Container>
    )
}

export default SystemError