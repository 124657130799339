import styled from 'styled-components'
import { colors } from '../../css/variables/colors';
import { fonts } from '../../css/variables/fonts';
import { mixins } from '../../css/variables/mixins';
import { ReactNode } from 'react';

type flexBoxStyleType = {
  inline?: boolean,
  gap?: number,
  mt?: number,
  align?: string,
  column?: boolean,
  sx?: string,
  responsiveColumn?: boolean,
  responsiveGap?: number,
  childJustify?: boolean,
  spaceBetween?: boolean,
  flexEnd: boolean
}
const FlexBoxStyle = styled.div<flexBoxStyleType>`
  display: ${props => props.inline ? 'inline-flex' : 'flex'};
  ${props => props.gap ?"gap:" + props.gap + "px;" : "" }
  margin-top: ${props => props.mt}px;
  align-items: ${props => props.align};
  flex-direction: ${props => props.column? "column" : false};
  ${props => props.sx ? props.sx : null};
  ${mixins.screenUnder()}{
    ${props => props.responsiveColumn ? "flex-direction: column;" : "" }
    gap:${props => props.responsiveGap}px;
  }
  &>*{
    ${props => props.childJustify ? 'flex:1' : null};
  }
  justify-content: ${props => props.spaceBetween ? 'space-between' : 'flex-start'};
  ${props => props.flexEnd ? "align-items: flex-end;" : ""} 
`
const Error = styled.span`
  display: block;
  color: ${colors.themeColor('text_error')};
  font-size: ${fonts.fontSizeRem('s')};
  margin-top: 4px;
`

export const FlexBox = ({
  inline= false,
  mt= 16,
  align= 'flex-start',
  gap= 16,
  responsiveGap= 16,
  childJustify= false,
  spaceBetween= false,
  sx= "",
  column= false,
  iserror = "",
  responsiveColumn= false,
  children = <></>,
  flexEnd = false
}: {
  children: ReactNode,
  inline?: boolean,
  mt?: number,
  align?: string,
  gap?: number,
  responsiveGap?: number,
  childJustify?: boolean,
  spaceBetween?: boolean,
  sx?: string,
  column?: boolean,
  iserror?: string,
  responsiveColumn?: boolean,
  flexEnd?: boolean
}) => (
  <>
    <FlexBoxStyle 
      inline={inline} 
      mt={mt} 
      align={align} 
      gap={gap} 
      responsiveGap={responsiveGap} 
      childJustify={childJustify} 
      spaceBetween={spaceBetween} 
      sx={sx} 
      column={column} 
      responsiveColumn={responsiveColumn} 
      flexEnd={flexEnd}
    >
      {children}
    </FlexBoxStyle>
    {iserror  !== '' ? (<Error>{iserror}</Error>) : (<></>)}
  </>
);
