import React, { useContext, useEffect, useState } from 'react';
import { EntryHead } from '../../../../ui-parts/ocnk/EntryHead';
import { FormTitle } from '../../../../ui-parts/ocnk/FormTitle';
import { Box } from '../../../../layouts/Box';
import { FlexBox } from '../../../../layouts/FlexBox';
import { RadioButtonGroup } from '../../../../ui-parts/ocnk/RadioButtonGroup';
import { TextField } from '../../../../ui-parts/ocnk/TextField';
import { SelectMenu } from '../../../../ui-parts/ocnk/SelectMenu';
import { Button } from '../../../../ui-parts/ocnk/Button';
import { FileInput } from '../../../../ui-parts/ocnk/FileInput';
import useEntryContentsMerchantHooks from '../../../../../hooks/useEntryContentsMerchantHooks';
import { toolChipList } from '../../../../../constance/constance';
import styled from 'styled-components';
import { fonts } from '../../../../../css/variables/fonts';
import { ErrorMessage } from '../../../../ui-parts/ocnk';
import AnotherTabLink from '../../../../ui-parts/ocnk/AnotherTabLink';
import useGTMHooks from '../../../../../hooks/useGTMHooks';
import useLimitDirectAccess from '../../../../../hooks/useLimitDirectAccess';
import useNavigateByAgent from '../../../../../hooks/useNavigateByAgentHooks';
import { Context } from '../../../../../stores/Provider';

const ToolChipExplain = styled.div`
    font-size:${fonts.fontSizeRem('xs')};
    margin-bottom:0px;
	white-space: pre-line;
    text-align:left;
`
const LabelP = styled.p`
	font-size: ${fonts.fontSizeRem('s')};
	margin-top:15px;
	margin-bottom:4px
`

export const EntryContentsMerchant = () => {
	const {state} = useContext(Context)
	const {navigateByAgent} = useNavigateByAgent()
	const {DirectAccessCheck} = useLimitDirectAccess(state.agentPath)
	const { merchantState, representativeState, operationState, economicInfoData, radioItemsContractor, radioItemsSex, selectOptionsPrefectures, selectOptionsCE, selectOptionsMonth,
		selectOptionsDay, industryOption, postCodeErrMsg, setRadioMerchantStateHandler, setMerchantStateHandler, setRadioRepresentativeStateHandler, setRepresentativeStateHandler, setOperationStateHandler,searchAddressHandler,
		validateErrMsgs
	} = useEntryContentsMerchantHooks(navigateByAgent);
	const { pushDataLayerHandler } = useGTMHooks()

	const [directFlag, setDirectFlag] = useState<boolean>(false)
    useEffect(()=>{
      pushDataLayerHandler()
	  setDirectFlag(DirectAccessCheck(state.agentPath+'/entry/merchant')) 
	  // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

	if(!directFlag) return(<></>)
	
	return (
		<section>
			<EntryHead icon='shop'>お客様情報</EntryHead>
			
			<FormTitle required >
				今回、お申し込みされるのはどちらになりますか？
			</FormTitle>

			<RadioButtonGroup name='merchant_type' radioitems={radioItemsContractor} state={merchantState.merchant_type} setState={setRadioMerchantStateHandler} iserror={validateErrMsgs.merchant_type.message} />
			<h2>ご契約者情報</h2>

			{merchantState.merchant_type === '1' &&
				<>
					<FormTitle required>法人番号</FormTitle>
					<ToolChipExplain>法人番号（13桁）がご不明の場合は、下記の国税庁のサイトにてお調べいただけます。</ToolChipExplain>
					<AnotherTabLink href={'https://www.houjin-bangou.nta.go.jp/'}>法人番号検索サイト</AnotherTabLink>
					<TextField id='merchant_corporate_number' placeholder='01234567890123' fullwidth defaultValue={merchantState.merchant_corporate_number} onChange={setMerchantStateHandler} iserror={validateErrMsgs.merchant_corporate_number.message} />
					
					<FormTitle required>法人名</FormTitle>
					<ToolChipExplain>{toolChipList.merchant_name_title}</ToolChipExplain>
					<TextField id='merchant_name' label='法人名' placeholder='株式会社ゼウス' fullwidth defaultValue={merchantState.merchant_name} onChange={setMerchantStateHandler} iserror={validateErrMsgs.merchant_name.message} explain={toolChipList.merchant_name}/>
					<TextField id='merchant_name_kana' label='法人名ｶﾅ' placeholder='ｾﾞｳｽ' fullwidth defaultValue={merchantState.merchant_name_kana} onChange={setMerchantStateHandler} iserror={validateErrMsgs.merchant_name_kana.message} explain={toolChipList.merchant_name_kana}/>
				</>
			}
			{merchantState.merchant_type === '2' &&
				<>
					<FormTitle required>ご契約者名</FormTitle>
					<ToolChipExplain>ご提出いただく本人確認資料に記載されている氏名をご入力ください。</ToolChipExplain>
					<ToolChipExplain>{toolChipList.name_attention}</ToolChipExplain>
					<FlexBox childJustify>
						<TextField id='merchant_last_name' label='姓' placeholder='山田' fullwidth defaultValue={merchantState.merchant_last_name} onChange={setMerchantStateHandler} iserror={validateErrMsgs.merchant_last_name.message} />
						<TextField id='merchant_first_name' label='名' placeholder='太朗' fullwidth defaultValue={merchantState.merchant_first_name} onChange={setMerchantStateHandler} iserror={validateErrMsgs.merchant_first_name.message} />
					</FlexBox>
					<FlexBox childJustify>
						<TextField id='merchant_last_name_kana' label='姓ｶﾅ' placeholder='ﾔﾏﾀﾞ' fullwidth defaultValue={merchantState.merchant_last_name_kana} onChange={setMerchantStateHandler} iserror={validateErrMsgs.merchant_last_name_kana.message} />
						<TextField id='merchant_first_name_kana' label='名ｶﾅ' placeholder='ﾀﾛｳ' fullwidth defaultValue={merchantState.merchant_first_name_kana} onChange={setMerchantStateHandler} iserror={validateErrMsgs.merchant_first_name_kana.message} />
					</FlexBox>
					<FormTitle required>契約者性別</FormTitle>
					<RadioButtonGroup name='representative_sex' radioitems={radioItemsSex} state={representativeState.representative_sex} setState={setRadioRepresentativeStateHandler} iserror={validateErrMsgs.representative_sex.message} />
					<FormTitle required >契約者生年月日</FormTitle>
					<FlexBox childJustify>
						<SelectMenu id='representative_birthday_CE' label='西暦' fullwidth options={selectOptionsCE} defaultValue={representativeState.representative_birthday_CE} onChange={setRepresentativeStateHandler} iserror={validateErrMsgs.representative_birthday_CE.message} />
						<SelectMenu id='representative_birthday_month' label='月' fullwidth options={selectOptionsMonth} defaultValue={representativeState.representative_birthday_month} onChange={setRepresentativeStateHandler} iserror={validateErrMsgs.representative_birthday_month.message} />
						<SelectMenu id='representative_birthday_day' label='日' fullwidth options={selectOptionsDay} defaultValue={representativeState.representative_birthday_day} onChange={setRepresentativeStateHandler} iserror={validateErrMsgs.representative_birthday_day.message} />
					</FlexBox>
				</>
			}

			<FormTitle required>電話番号</FormTitle>
			<ToolChipExplain>{'固定電話番号をご登録ください。\n※固定電話番号がない場合は携帯電話番号可。フリーダイヤル不可。'}</ToolChipExplain>
			<FlexBox>
				<TextField id='merchant_phone_number_before' placeholder='000' defaultValue={merchantState.merchant_phone_number_before} width={80} onChange={setMerchantStateHandler} iserror={validateErrMsgs.merchant_phone_number_before.message} />
				<Box mt={26}>-</Box>
				<TextField id='merchant_phone_number_middle' placeholder='0000'  defaultValue={merchantState.merchant_phone_number_middle} width={80} onChange={setMerchantStateHandler} iserror={validateErrMsgs.merchant_phone_number_middle.message} />
				<Box mt={26}>-</Box>
				<TextField id='merchant_phone_number_after' placeholder='0000' defaultValue={merchantState.merchant_phone_number_after} width={80} onChange={setMerchantStateHandler} iserror={validateErrMsgs.merchant_phone_number_after.message} />
			</FlexBox>

			
			<FormTitle required>住所</FormTitle>
			{merchantState.merchant_type === '1' &&
				<ToolChipExplain>{toolChipList.merchant_address_corporate}</ToolChipExplain>
			}
			{merchantState.merchant_type === '2' &&
				<ToolChipExplain>{toolChipList.merchant_address_contractor}</ToolChipExplain>
			}
			<FlexBox>
				<FlexBox sx={ 'gap: 8px' }>
					<Box sx={ 'width: 80px' }>
						<TextField id='merchant_register_post_code3' placeholder='000' fullwidth defaultValue={merchantState.merchant_register_post_code3} onChange={setMerchantStateHandler} iserror={validateErrMsgs.merchant_register_post_code3.message} />
					</Box>
					<Box mt={26}>-</Box>
					<Box sx={ 'width: 90px' }>
						<TextField id='merchant_register_post_code4' placeholder='0000' fullwidth defaultValue={merchantState.merchant_register_post_code4} onChange={setMerchantStateHandler} iserror={validateErrMsgs.merchant_register_post_code4.message} />
					</Box>
				</FlexBox>
				
				<Box mt={16}>
					<Button variant='secoundary' onClick={searchAddressHandler}>住所検索</Button>
				</Box>
			</FlexBox>
			<ErrorMessage ApiErrMsg={postCodeErrMsg}/>

			<SelectMenu id='merchant_address_prefectures' label='都道府県' options={selectOptionsPrefectures} value={merchantState.merchant_address_prefectures} onChange={setMerchantStateHandler} iserror={validateErrMsgs.merchant_address_prefectures.message}/>
			<TextField id='merchant_address_municipality' label='市区町村' fullwidth value={merchantState.merchant_address_municipality} onChange={setMerchantStateHandler} iserror={validateErrMsgs.merchant_address_municipality.message}/>
			<TextField id='merchant_address_municipality_kana' label='市区町村ｶﾅ' fullwidth value={merchantState.merchant_address_municipality_kana} onChange={setMerchantStateHandler} iserror={validateErrMsgs.merchant_address_municipality_kana.message} explain={toolChipList.merchant_address_municiparity_kana} />
			<TextField id='merchant_address_streetbunch' label='町名・番地' fullwidth value={merchantState.merchant_address_streetbunch} onChange={setMerchantStateHandler} iserror={validateErrMsgs.merchant_address_streetbunch.message} explain={toolChipList.merchant_address_streetbunch} />
			<TextField id='merchant_address_streetbunch_kana' label='町名・番地ｶﾅ' fullwidth value={merchantState.merchant_address_streetbunch_kana} onChange={setMerchantStateHandler} iserror={validateErrMsgs.merchant_address_streetbunch_kana.message} explain={toolChipList.merchant_address_streetbunch_kana} />
			<TextField id='merchant_address_building' label='建物名・号室' fullwidth value={merchantState.merchant_address_building} onChange={setMerchantStateHandler} iserror={validateErrMsgs.merchant_address_building.message}  explain={toolChipList.merchant_address_building}/>
			<TextField id='merchant_address_building_kana' label='建物名・号室ｶﾅ' fullwidth value={merchantState.merchant_address_building_kana} onChange={setMerchantStateHandler} iserror={validateErrMsgs.merchant_address_building_kana.message} explain={toolChipList.merchant_address_building_kana} />

			{merchantState.merchant_type === '2' &&
				<>
					<FormTitle required >本人確認書類</FormTitle>
					<AnotherTabLink href={'https://www.cardservice.co.jp/info/guide/kakuninshorui.html'}>本人確認資料の種類</AnotherTabLink>
					<FileInput id='identity_doc_images' name='identity_doc_images' accept={['jpeg', 'png', 'pdf', 'jpg']} maxsize={30} multiple iserror={validateErrMsgs.identity_doc_images.message} page={"merchant"} />
				</>
			}
			{/* コンビニ選択時のみ表示(会社WEBサイトURL) */}
			{economicInfoData.cvs.system_setting.select_flag &&
				<>
					<FormTitle toolChip={toolChipList.merchant_website_url}>会社URL</FormTitle>
					<TextField id='merchant_website_url' placeholder='https://www.cardservice.co.jp/' fullwidth defaultValue={merchantState.merchant_website_url} onChange={setMerchantStateHandler} iserror={validateErrMsgs.merchant_website_url.message} explain={toolChipList.url_attention} />
				</>
			}

			<FormTitle toolChip={toolChipList.merchant_annual_business} toolChipWidth={290} required>年商</FormTitle>
			<TextField id='merchant_annual_business' placeholder='1000' defaultValue={merchantState.merchant_annual_business} onChange={setMerchantStateHandler} iserror={validateErrMsgs.merchant_annual_business.message} explain={toolChipList.merchant_annual_business_explain} maxWidth={220}/>&emsp;万円

			<FormTitle required >業種</FormTitle>
			<SelectMenu id='merchant_shop_category'options={industryOption} defaultValue={merchantState.merchant_shop_category} onChange={setMerchantStateHandler} iserror={validateErrMsgs.merchant_shop_category.message} />
			{merchantState.merchant_type === '1' &&
				<>
					<h2>代表者情報</h2>
					<FormTitle required toolChip={toolChipList.representative_name} toolChipWidth={370} responsiveToolChipPlacement={'center'}>代表者名</FormTitle>
					<ToolChipExplain>{toolChipList.name_attention}</ToolChipExplain>
					<FlexBox childJustify>
						<TextField id='representative_last_name' label='姓' placeholder='山田' fullwidth defaultValue={representativeState.representative_last_name} onChange={setRepresentativeStateHandler} iserror={validateErrMsgs.representative_last_name.message} />
						<TextField id='representative_first_name' label='名' placeholder='太朗' fullwidth defaultValue={representativeState.representative_first_name} onChange={setRepresentativeStateHandler} iserror={validateErrMsgs.representative_first_name.message} />
					</FlexBox>
					<FlexBox childJustify>
						<TextField id='representative_last_name_kana' label='姓ｶﾅ' placeholder='ﾔﾏﾀﾞ' fullwidth defaultValue={representativeState.representative_last_name_kana} onChange={setRepresentativeStateHandler} iserror={validateErrMsgs.representative_last_name_kana.message} />
						<TextField id='representative_first_name_kana' label='名ｶﾅ' placeholder='ﾀﾛｳ' fullwidth defaultValue={representativeState.representative_first_name_kana} onChange={setRepresentativeStateHandler} iserror={validateErrMsgs.representative_first_name_kana.message} />
					</FlexBox>

					<FormTitle required>代表者性別</FormTitle>
					<RadioButtonGroup name='representative_sex' radioitems={radioItemsSex} state={representativeState.representative_sex} setState={setRadioRepresentativeStateHandler} iserror={validateErrMsgs.representative_sex.message} />

					<FormTitle>代表者住所</FormTitle>
					<p className='mt16'>商業登記簿謄本に登記されている代表者様住所にて登録いたします。</p>

					<FormTitle required >生年月日</FormTitle>
					<FlexBox childJustify >
						<SelectMenu id='representative_birthday_CE' name='representative_birthday_CE' label='西暦' fullwidth options={selectOptionsCE} defaultValue={representativeState.representative_birthday_CE} onChange={setRepresentativeStateHandler} iserror={validateErrMsgs.representative_birthday_CE.message} />
						<SelectMenu id='representative_birthday_month' name='representative_birthday_month' label='月' fullwidth options={selectOptionsMonth} defaultValue={representativeState.representative_birthday_month} onChange={setRepresentativeStateHandler} iserror={validateErrMsgs.representative_birthday_month.message} />
						<SelectMenu id='representative_birthday_day' name='representative_birthday_day' label='日' fullwidth options={selectOptionsDay} defaultValue={representativeState.representative_birthday_day} onChange={setRepresentativeStateHandler} iserror={validateErrMsgs.representative_birthday_day.message} />
					</FlexBox>
				</>
			}

			<h2>担当者情報</h2>
			<FormTitle toolChip={toolChipList.operator_name} toolChipWidth={450} responsiveToolChipPlacement={'center'} required >運営担当者</FormTitle>
			<FlexBox childJustify>
				<TextField id='operator_last_name' label='姓' placeholder='山田' fullwidth defaultValue={operationState.operator_last_name} onChange={setOperationStateHandler} iserror={validateErrMsgs.operator_last_name.message} />
				<TextField id='operator_first_name' label='名' placeholder='太朗' fullwidth defaultValue={operationState.operator_first_name} onChange={setOperationStateHandler} iserror={validateErrMsgs.operator_first_name.message} />
			</FlexBox>
			<FlexBox childJustify>
				<TextField id='operator_last_name_kana' label='姓ｶﾅ' placeholder='ﾔﾏﾀﾞ' fullwidth defaultValue={operationState.operator_last_name_kana} onChange={setOperationStateHandler} iserror={validateErrMsgs.operator_last_name_kana.message} />
				<TextField id='operator_first_name_kana' label='名ｶﾅ' placeholder='ﾀﾛｳ' fullwidth defaultValue={operationState.operator_first_name_kana} onChange={setOperationStateHandler} iserror={validateErrMsgs.operator_first_name_kana.message} />
			</FlexBox>

			<TextField id='operator_email' label='運営担当者E-mail' placeholder='user@cardservice.co.jp' fullwidth defaultValue={operationState.operator_email} onChange={setOperationStateHandler} iserror={validateErrMsgs.operator_email.message} explain={toolChipList.operator_email}/>
			<LabelP>運営担当者電話番号</LabelP>
			<FlexBox>
				<TextField id='operator_phone_number_before' placeholder='000'  defaultValue={operationState.operator_phone_number_before} width={80} mt={0} onChange={setOperationStateHandler} iserror={validateErrMsgs.operator_phone_number_before.message} />
				<Box mt={10}>-</Box>
				<TextField id='operator_phone_number_middle' placeholder='0000'  defaultValue={operationState.operator_phone_number_middle} onChange={setOperationStateHandler} width={80} mt={0} iserror={validateErrMsgs.operator_phone_number_middle.message} />
				<Box mt={10}>-</Box>
				<TextField id='operator_phone_number_after' placeholder='0000'  defaultValue={operationState.operator_phone_number_after} onChange={setOperationStateHandler} width={80} mt={0} iserror={validateErrMsgs.operator_phone_number_after.message} />
			</FlexBox>
			
			<FormTitle toolChip={toolChipList.support_operator_contact} toolChipWidth={450} responsiveToolChipPlacement={'center'} required >ショップ連絡先</FormTitle>
			<TextField id='support_operator_email' label='ショップ連絡先E-mail' placeholder='user@cardservice.co.jp' fullwidth defaultValue={operationState.support_operator_email} onChange={setOperationStateHandler} iserror={validateErrMsgs.support_operator_email.message} explain={toolChipList.operator_email}/>

			<LabelP>ショップ連絡先電話番号</LabelP>
			<FlexBox>
				<TextField id='support_operator_phone_number_before' placeholder='000' defaultValue={operationState.support_operator_phone_number_before} mt={0} onChange={setOperationStateHandler} width={80} iserror={validateErrMsgs.support_operator_phone_number_before.message} />
				<Box mt={10}>-</Box>
				<TextField id='support_operator_phone_number_middle' placeholder='0000'  defaultValue={operationState.support_operator_phone_number_middle} mt={0} onChange={setOperationStateHandler} width={80} iserror={validateErrMsgs.support_operator_phone_number_middle.message} />
				<Box mt={10}>-</Box>
				<TextField id='support_operator_phone_number_after' placeholder='0000' defaultValue={operationState.support_operator_phone_number_after} mt={0} onChange={setOperationStateHandler} width={80} iserror={validateErrMsgs.support_operator_phone_number_after.message} />
			</FlexBox>

		</section>
	)
}

