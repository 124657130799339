import { useContext, useMemo, useState } from "react";
import styled from "styled-components"
import { colors } from "../css/variables/colors";
import { fonts } from "../css/variables/fonts";
import ImgPdf from '../images/PDF_32.png';
import { industryObj } from "../constance/constance";
import { getSession } from "../sessionStorage/sessionStorageMethod";
import { Context } from "../stores/Provider";

const FileItemStyle = styled.li`
  margin-top:6px ;
  position: relative;
  width: 100px;
  display: block;
`;
const ImgWrapStyle = styled.div`
  width: 100%;
  height:120px;
  display: flex;
  align-items: center;
  justify-content: center;
  background:#E6E6E6;
`;
const FilenameStyle = styled.span`
  display: block;
  word-wrap: break-word;
  font-size:${fonts.fontSizeRem('xxs')};
  color: ${colors.themeColor('text_lightgray')};
  margin-top: 4px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

const CvsFee = styled.p`
    margin-bottom:0px;
    white-space:nowrap;
`

const useEntryConfirmElementHooks = ({title, shopDevideData}:{title: string, shopDevideData?: any}) => {

    //セッションデータ抽出
    const agentMasterInfoData = getSession('agent_master')
    const merchantInfoData = getSession('merchant')
    const bankAccountInfoData = getSession('bank_account')
    const representativeInfoData = getSession('representative')
    const operationInfoData = getSession('operation')
    const economicInfoData = getSession('economic_condition')
    const fileInfoData = getSession('files')
    const shopInfoData = getSession('shop')
    const reviewContentsDivisionInfoData = getSession('review_contents_division')
    const economicConditionMasterInfoData = getSession('economic_condition_master')
    const systemSettingMasterInfoData = getSession('system_setting_master')
    const sumFeeInfoData = getSession('sum_fee')
    const userInfoData = getSession('user')
    const {state} = useContext(Context)
    const [passToggleFlag, setPassToggleFlag] = useState<boolean>(false)

    const demand_code = agentMasterInfoData.demand_code
    const economicCredit= economicInfoData.credit.system_setting
    const economicBank = economicInfoData.bank.system_setting
    const economicCVS = economicInfoData.cvs.system_setting
    const economicAtobarai = economicInfoData.atobarai.system_setting

    //ショップ情報の出し分け
    const ObjToDevide = useMemo(()=>{
       if(shopDevideData !== undefined) return shopDevideData
       return reviewContentsDivisionInfoData
    },[shopDevideData])

    //支払いサイトの文言設定
    const changePaymentMethodPhrase = (value: string) => {
        switch(value){
            case '1': return '月々クレジットカード払い'
            case '2': return '無料'
            case '3': return '年間一括銀行振り込み払い'
            default: break;
        }
    }

    //サブスクの期間文言表示
    const subscriptionCodePhrase = useMemo(()=>{
        switch(shopInfoData.shop_addition_subscription_code){
            case "1" : return '１ヵ月未満（毎週、月二回 等）'
            case "2" : return '１ヵ月毎'
            case "3" : return '３ヵ月毎'
            case "4" : return '６ヵ月毎'
            case "5" : return '１２ヵ月毎'
            case "6" : return '１３ヵ月毎'
            case "7" : return 'その他'
            default : return ''
        }
    },[shopInfoData.shop_addition_subscription_code])

    //商品コンテンツをコード値から文言に変更
    const productContentPhrase = useMemo(()=>{
        let result = ""
        const replaceMethod = (data: string) => {
            return data.replace('SELL_GOODS','物販').replace('SERVICE','役務').replace('DIGITAL_EMAIL','デジコン')
        }
        result = replaceMethod(shopInfoData.shop_addition_product_content_code)
        return result
    },[shopInfoData])

    //コンビニの手数料レンジ表示
    const cvsFeeArray = useMemo(()=>{
        const cvsFeeArray = [
            {range:"2,000", fee:economicConditionMasterInfoData.cvs.fee_range_2000},
            {range:"3,000", fee:economicConditionMasterInfoData.cvs.fee_range_3000},
            {range:"5,000", fee:economicConditionMasterInfoData.cvs.fee_range_5000},
            {range:"7,000", fee:economicConditionMasterInfoData.cvs.fee_range_7000},
            {range:"10,000", fee:economicConditionMasterInfoData.cvs.fee_range_10000},
            {range:"30,000", fee:economicConditionMasterInfoData.cvs.fee_range_30000},
            {range:"50,000", fee:economicConditionMasterInfoData.cvs.fee_range_50000},
            {range:"100,000", fee:economicConditionMasterInfoData.cvs.fee_range_100000},
            {range:"200,000", fee:economicConditionMasterInfoData.cvs.fee_range_200000},
            {range:"300,000", fee:economicConditionMasterInfoData.cvs.fee_range_300000},
        ]
        return cvsFeeArray
    },[economicConditionMasterInfoData])
        

    //コンビニ用取引手数料
    const cvsFee = useMemo(()=>{
        const cvsFee = cvsFeeArray.map((key:{range: string, fee: number},index: number)=>{
            return(
                <div className="cvs-settle-fee" key={index}>
                    <p className="normal-weight mb0">￥{key.range}円未満</p>
                    <CvsFee>￥{key.fee}</CvsFee>
                </div>
            )
        })
        return cvsFee
    },[cvsFeeArray])

    //経済条件のサービス出し分け
    const titleDiv = useMemo(()=>{
        let titleDiv = {
            merchant:false,
            bank:false,
            shop:false,
            economic:false,
            login: false
        }
        titleDiv = {...titleDiv, [title]:true}
        return titleDiv
    },[title])

    //業種文言表示
    const industryName = useMemo(()=>{
        return industryObj[merchantInfoData.merchant_shop_category]
    },[merchantInfoData.merchant_shop_category])

    //サイト状態文言表示
    const siteLabel = useMemo(()=>{
        let siteLabel = {code:"",method:""};
        if(shopInfoData.shop_addition_site_status_code === 0){
            siteLabel = {...siteLabel, code:"準備中"}
        }
        if(shopInfoData.shop_addition_site_status_code === 1){
            siteLabel = {...siteLabel, code:"オープン済み"}
        }
        if(shopInfoData.shop_addition_site_status_method === 0){
            siteLabel = {...siteLabel, method:"サンプルサイト・デモサイトによる審査"}
        }
        if(shopInfoData.shop_addition_site_status_method === 1){
            siteLabel = {...siteLabel, method:"資料による審査"}
        }
        return siteLabel
    },[shopInfoData.shop_addition_site_status_code,shopInfoData.shop_addition_site_status_method])

    //BtoB文言表示
    const btobLabel = useMemo(()=>{
        switch(shopInfoData.shop_addition_btob_code){
            case "1": return "なし"
            case "2": return "卸を一部含む"
            case "3": return "卸のみ"
            default : return ""
        } 
    },[shopInfoData.shop_addition_btob_code])

    //ファイル画像表示関数
    const fileImagesShowHandler = (fileName: string) => {
        const imgData = fileInfoData[fileName].map((file: {type: string, file_data: string, file_name_origin: string, file_url: string},index: number)=>(
                <FileItemStyle key={index}>
                    <ImgWrapStyle>
                        {file.type.match(/image/) ? (
                        <img
                            src={file.file_data ? file.file_data : file.file_url} alt={file.file_name_origin}
                        />
                        ) : (
                        <img src={ImgPdf} alt={file.file_name_origin} />
                        )}
                    </ImgWrapStyle>
                    <FilenameStyle>{file.file_name_origin}</FilenameStyle>
                </FileItemStyle>
        ))
        return imgData
    }
    // パスワードアスタリスク変換
    const passChangeToAst = useMemo(() => {
        let result = ""
        const passLength = state.user.web_entry_password.length
        for(let i=0;i<passLength;i++){
            result = result.concat('*')
        }
        return result
    },[state.user.web_entry_password])

    const passToggleHandler = () => {
        setPassToggleFlag(!passToggleFlag)
    }
    return {economicInfoData,economicConditionMasterInfoData,shopInfoData,merchantInfoData,representativeInfoData,operationInfoData,bankAccountInfoData,sumFeeInfoData,systemSettingMasterInfoData,demand_code,ObjToDevide,economicCredit,economicBank,economicCVS,economicAtobarai,industryName,changePaymentMethodPhrase,cvsFee,titleDiv,siteLabel,fileImagesShowHandler,productContentPhrase,fileInfoData,subscriptionCodePhrase,agentMasterInfoData,userInfoData,btobLabel,passChangeToAst,passToggleFlag,passToggleHandler,state}
}

export default useEntryConfirmElementHooks
