import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import styled from "styled-components"
import { useLocation } from "react-router-dom";
import { selectItemsPaymentMethod } from "../constance/constance";
import { setCreditFeeData, setCvsFeeData } from "../function/setFeeData";
import { getSession, setEconomicInfoData, setSumFeeInfoData  } from "../sessionStorage/sessionStorageMethod";
import { initEconomicDataForInsert, initEconomicSessionDataType, sumFeeDataForInsertType } from "../constance/typeModel";
const ToolChipDiv = styled.div`
    white-space: pre-line;
`;
const useEconomicSelectElementHooks = (navigateByAgent: Function) => {
    //セッションデータ抽出
    const agentMasterInfoData = getSession('agent_master')
    const economicInfoData = getSession('economic_condition')
    const economicConditionMasterInfoData = getSession('economic_condition_master')
    const systemSettingMasterInfoData = getSession('system_setting_master')
    const sumFeeInfoData = getSession('sum_fee')
    const economicDevidedInfoData = getSession('economic_devided')
    const location = useLocation()

    //確認画面からの遷移かを確認するフラグ
    const confirmFlag = (location.state !== null && location.state.confirm_flag !== null) ? location.state.confirm_flag : false
    const [economicState, setEconomicState] = useState(economicInfoData)
    const [sumFeeState, setSumFeeState] = useState(sumFeeInfoData)
    //ステイトとセッションに同時に保存
    //経済条件データセット
    const setEconomicDataHandler = (data: initEconomicDataForInsert) => {
        setEconomicInfoData({...economicState,...data})
        setEconomicState({...economicState,...data})
    }

    //合計金額
    const setSumFeeDataHandler = (data: sumFeeDataForInsertType) => {
        setSumFeeInfoData({...sumFeeState,...data})
        setSumFeeState({...sumFeeState,...data})
    }

    //画面上のみ作動するトグルのフラグ
    const initToggleState: {
        [key: string]: boolean,
        payment_site_credit:boolean,
        option_service_credit:boolean,
        system_option_credit:boolean,
        payment_method_credit:boolean,
        payment_site_cvs:boolean,
        option_service_cvs:boolean,
        system_option_cvs:boolean,
        payment_site_bank:boolean,
        option_service_bank:boolean,
        system_option_bank:boolean,
        payment_site_atobarai:boolean,
    } = {
        payment_site_credit:true,
        option_service_credit:true,
        system_option_credit:true,
        payment_method_credit:true,
        payment_site_cvs:true,
        option_service_cvs:true,
        system_option_cvs:true,
        payment_site_bank:true,
        option_service_bank:true,
        system_option_bank:true,
        payment_site_atobarai:true,
    }
    //初期バリデーション文言
    const initValidateState : {
        credit:{
            early_payment:string,
            monthly_payment_method:string,
        }
    } = {
        credit:{
            early_payment:"",
            monthly_payment_method:"",
        },
    }
    // クレジット支払いサイトラジオボタン
    const paymentSiteRadio = [
        { 
            label: <ToolChipDiv>{`月2回締め 月2回払い\n￥`+economicConditionMasterInfoData.credit.option_fee_early_deposit_service_two+`/月`}</ToolChipDiv>, 
            value: "月2回締め 月2回払い" 
        },
        {
            label: <ToolChipDiv>{`月3回締め 月3回払い\n￥`+economicConditionMasterInfoData.credit.option_fee_early_deposit_service_three+`/月`}</ToolChipDiv>, 
            value: "月3回締め 月3回払い" 
        },
    ]
    //画面上のみ作動するトグル
    const [toggleState, setToggleState] = useState(initToggleState);

    // バリデーション結果
    const [validateState, setValidateState] = useState(initValidateState)
    
    const ToggleState = toggleState

    const radioEarlyPaymentRef = useRef<HTMLInputElement>(null)
    const monthlyPaymentRef = useRef<HTMLInputElement>(null)

    //demand_code抽出
    const demandCode = agentMasterInfoData.demand_code

    //オプションサービスのどれか１項目でも表示させるものがあれば、true
    const optionExistFlg = systemSettingMasterInfoData.credit_3d_agent_flag || systemSettingMasterInfoData.credit_cvv_agent_flag
        || systemSettingMasterInfoData.credit_subscription_agent_flag || systemSettingMasterInfoData.credit_div2_bonus_agent_flag || systemSettingMasterInfoData.credit_auth_agent_flag

    //クレジット取引手数料表示
    const creditFee = useMemo(()=>{
        return setCreditFeeData(economicConditionMasterInfoData)
    },[economicConditionMasterInfoData])
    //コンビニ取引手数料表示
    const cvsFee = useMemo(()=>{
        return setCvsFeeData(economicConditionMasterInfoData)
    },[economicConditionMasterInfoData])

    //支払いサイトプルダウン(クレカ)
	const selectItemsCreditPaymentSite = useMemo(()=>{
        const initArray = [
            { label: '選択してください', value: '', disabled: false },
            { label: economicConditionMasterInfoData.credit.payment_site, value: economicConditionMasterInfoData.credit.payment_site, disabled: false },
        ] 
        economicConditionMasterInfoData.credit.early_payment_two && initArray.push({ label: '月2回締め 月2回払い', value: '月2回締め 月2回払い', disabled: false })
        economicConditionMasterInfoData.credit.early_payment_three && initArray.push({ label: '月3回締め 月3回払い', value: '月3回締め 月3回払い', disabled: false })
        return initArray
    },[economicConditionMasterInfoData.credit.early_payment_three,economicConditionMasterInfoData.credit.early_payment_two,economicConditionMasterInfoData.credit.payment_site]) 

    //支払いサイトの値段文言変更
    const changePhrase = useCallback((type: string) => {
        switch(type){
            case economicConditionMasterInfoData.credit.payment_site: return '無料';
            case '月2回締め 月2回払い': return <>月額プラン:￥<span className="font-m">{economicConditionMasterInfoData.credit.option_fee_early_deposit_service_two}</span>/月</>;
            case '月3回締め 月3回払い': return <>月額プラン:￥<span className="font-m">{economicConditionMasterInfoData.credit.option_fee_early_deposit_service_three}</span>/月</>;
            default: break;
        }
    },[economicConditionMasterInfoData])
    
    //支払いサイト金額_クレジット文言
    const paymentSiteCreditPhrase = useMemo(()=>{
        return changePhrase(economicState.credit.economic_requirement.payment_site)
    },[economicState.credit.economic_requirement.payment_site,changePhrase])
    //支払いサイト金額_コンビニ文言
    const paymentSiteConveniencePhrase = useMemo(()=>{
        return changePhrase(economicState.cvs.economic_requirement.payment_site)
    },[economicState.cvs.economic_requirement.payment_site,changePhrase])
    //支払いサイト金額_銀行振り込み文言
    const paymentSiteBankPhrase = useMemo(()=>{
        return changePhrase(economicState.bank.economic_requirement.payment_site)
    },[economicState.bank.economic_requirement.payment_site,changePhrase])

    //画面上のみ作動するトグルのハンドラ
    const setToggleStateHandler = (key: string) => {
        return setToggleState({...toggleState, [key]:!toggleState[key]})
    }

    type serviceOptionalFeeType = {
        [key: string]: number | undefined,
        option_fee_3d_secure?: number,
        option_fee_cvv?: number,
        option_fee_continuous_billing?: number,
        option_fee_payment_now?: number,
        option_fee_confirmation_email?: number,
        option_fee_id_password_storage?: number,
        option_fee_customer_support?: number,
        option_fee_div2_bonus?: number,
    }

    type setOptionFeeType = {
        [key: string]: serviceOptionalFeeType | undefined,
        credit: serviceOptionalFeeType,
        cvs?: serviceOptionalFeeType,
        bank?: serviceOptionalFeeType,
        atobarai?: serviceOptionalFeeType
    }

    //システム利用料とオプション利用料
    // やりたいこと
    // option → 各サービスごとにシステム使用料(system_usage_fee)をセットする
    // system → 各サービスごとに選択したオプションと、支払いサイトの料金とシステム使用料を合算した値をセットする。
    const calcSystemFee = useCallback((optionFeeData: setOptionFeeType) => {
        //料金を格納するオブジェクト
        let sysetemFeeData :{
            [key: string]: number
            credit: number,
            cvs: number,
            bank: number,
            atobarai: number
        } = {
            credit: 0,
            cvs: 0,
            bank: 0,
            atobarai: 0
        }
        //クレジットの支払いサイトが「月2回締め 月2回払い」「月3回締め 月3回払い」で計算するマスタの料金を変える
        let creditPaymentSiteFee: number = 0;
        if(economicState.credit.economic_requirement.payment_site === '月2回締め 月2回払い'){
            creditPaymentSiteFee =  economicConditionMasterInfoData.credit.option_fee_early_deposit_service_two
        } 
        if(economicState.credit.economic_requirement.payment_site === '月3回締め 月3回払い'){
            creditPaymentSiteFee =  economicConditionMasterInfoData.credit.option_fee_early_deposit_service_three
        } 
        if (demandCode === "system") { 
            // system
            // 決済サービスごとに料金挿入
            if(Object.keys(optionFeeData).length === 0) return sysetemFeeData
            // クレジット
            if(optionFeeData.credit){
                // オプションサービスcreditの合計+システム利用料
                sysetemFeeData.credit = Object.values(optionFeeData.credit).reduce((sum,element)=>sum!+element!,0) + economicConditionMasterInfoData.credit.system_usage_fee
                // 支払いサイトの合算
                if(economicState.credit.economic_requirement.early_payment_flag){
                    sysetemFeeData.credit += creditPaymentSiteFee
                }
            } 
            // 銀行振り込み
            if(optionFeeData.bank){
                // オプションサービスの合計+システム利用料
                sysetemFeeData.bank = Object.values(optionFeeData.bank).reduce((sum,element)=>sum!+element!,0) + economicConditionMasterInfoData.bank.system_usage_fee
                // 支払いサイトの合算
                if(economicState.bank.economic_requirement.early_payment_flag){
                    sysetemFeeData.bank += economicConditionMasterInfoData.bank.option_fee_early_deposit_service
                }
            } 
            // コンビニ
            if(optionFeeData.cvs){
                // オプションサービスの合計+システム利用料
                sysetemFeeData.cvs = Object.values(optionFeeData.cvs).reduce((sum,element)=>sum!+element!,0) + economicConditionMasterInfoData.cvs.system_usage_fee
                // 支払いサイトの合算
                if(economicState.cvs.economic_requirement.early_payment_flag){
                    sysetemFeeData.cvs += economicConditionMasterInfoData.cvs.option_fee_early_deposit_service 
                }
            } 
            // あと払い
            if(optionFeeData.atobarai){
                // オプションサービスの合計+システム利用料
                sysetemFeeData.atobarai = Object.values(optionFeeData.atobarai).reduce((sum,element)=>sum!+element!,0) + economicConditionMasterInfoData.atobarai.system_usage_fee
            } 
        } else {
            // option
            sysetemFeeData = {
                credit: economicConditionMasterInfoData.credit.system_usage_fee,
                cvs: economicConditionMasterInfoData.cvs.system_usage_fee,
                bank: economicConditionMasterInfoData.bank.system_usage_fee,
                atobarai: economicConditionMasterInfoData.atobarai.system_usage_fee,
            };
        }
        return sysetemFeeData
    },[demandCode,economicState,economicConditionMasterInfoData])

    //オプション選択で選択されたオプションから料金を各項目にセット
    // API用の値のセット    
    // 選択されている項目の料金をセット
    const setOptionFee = useCallback(() => {
        //最終的な料金データを入れるオブジェクト
        let optionFeeData: setOptionFeeType = {
            credit: {}
        }
        //service_code_listを参照すると以前のデータが入ってしまうため、セレクトフラグを見る
        let serviceCode: string[] = []
        if(economicState.credit.system_setting.select_flag) serviceCode.push("credit")
        if(economicState.cvs.system_setting.select_flag) serviceCode.push("cvs")
        if(economicState.bank.system_setting.select_flag) serviceCode.push("bank")
        if(economicState.atobarai.system_setting.select_flag) serviceCode.push("atobarai")
        
        const keyNameObj: {
            [key: string]: string
        } = {
            cvv:"option_fee_cvv",
            threed_secure:"option_fee_3d_secure",
            send_mail:"option_fee_confirmation_email",
            subscription:"option_fee_continuous_billing",
            payment_now:"option_fee_payment_now",
            customer_support:"option_fee_customer_support",
            div:"option_fee_div2_bonus",
            id_password_storage:"option_fee_id_password_storage",
        }
        const setOptionObjectFee = (service: string) => {
            let optionFeeObject:{[key:string]:number} = {}
            //system_settingのtrueのオプションのみ料金と項目名を料金データにセット
            const systemSetting = economicState[service].system_setting
            //オプションがtrueもしくはkeyNameObjにオプション名がある項目のみ抽出
            const trueOptionObject = Object.keys(systemSetting).filter((key:string)=>key in keyNameObj && systemSetting[key])
            trueOptionObject.forEach((optionService:string)=>{
                //keyNameObjからマスタデータの項目名を抽出
                const masterOptionName = keyNameObj[optionService]
                //料金をoptionFeeDataに挿入
                optionFeeObject[masterOptionName] = economicConditionMasterInfoData[service][masterOptionName]
            })
            return optionFeeObject
        }

        if(economicState.credit.system_setting.select_flag) optionFeeData.credit = setOptionObjectFee("credit")
        if(economicState.cvs.system_setting.select_flag) optionFeeData.cvs = setOptionObjectFee("cvs")
        if(economicState.bank.system_setting.select_flag) optionFeeData.bank = setOptionObjectFee("bank")
        if(economicState.atobarai.system_setting.select_flag) optionFeeData.atobarai = setOptionObjectFee("atobarai")
        return optionFeeData
    },[economicConditionMasterInfoData,economicState])

    //合計金額が0円の時、支払い方法を2に
    const setMonthlyPaymentHandler = () => {
        let economicInsertData: initEconomicSessionDataType
        let economicCreditRequirement = {...economicState.credit.economic_requirement}
        let economicBankRequirement = {...economicState.bank.economic_requirement}
        let economicCvsRequirement = {...economicState.cvs.economic_requirement}
        let economicAtobaraiRequirement = {...economicState.atobarai.economic_requirement}
        if(sumFeeState.credit === 0 && sumFeeState.bank === 0 && sumFeeState.cvs === 0 && sumFeeState.atobarai === 0){
            economicCreditRequirement = {...economicCreditRequirement, monthly_payment_method:"2"}
            economicBankRequirement = {...economicBankRequirement, monthly_payment_method:"2"}
            economicCvsRequirement = {...economicCvsRequirement, monthly_payment_method:"2"}
            economicAtobaraiRequirement = {...economicAtobaraiRequirement, monthly_payment_method:"2"}
        }
        economicInsertData = {
            ...economicState, 
            credit:{
                ...economicState.credit,
                economic_requirement:economicCreditRequirement
            },
            bank:{
                ...economicState.bank,
                economic_requirement:economicBankRequirement
            },
            cvs:{
                ...economicState.cvs,
                economic_requirement:economicCvsRequirement
            },
            atobarai:{
                ...economicState.atobarai,
                economic_requirement:economicAtobaraiRequirement
            },
        }
        return economicInsertData
    }

    //オプションの付帯サービスと選択可能サービスが存在するかどうかのフラグ
    const optionExistFlag = useMemo(()=>{
        let flags = {
            credit:{
                default:false,
                option:false,
            },
            bank:{
                default:false,
                option:false,
            },
            cvs:{
                default:false,
                option:false,
            },
        }
        if(systemSettingMasterInfoData.credit_3d_agent_flag && !systemSettingMasterInfoData.credit_3d_merchant_flag) flags.credit.default = true
        if(systemSettingMasterInfoData.credit_3d_agent_flag && systemSettingMasterInfoData.credit_3d_merchant_flag) flags.credit.option = true
        if(systemSettingMasterInfoData.credit_cvv_agent_flag && !systemSettingMasterInfoData.credit_cvv_merchant_flag) flags.credit.default = true
        if(systemSettingMasterInfoData.credit_cvv_agent_flag && systemSettingMasterInfoData.credit_cvv_merchant_flag) flags.credit.option = true
        if(systemSettingMasterInfoData.credit_subscription_agent_flag && !systemSettingMasterInfoData.credit_subscription_merchant_flag) flags.credit.default = true
        if(systemSettingMasterInfoData.credit_subscription_agent_flag && systemSettingMasterInfoData.credit_subscription_merchant_flag) flags.credit.option = true
        if(systemSettingMasterInfoData.credit_div2_bonus_agent_flag && !systemSettingMasterInfoData.credit_div2_bonus_merchant_flag) flags.credit.default = true
        if(systemSettingMasterInfoData.credit_div2_bonus_agent_flag && systemSettingMasterInfoData.credit_div2_bonus_merchant_flag) flags.credit.option = true
        if(systemSettingMasterInfoData.credit_send_mail_agent_flag && !systemSettingMasterInfoData.credit_send_mail_merchant_flag) flags.credit.default = true
        if(systemSettingMasterInfoData.credit_send_mail_agent_flag && systemSettingMasterInfoData.credit_send_mail_merchant_flag) flags.credit.option = true
        if(systemSettingMasterInfoData.credit_id_password_storage_agent_flag && !systemSettingMasterInfoData.credit_id_password_storage_merchant_flag) flags.credit.default = true
        if(systemSettingMasterInfoData.credit_id_password_storage_agent_flag && systemSettingMasterInfoData.credit_id_password_storage_merchant_flag) flags.credit.option = true
        if(systemSettingMasterInfoData.credit_customer_support_agent_flag && !systemSettingMasterInfoData.credit_customer_support_merchant_flag) flags.credit.default = true
        if(systemSettingMasterInfoData.credit_customer_support_agent_flag && systemSettingMasterInfoData.credit_customer_support_merchant_flag) flags.credit.option = true
        if(systemSettingMasterInfoData.credit_payment_now_agent_flag && !systemSettingMasterInfoData.credit_payment_now_merchant_flag) flags.credit.default = true
        if(systemSettingMasterInfoData.credit_payment_now_agent_flag && systemSettingMasterInfoData.credit_payment_now_merchant_flag) flags.credit.option = true
        if(systemSettingMasterInfoData.credit_early_payment_service_agent_flag && !systemSettingMasterInfoData.credit_early_payment_service_merchant_flag) flags.credit.default = true
        if(systemSettingMasterInfoData.credit_early_payment_service_agent_flag && systemSettingMasterInfoData.credit_early_payment_service_merchant_flag) flags.credit.option = true
        if(systemSettingMasterInfoData.cvs_send_mail_agent_flag && !systemSettingMasterInfoData.cvs_send_mail_merchant_flag) flags.cvs.default = true
        if(systemSettingMasterInfoData.cvs_send_mail_agent_flag && systemSettingMasterInfoData.cvs_send_mail_merchant_flag) flags.cvs.option = true
        if(systemSettingMasterInfoData.cvs_customer_support_agent_flag && !systemSettingMasterInfoData.cvs_customer_support_merchant_flag) flags.cvs.default = true
        if(systemSettingMasterInfoData.cvs_customer_support_agent_flag && systemSettingMasterInfoData.cvs_customer_support_merchant_flag) flags.cvs.option = true
        if(systemSettingMasterInfoData.cvs_early_payment_service_agent_flag && !systemSettingMasterInfoData.cvs_early_payment_service_merchant_flag) flags.cvs.default = true
        if(systemSettingMasterInfoData.cvs_early_payment_service_agent_flag && systemSettingMasterInfoData.cvs_early_payment_service_merchant_flag) flags.cvs.option = true
        if(systemSettingMasterInfoData.bank_send_mail_agent_flag && !systemSettingMasterInfoData.bank_send_mail_merchant_flag) flags.bank.default = true
        if(systemSettingMasterInfoData.bank_send_mail_agent_flag && systemSettingMasterInfoData.bank_send_mail_merchant_flag) flags.bank.option = true
        if(systemSettingMasterInfoData.bank_customer_support_agent_flag && !systemSettingMasterInfoData.bank_customer_support_merchant_flag) flags.bank.default = true
        if(systemSettingMasterInfoData.bank_customer_support_agent_flag && systemSettingMasterInfoData.bank_customer_support_merchant_flag) flags.bank.option = true
        if(systemSettingMasterInfoData.bank_early_payment_service_agent_flag && !systemSettingMasterInfoData.bank_early_payment_service_merchant_flag) flags.bank.default = true
        if(systemSettingMasterInfoData.bank_early_payment_service_agent_flag && systemSettingMasterInfoData.bank_early_payment_service_merchant_flag) flags.bank.option = true
        return flags
    },[systemSettingMasterInfoData])

    //オプションを選択で経済条件に値をセット,合計金額をセット
    useEffect(() => {
        const optionFeeData = setOptionFee()
        //システム利用料計算
        const sumCost = calcSystemFee(optionFeeData)
        setSumFeeDataHandler(sumCost)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[economicState.credit.system_setting,economicState.bank.system_setting,economicState.cvs.system_setting,economicState.credit.economic_requirement.payment_site,
        economicState.bank.economic_requirement.early_payment_flag,economicState.cvs.economic_requirement.early_payment_flag,economicState.atobarai.system_setting.select_flag])
        
    //データと連携して画面上で動くトグルのハンドラ
    const setEconomicInfoDataHandler = (mainDivision: string,subDivision: string,key: string) => {
        const booleanData = !economicState[mainDivision][subDivision][key]
        const preSetData = {...economicState[mainDivision][subDivision], [key]:booleanData}
        const setData = {...economicState[mainDivision], [subDivision]:preSetData }
        //クレジットの早期入金フラグががオフになった時、支払いサイトをマスターデータに戻す
        if(mainDivision === "credit" && key === "early_payment_flag"){
            setData.economic_requirement.payment_site = economicConditionMasterInfoData.credit.payment_site
        }
        
        return setEconomicDataHandler({[mainDivision]:setData})
    }

    //プルダウンハンドラ
    const setSelectEconomicInfoDataHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        //idからサービスを分ける（credit,cvs,bank）
        const div = e.target.id.split('_')[0]
        const serviceData = {
            ...economicState[div], 
            economic_requirement:{
                ...economicState[div].economic_requirement,
                [e.target.name] : e.target.value,
            }
        }
         return setEconomicDataHandler({[div]:serviceData})
    }
    //ラジオボタンハンドラ
    const setPaymentSiteRadioHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const economicData = economicState
        economicData.credit.economic_requirement.payment_site = e.target.value
        return setEconomicDataHandler({...economicData})
    }

    //支払い方法のプルダウンは一括で全部変更
    const setMonthlyPaymentMethodHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        let economicInsertData = economicState
        economicInsertData.credit.economic_requirement.monthly_payment_method = e.target.value
        economicInsertData.bank.economic_requirement.monthly_payment_method = e.target.value
        economicInsertData.cvs.economic_requirement.monthly_payment_method = e.target.value
        economicInsertData.atobarai.economic_requirement.monthly_payment_method = e.target.value
        return setEconomicDataHandler(economicInsertData)
    }

    //支払いサイト・月次支払必須バリデーション
    const validateMessageSetHandler = () => {
        let validateData: {
            credit:{
                monthly_payment_method:string,
                early_payment:string
            },
        } = {
            credit:{
                monthly_payment_method:"",
                early_payment:""
            },
        };
        let validateFlg: boolean = false;
        if(economicState.credit.economic_requirement.early_payment_flag){
            if(!(economicState.credit.economic_requirement.payment_site === "月2回締め 月2回払い" || economicState.credit.economic_requirement.payment_site === "月3回締め 月3回払い")){
                validateData.credit.early_payment = "必須項目です"
                validateFlg = true
            }
        }
        if(demandCode === "system"){
            if((sumFeeState.credit !== 0 || sumFeeState.bank !== 0 || sumFeeState.cvs !== 0 || sumFeeState.atobarai !== 0 )
            && economicState.credit.economic_requirement.monthly_payment_method === ""){
                validateData.credit.monthly_payment_method = "必須項目です"
                validateFlg = true
            }
        }
        if(validateData.credit.early_payment !== "" && radioEarlyPaymentRef.current !== null){
            window.scrollTo({
                top:radioEarlyPaymentRef.current.offsetTop-170,
            })
        } else if(validateData.credit.monthly_payment_method !== "" && monthlyPaymentRef.current !== null) {
            window.scrollTo({
                top:monthlyPaymentRef.current.offsetTop,
            })
        }
        return {validateData, validateFlg}
    }

    //戻るボタンonClick
    const navigateBack = () => {
        return navigateByAgent('/dashboard')
    }

    //支払いサイト・月次支払必須バリデーション実行後、エラーがなければ経済条件を保存し経済確認画面
    const navigateEconomicEstimate = () => {
        const {validateData, validateFlg} = validateMessageSetHandler()
        setValidateState(validateData)

        if(validateFlg) return 
        const economicInsertData = setMonthlyPaymentHandler()
        setEconomicInfoData(economicInsertData)
        return navigateByAgent('/economic_estimate',confirmFlag)
    }

    //service_code_listの補完,支払いサイト補完
    useEffect(()=>{
        let titleArray: string[] = [];
        if(economicState.credit.system_setting.select_flag) titleArray.push('credit')
        if(economicState.bank.system_setting.select_flag) titleArray.push('bank')
        if(economicState.cvs.system_setting.select_flag) titleArray.push('cvs')
        if(economicState.atobarai.system_setting.select_flag) titleArray.push('atobarai')
        if(titleArray.length === 2){
            titleArray.splice(1,0,"/")
        }else if(titleArray.length === 3){
            titleArray.splice(1,0,"/")
            titleArray.splice(3,0,"/")
        }else if(titleArray.length === 4){
            titleArray.splice(1,0,"/")
            titleArray.splice(3,0,"/")
            titleArray.splice(5,0,"/")
        }
        
        const titleString = titleArray.toString().replace(/,/g,'');
        setEconomicDataHandler({service_code_list:titleString})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[economicState.credit.system_setting.select_flag,economicState.bank.system_setting.select_flag,economicState.cvs.system_setting.select_flag,
        economicState.atobarai.system_setting.select_flag])


    return {creditFee,cvsFee,sumFeeState,economicDevidedInfoData,economicConditionMasterInfoData,optionExistFlg,systemSettingMasterInfoData,
        validateState,demandCode,ToggleState,economicState,paymentSiteCreditPhrase,paymentSiteConveniencePhrase,paymentSiteBankPhrase,
        selectItemsCreditPaymentSite,selectItemsPaymentMethod,setToggleStateHandler,navigateBack,navigateEconomicEstimate,setEconomicInfoDataHandler,
        setSelectEconomicInfoDataHandler,setMonthlyPaymentMethodHandler,paymentSiteRadio,
        setPaymentSiteRadioHandler,optionExistFlag,radioEarlyPaymentRef,monthlyPaymentRef}
}

export default useEconomicSelectElementHooks