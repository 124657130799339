import { MouseEventHandler } from "react"
import { Button } from "./Button"

const PdfDownloadButton = ({ 
	pdfDownloadHandler, 
	disabledFlag = false
}:{
	pdfDownloadHandler: MouseEventHandler<HTMLButtonElement>, 
	disabledFlag? : boolean
}) => {
	return (
		<Button onClick={pdfDownloadHandler} disabled={disabledFlag}>
			お申し込み情報ダウンロード
		</Button>
	)
}

export default PdfDownloadButton