import React from 'react';
import ImgEntryIconShop from '../../../images/entry_icon_shop.svg';
import ImgEntryIconBank from '../../../images/entry_icon_bank.svg';
import ImgEntryIconShopinfo from '../../../images/entry_icon_shopinfo.svg';
import ImgEntryIconCart from '../../../images/entry_icon_cart.svg';
import ImgEntryIconSetting from '../../../images/entry_icon_setting.svg';


const iconSrc: {
  [key: string]: string
} = {
  'shop': ImgEntryIconShop,
  'bank': ImgEntryIconBank,
  'shopinfo': ImgEntryIconShopinfo,
  'cart': ImgEntryIconCart,
  'setting': ImgEntryIconSetting
}

export const EntryIcons = ({
  icon
}: {
  icon: string
}) => {
  return (
      <img src={iconSrc[icon]} alt={icon} />
  );
};
