import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../../css/variables/colors';
import { Button } from '../../../ui-parts/ocnk/Button';
import { ServiceList, ServiceListItem } from '../../../ui-parts/ocnk/ServiceList';
import { Typography } from '../../../ui-parts/ocnk/Typography';
import ServiceImgCard from '../../../../images/pct_service_card.svg';
import ServiceImgConvenience from '../../../../images/pct_service_convenience.svg';
import ServiceImgBank from '../../../../images/pct_service_bank.svg';
import ServiceImgAtobarai from '../../../../images/atobaraiIcon.svg';
import useServiceTypeElmHooks from '../../../../hooks/useServiceTypeElmHooks';
import useNavigateByAgent from '../../../../hooks/useNavigateByAgentHooks';



const ServiceTypeElmStyle = styled.div`
  background:${colors.themeColor('background_main')};
  padding: 12px;
  border:1px solid ${colors.themeColor('border_box')};
  border-radius: 8px;
`;
const HeadStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;


export const ServiceTypeElm = () => {
  const {navigateByAgent} = useNavigateByAgent()
  const {navigateEconomicSelect, economicInfoData,economicDevidedInfoData} = useServiceTypeElmHooks(navigateByAgent);
  return (
    <ServiceTypeElmStyle>
      <HeadStyle>
        <Typography size={'ms'} bold>ご利用サービス</Typography>
        <Button variant={'secoundary'} size={'small'} onClick={navigateEconomicSelect}>変更</Button>
      </HeadStyle>
      <ServiceList>
        {economicDevidedInfoData.credit &&
          <ServiceListItem img={ServiceImgCard} title={'クレジットカード決済'} isUsed={economicInfoData.credit.system_setting.select_flag} />
        }
        {economicDevidedInfoData.atobarai &&
          <ServiceListItem img={ServiceImgAtobarai} title={'コンビニあと払い'} isUsed={economicInfoData.atobarai.system_setting.select_flag}/>
        }
        {economicDevidedInfoData.bank &&
          <ServiceListItem img={ServiceImgBank} title={'ネットバンク決済（入金おまかせサービス）'} isUsed={economicInfoData.bank.system_setting.select_flag}/>
        }
        {economicDevidedInfoData.cvs &&
          <ServiceListItem img={ServiceImgConvenience} title={'コンビニ決済'} isUsed={economicInfoData.cvs.system_setting.select_flag} />
        }
      </ServiceList>

    </ServiceTypeElmStyle>
  )
}
