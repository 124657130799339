// ファイルデータの送信、表示コンポーネント
import styled from 'styled-components';
import { colors } from '../../../../css/variables/colors';
import { fonts } from '../../../../css/variables/fonts';
import { InquiryFileItem, Button } from '../../../ui-parts/ocnk';
import useInquiryFileHooks from '../../../../hooks/useInquiryFileHooks';
import attachedIcon from "../../../../images/icon_attached.svg";
import UserIcon from '../../../../images/SlideInUserIcon.svg';
import paperAirPlaneIcon from '../../../../images/paperAirPlane.svg';
import sendOKIcon from '../../../../images/sendOK.svg';
import { sessionFileType } from '../../../../constance/typeModel';
import { ChangeEventHandler } from 'react';

const FileWrapperStyle = styled.div`
  margin-top: 16px;
`
const ActionWrapperStyle = styled.div`
  display: flex;
  margin-top: 16.5px;
`
const AttachedWrapperStyle = styled.div`
  margin-right: 16px;
  position: relative;
  z-index: 0;
`

type attachedIconStyleType = {
  navigation?: boolean,
  statusClosed?: boolean
}
const AttachedIconStyle = styled.label<attachedIconStyleType>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height:${props => props.navigation ? '46' : '40'}px;
  background: ${props => props.statusClosed ? colors.themeColor('background_disabled') : colors.themeColor('background_main')};
  input {
    display: none;
  }
`
const AttachedIconImgStyle = styled.div`
  width: 10.5px;
  height: 18px;
  background: url(${attachedIcon}) no-repeat center;
`
const TipStyle = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  z-index: -10;
  transform: translate(-50%, -150%);
  text-align: center;
  padding: 10px 0;
  border-radius: 4px;
  min-width: 128px;
  background: rgba(0, 0, 0, 0.6);
  color: ${colors.themeColor('text_white')};
  font-size: ${fonts.fontSizeRem('ms')};
  ::before {
    content: '';
    position: absolute;
    transform: translate(-50%, 100%);
    left: 50%;
    bottom: 0%;
    width: 0;
    height: 0;
    border-top: 19px solid rgba(0, 0, 0, 0.6);
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 19px solid transparent;
  }
`
const UserIconStyle = styled.div`
  width: 36px;
  height: 46px;
  background: url(${UserIcon}) no-repeat center;

`
const CommentSendFormWrapper = styled.div`
  display:flex;
  border-bottom:1px solid ${colors.themeColor('border_box')};
  border-top:1px solid ${colors.themeColor('border_box')};
`
const SlideInTextArea = styled.textarea`
  border:none;
  width:100%;
  resize:none;
  font-size:${fonts.fontSizeRem('m')}px;
  padding-top:13px;
  padding-left:10px;
  colors:${colors.themeColor('text-placeholder')};
  outline:none;
`
const SlideInButton = styled.button`
  border:none;
  background-color:${colors.themeColor('background_button_secoundary')};
`

const InquiryFileElement = ({
  registerHandler,
  ticket_id,
  status,
  FileDatas,
  setFileData,
  navigation,
  textareaRef,
  textareaChange,
  canceledFlag
}: {
  registerHandler: Function,
  ticket_id: number,
  status: string,
  FileDatas: sessionFileType[]|null,
  setFileData: Function,
  navigation?: boolean,
  textareaRef?: any,
  textareaChange?: ChangeEventHandler<HTMLElement>,
  canceledFlag?: boolean
}) => {

  const {tip, tipChangeHandler, FileRef, loadFile, deleteFile} = useInquiryFileHooks(FileDatas, setFileData);
  return <>
    {navigation ?
      <>
        {/* アップロードしたファイルを表示する部分 */}
        <FileWrapperStyle>
          {FileDatas !== null && FileDatas.map((value: sessionFileType, index: number) => (
            <InquiryFileItem
              key={index}
              file={value}
              deleteFile={()=>deleteFile(index)}
              upload
            />
          ))}
        </FileWrapperStyle>
        <CommentSendFormWrapper>
            <UserIconStyle/>
            <SlideInTextArea ref={textareaRef} id='textarea' onChange={textareaChange} className='textarea' placeholder="コメントを追加" disabled={(status === 'CLOSED' || canceledFlag) ? true : false}></SlideInTextArea>
            <AttachedIconStyle
              onMouseEnter={tipChangeHandler}
              onMouseLeave={tipChangeHandler}
              navigation={navigation}
            >
              <AttachedIconImgStyle/>
              <input ref={FileRef} onChange={loadFile} type="file" name="example" accept="image/*" multiple disabled={(status === "CLOSED" || canceledFlag) ? true :false}></input>
            </AttachedIconStyle>
            <SlideInButton onClick={() => registerHandler(ticket_id,FileDatas)} disabled={(status === "CLOSED" || canceledFlag) ? true :false}><img alt='SendCommentIcon' src={(textareaRef.current !== null && textareaRef.current.value === "") ? paperAirPlaneIcon : sendOKIcon}/></SlideInButton>
        </CommentSendFormWrapper>
      </>
      :
      <>
        {/* アップロードしたファイルを表示する部分 */}
        <FileWrapperStyle>
          {FileDatas !== null && FileDatas.map((value: sessionFileType, index: number) => (
            <InquiryFileItem
              key={index}
              file={value}
              deleteFile={()=>deleteFile(index)}
              upload
            />
          ))}
        </FileWrapperStyle>
        <ActionWrapperStyle>
          <AttachedWrapperStyle>
            <AttachedIconStyle
              onMouseEnter={tipChangeHandler}
              onMouseLeave={tipChangeHandler}
              statusClosed={(status === 'CLOSED' || canceledFlag)}
            >
              <AttachedIconImgStyle/>
              <input ref={FileRef} onChange={loadFile} type="file" name="example" accept="image/*" multiple disabled={(status === "CLOSED" || canceledFlag) ? true :false}></input>
            </AttachedIconStyle>
            {(tip && status !== 'CLOSED')&& <TipStyle>添付ファイル</TipStyle>}
          </AttachedWrapperStyle>
          <Button variant={'normal'} onClick={() => registerHandler(ticket_id,FileDatas)} disabled={(status === "CLOSED" || canceledFlag) ? true :false}>送信</Button>
        </ActionWrapperStyle>
      </>
    }
  </>;
};

export default InquiryFileElement;