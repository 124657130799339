import styled from 'styled-components'
import { colors } from '../../../css/variables/colors';
import { fonts } from '../../../css/variables/fonts';
import iconSuccess from '../../../images/icon_check_white.svg';
import iconError from '../../../images/icon_ng_white.svg';
import iconClose from '../../../images/icon_ng_gray.svg';
import { Context } from '../../../stores/Provider';
import { useContext } from 'react';

type snackbarStyleType = {
  open: boolean,
  type: string
}

const SnackbarStyle = styled.div<snackbarStyleType>`
  width: 376px;
  max-width: calc(100% - 32px);
  min-height: 66px;
  background:${colors.themeColor('background_main')};
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2), 0px 0px 12px rgba(0, 0, 0, 0.08);
  align-items: center;
  display: flex;
  padding: 10px 36px 10px 18px;
  font-size: ${fonts.fontSizeRem('s')} ;
  position: fixed;
  z-index:999;
  top:16px;
  right: 16px;
  transform: ${props => props.open ? 'scale(' + 1 + ')' : 'scale(' + 0 + ')'};
  opacity: ${props => props.open === true ? 1 : 0};
  transition: all .1s;
  &:after{
    content: '';
    position: absolute;
    width: 4px;
    height: 100%;
    top: 0;
    left: 0;
    ${props => props.type === 'success' ? 'background:' + colors.themeColor('primary_light') : null};
    ${props => props.type === 'error' ? 'background:' + colors.themeColor('background_error') : null};
  }
`

type markType = {
  type: string
}
const Mark = styled.span<markType>`
  width:28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  ${props => props.type === 'success' ? 'background:' + colors.themeColor('primary_light') : null};
  ${props => props.type === 'error' ? 'background:' + colors.themeColor('background_error') : null};
  img{
    width: 16px;
    height: 16px;
  }
`;
const Message = styled.span`
  flex:1;
`;
const CloseButton = styled.button`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: transparent;
  background-image: url(${iconClose});
  background-position: center;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
`;

export const Snackbar = ({
  open = false,
  message,
  type = 'success',
}: {
  open: boolean,
  message: string,
  type: string
}) => {
  const {state, dispatch} = useContext(Context)

  const iconSrc: {
    [key: string]: string,
    'success': string,
    'error': string,
  } = {
    'success': iconSuccess,
    'error': iconError,
  }
    //モーダル閉じるハンドラ
    const snackbarCloseHandler = () => {
      dispatch({
          type : 'set-modal-flag',
          payload : {
                ...state.is_open_modal,
                flag:false
          }
      })
    }
  
  return (
    <SnackbarStyle type={type} open={open}>
      <Mark type={type}>
        <img src={iconSrc[type]} alt='type' />
      </Mark>
      <Message>
        {message}
      </Message>
      <CloseButton onClick={()=>snackbarCloseHandler()} />
    </SnackbarStyle>
  );
};
