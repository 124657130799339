import React, { ReactNode } from 'react';
import styled from 'styled-components'
import { colors } from '../../../css/variables/colors';
import { fonts } from '../../../css/variables/fonts';
import { mixins } from '../../../css/variables/mixins';

type typographyStyleType = {
  sx?: string,
  size?: string,
  sizeDesktop?: string,
  sizeMobile?: string,
  color?: string,
  bold?: boolean
}

const TypographyStyle = styled.span<typographyStyleType>`
  ${props => props.sx};
  font-size: ${props => props.size};
  ${mixins.screenOver()}{
    ${props => props.sizeDesktop ? 'font-size:' + props.sizeDesktop : null}
  }
  ${mixins.screenUnder()}{
    ${props => props.sizeMobile ? 'font-size:' + props.sizeMobile : null}
  }
  color: ${props => props.color};
  font-weight:${props => props.bold ? 'bold' : 'normal'};
  white-space: nowrap;
`;

export const Typography = ({
  sizeDesktop,
  sizeMobile,
  sx,
  children,
  size = 'm',
  color = colors.themeColor('text_main'),
  bold = false
}: {
  size?:string,
  sizeDesktop?:string,
  sizeMobile?:string,
  color?:string,
  bold?:boolean,
  sx?:string,
  children?: ReactNode
}) => {
  return (
    <TypographyStyle size={fonts.fontSizeRem(size)} sizeDesktop={sizeDesktop && fonts.fontSizeRem(sizeDesktop)} sizeMobile={sizeMobile && fonts.fontSizeRem(sizeMobile)} color={color && colors.themeColor(color)} bold={bold} sx={sx}>
      {children}
    </TypographyStyle>
  );
};
