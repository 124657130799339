import styled from "styled-components"
import { colors } from "../../../../css/variables/colors"
import { fonts } from "../../../../css/variables/fonts"
import useEconomicEstimateTableHooks from "../../../../hooks/useEconomicEstimateTableHooks"
import useFinalAgreementTableHooks from "../../../../hooks/useFinalAgreementTableHooks"
import { FlexBox } from "../../../layouts/FlexBox"
import { mixins } from "../../../../css/variables/mixins"
import { ChangeTag } from "./ChangeTag"
import { feeArrayType, finalAgreementCompareDataType, initShopSessionDataType } from "../../../../constance/typeModel"
import { finalAgreementDiffExtractionTablesResponseDataType } from "../../../../constance/providerTypeModel"

const ServiceCostTable = styled.table`
	width:100%;
	table-layout: fixed;
    ${mixins.screenUnder()}{
        display:none;
    }
`

const TableTr = styled.tr`
	p{
		margin-bottom:0px;
		color:${colors.themeColor('text_main')}
	}
	.blue{
		color:${colors.themeColor('primary_dark')};
	}
	.main-color{
		color:${colors.themeColor('text_main')}
	}
	.normal-weight{
		font-weight:normal;
	}
	.cvs-settle-wrap{
		display:flex;
		flex-wrap:wrap;
		justify-content:space-between;
	}
	.cvs-settle-fee{
		padding:4px;
		width:100%;
		display:flex;
		justify-content:space-between;
		border-bottom:solid 1px ${colors.themeColor('border_box')};
	}
	.cvs-settle-fee-p{
		display:inline-block;
		font-size:14px;
	}
    .credit-settle-fee{
		width:100%;
		padding:4px;
		display:flex;
		justify-content:space-between;
		border-bottom:solid 1px ${colors.themeColor('border_box')};
	}
`

type tableThType = {
  white?: boolean
}

const TableTh = styled.th<tableThType>`
	width:24%;
	border: solid 1px ${colors.themeColor('border_box')};
	font-size: ${fonts.fontSizeRem('ms')};
	background: ${props => props.white ? colors.themeColor('background_main') : colors.themeColor('background_whitegray')};
	font-weight:bold;
	padding:10px;
    position:relative;
`
const TopTableTh = styled.th`
	border: solid 1px ${colors.themeColor('border_box')};
	font-size: ${fonts.fontSizeRem('ms')};
	font-weight:normal;
	background: ${colors.themeColor('background_whitegray')};
	font-weight:bold;
	padding:10px;
    position:relative;
`

// valignがany以外ではエラーになるためany設置
type tableTdType = {
  creditFee?: boolean,
  valign?: any
}

const TableTd = styled.td<tableTdType>`
	border: solid 1px ${colors.themeColor('border_box')};
	${props => props.creditFee ? '' : 'font-size:' + fonts.fontSizeRem('ms') + ';'}
	font-weight:bold;
	padding:9px;
  ${props => props.valign ? 'vertical-align:top;' : ''}
`
const SumFeeP = styled.p`
    font-size:${fonts.fontSizeRem('ms')} !important;
`

const UnderTable = styled.div`
    display: flex;
    flex-direction: column;
    ${mixins.screenOver()}{
        display: none;
    }
    p{
		margin-bottom:0px;
		color:${colors.themeColor('text_main')}
	}
	.blue{
		color:${colors.themeColor('primary_dark')};
	}
	.main-color{
		color:${colors.themeColor('text_main')}
	}
	.normal-weight{
		font-weight:normal;
	}
	.cvs-settle-wrap{
		display:flex;
		flex-wrap:wrap;
		justify-content:space-between;
	}
	.cvs-settle-fee{
		padding:4px;
		width:100%;
		display:flex;
		justify-content:space-between;
		border-bottom:solid 1px ${colors.themeColor('border_box')};
	}
	.cvs-settle-fee-p{
		display:inline-block;
		font-size:14px;
	}
    .credit-settle-fee{
		width:100%;
		padding:4px;
		display:flex;
		justify-content:space-between;
		border-bottom:solid 1px ${colors.themeColor('border_box')};
	}
`
const UnderTitle = styled.div`
    text-align: left;
    border: solid 1px ${colors.themeColor('border_box')};
	font-size: ${fonts.fontSizeRem('ms')};
	background: ${colors.themeColor('background_whitegray')};
	font-weight:bold;
	padding:8px 10px;
`
const UnderDataWrapper = styled.div`
    display: flex;
    width:100%;
`

type underDataType = {
  creditFee?: boolean,
  valign?: boolean,
  right?: boolean
}

const UnderData = styled.div<underDataType>`
    border: solid 1px ${colors.themeColor('border_box')};
    font-size: ${fonts.fontSizeRem('ms')};
    font-weight: bold;
    padding:8px 10px;
    width: 50%;
    ${props => props.creditFee ? '' : `font-size:${fonts.fontSizeRem('ms')};`}
    ${props => props.valign ? 'vertical-align:top;' : ''}
    ${props => props.right ? 'text-align: right;' : ''}
`

const FeeSpan = styled.span`
  font-size: ${fonts.fontSizeRem('xl')};
`

const FinalAgreementTable = ({ 
  options, 
  paymentSiteFee, 
  sumSystemCost, 
  compareData, 
  responseData, 
  originPaymentSiteFee, 
  page, 
  creditDifferenceFlag, 
  contentName, 
  shopData, 
  productContentPhrase,
  originFeeArrayHandler 
}: {
  options: feeArrayType[], 
  paymentSiteFee?: number, 
  sumSystemCost?: number, 
  compareData: finalAgreementCompareDataType, 
  responseData: finalAgreementDiffExtractionTablesResponseDataType, 
  originPaymentSiteFee?: number, 
  page: string, 
  creditDifferenceFlag?: boolean, 
  contentName: string, 
  shopData: initShopSessionDataType, 
  productContentPhrase: {pre: string, post: string},
  originFeeArrayHandler:Function,
}) => {
  const { economicInfoData, creditFee, systemOptionFee, demand_code } = useEconomicEstimateTableHooks({ options, page })
  const { finalAgreementSystemOptionFee } = useFinalAgreementTableHooks({ options, compareData, responseData, originFeeArrayHandler })

  return (
    <>
      {/* レスポンシブ対応画面用 */}
      {contentName === 'shop' &&
        <>
          {compareData.shop.shop_addition_product_content_freetext &&
            <UnderTable>
              <UnderTitle>取扱商品</UnderTitle>
              <UnderDataWrapper>
                <UnderData right>
                  <ChangeTag afterFlag={false} />
                    {shopData.shop_addition_product_content_freetext}
                </UnderData>
                <UnderData right>
                  <ChangeTag afterFlag={true} changeDetail={'usabled'} />
                  {responseData.shop_addition_product_content_freetext}
                </UnderData>
              </UnderDataWrapper>
            </UnderTable>
          }
          {compareData.shop.shop_addition_product_content_code &&
            <UnderTable>
              <UnderTitle>商品コンテンツ</UnderTitle>
              <UnderDataWrapper>
                <UnderData right>
                  <ChangeTag afterFlag={false} />
                  {productContentPhrase.pre}
                </UnderData>
                <UnderData right>
                <ChangeTag afterFlag={true} changeDetail={'usabled'}/>
                  {productContentPhrase.post}
                </UnderData>
              </UnderDataWrapper>
            </UnderTable>
          }
        </>
      }

      {contentName !== 'shop' &&
        <>
          <UnderTable>
            <UnderTitle>取引手数料</UnderTitle>
            <UnderDataWrapper>
              <UnderData>
                <ChangeTag afterFlag={false} />
                {creditFee}
              </UnderData>
              <UnderData>
                <ChangeTag afterFlag={true} />
                <div className="opacity">
                  {creditFee}
                </div>
              </UnderData>
            </UnderDataWrapper>
          </UnderTable>
          <UnderTable>
            <UnderTitle>初期費用（開設契約金）</UnderTitle>
            <UnderDataWrapper>
              <UnderData className="blue align_right">
                <ChangeTag afterFlag={false} />
                ￥ <FeeSpan>{economicInfoData.credit.economic_requirement.opening_contract_fee}</FeeSpan>
              </UnderData>
              <UnderData className="blue align_right">
                <ChangeTag afterFlag={true} />
                <div className="opacity">
                  ￥<FeeSpan>{economicInfoData.credit.economic_requirement.opening_contract_fee}</FeeSpan>
                </div>
              </UnderData>
            </UnderDataWrapper>
          </UnderTable>
          <UnderTable>
            <UnderTitle>システム料金</UnderTitle>
            <UnderDataWrapper>
              <UnderData valign className="align_right">
                <ChangeTag afterFlag={false} />
                <SumFeeP className="blue">￥ <FeeSpan>{originPaymentSiteFee}</FeeSpan> /月</SumFeeP>
                {(options.length !== 0 && demand_code === 'system') &&
                  <div className="mt4">
                    <p className="mb8 opacity">オプション利用料を含む</p>
                    {systemOptionFee}
                  </div>
                }
              </UnderData>
              <UnderData valign className="align_right">
                <ChangeTag afterFlag={true} />
                <SumFeeP className="blue opacity">￥ <FeeSpan>{sumSystemCost}</FeeSpan> /月</SumFeeP>
                {(options.length !== 0 && demand_code === 'system') &&
                  <div className="mt4">
                    <p className="mb8 opacity">オプション利用料を含む</p>
                    {finalAgreementSystemOptionFee}
                  </div>
                }
              </UnderData>
            </UnderDataWrapper>
          </UnderTable>
          {(options.length !== 0 && demand_code !== 'system') &&
            <UnderTable>
              <UnderTitle>オプション利用料</UnderTitle>
              <UnderDataWrapper>
                <UnderData creditFee valign>
                  <ChangeTag afterFlag={false} />
                  {systemOptionFee}
                </UnderData>
                <UnderData creditFee valign>
                  <ChangeTag afterFlag={creditDifferenceFlag} changeDetail={creditDifferenceFlag ? 'after' : ''} />
                  {finalAgreementSystemOptionFee}
                </UnderData>
              </UnderDataWrapper>
            </UnderTable>
          }
          <UnderTable>
            <UnderTitle>売上処理料</UnderTitle>
            <UnderDataWrapper>
              <UnderData className="blue align_right">
                <ChangeTag afterFlag={false} />
                ￥ <FeeSpan>{economicInfoData.credit.economic_requirement.sales_processing_fee}</FeeSpan>
              </UnderData>
              <UnderData className="blue align_right">
                <ChangeTag afterFlag={true} />
                <div className="opacity">
                  ￥<FeeSpan>{economicInfoData.credit.economic_requirement.sales_processing_fee}</FeeSpan>
                </div>
              </UnderData>
            </UnderDataWrapper>
          </UnderTable>
          <UnderTable>
            <UnderTitle>支払いサイト(入金サイクル)</UnderTitle>
            <UnderDataWrapper>
              <UnderData>
                <ChangeTag afterFlag={false} />
                <FlexBox spaceBetween>
                  <p className="main-color font-ms bold">
                    {economicInfoData.credit.economic_requirement.payment_site}
                  </p>
                  <p className="blue font-ms bold nowrap">
                    {paymentSiteFee !== 0 ?
                      <>
                        ￥ <FeeSpan>{paymentSiteFee}</FeeSpan> /月
                      </>
                      :
                      '無料'
                    }
                  </p>
                </FlexBox>
              </UnderData>
              <UnderData>
                <ChangeTag afterFlag={true} />
                <FlexBox spaceBetween>
                  <p className="main-color font-ms bold opacity">
                    {economicInfoData.credit.economic_requirement.payment_site}
                  </p>
                  <p className="blue font-ms bold nowrap opacity">
                    {paymentSiteFee !== 0 ?
                      <>
                        ￥ <FeeSpan>{paymentSiteFee}</FeeSpan> /月
                      </>
                      :
                      '無料'
                    }
                  </p>
                </FlexBox>
              </UnderData>
            </UnderDataWrapper>
          </UnderTable>
        </>
      }


      {/* PC画面用テーブル */}
      <ServiceCostTable>
        {(contentName === "shop" && (compareData.shop.shop_addition_product_content_freetext || compareData.shop.shop_addition_product_content_code)) &&
          <>
            <tbody>
              <TableTr>
                <TableTh>取扱商品</TableTh>
                <TableTd>
                  {compareData.shop.shop_addition_product_content_freetext ?
                    <>
                      <div className="opacity">
                        {shopData.shop_addition_product_content_freetext}
                      </div>
                      <div className="red_text">
                        {responseData.shop_addition_product_content_freetext}　に変更されました
                      </div>
                    </>
                    :
                    <div>{shopData.shop_addition_product_content_freetext}</div>
                  }

                </TableTd>
              </TableTr>
              <TableTr>
                <TableTh>商品コンテンツ</TableTh>
                <TableTd>
                  {compareData.shop.shop_addition_product_content_code ?
                    <>
                      <div className="opacity">{productContentPhrase.pre}</div>
                      <div className="red_text">{productContentPhrase.post}　に変更されました</div>
                    </>
                    :
                    <div>{productContentPhrase.pre}</div>
                  }
                </TableTd>
              </TableTr>
            </tbody>
          </>
        }
        {contentName !== "shop" &&
          <tbody>
            <TableTr>
              <TableTh white></TableTh>
              <TopTableTh>変更前</TopTableTh>
              <TopTableTh>変更後</TopTableTh>
            </TableTr>
            <TableTr>
              <TableTh>取引手数料</TableTh>
              <TableTd creditFee={true}>
                {creditFee}
              </TableTd>
              <TableTd>
                <div className="opacity">
                  {creditFee}
                </div>
              </TableTd>
            </TableTr>
            <TableTr>
              <TableTh>初期費用（開設契約金）</TableTh>
              <TableTd className="blue align_right">
                ￥ <span className="font-xl">{economicInfoData.credit.economic_requirement.opening_contract_fee}</span>
              </TableTd>
              <TableTd className="blue align_right">
                <div className="opacity">
                  ￥<span className="font-xl">{economicInfoData.credit.economic_requirement.opening_contract_fee}</span>
                </div>
              </TableTd>
            </TableTr>
            <TableTr>
              <TableTh>システム料金</TableTh>
              <TableTd valign className="align_right">
                <SumFeeP className="blue">￥ <span className="font-xl">{originPaymentSiteFee}</span> /月</SumFeeP>
                {(options.length !== 0 && demand_code === 'system') &&
                  <div className="mt4">
                    <p className="mb8 opacity">オプション利用料を含む</p>
                    {systemOptionFee}
                  </div>
                }
              </TableTd>
              <TableTd valign className="align_right">
                <SumFeeP className="blue opacity">￥ <span className="font-xl">{sumSystemCost}</span> /月</SumFeeP>
                {(options.length !== 0 && demand_code === 'system') &&
                  <div className="mt4">
                    <p className="mb8 opacity">オプション利用料を含む</p>
                    {finalAgreementSystemOptionFee}
                  </div>
                }
              </TableTd>
            </TableTr>
            {(options.length !== 0 && demand_code !== 'system') &&
              <TableTr>
                <TableTh>オプション利用料</TableTh>
                <TableTd creditFee valign>
                  {systemOptionFee}
                </TableTd>
                <TableTd creditFee valign>
                  {finalAgreementSystemOptionFee}
                </TableTd>
              </TableTr>
            }
            <TableTr>
              <TableTh>売上処理料</TableTh>
              <TableTd className="blue align_right">
                ￥ <span className="font-xl">{economicInfoData.credit.economic_requirement.sales_processing_fee}</span>
              </TableTd>
              <TableTd className="blue align_right">
                <div className="opacity">
                  ￥<span className="font-xl">{economicInfoData.credit.economic_requirement.sales_processing_fee}</span>
                </div>
              </TableTd>
            </TableTr>
            <TableTr>
              <TableTh>支払いサイト(入金サイクル)</TableTh>
              <TableTd>
                <FlexBox spaceBetween>
                  <p className="main-color font-m bold">
                    {economicInfoData.credit.economic_requirement.payment_site}
                  </p>
                  <p className="blue font-m bold nowrap">
                    {paymentSiteFee !== 0 ?
                      <>
                        ￥ <span className="font-xl">{paymentSiteFee}</span> /月
                      </>
                      :
                      '無料'
                    }
                  </p>
                </FlexBox>
              </TableTd>
              <TableTd>
                <FlexBox spaceBetween>
                  <p className="main-color font-m bold opacity">
                    {economicInfoData.credit.economic_requirement.payment_site}
                  </p>
                  <p className="blue font-m bold nowrap opacity">
                    {paymentSiteFee !== 0 ?
                      <>
                        ￥ <span className="font-xl">{paymentSiteFee}</span> /月
                      </>
                      :
                      '無料'
                    }
                  </p>
                </FlexBox>
              </TableTd>
            </TableTr>
          </tbody>
        }
      </ServiceCostTable>
    </>
  )
}

export default FinalAgreementTable