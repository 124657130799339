import React from 'react'
import { Box } from '../../../components/layouts/Box';
import { Container } from "../../../components/layouts/Container";
import { TitleElement } from '../../../components/ui_elements';
import useStartHooks from '../../../hooks/useStartHooks';
import { BackButton } from '../../../components/ui-parts/ocnk/BackButton';
import useNavigateByAgent from '../../../hooks/useNavigateByAgentHooks';
import PdfExplainBox from '../../../components/ui-parts/ocnk/PdfExplainBox';
import OcnkTeikiUseStartElement from '../../../components/ui_elements/ocnkTeiki/UseStart/UseStartElement';
import OcnkTeikiUseStartLinkElement from '../../../components/ui_elements/ocnkTeiki/UseStart/UseStartLinkElement';

const UseStart = () => {
  const {navigateByAgent} =useNavigateByAgent()
  const { useStartData, serviceCodeList, serviceStatusData, phraseByAgent, openExamiantionFlagState, examinationOpenHandler, ContentStatus, economicInfoData, creditBrandData, serviceStopFlag } = useStartHooks(navigateByAgent);
  return (
    <Container isLogin={true}>
      <TitleElement />
        <div className="mb12 align_right display-mobile">
					<BackButton onClick={()=>navigateByAgent('/dashboard')}>ダッシュボードに戻る</BackButton>
				</div>
      <OcnkTeikiUseStartElement
        managementData={useStartData.managementData}
        serviceData={useStartData.serviceData}
        serviceCodeList={serviceCodeList}
        serviceStatusData={serviceStatusData}
        service={'other'}
        phraseByAgent={phraseByAgent}
        examinationOpenHandler={examinationOpenHandler}
        openExamiantionFlagState={openExamiantionFlagState}
        ContentStatus={ContentStatus}
        economicInfoData={economicInfoData}
        creditBrandData={creditBrandData}
        serviceStopFlag={serviceStopFlag}
      />
      <Box mt={20} />
      <OcnkTeikiUseStartElement
        service={'atobarai'}
        serviceCodeList={serviceCodeList}
        atobaraiManagementData={useStartData.atobaraiManagementData}
        serviceData={useStartData.serviceData}
        serviceStatusData={serviceStatusData}
        phraseByAgent={phraseByAgent}
        examinationOpenHandler={examinationOpenHandler}
        openExamiantionFlagState={openExamiantionFlagState}
        ContentStatus={ContentStatus}
        economicInfoData={economicInfoData}
        creditBrandData={creditBrandData}
        serviceStopFlag={serviceStopFlag}
      />
      <Box mt={20} />
      <PdfExplainBox/>
      <OcnkTeikiUseStartLinkElement />
    </Container>
  );
};

export default UseStart