import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Context } from "../stores/Provider";
import { GetMethod } from "../api/Api";
import { getSession } from "../sessionStorage/sessionStorageMethod";
import { initApiResponse, initExaminationStatusContext } from "../constance/constance";
import { logoutAbnormalFunction } from "../function/logoutAbnormalFunction";
import { initApiModelType, initExaminationStatusContextType, statusRequestType, statusResponseType } from "../constance/typeModel";

const useExaminationStatusHooks = (navigateByAgent: Function) => { 
    const { state,dispatch } = useContext(Context);

    //セッションデータ抽出
	const userInfoData = getSession('user')
    const [serviceCodeList, setServiceCodeList] = useState("credit")
    const [ExaminationStatusContext, setExaminationStatusContext] = useState<initExaminationStatusContextType>(initExaminationStatusContext)
    const allServiceData = ExaminationStatusContext.entry.service

    const creditOrderArray = ['visa','master','jcb','amex','diners'] 
    const cvsOrderArray = ['seven', 'lawson','familymart','ministop','yamazaki','seicomart']

    const serviceStopFlag = useMemo(()=>{
        const serviceStopFlag = {
            credit: (allServiceData.credit && (allServiceData.credit.status === 'CANCEL' || allServiceData.credit.status === 'TERMINATION')),
            cvs: (allServiceData.cvs && (allServiceData.cvs.status === 'CANCEL' || allServiceData.cvs.status === 'TERMINATION')),
            bank: (allServiceData.bank && (allServiceData.bank.status === 'CANCEL' || allServiceData.bank.status === 'TERMINATION')),
            atobarai: (allServiceData.atobarai && (allServiceData.atobarai.status === 'CANCEL' || allServiceData.atobarai.status === 'TERMINATION'))
        }
        return serviceStopFlag
    },[allServiceData])
    

    //審査ステータス情報取得
    const getExaminationStatus = useCallback(async() => {
        let response: initApiModelType<statusResponseType> = initApiResponse
        const data = { entry_id: userInfoData.entry_id }
        response = await GetMethod<statusRequestType, statusResponseType>(data, '/status')
        if(response.code !== 200){
            if(response.code === 401) return logoutAbnormalFunction(response,dispatch,navigateByAgent,state.agentPath)
            return navigateByAgent('/system_error')
        } 

        // 型ガード用条件式
        if(response.response_data === undefined) return
        
        // ミニストップにローソンと同じステータスを挿入
        if(response.response_data.entry.service.cvs){
            response.response_data.entry.service.cvs.use_cvs.ministop = response.response_data.entry.service.cvs.use_cvs.lawson
        }

        setServiceCodeList(response.response_data.entry.service_code_list)
        setExaminationStatusContext(response.response_data)
    },[dispatch,navigateByAgent,userInfoData.entry_id,state.agentPath])


    //画面読み込み時処理
    useEffect(() => {
        getExaminationStatus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { allServiceData, serviceCodeList, navigateByAgent, creditOrderArray, cvsOrderArray, serviceStopFlag }
}

export default useExaminationStatusHooks

