import styled from 'styled-components';
import { colors } from '../../../../css/variables/colors';
import { mixins } from '../../../../css/variables/mixins';

const Wrapper = styled.div`
  padding: 12px 20px;
  background: ${colors.themeColor('light_yellow')};
  border: 3px solid ${colors.themeColor('border_box')};
  border-radius: 8px;
  align-items: center;
  ${mixins.screenOver()}{
    display: flex;
  }
  ${mixins.screenUnder()}{
  }
`

const ExminationCancelBarElement = () => {
  return (
    <Wrapper>
      <p className='font-m mb0 bold'>審査状況をご確認ください</p>
    </Wrapper>
  )
}

export default ExminationCancelBarElement