import styled from 'styled-components'
import { colors } from '../../css/variables/colors';
import { ReactNode } from 'react';


type formChildBlockskinStyleType = {
  mt?: number,
  sx?: string
}
const FormChildBlockskinStyle = styled.div<formChildBlockskinStyleType>`
  margin-top: ${props => props.mt}px;
  padding: 20px 12px;
  margin-left: 4px;
  margin-right: 4px;
  border: 1px solid ${colors.themeColor('border_box')};
  border-radius: 8px;
  ${props => props.sx ? props.sx : null};
`;

export const FormChildBlockskin = ({
  mt = 16,
  sx = "",
  children = <></>
}: {
  mt? : number,
  sx? : string,
  children : ReactNode,
}) => (
  <FormChildBlockskinStyle mt={mt} sx={sx}>
    {children}
  </FormChildBlockskinStyle>
);
