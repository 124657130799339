import { useContext, useEffect } from "react"
import { Context } from "../../../stores/Provider"
import { Outlet } from "react-router-dom"

const AgentBeforeRender = () => {
    const {dispatch} = useContext(Context)
    useEffect(()=>{
        dispatch({
          type: 'set-context-data',
          payload: {
            agentPath: '/ocnk-teiki'
          }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    return(
        <Outlet/>
    )
} 

export default AgentBeforeRender