import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { initBankAccountSessionData } from "../constance/sessionData";
import { itemValidators } from "../validators/validationPattern";
import { getSession, setBankAccountInfoData } from "../sessionStorage/sessionStorageMethod";
import { Context } from "../stores/Provider";
import { setIsLoading } from "../function/setIsLoading";
import { bankDataType, setBankDataHandlerType, bankDataFormatResponseDataType } from "../constance/typeModel";


const useEntryContentsBankAccountHooks = () => {
    //プルダウンメニュー型定義
    type initPulldownType = {
        label:string, 
        value:string, 
        disabled:boolean, 
        memo:string,
    }[]
    const [bankPulldownState, setBankPulldownState] = useState<initPulldownType>([])
    const [bankBranchPulldownState, setBankBranchPulldownState] = useState<initPulldownType>([])
    const {state, dispatch} = useContext(Context)
    const [showFlag, setShowFlag] = useState<boolean>(false)
    const [disableFlag, setDisabledFlag] = useState<boolean>(true)
    const merchantInfoData = getSession('merchant')
    const userInfoData = getSession('user')
    const ip_flag = userInfoData.ip_flag
    const bankResponseState = state.entry_bank_pulldown
    const errMsg = state.auth.err_message

    //コンテキストデータ抽出
    const bankAccountState = useMemo(()=>{
        if(state.entry.bank_account === null || state.entry.bank_account === undefined || state.entry.bank_account === initBankAccountSessionData) return getSession('merchant')
        return state.entry.bank_account
    },[state.entry.bank_account])

    const validateErrMsgs = state.validateMessages.bank_account

    //セッションとコンテキストに同時に保存
    const setBankDataHandler = (data: setBankDataHandlerType) => {
        const insertData = {...bankAccountState, ...data}
        setBankAccountInfoData(insertData)
        dispatch({
            type : 'set-entry-bank-account',
            payload : insertData
        })
    }

    //プルダウンをステイトとコンテキストに保存(銀行名)
    const setBankPulldownStateHandler = (data: bankDataType) => {
        setBankPulldownState(data)
    }
    //プルダウンをステイトとコンテキストに保存(支店名)
    const setBankBranchPulldownStateHandler = (data: bankDataType) => {
        setBankBranchPulldownState(data)
    }
    
    //銀行プルダウンセットハンドラ
    const bankListSetHandler = useCallback((response: bankDataFormatResponseDataType) => {
        //0始まりのコードを先頭に持ってくるために一度０始まりだけのリストを作成し、最後で結合する
        let bankNonZeroStartList: initPulldownType = []
        let bankZeroStartList: initPulldownType = []
        let resultArray: initPulldownType = []
        for(let bankCode in response){
            if(bankCode.substring(0,1) === "0"){
                bankZeroStartList.push({label:"", value:response[bankCode].bank_name, disabled:false, memo:bankCode.padStart(4, "0")})
            } else {
                bankNonZeroStartList.push({label:"", value:response[bankCode].bank_name, disabled:false, memo:bankCode.padStart(4, "0")})
            }
        }
        resultArray = bankZeroStartList.concat(bankNonZeroStartList)
        setBankPulldownStateHandler(resultArray)  
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    
    // 画面読み込み時処理
    useEffect(()=>{
        //自動スクロールトップ&showフラグオン
        const timeoutProcess = () => {
            window.scrollTo(0,0)
            setShowFlag(true)
            //ローディング画面を非表示
            setIsLoading(false,dispatch)
        }
        //ローディング画面開始
        setIsLoading(true, dispatch)
        setTimeout(timeoutProcess,1500)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    // 銀行プルダウンセット
    useEffect(()=>{
        bankListSetHandler(state.entry_bank_pulldown)
    },[state.entry_bank_pulldown,bankListSetHandler])

    //銀行名からコードを抽出
    const getBankCode = (response: bankDataFormatResponseDataType, pulldownState: string) => {
        let bankCode: null | string = null;
        for(let code in response){
            if(response[code].bank_name === pulldownState) bankCode = code 
        }
        return bankCode
    }
    //bank_name,bank_codeをリコイルにセット&支店プルダウン作成
    useEffect(()=>{
        //銀行名未選択または銀行取得レスポンスがセットされていないときはリターン
        if(bankAccountState.bank_account_bank_name === "" || Object.keys(bankResponseState).length === 0) return
        const bankBranchList: initPulldownType = []
        const bankName = bankAccountState.bank_account_bank_name
        const bankCode = getBankCode(bankResponseState, bankAccountState.bank_account_bank_name)
        if(bankCode === null){
            setDisabledFlag(true)    
            return setBankBranchPulldownStateHandler(bankBranchList)
        } 
        setDisabledFlag(false)
        setBankDataHandler({bank_account_bank_name:bankName, bank_account_bank_code:bankCode})
        //支店プルダウンの決定
        for(let branchNum in bankResponseState[bankCode].bank_branch){
            const branchObj = bankResponseState[bankCode].bank_branch[branchNum]
            bankBranchList.push({label:branchObj.branch_name, value:branchObj.branch_name, disabled:false, memo:branchNum})
        }
        setBankBranchPulldownStateHandler(bankBranchList)
    //更新は銀行名が決まった時,レスポンスがステイトに保存されたとき、プルダウンが決定したとき
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[bankAccountState.bank_account_bank_name,bankResponseState,bankPulldownState])

    //金融機関名と支店名のvalueをセットするハンドラ
    const setPulldownBankStateHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value
        let bankCode = bankAccountState.bank_account_bank_code
        let branchName = bankAccountState.bank_account_bank_branch
        let branchCode = bankAccountState.bank_account_bank_branch_code
        if(e.target.name === "bank_account_bank_name"){
            //４桁まで上の桁を0埋め
            value = e.target.value
            //支店に関わるデータをクリアする
            branchName = ""
            branchCode = ""
        }
        if(e.target.name === "bank_account_bank_branch"){
            bankBranchPulldownState.forEach((key)=>{
                if(e.target.value !== "" && key.value === e.target.value){
                    branchCode = bankResponseState[bankCode].bank_branch[key.memo].branch_code
                }
            })
            branchName = e.target.value
        }
        return setBankDataHandler({...bankAccountState, [e.target.name]:value,  bank_account_bank_branch:branchName, bank_account_bank_branch_code:branchCode})
    }
    //入力時に即座にバリデーションをかける関数
    const onClickValidator = (e: React.ChangeEvent<HTMLInputElement>) => {
        const validateMessage = itemValidators("bank_account",e.target.id,e.target.value)
        dispatch({
            type:"set-validate-bank_account",
            payload:{
                ...state.validateMessages.bank_account,
                [e.target.id]:validateMessage
            }
        })
    }
    //pulldown以外のステイトセットハンドラ
    const setBankAccountBankStateHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const smallToBigArray = [ ['ｬ','ﾔ'],['ｭ','ﾕ'],['ｮ','ﾖ'],['ｯ','ﾂ'],['ｧ','ｱ'],['ｨ','ｲ'],['ｩ','ｳ'],['ｪ','ｴ'],['ｫ','ｵ'] ]
        //銀行口座名の時、小文字カナを大文字カナに変換する
        if(e.target.id === "bank_account_name"){
            smallToBigArray.forEach((item)=>{
                e.target.value = e.target.value.replace(item[0],item[1])      
            })
        } 
        //入力時即座にバリデーションをかける
        onClickValidator(e)
        return setBankDataHandler({...bankAccountState, [e.target.id]:e.target.value})
    }

    //ラジオボタン用ハンドラ
    const setRadioBankAccountBankStateHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        return setBankDataHandler({...bankAccountState, [e.target.name]:e.target.value})
    }

    // 口座情報ラジオボタン
    const radioItemsAccount = [
        { label: '普通', value: "1" },
        { label: '当座', value: "2" },
    ]
    
    return { radioItemsAccount, bankAccountState, merchantInfoData, bankPulldownState, bankBranchPulldownState, showFlag,ip_flag, errMsg, disableFlag, setBankAccountBankStateHandler, setRadioBankAccountBankStateHandler, setPulldownBankStateHandler, validateErrMsgs }
}

export default useEntryContentsBankAccountHooks