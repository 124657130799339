import React, { ReactNode } from 'react';

const EntryStepBar = ({
  children,
}: {
  children: ReactNode
}) => {

  return (
    <li className='step_bar'>
      <div className='step_bar_inner'>
        {children}
      </div>
    </li>
  )
}

export default EntryStepBar