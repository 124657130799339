import React, { ChangeEventHandler, ReactNode } from 'react';
import styled from 'styled-components';
import { colors } from '../../../css/variables/colors';
import { fonts } from '../../../css/variables/fonts';

type radioButtonGroupStyleType = {
  mt?: number,
  iserror?: string
}

const RadioButtonGroupStyle = styled.div<radioButtonGroupStyleType>`
  display: flex;
  margin: ${props => props.mt}px 4px 4px 4px;
  border:1px solid ${props => props.iserror ? colors.themeColor('border_error') + ' !important' : colors.themeColor('border_input')};
  border-radius :6px;
  &>*{
    flex: 1;
  }
  input{
    display: none;
  }
`;
const RadioButtonItem = styled.div`
  &:first-child label{
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  &:last-child label{
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;
const RadioButtonLabelStyle = styled.label`
  background:${colors.themeColor('background_disabled')};
  border:1px solid #D6D6D6;
  padding: 12px 12px;
  color: #959595;
  display: inline-block;
  text-align: center;
  width: 100%;
  height:100%;
  cursor: pointer;
  input:checked + &{
    background: ${colors.themeColor("background_main")};
    color: ${colors.themeColor('primary_dark')};
    font-weight: bold;
    border: 1px solid ${colors.themeColor('primary_light')};
  }
`
const Error = styled.span`
  display: block;
  color: ${colors.themeColor('text_error')};
  font-size: ${fonts.fontSizeRem('s')};
  margin-top: 4px;
`
;

export const RadioButtonGroup = ({
  name,
  radioitems,
  mt = 16,
  state,
  setState,
  iserror,
  disabled,
  refProp
}: {
  name: string,
  radioitems: {label: string |ReactNode, value:string|number}[],
  mt?: number,
  state: string | number | boolean,
  setState?: ChangeEventHandler<HTMLElement>,
  iserror?: string,
  disabled?: boolean,
  refProp?: any
}) => {
  
  return <>
    <RadioButtonGroupStyle mt={mt} iserror={iserror}>
      {radioitems.map((item: {label:string|ReactNode, value:string|number}, index: number) => (
        <RadioButtonItem key={index}>
          <input type='radio' name={name} value={item.value} checked={(item.value === 'true' || item.value === 'false' ? JSON.parse(item.value) : item.value ) === state} onChange={setState} id={name+'_'+index} data-testid={name} disabled={disabled ? true : false} ref={refProp}/>
          <RadioButtonLabelStyle htmlFor={name+'_'+index}>
            {item.label}
          </RadioButtonLabelStyle>
        </RadioButtonItem>
      ))}
    </RadioButtonGroupStyle>
    {iserror  !== '' ? (<Error>{iserror}</Error>) : (<></>)}
    </>;
};
