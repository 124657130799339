import { useMemo } from "react"
import { ocnkTeikiSetCountBankAccountData, ocnkTeikiSetCountMerchantData, ocnkTeikiSetCountShopAccountData, setCompleteCount, setCountBankAccountData, setCountMerchantData, setCountShopAccountData } from "../function/setCountData"
import { getSession } from "../sessionStorage/sessionStorageMethod"

export const useEntryTopHooks = (navigateByAgent:Function) => {
    const agentMasterInfoData = getSession('agent_master')
    const merchantInfoData = getSession('merchant')
    const bankAccountInfoData = getSession('bank_account')
    const representativeInfoData = getSession('representative')
    const operationInfoData = getSession('operation')
    const economicInfoData = getSession('economic_condition')
    const fileInfoData = getSession('files')
    const shopInfoData = getSession('shop')
    const reviewContentsDivisionInfoData = getSession('review_contents_division')
    const userInfoData = getSession('user')
    const ObjToDevide = reviewContentsDivisionInfoData
    const ipFlag = userInfoData.ip_flag

    //入力数カウント用店舗情報データ
    const countMerchantData = useMemo(() => {
        switch(userInfoData.agent_master_id){
            case 1:
                return setCountMerchantData(merchantInfoData,representativeInfoData,operationInfoData,fileInfoData,agentMasterInfoData)
            case 3:
            case 4:
            case 5:
            case 7:
                return ocnkTeikiSetCountMerchantData(merchantInfoData,representativeInfoData,operationInfoData,fileInfoData,agentMasterInfoData,userInfoData)
            default :
                return setCountMerchantData(merchantInfoData,representativeInfoData,operationInfoData,fileInfoData,agentMasterInfoData)
        }
    },[merchantInfoData,representativeInfoData,operationInfoData,fileInfoData,agentMasterInfoData,userInfoData])

    //入力数カウント用口座情報データ
    const countBankAccountData = useMemo(()=>{
        switch(userInfoData.agent_master_id){
            case 1:
                return setCountBankAccountData(bankAccountInfoData,fileInfoData)
            case 3:
            case 4:
            case 5:
            case 7:
                return ocnkTeikiSetCountBankAccountData(bankAccountInfoData,fileInfoData,userInfoData)
            default :
                return setCountBankAccountData(bankAccountInfoData,fileInfoData)
        }
    },[bankAccountInfoData,fileInfoData,userInfoData])

    //入力数カウント用ショップ情報データ
    const countShopAccountData = useMemo(() => {
        switch(userInfoData.agent_master_id){
            case 1:
                return setCountShopAccountData(shopInfoData,fileInfoData,agentMasterInfoData,economicInfoData,ObjToDevide,merchantInfoData)
            case 3:
            case 4:
            case 5:
            case 7:
                return ocnkTeikiSetCountShopAccountData(shopInfoData,fileInfoData,ObjToDevide,userInfoData)          
            default :
                return setCountShopAccountData(shopInfoData,fileInfoData,agentMasterInfoData,economicInfoData,ObjToDevide,merchantInfoData)
        }
    },[shopInfoData,fileInfoData,ObjToDevide,agentMasterInfoData,economicInfoData,merchantInfoData,userInfoData])

    //入力完了値表示
    const completeCountData = useMemo(()=>{
        let completeFlags = {merchant:false, bank:false, shop:false}
        const merchantWholeData = Object.keys(countMerchantData).length
        const bankWholeData = Object.keys(countBankAccountData).length
        const shopWholeData = Object.keys(countShopAccountData).length
        const merchantCompleteData = setCompleteCount(countMerchantData)
        const bankCompleteData = setCompleteCount(countBankAccountData)
        const shopCompleteData = setCompleteCount(countShopAccountData)
        if(merchantWholeData === merchantCompleteData) completeFlags.merchant = true
        if(bankWholeData === bankCompleteData) completeFlags.bank = true
        if(shopWholeData === shopCompleteData) completeFlags.shop = true
        let completeCountData = {
            merchant:{
                complete:merchantCompleteData,
                whole:merchantWholeData,
                all_complete_flag: completeFlags.merchant
            },
            bank:{
                complete:bankCompleteData,
                whole:bankWholeData,
                all_complete_flag: completeFlags.bank
            },
            shop:{
                complete:shopCompleteData,
                whole:shopWholeData,
                all_complete_flag: completeFlags.shop
            },
        }
        return completeCountData
    },[countBankAccountData,countMerchantData,countShopAccountData])

    //ダッシュボード遷移画面
    const moveToDashboard = () => {
        return navigateByAgent('/dashboard')
    }
    //加盟店情報画面表示
    const moveToEntryMerchant = () => {
        return navigateByAgent('/entry/merchant')
    }    
    //口座情報画面表示
    const moveToEntryBankAccount = () => {
        return navigateByAgent('/entry/bank_account')
    }    
    //ショップ情報画面表示
    const moveToEntryShop = () => {
        return navigateByAgent('/entry/shop')
    }    

    return { moveToEntryMerchant, moveToDashboard, moveToEntryBankAccount, moveToEntryShop,completeCountData, userInfoData,ipFlag }
}