import { useEffect } from "react";
import ErrorBoundary from "../../../api/ErrorBoundary";
import useGTMHooks from "../../../hooks/useGTMHooks";
import ResetPasswordMail from "../renderPages/ResetPasswordMail";
import { agentMasterLists } from "../../../constance/constance";

const ResetPasswordMailBeforeRender = () => {
    const { pushDataLayerHandler } = useGTMHooks()

    useEffect(()=>{
      pushDataLayerHandler()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return(
        <ErrorBoundary>
            <ResetPasswordMail agentMasterList={agentMasterLists.ocnk}/>
        </ErrorBoundary>
    )
}

export default ResetPasswordMailBeforeRender