import { useContext } from "react";
import { PostMethod } from "../api/Api"
import { getSession, setBankAccountInfoData, setMerchantInfoData, setOperationInfoData, setRepresentativeInfoData, setShopInfoData } from "../sessionStorage/sessionStorageMethod";
import { Context } from "../stores/Provider";
import { setIsLoading } from "../function/setIsLoading";
import { initShopSessionDataType, initApiModelType, entryInfoPostRequestDataType, entryInfoPostResponseDataType, initUserSessionDataType, initBankAccountSessionDataType, entryInfoMerchantRequestDataType, entryInfoShopRequestDataType } from "../constance/typeModel";
import { initApiResponse } from "../constance/constance"
import { paymentSiteChangeToCodeHandler } from "../function/paymentSiteToCode";

const useEntryConfirmHooks = (navigateByAgent: Function, redirect?: boolean) => {
	
	//セッションデータ抽出
	const userInfoData = getSession('user')
	const merchantInfoData = getSession('merchant')
	const bankAccountInfoData = getSession('bank_account')
	const representativeInfoData = getSession('representative')
	const operationInfoData = getSession('operation')
	const shopInfoData = getSession('shop')
	const reviewContentsDevideInfoData = getSession('review_contents_division')
	const economicInfoData = getSession('economic_condition')
	const systemSettingInfoData = getSession('system_setting_master')
	const {state, dispatch} = useContext(Context)
	const isOpenModal = state.is_open_modal

    //レスポンスをリコイルにセット
    const setRecoilDataHandler = (response: initApiModelType<entryInfoPostResponseDataType>) => {
		let merchantObj = {}
		let representativeObj = {}
		let operatorObj = {}
		let bankObj = {}
		if(response.response_data === undefined) return
		for(let merchantItem in response.response_data.merchant){
			if(merchantItem.split('_')[0] === "merchant"){
				merchantObj = {...merchantObj,[merchantItem]:response.response_data.merchant[merchantItem]}
			}
			if(merchantItem.split('_')[0] === "representative"){
				representativeObj = {...representativeObj,[merchantItem]:response.response_data.merchant[merchantItem]}
			}
			if(merchantItem.split('_')[0] === "operator" || merchantItem.split('_')[0] === "support"){
				operatorObj = {...operatorObj,[merchantItem]:response.response_data.merchant[merchantItem]}
			}
		}
		for(let merchantItem in response.response_data.merchant){
			if(merchantItem.split('_')[0] === "bank"){
				bankObj = {...bankObj,[merchantItem]:response.response_data.merchant[merchantItem]}
			}
		}
		
		if(response.response_data.shop === undefined) return

		setMerchantInfoData({...merchantInfoData,...merchantObj})
		setRepresentativeInfoData({...representativeInfoData,...representativeObj})
		setOperationInfoData({...operationInfoData,...operatorObj})
		setBankAccountInfoData({...bankAccountInfoData,...bankObj})
		setShopInfoData({...shopInfoData,...response.response_data.shop })
	}

	type devideDataType = {
		[key: string]: string,
		antique_flag: string,
		btob_flag: string,
		estimate_flag: string,
		online_local_flag: string,
		prepaid_continuous_flag: string,
		purchase_points_flag: string,
		real_store_flag: string,
		rental_flag: string,
		reserve_item_flag: string,
		subscription_flag: string,
	}
	
	const setReviewContentsNullHandler = (shopData: initShopSessionDataType, deivdeData :devideDataType, merchantData:{merchant_type:string}) => {
		const setNameObj: {
			[key: string]: string[],
			antique_flag: ["shop_addition_antique_flag"],
			btob_flag: ["shop_addition_btob_code"],
			estimate_flag: ["shop_addition_estimated_product_flag"],
			online_local_flag: ["shop_addition_online","shop_addition_offline"],
			prepaid_continuous_flag: ["shop_addition_prepaid_flag"],
			purchase_points_flag: ["shop_addition_point_purchase_flag"],
			real_store_flag: ["shop_addition_physical_store_flag","shop_addition_physical_store_url_flag","shop_addition_physical_store_url"],
			rental_flag: ["shop_addition_rental_flag","shop_addition_rental_product_url"],
			reserve_item_flag: ["shop_addition_reserved_product_flag","shop_addition_reservation_period_flag"],
			subscription_flag: ["shop_addition_subscription_flag","shop_addition_subscription_code"],
		} = {
			antique_flag: ["shop_addition_antique_flag"],
			btob_flag: ["shop_addition_btob_code"],
			estimate_flag: ["shop_addition_estimated_product_flag"],
			online_local_flag: ["shop_addition_online","shop_addition_offline"],
			prepaid_continuous_flag: ["shop_addition_prepaid_flag"],
			purchase_points_flag: ["shop_addition_point_purchase_flag"],
			real_store_flag: ["shop_addition_physical_store_flag","shop_addition_physical_store_url_flag","shop_addition_physical_store_url"],
			rental_flag: ["shop_addition_rental_flag","shop_addition_rental_product_url"],
			reserve_item_flag: ["shop_addition_reserved_product_flag","shop_addition_reservation_period_flag"],
			subscription_flag: ["shop_addition_subscription_flag","shop_addition_subscription_code"],
		}
		let resultObj: {[key: string]:string | number | boolean | null} = {}
		for(let flagName in setNameObj){
			const shopItemArray = setNameObj[flagName]
			//出し分けフラグがtrueの時、すでに入っているデータを入れる
			if(flagName === 'real_store_flag') continue
			if(deivdeData[flagName]){
				shopItemArray.forEach((itemName: string)=>{
					const shopItemValue = shopData[itemName]
					resultObj[itemName] = shopItemValue	
				})
			//falseの場合、nullを入れる。
			} else {
				shopItemArray.forEach((itemName: string)=>{
					resultObj[itemName] = null	
				})
			}	
		}
		//実店舗のみ条件が多いため別処理
		if(deivdeData.real_store_flag && merchantData.merchant_type === '2'){
			setNameObj.real_store_flag.forEach((itemName: string)=>{
				const shopItemValue = shopData[itemName]
				resultObj[itemName] = shopItemValue	
			})
		} else {
			setNameObj.real_store_flag.forEach((itemName: string)=>{
				resultObj[itemName] = null	
			})
		}
		return resultObj
	}

	// 経済条件セット
	const setEconomicData = () => {
		let economicInsertData = economicInfoData
		let creditSystemInsert = {...economicInfoData.credit.system_setting}
        let bankSystemInsert = {...economicInfoData.bank.system_setting}
        let cvsSystemInsert = {...economicInfoData.cvs.system_setting}
		let creditEconomicInsert = {...economicInfoData.credit.economic_requirement}
        let bankEconomicInsert = {...economicInfoData.bank.economic_requirement}
        let cvsEconomicInsert = {...economicInfoData.cvs.economic_requirement}
        let atobaraiEconomicInsert = {...economicInfoData.atobarai.economic_requirement}
		
		// cgi_url追加
        if(systemSettingInfoData.credit_cgi_agent_flag && systemSettingInfoData.credit_cgi_url_agent !== null){
            creditSystemInsert.cgi_url = systemSettingInfoData.credit_cgi_url_agent.replace('{accountId}', shopInfoData.account_id)
        }
        if(systemSettingInfoData.bank_cgi_agent_flag && systemSettingInfoData.bank_cgi_url_agent !== null){
            bankSystemInsert.cgi_url = systemSettingInfoData.bank_cgi_url_agent.replace('{accountId}', shopInfoData.account_id)
        }
        if(systemSettingInfoData.cvs_cgi_agent_flag && systemSettingInfoData.cvs_cgi_url_agent !== null){
            cvsSystemInsert.cgi_url = systemSettingInfoData.cvs_cgi_url_agent.replace('{accountId}', shopInfoData.account_id)
        }
		//支払いサイト変換
        creditEconomicInsert.payment_site = paymentSiteChangeToCodeHandler(creditEconomicInsert.payment_site) 
        bankEconomicInsert.payment_site = paymentSiteChangeToCodeHandler(bankEconomicInsert.payment_site) 
        cvsEconomicInsert.payment_site = paymentSiteChangeToCodeHandler(cvsEconomicInsert.payment_site) 
        atobaraiEconomicInsert.payment_site = paymentSiteChangeToCodeHandler(atobaraiEconomicInsert.payment_site) 

		economicInsertData = {
			...economicInsertData, 
			credit:{
				economic_requirement:creditEconomicInsert, 
				system_setting:creditSystemInsert
			}, 
			bank:{
				economic_requirement:bankEconomicInsert,
				system_setting:bankSystemInsert
			},
			cvs:{
				economic_requirement:cvsEconomicInsert, 
				system_setting:cvsSystemInsert
			},
			atobarai:{
				...economicInsertData.atobarai,
				economic_requirement:atobaraiEconomicInsert
			}
		}
		return economicInsertData
	}

	// API送信データを代理店とIPの有無によって変更
	const setMainData = (
		userInfoData: initUserSessionDataType, 
		postStatus: string, 
		merchantInsertData: entryInfoMerchantRequestDataType,
		bankAccountInfoData: initBankAccountSessionDataType,
		shopInsertData: entryInfoShopRequestDataType,
	): entryInfoPostRequestDataType => {
		const economicInsertData = setEconomicData()
		if(userInfoData.ip_flag){
			let mainData:entryInfoPostRequestDataType = {
				...userInfoData,
				status:postStatus,
				merchant:{
					merchant:merchantInsertData,
					bank:{
						...bankAccountInfoData,
						bank_account_category: null
					}
				},
				shop:shopInsertData,
				services:economicInsertData
			}
			return mainData
		} else {
			let mainData:entryInfoPostRequestDataType = {
				...userInfoData,
				status:postStatus,
				merchant:{
					merchant:merchantInsertData,
					bank:bankAccountInfoData
				},
				shop:shopInsertData,
				services:economicInsertData
			}
			return mainData
		}
	}


    //ステータスをENTRY_OKに変更して、APIを投げる
    const entryComplete = async() => {
		let response: initApiModelType<entryInfoPostResponseDataType> = initApiResponse
		const setReviewContentsNullData = setReviewContentsNullHandler(shopInfoData,reviewContentsDevideInfoData,merchantInfoData)
		const merchantWebsiteUrl = economicInfoData.cvs.system_setting.select_flag ? merchantInfoData.merchant_website_url : ""
		const shopNameAtobarai = economicInfoData.atobarai.system_setting.select_flag ? (shopInfoData.shop_atobarai_customer_notice_store_name !== "" ? shopInfoData.shop_atobarai_customer_notice_store_name.replace(/-/g,'－') : "") : ""
		const shopCvsWebsiteName = economicInfoData.cvs.system_setting.select_flag ? shopInfoData.shop_cvs_website_name : ""
		const shopCvsWebsiteNameKana = economicInfoData.cvs.system_setting.select_flag ? shopInfoData.shop_cvs_website_name_kana : ""
		const shopAdditionPriceRangeMax = economicInfoData.cvs.system_setting.select_flag ? shopInfoData.shop_addition_price_range_max : null
		const shopAdditionPriceRangeMin = economicInfoData.cvs.system_setting.select_flag ? shopInfoData.shop_addition_price_range_min : null
		
        const merchantInsertData = {
            ...merchantInfoData,
            ...representativeInfoData,
            ...operationInfoData,
			merchant_website_url: merchantWebsiteUrl
        }
		let shopInsertData = {
			...shopInfoData,
			...setReviewContentsNullData,
			shop_atobarai_customer_notice_store_name:shopNameAtobarai,
			shop_cvs_website_name:shopCvsWebsiteName,
			shop_cvs_website_name_kana:shopCvsWebsiteNameKana,
		}
		if(userInfoData.agent_master_id === 1){
			shopInsertData = {
				...shopInsertData,
				shop_addition_price_range_max:shopAdditionPriceRangeMax,
				shop_addition_price_range_min:shopAdditionPriceRangeMin,
			}
		}
		
		const postStatus = state.user_status.status === "WAITING" ? "FIXED" : "ENTRY_OK"

		const mainData = setMainData(userInfoData,postStatus,merchantInsertData,bankAccountInfoData,shopInsertData)
		//ローディング画面開始
        setIsLoading(true, dispatch)
        response = await PostMethod<entryInfoPostRequestDataType, entryInfoPostResponseDataType>(mainData, '/entry_info')

        return response
    }
    //API正常時処理
    const normal = (response: initApiModelType<entryInfoPostResponseDataType>) => {
		const navigatePath = redirect ? '/redirect_completed' : '/entry_completed' 
		setRecoilDataHandler(response)
		//ローディング画面開始
        setIsLoading(false, dispatch)
        return navigateByAgent(navigatePath)
    }
    //API異常時処理
    const abnormal = () => {
		const navigatePath = redirect ? '/redirect_confirm' : '/entry_confirm' 
		dispatch({
			type:"set-modal-flag",
			payload:{
				path : 'confirm',
				type : 'error',
				flag : true,
				message :'申し込み登録が完了できませんでした。'
			}
		})
		//ローディング画面開始
		setIsLoading(false, dispatch)
        return navigateByAgent(navigatePath)
    }
	//ダッシュボード遷移ハンドラ
    const navigateDashboardHandler = () => {
        return navigateByAgent('/dashboard')
    }
    return {isOpenModal,entryComplete,normal,abnormal,navigateDashboardHandler,userInfoData}
}

export default useEntryConfirmHooks