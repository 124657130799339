import styled from 'styled-components';
import { colors } from '../../../../css/variables/colors';
import { BackGround } from '../../../layouts/BackGround';
import { Box } from '../../../layouts/Box';
import { UseStartTable } from '../../../ui-parts/ocnk';
import AnotherTabLink from '../../../ui-parts/ocnk/AnotherTabLink';
import { fonts } from '../../../../css/variables/fonts';
import { FlexBox } from '../../../layouts/FlexBox';
import { mixins } from '../../../../css/variables/mixins';
import { creditStatusType, initEconomicSessionDataType, serviceStatusType } from '../../../../constance/typeModel';

const TitleWrapper = styled.h2`
  background-color:${colors.themeColor('background_whitegray')} !important;
  margin-top:0px;
  margin-bottom:36px;
  border-bottom:0px;
  padding-top:8px;
  padding-bottom:8px;
  padding-left:10px;
`

type serviceTypeTitleType = {
  serviceStopFlag: boolean
}
const ServiceTypeTitle = styled.h3<serviceTypeTitleType>`
  ${props => props.serviceStopFlag ? 'margin-bottom: 4px;' : ''}
`

type graySpanType = {
  serviceStopFlag: boolean
}
const GraySpan = styled.span<graySpanType>`
${props => props.serviceStopFlag ? 'color:'+colors.themeColor('text_lightgray')+';' : ''}
`

const CancelTerminateRedTextStyle = styled.p`
  color:${colors.themeColor('text_error')};
`
const CancelTerminatePhraseStyle = styled.span`
  background-color: ${colors.themeColor('text_lightgray')};
  padding: 4px;
  border-radius:20px;
  color: ${colors.themeColor('text_white')};
  font-size:${fonts.fontSizeRem('ms')};
`
const TableWrapper = styled.table`
  width: 65%;
  ${mixins.screenUnder()}{
    width: 100%;
  }
`

type innerTrType = {
  bottomBorderDouble?: boolean,
  topBorderDouble?: boolean
}
const InnerTr = styled.tr<innerTrType>`
  width: 100%;
  ${props => props.bottomBorderDouble ? "border-bottom: double 8px" + colors.themeColor('border_box') + ";" : ""}
  ${props => props.topBorderDouble ? "border-top: double 8px" + colors.themeColor('border_box') + ";" : ""}
`

type innerThType = {
  credit?: boolean,
  variant?: boolean,
  bottomBorderDouble?: boolean
}
const InnerTh = styled.th<innerThType>`
  border: solid 1px ${colors.themeColor('border_box')};
  padding: 8px 12px;
  white-space: nowrap;
  ${props => props.credit ? "width: 30%;" : ""}
  ${props => props.variant ? "background-color: " + colors.themeColor('background_whitegray') + ";" : ""}
  ${props => props.bottomBorderDouble ? "border-bottom: double 8px" + colors.themeColor('border_box') + ";" : ""}
  ${mixins.screenUnder()}{
    white-space: normal;
    width:112px;
    font-size: ${fonts.fontSizeRem('s')};
  }
`

type innerTdType = {
  variant?: boolean,
  bold?: boolean
}
const InnerTd = styled.td<innerTdType>`
  width: 70%;
  border: solid 1px #D6E3EB;
  padding: 8px 12px;
  ${props => props.variant ? "background-color: " + colors.themeColor('background_whitegray') + ";" : ""}
  ${props => props.bold ? "font-weight: bold;" : ""}
  ${mixins.screenOver()}{
    white-space: nowrap;
  }
  ${mixins.screenUnder()}{
    word-break: break-all;
  }
`
const TableCaption = styled.caption`
  font-weight: bold;
  margin-bottom: 0px;
  margin-top: 24px;
  text-align: left;
`
const OcnkTeikiUseStartElement = ({
  serviceData,
  managementData,
  serviceCodeList,
  atobaraiManagementData,
  serviceStatusData,
  service,
  phraseByAgent,
  openExamiantionFlagState,
  examinationOpenHandler,
  ContentStatus,
  economicInfoData,
  creditBrandData,
  serviceStopFlag
}: {
  serviceData: {
    credit: string[][],
    cvs: string[][],
    bank: string[][],
    atobarai: string[][]
  },
  managementData?: string[][],
  serviceCodeList: string,
  atobaraiManagementData?: string[][],
  serviceStatusData: serviceStatusType,
  service: string,
  phraseByAgent: {
    bank:{
      deposit_target: string,
      insufficient_amount: string,
      overdeposit: string,
      early_payment: string,
      payment_due_date: string
    },
    cvs:{
      payment_due_date: string
    }
  },
  openExamiantionFlagState: {
    credit: boolean, bank: boolean, cvs: boolean
  },
  examinationOpenHandler: Function,
  ContentStatus: Function,
  economicInfoData: initEconomicSessionDataType,
  creditBrandData: creditStatusType,
  serviceStopFlag: {
    credit: boolean,
    cvs: boolean,
    bank: boolean,
    atobarai: boolean 
  }
}) => {
  
  return (
    <>
      {service === 'other' &&
        <BackGround>
            <div>
              <TitleWrapper>クレジットカード決済のご利用</TitleWrapper>
              <p className='red_text'>以下の設定情報はサービスご利用するために必要になりますので大切に保管してください。</p>
            </div>
            <div>
              <h2 className="mt36 mb20">設定情報</h2>
              <p className='newline'>{'IPコードとZキーはおちゃのこネットにて自動設定します。\nショップでの利用開始処理についてはおちゃのこネットの管理画面マニュアル等にてご確認ください。'}</p>
              {/* serviceCodeListにcreditが入っており、IPコード無しかつキャンセル・解約でない以外の場合にのみ表示 */}
              {(serviceCodeList.includes("credit") && !(serviceData.credit && serviceData.credit[0][1] === "" && !serviceStopFlag.credit)) &&
                <>
                  <ServiceTypeTitle serviceStopFlag={serviceStopFlag.credit}><GraySpan serviceStopFlag={serviceStopFlag.credit}>クレジットカード決済</GraySpan>　{serviceStatusData.credit.status === 'CANCEL' && <CancelTerminatePhraseStyle>キャンセル済み</CancelTerminatePhraseStyle>}{serviceStatusData.credit.status === 'TERMINATION' && <CancelTerminatePhraseStyle>解約済み</CancelTerminatePhraseStyle>}</ServiceTypeTitle>
                  {!serviceStopFlag.credit ?
                    <div className='ml16'>
                      <UseStartTable
                        tableData={serviceData.credit}
                      />
                        <TableWrapper>
                          <TableCaption>ご利用カードブランドと支払方法</TableCaption>
                          <tbody>
                            <tr>
                              <InnerTh variant rowSpan={2}>カードブランド</InnerTh>
                              <InnerTh variant rowSpan={2}>審査状況</InnerTh>
                              <InnerTh variant colSpan={4} rowSpan={1}>支払い方法</InnerTh>
                            </tr>
                            <tr>
                              <InnerTh variant rowSpan={1}>一括</InnerTh>
                              <InnerTh variant rowSpan={1}>分割</InnerTh>
                              <InnerTh variant rowSpan={1}>リボ</InnerTh>
                              <InnerTh variant rowSpan={1}>二回払い・ボーナス</InnerTh>
                            </tr>
                            {creditBrandData.map((brandData)=>(
                              <tr key={brandData.label}>
                                <InnerTd>{brandData.label}</InnerTd>
                                <InnerTd className='align_center'>{brandData.status}</InnerTd>
                                <InnerTd className='align_center'>{brandData.div_status.one ? "〇" : "-"}</InnerTd>
                                <InnerTd className='align_center'>{brandData.div_status.div ? "〇" : "-"}</InnerTd>
                                <InnerTd className='align_center'>{brandData.div_status.rivo ? "〇" : "-"}</InnerTd>
                                <InnerTd className='align_center'>{brandData.div_status.div2_bonus ? "〇" : "-"}</InnerTd>
                              </tr>
                            ))}
                          </tbody>
                        </TableWrapper>

                        <TableWrapper>
                          <TableCaption>システム設定内容</TableCaption>
                          <tbody>
                            <InnerTr>
                              <InnerTh>売上処理方法</InnerTh>
                              <InnerTd>{economicInfoData.credit.system_setting.auth ? "仮売決済" : "即時決済"}</InnerTd>
                            </InnerTr>
                            <InnerTr>
                              <InnerTh>セキュリティコード</InnerTh>
                              <InnerTd>{economicInfoData.credit.system_setting.cvv ? "利用する" : "利用しない"}</InnerTd>
                            </InnerTr>
                            <InnerTr>
                              <InnerTh>EMV3Dセキュア（3Dセキュア2.0）</InnerTh>
                              <InnerTd>{economicInfoData.credit.system_setting.threed_secure ? "利用する" : "利用しない"}</InnerTd>
                            </InnerTr>
                          </tbody>
                        </TableWrapper>
                      <p className='bold red_text mt16'>●必ずご確認ください●</p>
                      <FlexBox responsiveGap={0}>
                        <p>※</p>
                        <p className='newline bold'>{'ご注文受注時に、クレジットカード決済のステータスは仮売上状態（決済未完了状態）となります。\n売上として計上するにあたり、おちゃのこネット管理画面より売上処理が必要になります。\n操作方法については以下URLをご参照ください。\n'}<AnotherTabLink href={'https://www.ocnk.net/faq/index.php?action=artikel&id=1485'}>https://www.ocnk.net/faq/index.php?action=artikel&id=1485</AnotherTabLink></p>
                      </FlexBox>
                      <FlexBox responsiveGap={0}>
                        <p>※</p>
                        <p className='newline bold'>{'EMV3Dセキュア（3Dセキュア2.0）ご利用の場合、\n認証が通った取引であっても、金額変更を行った取引は3Dセキュア対象外となります。'}</p>
                      </FlexBox>
                    </div>
                    :
                    serviceStatusData.credit.status === 'CANCEL' ?
                      <CancelTerminateRedTextStyle>キャンセル済みのためご利用いただけません。</CancelTerminateRedTextStyle>
                      :
                      <CancelTerminateRedTextStyle>解約済みのためご利用いただけません。</CancelTerminateRedTextStyle>
                  }
                </>
              }
              {/* serviceCodeListにbankが入っており、IPコード無しかつキャンセル・解約でない以外の場合にのみ表示 */}
              {(serviceCodeList.includes("bank") && !(serviceData.bank && serviceData.bank[0][1] === "" && !serviceStopFlag.bank)) &&
                <>
                  <hr/>
                  <ServiceTypeTitle serviceStopFlag={serviceStopFlag.bank}><GraySpan serviceStopFlag={serviceStopFlag.bank}>ネットバンク決済（入金おまかせサービス）</GraySpan>　{serviceStatusData.bank.status === 'CANCEL' && <CancelTerminatePhraseStyle>キャンセル済み</CancelTerminatePhraseStyle>}{serviceStatusData.bank.status === 'TERMINATION' && <CancelTerminatePhraseStyle>解約済み</CancelTerminatePhraseStyle>}</ServiceTypeTitle>
                  {!serviceStopFlag.bank ?
                    <div className='ml16'>
                      <UseStartTable
                        tableData={serviceData.bank}
                      />
                      <p className='bold mb0 mt24' onClick={()=>examinationOpenHandler('bank')}>ネットバンク決済（入金おまかせサービス）詳細 {openExamiantionFlagState.bank ? "▲" : "▼"}</p>
                      {openExamiantionFlagState.bank &&
                        <TableWrapper>
                            <TableCaption>システム設定内容</TableCaption>
                            <tbody>
                                <InnerTr>
                                  <InnerTh>入金対象</InnerTh>
                                  <InnerTd>{phraseByAgent.bank.deposit_target}</InnerTd>
                                </InnerTr>
                                <InnerTr>
                                  <InnerTh>金額不足</InnerTh>
                                  <InnerTd>{phraseByAgent.bank.insufficient_amount}</InnerTd>
                                </InnerTr>
                                <InnerTr>
                                  <InnerTh>過剰入金</InnerTh>
                                  <InnerTd>{phraseByAgent.bank.overdeposit}</InnerTd>
                                </InnerTr>
                                <InnerTr>
                                  <InnerTh>期日後入金</InnerTh>
                                  <InnerTd>{phraseByAgent.bank.early_payment}</InnerTd>
                                </InnerTr>
                                <InnerTr>
                                  <InnerTh>ユーザー用支払期日</InnerTh>
                                  <InnerTd>{phraseByAgent.bank.payment_due_date}</InnerTd>
                                </InnerTr>
                              
                          </tbody>
                        </TableWrapper>
                      }
                      </div>
                    :
                    serviceStatusData.bank.status === 'CANCEL' ?
                      <CancelTerminateRedTextStyle>キャンセル済みのためご利用いただけません。</CancelTerminateRedTextStyle>
                      :
                      <CancelTerminateRedTextStyle>解約済みのためご利用いただけません。</CancelTerminateRedTextStyle>
                  }
                </>
              }
              {/* serviceCodeListにcvsが入っており、IPコード無しかつキャンセル・解約でない以外の場合にのみ表示 */}
              {(serviceCodeList.includes("cvs") && !(serviceData.cvs && serviceData.cvs[0][1] === "" && !serviceStopFlag.cvs)) &&
                <>
                  <hr/>
                  <ServiceTypeTitle serviceStopFlag={serviceStopFlag.cvs}><GraySpan serviceStopFlag={serviceStopFlag.cvs}>コンビニ決済</GraySpan>　{serviceStatusData.cvs.status === 'CANCEL' && <CancelTerminatePhraseStyle>キャンセル済み</CancelTerminatePhraseStyle>}{serviceStatusData.cvs.status === 'TERMINATION' && <CancelTerminatePhraseStyle>解約済み</CancelTerminatePhraseStyle>}</ServiceTypeTitle>
                  {!serviceStopFlag.cvs ?
                    <div className='ml16'>
                      <UseStartTable
                        tableData={serviceData.cvs}
                      />
                      <p className='bold mb0 mt24' onClick={()=>examinationOpenHandler('cvs')}>コンビニ決済詳細 {openExamiantionFlagState.cvs ? "▲" : "▼"}</p>
                      {openExamiantionFlagState.cvs &&
                        <>
                          <TableWrapper>
                              <TableCaption>利用可能コンビニ</TableCaption>
                              <tbody>
                                <InnerTr>
                                  <InnerTh variant>コンビニ</InnerTh>
                                  <InnerTh variant>審査状況</InnerTh>
                                </InnerTr>
                                <InnerTr>
                                  <InnerTh>セブンイレブン</InnerTh>
                                  <InnerTd>{ContentStatus(serviceStatusData.cvs.use_cvs.seven)}</InnerTd>
                                </InnerTr>
                                <InnerTr>
                                  <InnerTh>ローソン・ミニストップ</InnerTh>
                                  <InnerTd>{ContentStatus(serviceStatusData.cvs.use_cvs.lawson)}</InnerTd>
                                </InnerTr>
                                <InnerTr>
                                  <InnerTh>ファミリーマート</InnerTh>
                                  <InnerTd>{ContentStatus(serviceStatusData.cvs.use_cvs.familymart)}</InnerTd>
                                </InnerTr>
                                <InnerTr>
                                  <InnerTh>セイコーマート</InnerTh>
                                  <InnerTd>{ContentStatus(serviceStatusData.cvs.use_cvs.seicomart)}</InnerTd>
                                </InnerTr>
                                <InnerTr>
                                  <InnerTh>デイリーヤマザキ</InnerTh>
                                  <InnerTd>{ContentStatus(serviceStatusData.cvs.use_cvs.yamazaki)}</InnerTd>
                                </InnerTr>
                                </tbody>
                          </TableWrapper>
                          <TableWrapper>
                              <TableCaption>システム設定内容</TableCaption>
                              <tbody>
                                <InnerTr>
                                  <InnerTh>ユーザー用支払期日</InnerTh>
                                  <InnerTd>{phraseByAgent.cvs.payment_due_date}</InnerTd>
                                </InnerTr>
                            </tbody>
                          </TableWrapper>
                        </>
                      }
                    </div>
                    :
                    serviceStatusData.cvs.status === 'CANCEL' ?
                      <CancelTerminateRedTextStyle>キャンセル済みのためご利用いただけません。</CancelTerminateRedTextStyle>
                      :
                      <CancelTerminateRedTextStyle>解約済みのためご利用いただけません。</CancelTerminateRedTextStyle>
                  }
                </>
              }
            </div>
            <hr/>
            <p className='newline mt32 mb24'>{'決済の確認やキャンセル処理、明細ダウンロード等の操作を行うことができる\nゼウス売上管理画面をご案内させていただきます。'}</p>
            <UseStartTable
              tableData={managementData}
            />
            <p className='newline mt24 red_text mb0'>{"以下の件名で別途お送りしているメール内から「仮パスワード」をご確認ください。\nメール件名：【ゼウス】仮パスワードのご案内"}</p>
            <p className='mb32'>また、パスワードの設定は<AnotherTabLink href={"https://www.cardservice.co.jp/zmc/manual/pdf/admin_controlpanel.pdf"}>売上管理画面マニュアル</AnotherTabLink>　P21をご参照ください。</p>
            <p className='mb0'>※ゼウス売上管理画面の操作方法の詳細は<AnotherTabLink href={'https://www.cardservice.co.jp/zmc/manual/admin.html'}>売上管理画面マニュアル</AnotherTabLink>をご確認ください。</p>
            <p>※ゼウス売上管理画面のよくある質問は<AnotherTabLink href={'https://support.cardservice.co.jp/hc/ja'}>FAQ</AnotherTabLink>でご確認ください。</p>
            
        </BackGround>
      }
      {/* serviceCodeListにatobaraiが入っており、IPコード無しかつキャンセル・解約でない以外の場合にのみ表示 */}
      {((service === 'atobarai' && serviceCodeList.includes("atobarai")) && !(serviceData.atobarai && serviceData.atobarai[0][1] === "" && !serviceStopFlag.atobarai)) &&
        <BackGround>
          <>
            <TitleWrapper>あと払い決済（コンビニあと払い決済）のご利用</TitleWrapper>
            {!serviceStopFlag.atobarai ?
              <>
                <h2 className="mt16 mb20">設定情報</h2>
                <UseStartTable
                  tableData={serviceData.atobarai}
                />
                <hr/>
                <p className='mb0'>また、ご利用中のショッピングカート等の管理画面で行えない一部の処理については</p>
                <p>「あと払い決済サービス管理画面」でご対応ください。</p>
                <Box mt={20}>
                    <UseStartTable
                      tableData={atobaraiManagementData}
                      tableWidth={70}
                    />
                </Box>
                <p className='mb0 mt28'>※各ショッピングカートの管理画面で処理できる機能は<AnotherTabLink href={'https://support.cardservice.co.jp/hc/ja/articles/20584285975833'} >こちら</AnotherTabLink> をご確認ください。</p>
                <p className='mb0'>※「あと払い決済サービス管理画面」の操作方法の詳細は<AnotherTabLink href={'https://www.cardservice.co.jp/zmc/manual/pdf/admin_controlpanel_bnpl.pdf'}>あと払い決済サービス運用マニュアル</AnotherTabLink>をご確認ください。</p>
                <p className='mb0'>※「あと払い決済サービス」のよくあるご質問は<AnotherTabLink href={'https://support.cardservice.co.jp/hc/ja/categories/25147993294361'}>FAQ</AnotherTabLink> でご確認ください。</p>              </>
              :
              serviceStatusData.atobarai.status === 'CANCEL' ?
                <>
                  <ServiceTypeTitle serviceStopFlag={serviceStopFlag.atobarai}><GraySpan serviceStopFlag={serviceStopFlag.atobarai}>あと払い決済</GraySpan>　<CancelTerminatePhraseStyle>キャンセル済み</CancelTerminatePhraseStyle></ServiceTypeTitle>
                  <CancelTerminateRedTextStyle>キャンセル済みのためご利用いただけません。</CancelTerminateRedTextStyle>
                </>
                :
                <>
                  <ServiceTypeTitle serviceStopFlag={serviceStopFlag.atobarai}><GraySpan serviceStopFlag={serviceStopFlag.atobarai}>あと払い決済</GraySpan>　<CancelTerminatePhraseStyle>解約済み</CancelTerminatePhraseStyle></ServiceTypeTitle>
                  <CancelTerminateRedTextStyle>解約済みのためご利用いただけません。</CancelTerminateRedTextStyle>
                </>
            }
          </>
        </BackGround>
      }
    </>
  )
}

export default OcnkTeikiUseStartElement