import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// 各ページを読み込む
import { EntryColumn } from '../components/ui_elements/ocnk/Entry/EntryColumn';
import DashBoardBeforeRender from '../pages/ocnk/beforeRenderPages/DashBoardBeforeRender';
import ResetPasswordMailBeforeRender from '../pages/ocnk/beforeRenderPages/ResetPasswordMailBeforeRender';
import ResetPasswordBeforeRender from '../pages/ocnk/beforeRenderPages/ResetPasswordBeforeRender';
import EconomicSelectBeforeRender from '../pages/ocnk/beforeRenderPages/EconomicSelectBeforeRender';
import EconomicEstimateBeforeRender from '../pages/ocnk/beforeRenderPages/EconomicEstimateBeforeRender';
import EntryTopBeforeRender from '../pages/ocnk/beforeRenderPages/EntryTopBeforeRender';
import EntryBeforeRender from '../pages/ocnk/beforeRenderPages/EntryBeforeRender';
import EntryConfirmBeforeRender from '../pages/ocnk/beforeRenderPages/EntryConfirmBeforeRender';
import EntryCompletedBeforeRender from '../pages/ocnk/beforeRenderPages/EntryCompletedBeforeRender';
import EntryCheckBeforeRender from '../pages/ocnk/beforeRenderPages/EntryCheckBeforeRender';
import ExaminationStatusBeforeRender from '../pages/ocnk/beforeRenderPages/ExaminationStatusBeforeRender';
import InquiryBeforeRender from '../pages/ocnk/beforeRenderPages/InquiryBeforeRender';
import FinalAgreementBeforeRender from '../pages/ocnk/beforeRenderPages/FinalAgreementBeforeRender';
import FinalAgreementCompletedBeforeRender from '../pages/ocnk/beforeRenderPages/FinalAgreementCompletedBeforeRender';
import UseStartBeforeRender from '../pages/ocnk/beforeRenderPages/UseStartBeforeRender';
import ChangePasswordMailBeforeRender from '../pages/ocnk/beforeRenderPages/ChangePasswordMailBeforeRender';
import LoginBeforeRender from '../pages/ocnk/beforeRenderPages/LoginBeforeRender';
import NotFoundBeforeRender from '../pages/ocnk/beforeRenderPages/NotFoundBeforeRender';
import SystemErrorBeforeRender from '../pages/ocnk/beforeRenderPages/SystemErrorBeforeRender';
import UserRegisterBeforeRender from '../pages/ocnk/beforeRenderPages/UserRegisterBeforeRender';
import CompletedRegisterBeforeRender from '../pages/ocnk/beforeRenderPages/CompletedRegisterBeforeRender';
import UserRegisterAuthenticationBeforeRender from '../pages/ocnk/beforeRenderPages/UserRegisterAuthenticationBeforeRender';
import ResetPasswordMailCompletedBeforeRender from '../pages/ocnk/beforeRenderPages/ResetPasswordMailCompletedBeforeRender';
import UseStartPrecautionsBeforeRender from '../pages/ocnk/beforeRenderPages/UseStartPrecautionsBeforeRender';
import OcnkTeikiLoginBeforeRender from '../pages/ocnkTeiki/beforeRenderPages/LoginBeforeRender';
import OcnkTeikiDashBoardBeforeRender from '../pages/ocnkTeiki/beforeRenderPages/DashBoardBeforeRender';
import OcnkTeikiResetPasswordMailBeforeRender from '../pages/ocnkTeiki/beforeRenderPages/ResetPasswordMailBeforeRender';
import OcnkTeikiResetPasswordMailCompletedBeforeRender from '../pages/ocnkTeiki/beforeRenderPages/ResetPasswordMailCompletedBeforeRender';
import OcnkTeikiResetPasswordBeforeRender from '../pages/ocnkTeiki/beforeRenderPages/ResetPasswordBeforeRender';
import OcnkTeikiChangePasswordMailBeforeRender from '../pages/ocnkTeiki/beforeRenderPages/ChangePasswordMailBeforeRender';
import OcnkTeikiEntryTopBeforeRender from '../pages/ocnkTeiki/beforeRenderPages/EntryTopBeforeRender';
import OcnkTeikiEntryBeforeRender from '../pages/ocnkTeiki/beforeRenderPages/EntryBeforeRender';
import OcnkTeikiEntryConfirmBeforeRender from '../pages/ocnkTeiki/beforeRenderPages/EntryConfirmBeforeRender';
import OcnkTeikiEntryCompletedBeforeRender from '../pages/ocnkTeiki/beforeRenderPages/EntryCompletedBeforeRender';
import OcnkTeikiEntryCheckBeforeRender from '../pages/ocnkTeiki/beforeRenderPages/EntryCheckBeforeRender';
import OcnkTeikiExaminationStatusBeforeRender from '../pages/ocnkTeiki/beforeRenderPages/ExaminationStatusBeforeRender';
import OcnkTeikiInquiryBeforeRender from '../pages/ocnkTeiki/beforeRenderPages/InquiryBeforeRender';
import OcnkTeikiSystemErrorBeforeRender from '../pages/ocnkTeiki/beforeRenderPages/SystemErrorBeforeRender';
import OcnkTeikiNotFoundBeforeRender from '../pages/ocnkTeiki/beforeRenderPages/NotFoundBeforeRender';
import AgentBeforeRender from '../pages/ocnkTeiki/beforeRenderPages/AgentBeforeRender';
import OcnkTeikiEntryRedirectBeforeRender from '../pages/ocnkTeiki/beforeRenderPages/EntryRedirectBeforeRender';
import { OcnkTeikiEntryColumn } from '../components/ui_elements/ocnkTeiki/Entry/EntryColumn';
import OcnkTeikiFinalAgreementBeforeRender from '../pages/ocnkTeiki/beforeRenderPages/FinalAgreementBeforeRender';
import OcnkTeikiFinalAgreementCompletedBeforeRender from '../pages/ocnkTeiki/beforeRenderPages/FinalAgreementCompletedBeforeRender';
import OcnkTeikiUseStartBeforeRender from '../pages/ocnkTeiki/beforeRenderPages/UseStartBeforeRender';
import OcnkTeikiUseStartPrecautionsBeforeRender from '../pages/ocnkTeiki/beforeRenderPages/UseStartPrecautionsBeforeRender';
import OcnkTeikiEconomicEstimateBeforeRender from '../pages/ocnkTeiki/beforeRenderPages/EconomicEstimateBeforeRender';
import OcnkTeikiRedirectEconomicBeforeRender from '../pages/ocnkTeiki/beforeRenderPages/RedirectEconomicBeforeRender';
import OcnkTeikiRedirectPasswordBeforeRender from '../pages/ocnkTeiki/beforeRenderPages/RedirectPasswordBeforeRender';
import OcnkTeikiEmailSettingBeforeRender from '../pages/ocnkTeiki/beforeRenderPages/EmailSettingBeforeRender';
import OcnkTeikiCompletedEmailSettingBeforeRender from '../pages/ocnkTeiki/beforeRenderPages/CompletedEmailSettingBeforeRender';
// ルートを通す
const Routing = () => {
    return (
        <BrowserRouter>
            <Routes>
                {/* ログイン画面 */}
                <Route path="login" element={<LoginBeforeRender />} />
                {/* ダッシュボード画面 */}
                <Route path="dashboard" element={<DashBoardBeforeRender />} />
                {/* ユーザー登録画面 */}
                <Route path="user_register" element={<UserRegisterBeforeRender />} />
                {/* ユーザー登録メール送信完了画面 */}
                <Route path="completed_register" element={<CompletedRegisterBeforeRender />} />
                {/* 認証成功リダイレクト画面 */}
                <Route path="user_register_auth" element={<UserRegisterAuthenticationBeforeRender />} />
                {/* リセットパスワードメール送信画面 */}
                <Route path="reset_password_mail" element={<ResetPasswordMailBeforeRender />} />
                <Route path="reset_password_mail_completed" element={<ResetPasswordMailCompletedBeforeRender/>} />
                {/* リセットパスワード設定画面 */}
                <Route path="reset_password" element={<ResetPasswordBeforeRender />} />
                {/* パスワード変更メール送信画面 */}
                <Route path="change_password_mail" element={<ChangePasswordMailBeforeRender />} />
                {/* 経済条件選択画面 */}
                <Route path="economic_select" element={<EconomicSelectBeforeRender />} />
                {/* 経済条件確認画面 */}
                <Route path="economic_estimate" element={<EconomicEstimateBeforeRender />} />
                {/* 申込TOP */}
                <Route path="entry_top" element={<EntryTopBeforeRender />} />
                {/* 申し込み画面 */}
                <Route path="entry" element={<EntryBeforeRender />} >
                    {/* 加盟店情報 */}
                    <Route path="merchant" element={<EntryColumn path='merchant'/>} />
                    {/* 口座情報 */}
                    <Route path="bank_account" element={<EntryColumn path='bank_account'/>} />
                    {/* ショップ情報 */}
                    <Route path="shop" element={<EntryColumn path='shop'/>} />
                </Route>
                {/* 申し込み確認画面 */}
                <Route path="entry_confirm" element={<EntryConfirmBeforeRender />} />
                {/* 申し込み完了画面 */}
                <Route path="entry_completed" element={<EntryCompletedBeforeRender />} />
                {/* 申し込み内容チェック画面 */}
                <Route path="entry_check" element={<EntryCheckBeforeRender />} />
                {/* 審査状況確認画面 */}
                <Route path="examination_status" element={<ExaminationStatusBeforeRender />} />
                {/* 不備確認画面 */}
                <Route path="inquiry" element={<InquiryBeforeRender />} />
                {/* 二次審査後合意画面 */}
                <Route path="final_agreement" element={<FinalAgreementBeforeRender />} />
                {/* 二次審査後合意完了画面 */}
                <Route path="final_agreement_completed" element={<FinalAgreementCompletedBeforeRender />} />
                {/* ご利用開始画面 */}
                <Route path="use_start" element={<UseStartBeforeRender />} />
                {/* ご利用開始注意事項 */}
                <Route path="use_start_precautions" element={<UseStartPrecautionsBeforeRender />} />
                {/* システムエラー */}
                <Route path="system_error" element={<SystemErrorBeforeRender />} />
                {/* NtoFound */}
                <Route path="*" element={<NotFoundBeforeRender />} />
                {/* おちゃのこ定期用ルーティング */}
                <Route path='ocnk-teiki' element={<AgentBeforeRender/>}>
                    {/* ログイン画面 */}
                    <Route path="login" element={<OcnkTeikiLoginBeforeRender />} />
                    {/* ダッシュボード画面 */}
                    <Route path="dashboard" element={<OcnkTeikiDashBoardBeforeRender />} />
                    {/* リセットパスワードメール送信画面 */}
                    <Route path="reset_password_mail" element={<OcnkTeikiResetPasswordMailBeforeRender />} />
                    <Route path="reset_password_mail_completed" element={<OcnkTeikiResetPasswordMailCompletedBeforeRender/>} />
                    {/* リセットパスワード設定画面 */}
                    <Route path="reset_password" element={<OcnkTeikiResetPasswordBeforeRender />} />
                    {/* パスワード変更メール送信画面 */}
                    <Route path="change_password_mail" element={<OcnkTeikiChangePasswordMailBeforeRender />} />
                    {/* 申込TOP */}
                    <Route path="entry_top" element={<OcnkTeikiEntryTopBeforeRender />} />
                    {/* 経済条件確認 */}
                    <Route path="economic_estimate" element={<OcnkTeikiEconomicEstimateBeforeRender />} />
                    {/* 申し込み画面 */}
                    <Route path="entry" element={<OcnkTeikiEntryBeforeRender />} >
                        {/* 加盟店情報 */}
                        <Route path="merchant" element={<OcnkTeikiEntryColumn path='merchant'/>} />
                        {/* 口座情報 */}
                        <Route path="bank_account" element={<OcnkTeikiEntryColumn path='bank_account'/>} />
                        {/* ショップ情報 */}
                        <Route path="shop" element={<OcnkTeikiEntryColumn path='shop'/>} />
                    </Route>
                    {/* メールアドレス設定画面 */}
                    <Route path="email_setting" element={<OcnkTeikiEmailSettingBeforeRender />} />
		    {/* メールアドレス設定完了画面*/}
		    <Route path="email_setting_completed" element={<OcnkTeikiCompletedEmailSettingBeforeRender />} />
                    {/* リダイレクト経済条件 */}
                    <Route path="redirect_economic_estimate" element={<OcnkTeikiRedirectEconomicBeforeRender redirect/>} />
                    {/* リダイレクト時申し込み画面 */}
                    <Route path="redirect" element={<OcnkTeikiEntryRedirectBeforeRender redirect/>} >
                        {/* 加盟店情報 */}
                        <Route path="merchant" element={<OcnkTeikiEntryColumn path='merchant' redirect/>} />
                        {/* 口座情報 */}
                        <Route path="bank_account" element={<OcnkTeikiEntryColumn path='bank_account' redirect/>} />
                        {/* ショップ情報 */}
                        <Route path="shop" element={<OcnkTeikiEntryColumn path='shop' redirect/>} />
                    </Route>
                    {/* リダイレクトパスワード変更画面 */}
                    <Route path="redirect_password" element={<OcnkTeikiRedirectPasswordBeforeRender />} />
                    {/* リダイレクト申し込み確認画面 */}
                    <Route path="redirect_confirm" element={<OcnkTeikiEntryConfirmBeforeRender redirect />} />
                    {/* リダイレクト申し込み完了画面 */}
                    <Route path="redirect_completed" element={<OcnkTeikiEntryCompletedBeforeRender redirect />} />
                    {/* 申し込み確認画面 */}
                    <Route path="entry_confirm" element={<OcnkTeikiEntryConfirmBeforeRender />} />
                    {/* 申し込み完了画面 */}
                    <Route path="entry_completed" element={<OcnkTeikiEntryCompletedBeforeRender />} />
                    {/* 申し込み内容チェック画面 */}
                    <Route path="entry_check" element={<OcnkTeikiEntryCheckBeforeRender />} />
                    {/* 審査状況確認画面 */}
                    <Route path="examination_status" element={<OcnkTeikiExaminationStatusBeforeRender />} />
                    {/* 不備確認画面 */}
                    <Route path="inquiry" element={<OcnkTeikiInquiryBeforeRender />} />
                    {/* 二次審査後合意画面 */}
                    <Route path="final_agreement" element={<OcnkTeikiFinalAgreementBeforeRender />} />
                    {/* 二次審査後合意完了画面 */}
                    <Route path="final_agreement_completed" element={<OcnkTeikiFinalAgreementCompletedBeforeRender />} />
                    {/* ご利用開始画面 */}
                    <Route path="use_start" element={<OcnkTeikiUseStartBeforeRender />} />
                    {/* ご利用開始注意事項 */}
                    <Route path="use_start_precautions" element={<OcnkTeikiUseStartPrecautionsBeforeRender />} />
                    {/* システムエラー */}
                    <Route path="system_error" element={<OcnkTeikiSystemErrorBeforeRender />} />
                    {/* NtoFound */}
                    <Route path="*" element={<OcnkTeikiNotFoundBeforeRender />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}
export { Routing };
