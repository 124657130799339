import React, { ChangeEventHandler } from 'react';
import styled from 'styled-components';
import { fonts } from '../../../css/variables/fonts';
import { colors } from '../../../css/variables/colors';
import ImgSelectArrowDown from '../../../images/icon_select_arrow_bottom.svg';
import { mixins } from '../../../css/variables/mixins';
import { selectMenuObject, selectMenuType } from '../../../constance/typeModel';

type selectMenuStyleType = {
  fullwidth?: boolean,
  mt?: number
}

const SelectMenuStyle = styled.div<selectMenuStyleType>`
  display:${props => props.fullwidth ? 'flex' : 'inline-flex'};
  flex-direction: column;
  margin-top: ${props => props.mt}px;
  label{
    display: block;
    margin-bottom: 4px;
    font-size:${fonts.fontSizeRem('s')};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

type selectStyleType = {
  backGroundColor?: string,
  fullwidth?: boolean,
  iserror?: string,
  responsiveFullWidth?: boolean
}

const SelectStyle = styled.select<selectStyleType>`
  background-color: ${props => props.backGroundColor ? colors.themeColor(props.backGroundColor) : colors.themeColor('background_input')};
  background-image: url(${ImgSelectArrowDown});
  background-repeat: no-repeat;
  background-position: right 12px center;
  padding: 12px 36px 12px 16px;
  border-radius: 4px;
  width:${props => props.fullwidth ? '100%' : 'auto'};
  border:1px solid ${props => props.iserror ? colors.themeColor('border_error') + ' !important' : colors.themeColor('border_input')};
  outline: none;
  appearance: none;
  color: ${colors.themeColor('text_main')};
  font-size:${fonts.fontSizeRem('s')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${mixins.screenUnder()}{
    ${props => props.responsiveFullWidth ? 'width: 100%;' : ''}
  }
`
const Error = styled.span`
  display: block;
  color: ${colors.themeColor('text_error')};
  font-size: ${fonts.fontSizeRem('s')};
  margin-top: 4px;
`

export const SelectMenu = ({
  id,
  name,
  label,
  options,
  defaultValue,
  mt = 16,
  fullwidth = false,
  onChange,
  iserror,
  value,
  backGroundColor,
  responsiveFullWidth
}: {
  id: string,
  name?: string,
  label?: string,
  options: selectMenuType,
  defaultValue?: string,
  mt?: number,
  fullwidth?: boolean,
  onChange?: ChangeEventHandler<HTMLElement>,
  iserror?: string,
  value?: string,
  backGroundColor?: string,
  responsiveFullWidth?: boolean
}) => {
  return (
    <SelectMenuStyle mt={mt} fullwidth={fullwidth}>
      {label ? (
        <label htmlFor={id}>{label}</label>
      ) : (<></>)}

      <SelectStyle defaultValue={defaultValue} id={id} name={name} fullwidth={fullwidth} responsiveFullWidth={responsiveFullWidth} onChange={onChange} data-testid={name} value={value} iserror={iserror} backGroundColor={backGroundColor}>
        {options.map((item: selectMenuObject, index: number) => (
          <option key={index} value={item.value} disabled={item.disabled}>{item.label}</option>
        ))}
      </SelectStyle>
      {iserror  !== '' ? (<Error>{iserror}</Error>) : (<></>)}
    </SelectMenuStyle>
  );
};
