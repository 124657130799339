import { useCallback, useContext } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { Context } from "../stores/Provider"

const useLimitDirectAccess = (agentPath:string|undefined="") => {

    const location = useLocation()
    const navigate = useNavigate()
    const {dispatch} = useContext(Context)
    
    const DirectAccessCheck = useCallback((pageName: string, redirect?:boolean) => {
        let flag = false
        if (redirect) {
            return flag
        }
        // const routeName = (location.state !== null && location.state.routeTo !== null) ? (location.state.routeTo.includes('/') ? location.state.routeTo.split('/')[0] : location.state.routeTo) : ''
        const routeName = (location.state !== null && location.state.routeTo !== null) ? location.state.routeTo : ''
        if(routeName === pageName){
            flag = true
        } else {
            dispatch({
                type: 'set-context-data',
                payload: {
                    auth: {
                        err_message: '不正なアクセスです。再度ログインをお願いします。'
                    }
                }
            })
            navigate(agentPath+'/login')
            flag = false
        }
        return flag
    },[dispatch,location.state,navigate,agentPath])

    return {DirectAccessCheck}
    
}

export default useLimitDirectAccess