import styled from "styled-components";
import { colors } from '../../../css/variables/colors';
import { fonts } from '../../../css/variables/fonts';
import { mixins } from '../../../css/variables/mixins';
import AnotherTabLink from "./AnotherTabLink";

type tableWrapperType = {
  tableWidth: number
}
const TableWrapper = styled.table<tableWrapperType>`
  width: ${props => props.tableWidth}%;
  ${mixins.screenUnder()}{
    width: 100%;
  }
`
const InnerTr = styled.tr`
`

const InnerTh = styled.th`
  width: 200px;
  border: solid 1px ${colors.themeColor('border_box')};
  padding: 8px 12px;
  white-space: nowrap;
  background-color: ${(props: {variant: number}) => props.variant !== 0 ? colors.themeColor('background_main') : colors.themeColor('background_whitegray')};
  ${(props: {variant: number}) => props.variant ? "border-top: transparent" : null};
  ${mixins.screenUnder()}{
    white-space: normal;
    width:112px;
    font-size: ${fonts.fontSizeRem('s')};
  }
`

const InnerTd = styled.td`
  border: solid 1px #D6E3EB;
  padding: 8px 12px;
  background-color: ${(props: {variant: number}) => props.variant !== 0 ? colors.themeColor('background_main') : colors.themeColor('background_whitegray')};
  ${(props: {variant: number}) => props.variant ? "border-top: transparent" : null};
  ${mixins.screenUnder()}{
    word-break: break-all;
  }
`



export const UseStartTable = ({
  tableData = [[]],
  tableWidth = 65
}: {
  tableData?: string[][],
  tableWidth?: number
}) => {

  const urlTitleArray = ['管理画面URL', 'あと払い決済サービス管理画面URL']

  return (
    <TableWrapper tableWidth={tableWidth}>
      <tbody>
        {tableData.map((rowData: string[], rowDataIndex: number) => (
          <InnerTr key={rowDataIndex+'tr'}>
            <InnerTh variant={rowDataIndex} key={rowDataIndex+'th'}>
              {rowData[0]}
            </InnerTh>
            <InnerTd variant={rowDataIndex} key={rowDataIndex+'td'}>
              {urlTitleArray.includes(rowData[0]) ? (<AnotherTabLink href={rowData[1]}>{rowData[1]}</AnotherTabLink>) : rowData[1]}
            </InnerTd>
          </InnerTr>
        ))}
      </tbody>
    </TableWrapper>
  );
}
