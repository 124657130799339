import { Container } from "../../../components/layouts/Container";
import { ErrorHandler } from "../../../api/ErrorHandler";
import { emailSettingResponseType, initApiModelType } from "../../../constance/typeModel";
import useNavigateByAgent from "../../../hooks/useNavigateByAgentHooks";
import OcnkTeikiEmailSettingElement from "../../../components/ui_elements/ocnkTeiki/EmailSetting/EmailSettingElement";
import useEmailSettingHooks from "../../../hooks/useEmailSettingHooks";

const OcnkTeikiEmailSetting = ({
  agentMasterList
}:{
  agentMasterList: string[]
}) => {
  const {navigateByAgent} = useNavigateByAgent()
  const { sendEmail, setEmailHandler, EmailData, ErrMsg, apiErrMsg, normal, abnormal } = useEmailSettingHooks(agentMasterList);
  const { ErrorHandle } = ErrorHandler(navigateByAgent);

  //エラーハンドリング実行
  const sendEmailHandler = async () => {
    const response = await sendEmail(EmailData);
    ErrorHandle<initApiModelType<emailSettingResponseType>>(response, normal, abnormal, true)
  }

  return (
    <Container>
      <OcnkTeikiEmailSettingElement
        setEmailHandler={setEmailHandler}
        sendEmailHandler={sendEmailHandler}
        ErrMsg={ErrMsg}
	ApiErrMsg={apiErrMsg}
      />
    </Container>
  )
}

export default OcnkTeikiEmailSetting
