import { Box } from "../../../components/layouts/Box";
import { Container } from "../../../components/layouts/Container";
import { InquiryItemElement } from "../../../components/ui_elements";
import useInquiryNavHooks from "../../../hooks/useInquiryNavHooks";
import { BackButton } from "../../../components/ui-parts/ocnk/BackButton";
import { inquiryCommentArrayType } from "../../../constance/providerTypeModel";
import useNavigateByAgent from "../../../hooks/useNavigateByAgentHooks";
import LinkByAgent from "../../../components/ui-parts/ocnk/LinkByAgent";

const Inquiry = () => {
  const {navigateByAgent} = useNavigateByAgent()
  // 不備連携お知らせ処理
  const { InquiryCommentData, accordionNum, serchInquiryData } = useInquiryNavHooks(false,navigateByAgent);
  return (
    <Container
      isInquiry
      isLogin
    >
      <div className="align_right">
        <BackButton><LinkByAgent to={'/dashboard'}>ダッシュボードへ戻る</LinkByAgent></BackButton>
      </div>
      <div className="mt32">
        {InquiryCommentData.map((data: inquiryCommentArrayType, index: number) => {
          return (
            <div key={index}>
              {index === 0 ? <></> : <Box mt={20} />}
              <InquiryItemElement
                inquiryData={serchInquiryData(data[0].ticket_id)}
                inquiryComment={data}
                accordionOpen={accordionNum}
              />
            </div>
          )
        })}
      </div>

    </Container>
  );
};

export default Inquiry