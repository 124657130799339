import React, { MouseEventHandler, ReactNode } from 'react';
import styled from 'styled-components'
import { colors } from '../../../css/variables/colors';
import { ToggleButton } from './ToggleButton';

type accodionToggleStyleType = {
  toggle: boolean
}
const AccodionToggleStyle = styled.div<accodionToggleStyleType>`
  border: 3px solid ${colors.themeColor('border_box')} ;
  background: ${props => props.toggle ? colors.themeColor('background_main') : '#F4F5FA;'} ;
  &:first-of-type{
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  &:last-of-type{
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`
const HeadStyle = styled.div`
  display: block;
  cursor: pointer;
  display: flex;
  gap:20px;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
`

type bodyStyleType = {
  toggle: boolean
}
const BodyStyle = styled.div<bodyStyleType>`
  overflow: hidden;
  padding-top: 0;
  padding-left:16px;
  padding-right: 16px;
  padding-bottom: ${props => props.toggle ? '16px' : 0};
  height: ${props => props.toggle ? 'auto' : 0} ;
  transition: all .5s;
`
export const AccodionToggle = ({
  toggle,
  headtitle,
  children,
  onClick,
  disabled,
  credit
}: {
  toggle: boolean,
  headtitle: ReactNode,
  children: ReactNode,
  onClick?: MouseEventHandler<HTMLElement>,
  disabled?: boolean,
  credit?: boolean
}) => { 
  return (
    <AccodionToggleStyle toggle={toggle}>
      <HeadStyle onClick={onClick}>
        <div>
          {headtitle}
        </div>
        {!credit &&
          <ToggleButton toggle={toggle} disabled={disabled}/>
        }
      </HeadStyle>
      <BodyStyle toggle={toggle}>
        {children}
      </BodyStyle>
    </AccodionToggleStyle>
  );
};
