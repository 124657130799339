import { initLoginSessionDataType,
    initUserSessionDataType,
    initSumFeeSessionDataType,
    initAgentMasterSessionDataType,
    initSystemSettingMasterSessionDataType,
    economicConditionMasterDataType,
    initReviewContentsMasterSessionDataType,
    initMerchantSessionDataType,
    initBankAccountSessionDataType,
    initReviewContentsDivisionSessionDataType,
    initRepresentativeSessionDataType,
    initOperationSessionDataType,
    initFileSessionDataType,
    initShopSessionDataType,
    serviceStatusCheckType,
    initEconomicSessionDataType, 
    initEntryCheckDataType} from "../constance/typeModel"


export const setSession = <T>(key: string, data: T) => {
    return sessionStorage.setItem(key, JSON.stringify(data))
}

export const getSession = (key: string) => {
    let getItemValue = sessionStorage.getItem(key)
    return getItemValue !== null ? JSON.parse(getItemValue) : null
}
//session操作,セッションの値抽出
//ログイン情報
export const setLoginInfoData = (data: initLoginSessionDataType) => {
    setSession('login', data)
}
//ユーザ情報
export const setUserInfoData = (data: initUserSessionDataType) => {
    setSession('user', data)
}
//システム料金合計金額
export const setSumFeeInfoData = (data: initSumFeeSessionDataType) => {
    setSession('sum_fee', data)
}
//審査コンテンツ出し分け
export const setReviewContentsDivisionInfoData = (data: initReviewContentsDivisionSessionDataType) => {
    setSession('review_contents_division', data)
}
//代理店情報マスタ
export const setAgentMasterInfoData = (data: initAgentMasterSessionDataType) => {
    setSession('agent_master', data)
}
//経済条件マスタ(経済状況マスタの可能性？)
export const setEconomicConditionMasterInfoData = (data: economicConditionMasterDataType) => {
    setSession('economic_condition_master', data)
}
//システム設定マスタ
export const setSystemSettingMasterInfoData = (data: initSystemSettingMasterSessionDataType) => {
    setSession('system_setting_master', data)
}
//審査コンテンツマスタ
export const setReviewContentMasterInfoData = (data: initReviewContentsMasterSessionDataType) => {
    setSession('review_contents_master', data)
}
// 申し込み用経済条件
export const setEconomicInfoData = (data: initEconomicSessionDataType) => {
    setSession('economic_condition', data)
}
//申し込み用お客様情報
export const setMerchantInfoData = (data: initMerchantSessionDataType) => {
    setSession('merchant', data)
}
//申し込み用代表者情報
export const setRepresentativeInfoData = (data: initRepresentativeSessionDataType) => {
    setSession('representative', data)
}
//申し込み用担当者情報
export const setOperationInfoData = (data: initOperationSessionDataType) => {
    setSession('operation', data)
}
//申し込み用口座情報
export const setBankAccountInfoData = (data: initBankAccountSessionDataType) => {
    setSession('bank_account', data)
}
// 申し込み用ファイル情報
export const setFileInfoData = (data: initFileSessionDataType) => {
    setSession('files', data)
}
//申し込み用ショップ情報
export const setShopInfoData = (data:  initShopSessionDataType) => {
    setSession('shop', data)
}
//マスタによる経済条件出し分け情報
export const setEconomicDevidedInfoData = (data: serviceStatusCheckType) => {
    setSession('economic_devided', data)
}
//申し込み完了チェックマーク情報
export const setEntryCheckInfoData = (data: initEntryCheckDataType) => {
    setSession('entry_check', data)
}
