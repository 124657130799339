import { FormWrap } from '../../../layouts/FormWrap';
import { Box } from '../../../layouts/Box';
import { TextField, Button, ErrorMessage } from '../../../ui-parts/ocnk';
import { BackButton } from '../../../ui-parts/ocnk/BackButton';
import { ChangeEventHandler, MouseEventHandler } from 'react';
import LinkByAgent from '../../../ui-parts/ocnk/LinkByAgent';

const ResetPasswordMailElement = ({
  setEmailHandler,
  sendEmailHandler,
  ErrMsg,
  ApiErrMsg,
  changePassword
}: {
  setEmailHandler: ChangeEventHandler<HTMLInputElement>,
  sendEmailHandler: MouseEventHandler<HTMLButtonElement>,
  ErrMsg: string,
  ApiErrMsg?: string,
  changePassword?: boolean
}) => {
  const passPhrase = changePassword ? 'パスワード変更' : 'パスワード再設定'
  return (
    <>
      {changePassword && 
        <div className="mb32 align_right">
          <BackButton><LinkByAgent to={'/dashboard'}>ダッシュボードへ戻る</LinkByAgent></BackButton>
        </div>
      }
      <FormWrap>
        <div className='align_center'>
          <h1 className='mt0 mb0'>{passPhrase}</h1>
          <p className="mt16 align_left">ご登録のメールアドレスを入力してください。{passPhrase}のURLを送信します。</p>
        </div>
        <ErrorMessage ApiErrMsg={ApiErrMsg} />
        <TextField
          mt={20}
          onChange={setEmailHandler}
          iserror={ErrMsg}
          id='textfield1'
          label='メールアドレス'
          placeholder='user@cardservice.co.jp'
          fullwidth
        />
        <Box mt={32}>
          <Button variant='primary' fullwidth onClick={sendEmailHandler}>{passPhrase}メールを送信する</Button>
        </Box>
      </FormWrap>
    </>
  )
}

export default ResetPasswordMailElement