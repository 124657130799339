import { useEffect } from "react";
import ErrorBoundary from "../../../api/ErrorBoundary";
import useGTMHooks from "../../../hooks/useGTMHooks";
import { ocnkTeikiPlanLists } from "../../../constance/constance";
import OcnkTeikiEmailSetting from "../renderPages/EmailSetting";

const OcnkTeikiEmailSettingBeforeRender = () => {
    const { pushDataLayerHandler } = useGTMHooks()

    useEffect(()=>{
      pushDataLayerHandler()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return(
        <ErrorBoundary>
            <OcnkTeikiEmailSetting agentMasterList={ocnkTeikiPlanLists}/>
        </ErrorBoundary>
    )
}

export default OcnkTeikiEmailSettingBeforeRender