import styled from 'styled-components';
import { colors } from '../../../../css/variables/colors';
import { fonts } from '../../../../css/variables/fonts';
import { Box } from '../../../layouts/Box';
import { Button, ErrorMessage, SelectMenu } from '../../../ui-parts/ocnk';
import { mixins } from '../../../../css/variables/mixins';
import { ChangeEventHandler, MouseEventHandler } from 'react';

const WrapperStyle = styled.div`
  background: ${colors.themeColor('background_main')};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  width: 72%;
  max-width: 720px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${mixins.screenUnder()}{
    width: 93%;
  }
`

const TitleStyle = styled.h1`
  text-align: center;
  font-size: ${fonts.fontSizeRem('l')};  
  line-height: 27px;
  padding: 16px 0;
  border-bottom: solid 1px ${colors.themeColor('border_box')};
`

const SendWrapperStyle = styled.div`
  text-align: center;
  padding: 100px 0;
`

const SendTextStyle = styled.p`
  font-size: ${fonts.fontSizeRem('m')}; 
  br {
    display: block;
  }
`

const InputWrapperStyle = styled.div`
  padding: 16px;
  height: 100%;
`

const InputInnerStyle = styled.div`
  background: ${colors.themeColor('background_whitegray')};
  border: solid 1px ${colors.themeColor('border_box')};
  padding: 16px;
`

const InputStyle = styled.input`
  border: 1px solid ${colors.themeColor('border_box')};
  padding: 12px 16px;
  border-radius: 4px;
  outline: none;
  width: 100%;
  display: block;
  &:focus {
    border: 1px solid ${colors.themeColor('primary_light')};
  }
`

const TextAreaStyle = styled.textarea`
  -webkit-appearance: none;
  border: 1px solid ${colors.themeColor('border_box')};
  padding: 12px 16px;
  border-radius: 4px;
  outline: none;
  resize: vertical;
  width: 100%;
  display: block;
  height: 170px;
  &:focus {
    border: 1px solid ${colors.themeColor('primary_light')};
  }
  ::-webkit-resizer {
    display: none;
  }
  ${mixins.screenUnder()}{
    height: 90px;
  }
`

const ActionWrapperStyle = styled.div`
  padding: 16px;
  display: flex;
  justify-content: end;
  border-top: solid 1px ${colors.themeColor('border_box')};
`
const ModalWrapperStyle = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0,0,0,.8);
  z-index: 999;
`
const FormTitleStyle = styled.p`
  font-weight:bold;
  font-size : ${fonts.fontSizeRem('ms')};
  margin-bottom: 0px;
  margin-left:4px;
`


const InquiryModalElement = ({
  inquiryHandler,
  modalOpenHandler,
  setInquiryRegisterHandler,
  modalSendFlag,
  apiErrMsg,
  errMsg,
  selectOption
}: {
  inquiryHandler: MouseEventHandler<HTMLElement>,
  modalOpenHandler: MouseEventHandler<HTMLElement>,
  setInquiryRegisterHandler: ChangeEventHandler<HTMLElement>,
  modalSendFlag: boolean,
  apiErrMsg: string,
  errMsg: {
    text: string,
    select: string
  },
  selectOption: { label:string, value:string, disabled:boolean}[]
}) => {

  return(
    <ModalWrapperStyle>
      <WrapperStyle>
        <TitleStyle>{modalSendFlag ? 'お問い合わせありがとうございました。' : 'お問い合わせを作成'}</TitleStyle>
        {modalSendFlag ? 
          <SendWrapperStyle>
            <SendTextStyle>
              お問い合わせを受け付けました。
              <br />
              折り返し担当者よりご連絡いたしますので、
              <br />
              しばらくお待ちください。
            </SendTextStyle>
          </SendWrapperStyle>
          :
          <InputWrapperStyle>
          <ErrorMessage ApiErrMsg={errMsg.text} />
          <ErrorMessage ApiErrMsg={errMsg.select} />
          <ErrorMessage ApiErrMsg={apiErrMsg} />
            <InputInnerStyle>
              <FormTitleStyle>お名前</FormTitleStyle>
              <InputStyle id='send_mail_name' placeholder='山田　太郎' onChange={setInquiryRegisterHandler} />
              <Box mt={16} />
              <FormTitleStyle>メールアドレス</FormTitleStyle>
              <InputStyle id='send_mail_email' placeholder='user@cardservice.co.jp' onChange={setInquiryRegisterHandler} />
              <Box mt={16} />
              <FormTitleStyle>お問い合わせ内容</FormTitleStyle>
              <SelectMenu id='send_mail_title' options={selectOption} onChange={setInquiryRegisterHandler} backGroundColor={'background_main'} mt={0}/>
              <Box mt={16} />
              <FormTitleStyle>お問い合わせ内容（詳細）</FormTitleStyle>
              <TextAreaStyle id='send_mail_content' onChange={setInquiryRegisterHandler}/>
            </InputInnerStyle>
            {/* <InquiryFileElement /> */}
          </InputWrapperStyle>
        }
        <ActionWrapperStyle>
          {modalSendFlag ? 
            <Button onClick={modalOpenHandler} variant={'normal'}>閉じる</Button>
            :
            <>
              <Button onClick={modalOpenHandler} variant={'normal'}>キャンセル</Button>
              <Box mr={10}/>
              <Button onClick={inquiryHandler}>お問い合わせを作成する</Button>
            </>
          }
        </ActionWrapperStyle>
      </WrapperStyle>
    </ModalWrapperStyle>
  );
};
export default InquiryModalElement;