import { MouseEventHandler, ReactNode } from 'react';
import styled from 'styled-components'
import { colors } from '../../../css/variables/colors';
import { fonts } from '../../../css/variables/fonts';
import { mixins } from '../../../css/variables/mixins';

type buttonStyleType = {
  variant?: string,
  disabled?: boolean,
  size?: string,
  sizeDesktop?: string,
  sizeMobile?: string,
  fullwidth_mobile?: boolean,
  fullwidth?: boolean
}
const ButtonStyle = styled.button<buttonStyleType>`
  background: ${props => props.variant === 'primary' ? colors.themeColor('background_button_primary') : colors.themeColor('background_main')};
  ${props => props.disabled === true ? 'background:' + colors.themeColor('background_disabled') : null};
  color: ${props => props.disabled ? "#7d7d7d" : props.variant === 'primary' ? colors.themeColor('text_white') : colors.themeColor('text_button_secoundary')} !important;
  ${props => props.disabled === true ? 'color:' + colors.themeColor('text_disabled') : null};
  border: ${props => (props.variant === 'primary' || props.variant === 'none') ? 'none' : '1px solid' + colors.themeColor('border_box')};
  border-radius: 4px;
  font-weight: bold;
  padding: 8px 16px;
  line-height: 1.4;
  white-space: nowrap;
  ${props => props.size === 'large' ? 'padding:12px 18px' : null};
  ${props => props.size === 'small' ? 'padding:6px 12px' : null};
  ${props => props.size === 'large' ? 'font-size:' + fonts.fontSizeRem('m') : null};
  ${props => props.size === 'small' ? 'font-size:' + fonts.fontSizeRem('s') : null};
  ${props => props.sizeDesktop === 'large' ? mixins.screenOver()+'{padding:12px 18px}' : null};
  ${props => props.sizeDesktop === 'small' ? mixins.screenOver()+'{padding:6px 12px}' : null};
  ${props => props.sizeMobile === 'large' ? mixins.screenUnder()+'{padding:12px 18px}' : null};
  ${props => props.sizeMobile === 'small' ? mixins.screenUnder()+'{padding:6px 12px}' : null};
  font-size: ${fonts.fontSizeRem('ms')};
  ${props => props.size === 'large' ? 'font-size:'+fonts.fontSizeRem('m') : null};
  ${props => props.size === 'small' ? 'font-size:'+fonts.fontSizeRem('s') : null};
  
  width:${props => props.fullwidth ? '100%' : 'auto'};

  ${props => !props.disabled ? 'cursor: pointer;' : null};
  ${props => !props.disabled ? 'box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 0px 12px rgba(0, 0, 0, 0.08)' : null};
  &:hover,&:active{
    ${props => props.disabled === true ? null : props.variant === 'primary' ? "background:" + colors.themeColor('background_button_primary_active') : null}
    ${props => props.disabled === true ? null : props.variant === 'secoundary' ? "color:" + colors.themeColor('text_button_secoundary_active') : null}
  }
  &:active{
    box-shadow: none;
  }
  a{
    color: ${props => props.variant === 'primary' ? colors.themeColor('text_white') : colors.themeColor('text_button_secoundary')} !important;
  }
  ${mixins.screenUnder()}{
    ${props => props.fullwidth_mobile && "width: 100%;"}
  }
`

export const Button = ({
  variant = 'primary',
  fullwidth = false,
  size = 'normal',
  disabled = false,
  onClick,
  children,
  sizeDesktop,
  sizeMobile,
  fullwidth_mobile
}: {
  onClick?: MouseEventHandler<HTMLButtonElement>,
  variant?: string,
  fullwidth?: boolean,
  size?: string,
  children?: ReactNode,
  sizeDesktop?: string,
  sizeMobile?: string,
  disabled?: boolean,
  fullwidth_mobile?: boolean
}) => {
  return (
    <ButtonStyle onClick={onClick} variant={variant} fullwidth={fullwidth} sizeDesktop={sizeDesktop} sizeMobile={sizeMobile} size={size} disabled={disabled} fullwidth_mobile={fullwidth_mobile}>
      {children}
    </ButtonStyle>
  );
};
