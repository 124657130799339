import styled from "styled-components";
import ContainerPopUpTitleWrapper from "../../../ui-parts/ocnk/ContainerPopUp/ContainerPopUpTitleWrapper";
import ContainerPopUpWrapper from "../../../ui-parts/ocnk/ContainerPopUp/ContainerPopUpWrapper";
import ExclamationIcon from "../../../ui-parts/ocnk/ExclamationIcon";
import { fonts } from "../../../../css/variables/fonts";
import { colors } from "../../../../css/variables/colors";
import { Link } from "react-router-dom";
import { ReactNode } from "react";

const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.themeColor('')}
`
const TitleStyle = styled.h1`
  font-size: ${fonts.fontSizeRem('ms')};
  text-decoration: underline;
  margin: 0;
  color: ${colors.themeColor('text_error')};
`
const BottomWrapperStyle = styled.div`
  margin-top: 6px;
  display: flex;
  align-items: center;
`
const BottomTextStyle = styled.div`
  font-size: ${fonts.fontSizeRem('xxs')};
  margin: 0 0 0 8px;
  white-space: pre-line;
`

const CloseDatePopUp = ({
    closeText
}:{
    closeText: ReactNode
}) => (
    <ContainerPopUpWrapper>
        <ContainerPopUpTitleWrapper color={colors.themeColor('background_pink')}>
            <TopWrapper>
                <TitleStyle>お知らせ：Web申込システムご利用終了のお知らせ</TitleStyle>
            </TopWrapper>
            <BottomWrapperStyle>
                <ExclamationIcon><p>！</p></ExclamationIcon>
                <BottomTextStyle>
                  {closeText}
                </BottomTextStyle>
            </BottomWrapperStyle>
        </ContainerPopUpTitleWrapper>
    </ContainerPopUpWrapper>
)

export default CloseDatePopUp
