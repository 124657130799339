import { ReactNode } from "react"
import styled from "styled-components"

const Wrapper = styled.div<{
	color: string | undefined
}>`
    padding: 12px 0;
    ${props => props.color ? `background-color:${props.color};` : ''}
`

const ContainerPopUpTitleWrapper = ({
	children,
	color
}: {
	children: ReactNode,
	color?: string
}) => (
	<Wrapper color={color}>
		{children}
	</Wrapper>
)

export default ContainerPopUpTitleWrapper