import { useEffect } from "react";
import ErrorBoundary from "../../../api/ErrorBoundary";
import useGTMHooks from "../../../hooks/useGTMHooks";
import NotFound from "../../ocnk/renderPages/NotFound";

const OcnkTeikiNotFoundBeforeRender = () => {
  const { pushDataLayerHandler } = useGTMHooks()

  useEffect(()=>{
    pushDataLayerHandler(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

    return(
      <ErrorBoundary>
        <NotFound/>
      </ErrorBoundary>
    )
}

export default OcnkTeikiNotFoundBeforeRender