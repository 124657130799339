import styled from 'styled-components';
import { colors } from '../../../../css/variables/colors';
import { fonts } from '../../../../css/variables/fonts';
import { inquiryContextAllDataObjectType } from '../../../../constance/providerTypeModel';
import LinkByAgent from '../LinkByAgent';

const Wrapper = styled.div`
  padding: 10px 0;
  border-top: solid 1px ${colors.themeColor('border_box')};
`

const TextStyle = styled.div`
  margin: 0 auto 0 16px;
  color: ${colors.themeColor('text_link')};
  font-size: ${fonts.fontSizeRem('s')};
`

const InquiryNavItem = ({
  inquiryItemData
}: {
  inquiryItemData: inquiryContextAllDataObjectType
}) => {
  return (
    <Wrapper>
      <LinkByAgent to='/inquiry' accordionNum={inquiryItemData.ticket_id}><TextStyle>{inquiryItemData.title}</TextStyle></LinkByAgent>
    </Wrapper>
  );
};

export default InquiryNavItem;

