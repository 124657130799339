import { initialState } from "../stores/Provider"
import { setSessionAllData } from "./setInitSessionData"

export const refreshMethods = (dispatch: Function, agentPath: string) => {
    const insertState = {
        ...initialState,
        agentPath: agentPath
    }
    //コンテキストをリセット
    const contextRefresh = () => {
        dispatch({
            type:"set-context-data",
            payload:insertState
        })
    }
    //セッションデータ初期値をセット
    setSessionAllData()
    contextRefresh()
}

export const logoutAbnormalFunction = (response: {code: number, message: string},dispatch: Function,navigateByAgent: Function,agentPath: string) => {
    //セッションデータとコンテキストをリセット
    refreshMethods(dispatch,agentPath)
    if(response.code === 401) response.message = 'セッションタイムアウト'
    dispatch({
        type: 'set-context-data',
        payload: {
            auth: {
                err_message: response.message,
                code: response.code,
            }
        }
    })
    
    return navigateByAgent('/login')
}