import styled from 'styled-components'
import { mixins } from '../../css/variables/mixins';
import { ReactNode } from 'react';

const EntryTopHeadSectionStyle = styled.div`
  margin-top: 24px;
  ${mixins.screenOver()}{
    display: grid;
    grid-template-columns: auto min-content;
    grid-template-rows: min-content 1fr;
    gap: 0px 16px;
    grid-auto-flow: row;
    align-content: start;
    grid-template-areas:
      "read service"
      "buttons service";
  }
  ${mixins.screenUnder()}{
    display: flex;
    flex-direction: column;
  }
`
const EntryTopHeadReadStyle = styled.div`
  ${mixins.screenOver()}{
    grid-area: read;
  }
`
const EntryTopHeadButtonsStyle = styled.div`
  ${mixins.screenOver()}{
    grid-area: buttons;
  }
  ${mixins.screenUnder()}{
    margin-top: 16px;
  }
`
const EntryTopHeadServicesStyle = styled.div`
  ${mixins.screenOver()}{
    grid-area: service;
  }
`

export const EntryTopHeadSection = ({
  children = <></>
} : {
  children : ReactNode
}) => {
  return (
    <EntryTopHeadSectionStyle>
      {children}
    </EntryTopHeadSectionStyle>
  );
};

export const EntryTopHeadRead = ({
  children = <></>
}: {
  children : ReactNode
}) => {
  return (
    <EntryTopHeadReadStyle>
      {children}
    </EntryTopHeadReadStyle>
  );
};

export const EntryTopHeadButtons = ({
  children = <></>
}: {
  children : ReactNode
}) => {
  return (
    <EntryTopHeadButtonsStyle>
      {children}
    </EntryTopHeadButtonsStyle>
  );
};

export const EntryTopHeadServices = ({
  children = <></>
}: {
  children : ReactNode
}) => (
  <EntryTopHeadServicesStyle>
    {children}
  </EntryTopHeadServicesStyle>
);
