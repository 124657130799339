const paymentSiteSimeArray = [
    ["EOM","月末締め"],
    ["M2","月2回締め"],
    ["M2_1025","月2回締め（10日25日）"],
    ["M3","月3回締め"],
    ["EVERYDAY","毎日締め"],
]

const paymentSiteHaraiArray = [
    ["N20","翌20日払い"],
    ["N15","翌15日払い"],
    ["NEND","翌末払い"],
    ["NN20","翌々20日払い"],
    ["NN15","翌々15日払い"],
    ["NN10","翌々10日払い"],
    ["NNEND","翌々末払い"],
    ["M2","月2回払い"],
    ["M2_1025","月2回払い（10日25日）"],
    ["M3","月3回払い"],
    ["N15_2","月2回払い（15日）"],
    ["END","月2回払い（末）"],
    ["N10","月3回払い（10日）"],
    ["20","月3回払い（20日）"],
    ["END_2","月3回払い（末）"],
    ["D2","2営業日後払い"],
    ["D3","3営業日後払い"],
    ["D7","7営業日後払い"],
]

export const paymentSiteChangeToCodeHandler = (phrase: string) => {
    let resultArray = phrase.split(' ')
    paymentSiteSimeArray.forEach((cutoffData)=>{
        if(cutoffData[1] === resultArray[0]) resultArray[0] = resultArray[0].replace(cutoffData[1],cutoffData[0])
    })
    paymentSiteHaraiArray.forEach((cutoffData)=>{
        if(cutoffData[1] === resultArray[1]) resultArray[1] = resultArray[1].replace(cutoffData[1],cutoffData[0])
    })
    const result = resultArray[0] + ' ' + resultArray[1]
    return result
}
export const paymentSiteChangeToPhraseHandler = (code: string) => {
    let resultArray = code.split(' ')
    paymentSiteSimeArray.forEach((cutoffData)=>{
        if(cutoffData[0] === resultArray[0]) resultArray[0] = resultArray[0].replace(cutoffData[0],cutoffData[1])
    })
    paymentSiteHaraiArray.forEach((cutoffData)=>{
        if(cutoffData[0] === resultArray[1]) resultArray[1] = resultArray[1].replace(cutoffData[0],cutoffData[1])
    })
    const result = resultArray[0] + ' ' + resultArray[1]
    return result
}
