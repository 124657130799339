import { initSystemSettingMasterType, initAgentMasterSessionDataType, initBankAccountSessionDataType, initEconomicConditionMasterSessionDataType, initEconomicDividedSessionDataType, initEconomicSessionDataType, initEntryCheckDataType, initFileSessionDataType, initLoginSessionDataType, initMerchantSessionDataType, initOperationSessionDataType, initRepresentativeSessionDataType, initReviewContentsDivisionSessionDataType, initReviewContentsMasterSessionDataType, initShopSessionDataType, initSumFeeSessionDataType, initSystemSettingMasterSessionDataType, initUserSessionDataType } from "./typeModel"

//ログイン情報の格納
export const initLoginSessionData: initLoginSessionDataType= {
    err_message: '',
    code: 0
}

//ユーザ情報の格納
export const initUserSessionData: initUserSessionDataType = {
    email: "",
    entry_id: 0,
    user_id: 0,
    agent_master_id: 0,
    ip_flag: false,
    redirect_auth_flag: false,
    // 加盟店情報を申込したフラグ
    is_merchant_entry: false,
    ip_code: 0
}

//システム利用料情報の格納
export const initSumFeeSessionData: initSumFeeSessionDataType = {
    credit: 0,
    cvs: 0,
    bank: 0,
    atobarai: 0,
}
//代理店マスタ情報の格納
export const initAgentMasterSessionData: initAgentMasterSessionDataType = {
    id: 0,
    zeus_agent_service_id: 0,
    zeus_agent_deal_id: 0,
    zeus_agent_id: 0,
    account_id_flag: true,
    demand_code: "",
    agent_name: ""
}

//審査コンテンツ出し分け情報の格納
export const initReviewContentsDivisionSessionData: initReviewContentsDivisionSessionDataType = {
    antique_flag: false,
    btob_flag: false,
    btob_special_rate_flag: false,
    estimate_flag: false,
    highrisk_flag: false,
    license_flag: false,
    license_name: "",
    online_local_flag: false,
    name: "",
    prepaid_continuous_flag: false,
    purchase_points_flag: false,
    real_store_flag: false,
    rental_flag: false,
    reserve_item_flag: false,
    sort_number: 0,
    special_rate_btob_flag: false,
    subscription_flag: false,
    zeus_medium_code: "",
}

//システム設定マスタ情報の格納
export const initSystemSettingMasterSessionData: initSystemSettingMasterSessionDataType = {
    credit_secureapi_flag: false,
    credit_securelink_flag: false,
    credit_linkpoint_flag: false,
    credit_div2_bonus_agent_flag: false,
    credit_div2_bonus_merchant_flag: false,
    credit_3d_agent_flag: false,
    credit_3d_merchant_flag: false,
    credit_cvv_agent_flag: false,
    credit_cvv_merchant_flag: false,
    credit_cvv_agent_setting: "",
    credit_subscription_agent_flag: false,
    credit_subscription_merchant_flag: false,
    credit_auth_agent_flag: false,
    credit_auth_merchant_flag: false,
    credit_send_mail_agent_flag: false,
    credit_send_mail_merchant_flag: false,
    credit_id_password_storage_agent_flag: false,
    credit_id_password_storage_merchant_flag: false,
    credit_customer_support_agent_flag: false,
    credit_customer_support_merchant_flag: false,
    credit_cgi_agent_flag: false,
    credit_cgi_merchant_flag: false,
    credit_cgi_param_agent:null,
    credit_cgi_param_merchant:null,
    credit_cgi_url_agent:null,
    credit_cgi_url_merchant:null,
    cvs_securelink_flag: false,
    cvs_linkpoint_flag: false,
    cvs_cgi_agent_flag: false,
    cvs_cgi_merchant_flag: false,
    cvs_cgi_param_agent:null,
    cvs_cgi_param_merchant:null,
    cvs_cgi_url_agent:null,
    cvs_cgi_url_merchant:null,
    cvs_send_mail_agent_flag: false,
    cvs_send_mail_merchant_flag: false,
    cvs_customer_support_agent_flag: false,
    cvs_customer_support_merchant_flag: false,
    bank_cgi_agent_flag: false,
    bank_cgi_merchant_flag: false,
    bank_cgi_param_agent:null,
    bank_cgi_param_merchant:null,
    bank_cgi_url_agent:null,
    bank_cgi_url_merchant:null,
    bank_send_mail_agent_flag: false,
    bank_send_mail_merchant_flag: false,
    bank_customer_support_agent_flag: false,
    bank_customer_support_merchant_flag: false,
}
//経済条件マスタ
export const initEconomicConditionMasterSessionData: initEconomicConditionMasterSessionDataType = {
    credit: {
        agent_master_id: 0,
        zeus_incentive_id: 0,
        payment_site: "",
        early_payment_two: false,
        early_payment_three: false,
        opening_contract_fee: 0,
        jcb_fee: 0,
        vm_fee: 0,
        diners_fee: 0,
        sales_processing_fee: 0,
        system_usage_fee: 0,
        option_fee_cvv: 0,
        option_fee_3d_secure: 0,
        option_fee_confirmation_email: 0,
        option_fee_continuous_billing: 0,
        option_fee_payment_now: 0,
        option_fee_customer_support: 0,
        option_fee_early_deposit_service_two: 0,
        option_fee_early_deposit_service_three: 0,
        option_fee_div_bonus: 0,
        option_fee_id_password_storage: 0,
        option_fee: 0,
    },
    cvs: {
        zeus_incentive_id: 0,
        payment_site: "",
        early_payment_flag: false,
        opening_contract_fee: 0,
        system_usage_fee: 0,
        option_fee_confirmation_email: 0,
        option_fee_customer_support: 0,
        option_fee_early_deposit_service: 0,
        fee_range_2000: "",
        fee_range_3000: "",
        fee_range_5000: "",
        fee_range_7000: "",
        fee_range_10000: "",
        fee_range_30000: "",
        fee_range_50000: "",
        fee_range_100000: "",
        fee_range_200000: "",
        fee_range_300000: "",
        sales_processing_fee: 0,
        option_fee: 0,
    },
    bank: {
        zeus_incentive_id: 0,
        payment_site: "",
        early_payment_flag: false,
        fee: 0,
        opening_contract_fee: 0,
        sales_processing_fee: 0,
        system_usage_fee: 0,
        option_fee_confirmation_email: 0,
        option_fee_customer_support: 0,
        option_fee_early_deposit_service: 0,
        option_fee: 0,
    },
    atobarai: {
        zeus_incentive_id: 0,
        fee: 0,
        payment_site: "",
        business_operator_fee: 0,
        opening_contract_fee: 0,
        sales_processing_fee: 0,
        system_usage_fee: 0,
    }
}

// 審査コンテンツマスタ情報の格納
export const initReviewContentsMasterSessionData: initReviewContentsMasterSessionDataType = {
    behavior_analysis_imple_flag: "",
    bor_sales_transactions_flag: false,
    can_do_without_seven: "",
    cancel_halfway: "",
    card_number_pass_flag: "",
    case_load_1_month_ago: 0,
    content_special_for_sale: "",
    door_to_door_sales_flag: false,
    injustice_address_using_flag: "",
    multilevel_marketing_sales_flag: false,
    other_measures_comment: "",
    other_measures_flag: "",
    payment_timing: "",
    pcidss_protection_flag: "",
    special_for_sale: false,
    specified_continuous_service_flag: false,
    telemarketing_sales_flag: false,
}

//申し込み用お客様情報の格納
export const initMerchantSessionData: initMerchantSessionDataType = {
    merchant_type: '',
    merchant_name: '',
    merchant_name_kana: '',
    merchant_last_name: '',
    merchant_first_name: '',
    merchant_last_name_kana: '',
    merchant_first_name_kana: '',
    merchant_phone_number: '',
    //画面上のみで必要な項目(以下３つ)
    merchant_phone_number_before: '',
    merchant_phone_number_middle: '',
    merchant_phone_number_after: '',
    merchant_register_post_code3: '',
    merchant_register_post_code4: '',
    merchant_register_post_code: '',
    merchant_address_prefectures: '',
    merchant_address_prefectures_kana: '',
    merchant_address_municipality: '',
    merchant_address_municipality_kana: '',
    merchant_address_streetbunch: '',
    merchant_address_streetbunch_kana: '',
    merchant_address_building: '',
    merchant_address_building_kana: '',
    merchant_website_url: '',
    merchant_annual_business: null,
    merchant_shop_category: '',
    merchant_corporate_number: '',
}

//申し込み用口座情報の格納
export const initBankAccountSessionData: initBankAccountSessionDataType = {
    bank_account_bank_name: '',
    bank_account_bank_code: '',
    bank_account_bank_branch: '',
    bank_account_bank_branch_code: '',
    bank_account_category: null,
    bank_account_number: '',
    bank_account_name: '',
}

//申し込み用代表者情報の格納
export const initRepresentativeSessionData: initRepresentativeSessionDataType = {
    representative_last_name: '',
    representative_first_name: '',
    representative_last_name_kana: '',
    representative_first_name_kana: '',
    representative_sex: '',
    representative_birthday_CE: '',
    representative_birthday_month: '',
    representative_birthday_day: '',
    representative_birthday: null,
}

//申し込み用担当者情報の格納
export const initOperationSessionData: initOperationSessionDataType = {
    operator_last_name: '',
    operator_first_name: '',
    operator_last_name_kana: '',
    operator_first_name_kana: '',
    operator_email: '',
    operator_phone_number: '',
    //画面上のみで必要な項目(以下３つ)
    operator_phone_number_before: '',
    operator_phone_number_middle: '',
    operator_phone_number_after: '',
    support_operator_email: '',
    support_operator_phone_number: '',
    //画面上のみで必要な項目(以下３つ)
    support_operator_phone_number_before: '',
    support_operator_phone_number_middle: '',
    support_operator_phone_number_after: '',
}

//申し込み用ファイル情報の格納
export const initFileSessionData: initFileSessionDataType = {
    identity_doc_images: [],
    passbook_images: [],
    antique: [],
    rental: [],
    license: [],
    examination_document: [],
}

//申し込み用ショップ情報の格納
export const initShopSessionData: initShopSessionDataType = {
    account_id: "",
    shop_name: "",
    shop_name_kana: "",
    shop_name_alphabet: "",
    shop_cvs_website_name: "",
    shop_cvs_website_name_kana: "",
    shop_website_url: "",
    shop_addition_site_status: "",
    //サイト状態の準備中・オープン済み選択のコード
    shop_addition_site_status_code: 0,
    //サイト状態準備中の精査方法
    shop_addition_site_status_method: 0,
    shop_addition_shop_website_check_url: "",
    shop_addition_shop_website_id: "",
    shop_addition_shop_website_password: "",
    shop_addition_price_range_min: null,
    shop_addition_price_range_max: null,
    shop_addition_product_content_code: "",
    shop_addition_product_content_code_sell_goods: false,
    shop_addition_product_content_code_service: false,
    shop_addition_product_content_code_digital_email: false,
    shop_addition_major_content: "",
    shop_addition_major_content_code: "",
    shop_addition_minor_product_type: "",
    shop_addition_medium_content: "",
    shop_addition_medium_content_code: "",
    shop_addition_product_content_freetext: "",
    shop_addition_license_flag: false,
    shop_addition_antique_flag: false,
    shop_addition_high_risk_flag: false,
    shop_addition_prepaid_flag: false,
    shop_addition_service_start_plan_date: "",
    shop_addition_subscription_flag: false,
    shop_addition_subscription_code: "",
    shop_addition_point_purchase_flag: false,
    shop_addition_reserved_product_flag: false,
    shop_addition_reservation_period_flag: false,
    shop_addition_estimated_product_flag: false,
    shop_addition_rental_flag: false,
    shop_addition_rental_product_url: "",
    //バリデーションのための追加項目
    shop_addition_rental_validate: false,
    shop_addition_online: false,
    shop_addition_offline: false,
    //バリデーションのための追加項目
    shop_addition_offer_place: false,
    shop_addition_btob_code: "",
    shop_addition_btob_special_rate_flag: false,
    shop_addition_non_infringing_product_presence_absence_flag: false,
    shop_addition_available_product_flag: false,
    shop_addition_physical_store_flag: false,
    shop_addition_physical_store_url_flag: false,
    shop_addition_physical_store_url: "",
    shop_addition_business_deal_url: "",
    shop_addition_use_continuation: false,
    shop_addition_conduct_start_yyyy: "",
    shop_addition_conduct_start_mm: "",
    shop_addition_case_load_1_month_ago: "",
    shop_addition_can_do_without_seven: "",
    shop_addition_non_administrative_penalties_five_year_flag: null,
    shop_addition_non_losing_judgment_flag: null,   
    shop_addition_payment_timing: "",
    shop_addition_special_for_sale: "",
    shop_addition_content_special_for_sale: "",
    shop_addition_cancel_halfway: "",
    shop_addition_card_number_pass_flag: "",
    shop_addition_card_number_pass_ym: "",
    shop_addition_pcidss_protection_flag: "",
    shop_addition_pcidss_protection_ym: "",
    shop_addition_authentication_imple_flag: "",
    shop_addition_authentication_imple_ym: "",
    shop_addition_security_code_imple_flag: "",
    shop_addition_security_code_imple_ym: "",
    shop_addition_injustice_address_using_flag: "",
    shop_addition_injustice_address_using_ym: "",
    shop_addition_behavior_analysis_imple_flag: "",
    shop_addition_behavior_analysis_imple_ym: "",
    shop_addition_other_measures_flag: "",
    shop_addition_other_measures_ym: "",
    shop_addition_other_measures_comment: "",
    shop_addition_door_to_door_sales_flag: false,
    shop_addition_telemarketing_sales_flag: false,
    shop_addition_multilevel_marketing_sales_flag: false,
    shop_addition_bor_sales_transactions_flag: false,
    shop_addition_specified_continuous_service_flag: false,
    //後払い用顧客通知店舗名
    shop_atobarai_customer_notice_store_name: "",
}

//経済条件申し込み用情報の格納
export const initEconomicSessionData: initEconomicSessionDataType = {
    service_code_list: "credit",
    credit: {
        system_setting: {
            type: "",
            cgi: false,
            cgi_url: null,
            cgi_param: null,
            div: false,
            threed_secure: false,
            cvv: false,
            cvv_setting: "",
            subscription: false,
            auth: false,
            payment_now: false,
            //フロントのみのフラグ
            select_flag: true,
            send_mail: false,
            id_password_storage: false,
            customer_support: false,
        },
        economic_requirement: {
            payment_site: "",
            early_payment_flag: false,
            opening_contract_fee: 0,
            jcb_fee: 0,
            vm_fee: 0,
            diners_fee: 0,
            sales_processing_fee: 0,
            system_usage_fee: 0,
            system_usage_fee_cvv: null,
            system_usage_fee_3d_secure: null,
            system_usage_fee_confirmation_email: null,
            system_usage_fee_continuous_billing: null,
            system_usage_fee_early_deposit_service: null,
            system_usage_fee_customer_support: null,
            system_usage_fee_div2_bonus: null,
            system_usage_fee_id_password_storage: null,
            system_usage_fee_payment_now: null,
            monthly_payment_method: "",
            monthly_option_fee_cvv: null,
            monthly_option_fee_3d_secure: null,
            monthly_option_fee_confirmation_email: null,
            monthly_option_fee_continuous_billing: null,
            monthly_option_fee_customer_support: null,
            monthly_option_fee_early_deposit_service: null,
            monthly_option_fee_div2_bonus: null,
            monthly_option_fee_id_password_storage: null,
            monthly_option_fee_payment_now: null,
            option_fee: null,
            zeus_incentive_id: 0
        }
    },
    bank: {
        system_setting: {
            type: "",
            cgi: false,
            cgi_url: null,
            cgi_param: null,
            send_mail: false,
            //フロントのみ
            select_flag: false,
            customer_support: false,
        },
        economic_requirement: {
            payment_site: "",
            early_payment_flag: false,
            fee: 0,
            opening_contract_fee: 0,
            sales_processing_fee: 0,
            system_usage_fee: 0,
            system_usage_fee_confirmation_email: null,
            system_usage_fee_customer_support: null,
            system_usage_fee_early_deposit_service: null,
            monthly_payment_method: "",
            monthly_option_fee_confirmation_email: null,
            monthly_option_fee_customer_support: null,
            monthly_option_fee_early_deposit_service: null,
            option_fee: 0,
            zeus_incentive_id: 0,
        }
    },
    cvs: {
        system_setting: {
            type: "",
            cgi: false,
            cgi_url: null,
            cgi_param: null,
            send_mail: false,
            //フロントのみ
            select_flag: false,
            customer_support: false,
        },
        economic_requirement: {
            early_payment_flag: false,
            opening_contract_fee: 0,
            system_usage_fee_confirmation_email: null,
            system_usage_fee_customer_support: null,
            system_usage_fee_early_deposit_service: null,
            fee_range_2000: "",
            fee_range_3000: "",
            fee_range_5000: "",
            fee_range_7000: "",
            fee_range_10000: "",
            fee_range_30000: "",
            fee_range_50000: "",
            fee_range_100000: "",
            fee_range_200000: "",
            fee_range_300000: "",
            sales_processing_fee: 0,
            system_usage_fee: 0,
            monthly_payment_method: "",
            monthly_option_fee_confirmation_email: null,
            monthly_option_fee_customer_support: null,
            monthly_option_fee_early_deposit_service: null,
            option_fee: 0,
            zeus_incentive_id: 0,
            payment_site: "",
        }
    },
    atobarai: {
        system_setting: {
            //フロントのみ
            select_flag: false,
        },
        economic_requirement: {
            //フロントのみのフラグ
            temp_payment_site: "",
            business_operator_fee: 0,
            opening_contract_fee: 0,
            sales_processing_fee: 0,
            system_usage_fee: 0,
            fee: 0,
            monthly_payment_method: "",
            zeus_incentive_id: 0,
            payment_site: "",
        }
    },
}

// 経済条件サービス出し分けフラグ
export const initEconomicDividedSessionData: initEconomicDividedSessionDataType = {
    credit: false,
    bank: false,
    cvs: false,
    atobarai: false,
}

// 申し込みトップ
export const initEntryCheckData: initEntryCheckDataType = {
    merchant: "default",
    bank_account: "default",
    shop: "default",
}

// ログイン
// system_setting_master
export const initSystemSettingMaster: initSystemSettingMasterType = {
    "system_setting_info_id": "",
    "credit_secureapi_flag": false,
    "credit_securelink_flag": false,
    "credit_linkpoint_flag": false,
    "credit_div2_bonus_agent_flag": false,
    "credit_div2_bonus_merchant_flag": false,
    "credit_3d_agent_flag": false,
    "credit_3d_merchant_flag": false,
    "credit_cvv_agent_flag": false,
    "credit_cvv_merchant_flag": false,
    "credit_cvv_agent_setting": "",
    "credit_subscription_agent_flag": false,
    "credit_subscription_merchant_flag": false,
    "credit_auth_agent_flag": false,
    "credit_auth_merchant_flag": false,
    "credit_send_mail_agent_flag": false,
    "credit_send_mail_merchant_flag": false,
    "credit_id_password_storage_agent_flag": false,
    "credit_id_password_storage_merchant_flag": false,
    "credit_customer_support_agent_flag": false,
    "credit_customer_support_merchant_flag": false,
    "credit_cgi_agent_flag": false,
    "credit_cgi_merchant_flag": false,
    "credit_cgi_url_agent": null,
    "credit_cgi_url_merchant": null,
    "credit_cgi_param_agent": null,
    "credit_cgi_param_merchant": null,
    "cvs_securelink_flag": false,
    "cvs_linkpoint_flag": false,
    "cvs_cgi_agent_flag": false,
    "cvs_cgi_merchant_flag": false,
    "cvs_cgi_url_agent": null,
    "cvs_cgi_url_merchant": null,
    "cvs_cgi_param_agent": null,
    "cvs_cgi_param_merchant": null,
    "cvs_send_mail_agent_flag": false,
    "cvs_send_mail_merchant_flag": false,
    "cvs_customer_support_agent_flag": false,
    "cvs_customer_support_merchant_flag": false,
    "bank_cgi_agent_flag": false,
    "bank_cgi_merchant_flag": false,
    "bank_cgi_url_agent": null,
    "bank_cgi_url_merchant": null,
    "bank_cgi_param_agent": null,
    "bank_cgi_param_merchant": null,
    "bank_send_mail_agent_flag": false,
    "bank_send_mail_merchant_flag": false,
    "bank_customer_support_agent_flag": false,
    "bank_customer_support_merchant_flag": false,
    "credit_payment_type": "",
    "credit_early_payment_service_agent_flag": false,
    "credit_payment_now_agent_flag": false,
    "credit_cvv_setting_agent": "",
    "credit_early_payment_service_merchant_flag": false,
    "credit_payment_now_merchant_flag": false,
    "credit_cvv_setting_merchant_flag": "",
    "bank_payment_type": "",
    "bank_early_payment_service_agent_flag": false,
    "bank_early_payment_service_merchant_flag": false,
    "cvs_payment_type": "",
    "cvs_early_payment_service_agent_flag": false,
    "cvs_early_payment_service_merchant_flag": false,
    "atobarai_payment_type": "SecureAPI",
    "atobarai_cgi_agent_flag": false,
    "atobarai_send_mail_agent_flag": false,
    "atobarai_customer_support_agent_flag": false,
    "atobarai_early_payment_service_agent_flag": false,
    "atobarai_cgi_merchant_flag": false,
    "atobarai_send_mail_merchant_flag": false,
    "atobarai_customer_support_merchant_flag": false,
    "atobarai_early_payment_service_merchant_flag": false
}