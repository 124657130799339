import { ButtonBack } from "../../../ui-parts/ocnk";
import styled from "styled-components";
import { useLocation } from 'react-router-dom';
import { useContext } from "react"
import { Context } from '../../../../stores/Provider'
import { mixins } from '../../../../css/variables/mixins';
import LinkByAgent from "../../../ui-parts/ocnk/LinkByAgent";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ButtonWrapper = styled.div`
  ${mixins.screenOver()}{
    display: block;
  }
  ${mixins.screenUnder()}{
    display: none;
  }
`

const TitleElement = () => {
  const { pathname } = useLocation();
  const {state} = useContext(Context)
  const agentPath = state.agentPath
  const path = pathname.replace(agentPath, '')

  const titleElement = (path: string) => {
    switch (path) {
      case '/examination_status':
        return '審査状況';
      case '/use_start':
        return 'ご利用開始の手順';
      default:
        return ''
    }
  }
  return (
    <>
      <Wrapper>
        <h1>{titleElement(path)}</h1>
        <ButtonWrapper>
          <ButtonBack><LinkByAgent to='/dashboard'>ダッシュボードに戻る</LinkByAgent></ButtonBack>
        </ButtonWrapper>
      </Wrapper>
      {path === '/use_start' &&
        <h3 className="mt0 mb20">決済サービスがご利用可能な状態となりました。</h3>
      }
    </>
  )
}

export default TitleElement