import { Container } from "../../../components/layouts/Container"
import EconomicSelectElement from "../../../components/ui_elements/ocnk/Economic/EconomicSelectElement";
import styled from "styled-components";
import { mixins } from "../../../css/variables/mixins";

const TitleStyle = styled.h1`
	text-align: center;
	${mixins.screenUnder()}{
		text-align: left;
	}
`

const EconomicSelect = () => {
	
	return (
		<Container isLogin={true} background={'default'} align={'top'}>
			<TitleStyle>ご利用になるサービスを選択しましょう</TitleStyle>
			<EconomicSelectElement />
		</Container>
	)
}

export default EconomicSelect