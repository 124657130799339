import styled from 'styled-components';
import { colors } from '../../../../css/variables/colors';
import { fonts } from '../../../../css/variables/fonts';
import { ReactNode } from 'react';

const WrapperStyle = styled.h2`
  width: 100%;
  padding: 10px;
  background: ${colors.themeColor('background_whitegray')};
  font-size: ${fonts.fontSizeRem('xl')};
  font-weight: 600;
  margin: 0;
  border: none;
`

const ExaminationContentTitle = ({
  children
}: {
  children: ReactNode
}) => {
  return (
    <WrapperStyle>
      {children}
    </WrapperStyle>
  )
}

export default ExaminationContentTitle