import styled from 'styled-components';
import { colors } from '../../../../css/variables/colors';
import { fonts } from '../../../../css/variables/fonts';
import { InquiryNavItem } from '../../../ui-parts/ocnk';
import noticeImg from '../../../../images/icon_notice.svg';
import noticeActiveImg from '../../../../images/icon_notice_active.svg';
import { MouseEventHandler } from 'react';
import { inquiryContextAllDataObjectType, inquiryContextAllDataType } from '../../../../constance/providerTypeModel';
import LinkByAgent from '../../../ui-parts/ocnk/LinkByAgent';
import ContainerPopUpWrapper from '../../../ui-parts/ocnk/ContainerPopUp/ContainerPopUpWrapper';
import ContainerPopUpTitleWrapper from '../../../ui-parts/ocnk/ContainerPopUp/ContainerPopUpTitleWrapper';
import ExclamationIcon from '../../../ui-parts/ocnk/ExclamationIcon';

const TopWrapper = styled.div`
  display: flex;
  align-items: center;
`

const TitleStyle = styled.h1`
  font-size: ${fonts.fontSizeRem('ms')};
  text-decoration: underline;
  margin: 0;
`

const ToggleBtnStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  margin-left: auto;
  border-radius: 4px;
  border: solid 1px ${colors.themeColor('border_box')};
  cursor: pointer;
  background: ${colors.themeColor('background_main')};
`

const NoticeImgStyle = styled.div`
  height: 15.51px;
  width: 16.5px;
  background: url(${noticeImg}) no-repeat center;
`

const NoticeActiveImgStyle = styled.div`
  width: 15px;
  height: 2px;
  background: url(${noticeActiveImg}) no-repeat center;
`

const BottomWrapperStyle = styled.div`
  margin-top: 6px;
  display: flex;
  align-items: center;
`

const BottomTextStyle = styled.p`
  font-size: ${fonts.fontSizeRem('xxs')};
  margin: 0 0 0 8px;
`

const InquiryNavElement = ({
  InquiryData,
  Toggle,
  toggleChangeHandler,
  nonClosedCount
}: {
  InquiryData: inquiryContextAllDataType,
  Toggle?: boolean,
  toggleChangeHandler: MouseEventHandler<HTMLElement>,
  nonClosedCount: number
}) => {

 
  return (
    <ContainerPopUpWrapper>
      <ContainerPopUpTitleWrapper>
        <TopWrapper>
          <LinkByAgent to='/inquiry'><TitleStyle>お知らせ：申し込み内容のご確認</TitleStyle></LinkByAgent>
          
          <ToggleBtnStyle onClick={toggleChangeHandler}>
            {Toggle ?  <NoticeImgStyle /> : <NoticeActiveImgStyle />}
          </ToggleBtnStyle>
        </TopWrapper>
        <BottomWrapperStyle>
          {nonClosedCount !== 0 && <ExclamationIcon><p>！</p></ExclamationIcon>}
          {nonClosedCount !== 0 &&
            <BottomTextStyle>確認項目が{nonClosedCount}件あります。</BottomTextStyle>
          }
          {nonClosedCount === 0 &&
            <BottomTextStyle>現在、返信が必要な確認項目はありません。</BottomTextStyle>
          }
        </BottomWrapperStyle>
      </ContainerPopUpTitleWrapper>
      {Toggle &&
        InquiryData.map((data: inquiryContextAllDataObjectType, index: number) => (
          <InquiryNavItem
            key={index}
            inquiryItemData={data}
          />
        ))
      }
      
    </ContainerPopUpWrapper>
  );
};

export default InquiryNavElement;
