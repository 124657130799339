import signinImg from '../../../../images/pct_signin.svg';
import { FormWrap } from '../../../layouts/FormWrap';
import { TextField, Button, ErrorMessage } from '../../../ui-parts/ocnk';
import { Box } from '../../../layouts/Box';
import { ChangeEventHandler, MouseEventHandler } from 'react';
import LinkByAgent from '../../../ui-parts/ocnk/LinkByAgent';
import AnotherTabLink from "../../../ui-parts/ocnk/AnotherTabLink";

const LoginElement = ({
  LoginHandler,
  setEmailHandler,
  setPasswordHandler,
  ApiErrMsg,
  ErrMsgs,
  IsClosed,
  agentMasterList,
  agentName
}: {
  LoginHandler: MouseEventHandler<HTMLButtonElement>,
  setEmailHandler: ChangeEventHandler<HTMLInputElement>,
  setPasswordHandler: ChangeEventHandler<HTMLInputElement>,
  ApiErrMsg: {
    err_message: string
  },
  ErrMsgs: {
    email: string,
    password: string
  },
  IsClosed: boolean,
  agentMasterList: string[] | number[],
  agentName: string
}) => {
  return (
    <FormWrap>
      <div className='align_center'>
        <img src={signinImg} alt='WebEntrySystemIcon' />
        <p className='bold font-xl mt20 mb0'>Web申込システム</p>
        <p className='bold font-l mt0 mb0'>{agentName}</p>
        <Box mt_desktop={16}>
          <h1 className='mt4 mb16'>ログイン</h1>
        </Box>
      </div>
      <ErrorMessage ApiErrMsg={ApiErrMsg.err_message} />
      <TextField
        onChange={setEmailHandler}
        iserror={ErrMsgs.email}
        type='email'
        id='textfield1'
        label='メールアドレス'
        placeholder='user@cardservice.co.jp'
        fullwidth
      />
      <TextField
        onChange={setPasswordHandler}
        iserror={ErrMsgs.password}
        type='password'
        id='textfield2'
        label='パスワード'
        fullwidth
        mt={0}
      />
      <Box mt={32} />
      <Button
        onClick={LoginHandler}
        variant='primary'
        fullwidth
      >
        ログイン
      </Button>
      <hr />
      {IsClosed ? 
        <p className='align_center'>よくあるご質問は<AnotherTabLink href={"https://support.cardservice.co.jp/hc/ja"}>FAQ</AnotherTabLink>でご確認ください。</p>
        :
        <p className='align_center'><LinkByAgent to="/reset_password_mail" agentMasterList={agentMasterList}>パスワードを忘れた方</LinkByAgent></p>
      }
      
    </FormWrap>
  )
}

export default LoginElement