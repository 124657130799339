import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { GetMethod } from "../api/Api"
import { initApiResponse, initCategoryMediumDevided, initCategoryResponse } from "../constance/constance"
import { initFileSessionData, initReviewContentsDivisionSessionData, initShopSessionData } from "../constance/sessionData"
import { itemValidators } from "../validators/validationPattern"
import { getSession, setReviewContentsDivisionInfoData, setShopInfoData } from "../sessionStorage/sessionStorageMethod"
import { Context } from "../stores/Provider"
import { setIsLoading } from "../function/setIsLoading"
import { logoutAbnormalFunction } from "../function/logoutAbnormalFunction"
import { comparePriceValidator, intValidate } from "../validators/publicValidator"
import { setShopDataHandlerType, categoryMediumType, initApiModelType, categoryMastersResponseDataType } from "../constance/typeModel"


export const useEntryCheckHooks = (navigateByAgent: Function) => {
    const [response,setResponse] = useState(initCategoryResponse)
    const {state, dispatch} = useContext(Context)
    const [showFlag, setShowFlag] = useState<boolean>(false)
    const [errMsg] = useState<string>("")

    //セッションデータ抽出
    const agentMasterInfoData = getSession('agent_master')
    const merchantInfoData = getSession('merchant')
    const economicInfoData = getSession('economic_condition')
    const userInfoData = getSession('user')
    const ipFlag = userInfoData.ip_flag
    

    //コンテキスト抽出
    //ショップ
    const shopState = useMemo(()=>{
        if(state.entry.shop === null || state.entry.shop === initShopSessionData) return getSession('shop')
        return state.entry.shop
    },[state.entry.shop])
    

    // 商品コンテンツによる出し分け
    const reviewContentsDivisionState = useMemo(()=>{
        if(state.entry.review_contents_division === null || state.entry.review_contents_division === initReviewContentsDivisionSessionData) return getSession('review_contents_division')
        return state.entry.review_contents_division
    },[state.entry.review_contents_division])

    
    // 商品コンテンツにより出し分け情報をコンテキストとセッションに保存
    const setReviewContentsDivisionDataHandler = (data: categoryMediumType) => {
        const insertData = {...reviewContentsDivisionState, ...data}
        setReviewContentsDivisionInfoData(insertData)
        dispatch({
            type : "set-review-content-division",
            payload : insertData
        })
    }

    //自動スクロールトップ&ローディング画面を非表示
    const scrollWindow = useCallback(() => {
        window.scrollTo(0,0)
        setIsLoading(false,dispatch)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    // 画面読み込み時処理
    useEffect(() => {
        //ローディング画面開始
        setIsLoading(true, dispatch)
        // 審査コンテンツ取得
        const getResponse = async () => {
            let response: initApiModelType<categoryMastersResponseDataType> = initApiResponse 
            const data = ""  
            response = await GetMethod<string, categoryMastersResponseDataType>(data, '/category_masters')
            if(response.code !== 200){
                if(response.code === 401){
                    return logoutAbnormalFunction(response, dispatch, navigateByAgent, state.agentPath)
                }
                return navigateByAgent('/system_error')
            }  
            if(response.code === 200)  setResponse(response)
            setShowFlag(true)
        } 
        getResponse()
        setTimeout(scrollWindow,1500)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

        
    //取扱商品コード大項目
    const selectMajorContents = useMemo(() => {
        const initArray = [
            { label: '選択してください', value: '', disabled: false, memo: '' },
        ]
        if(!showFlag || !response.response_data) return initArray
        for (let key in response.response_data.category_major) {
            const majorObj = { label: response.response_data.category_major[key].name, value: response.response_data.category_major[key].zeus_major_code, disabled: false, memo: key }
            initArray.push(majorObj)
        }
        return initArray
    }, [response,showFlag])

    //取扱商品コード中項目
    const selectMediumContents = useMemo(() => {
        const initArray = [
            { label: '選択してください', value: '', disabled: false, memo: '' },
        ]
        if(!showFlag || !response.response_data) return initArray
        let majorNumber: string = "";
        if(shopState.shop_addition_major_content_code === null) return initArray
        selectMajorContents.forEach((key) => {
            if (key.value === shopState.shop_addition_major_content_code) majorNumber = key.memo
        })
        if (shopState.shop_addition_major_content_code !== '') {
            const categoryMedium = response.response_data.category_major[majorNumber].category_medium

            for (let key in categoryMedium) {
                const mediumObj = { label: categoryMedium[key].name, value: categoryMedium[key].zeus_medium_code, disabled: false, memo: key }
                initArray.push(mediumObj)
            }
        }
        return initArray
    }, [response, selectMajorContents, shopState.shop_addition_major_content_code,showFlag])


    //出し分けのためのオブジェクト
    const ObjToDevide = useMemo(() => {
        let majorNumber: string = "";
        const allFalse = initCategoryMediumDevided
        if(!showFlag || !response.response_data) return allFalse
        if (shopState.shop_addition_major_content_code !== '' && shopState.shop_addition_medium_content_code !== '' && shopState.shop_addition_medium_content_code !== null) {
            selectMajorContents.forEach((key) => {
                if (key.value === shopState.shop_addition_major_content_code) majorNumber = key.memo
            })
            let mediumNumber: string = "";
            selectMediumContents.forEach((key) => {
                if (key.value === shopState.shop_addition_medium_content_code) mediumNumber = key.memo
            })
            
            const result = response.response_data.category_major[majorNumber].category_medium[mediumNumber]
            return result
        } else {
            return allFalse
        }
    }, [response,shopState.shop_addition_medium_content_code,shopState.shop_addition_major_content_code,selectMajorContents,selectMediumContents,showFlag])

    
        return{selectMajorContents, selectMediumContents, ObjToDevide,showFlag}
}

