import React, { useContext, useEffect, useState } from 'react';
import { EntryHead } from '../../../../ui-parts/ocnk/EntryHead';
import { FormTitle } from '../../../../ui-parts/ocnk/FormTitle';
import { Box } from '../../../../layouts/Box';
import { FlexBox } from '../../../../layouts/FlexBox';
import { RadioButtonGroup } from '../../../../ui-parts/ocnk/RadioButtonGroup';
import { TextField } from '../../../../ui-parts/ocnk/TextField';
import { SelectMenu } from '../../../../ui-parts/ocnk/SelectMenu';
import { FileInput } from '../../../../ui-parts/ocnk/FileInput';
import { Checkbox } from '../../../../ui-parts/ocnk/Checkbox';
import { FormChildBlockskin } from '../../../../layouts/FormChildBlockskin';
import { useEntryContentsShopHooks } from '../../../../../hooks/useEntryContentsShopHooks';
import { ErrorMessage } from '../../../../ui-parts/ocnk/ErrorMessage';
import { accountIdPhrase, toolChipList } from '../../../../../constance/constance';
import styled from 'styled-components';
import { fonts } from '../../../../../css/variables/fonts';
import useGTMHooks from '../../../../../hooks/useGTMHooks';
import useLimitDirectAccess from '../../../../../hooks/useLimitDirectAccess';
import useNavigateByAgent from '../../../../../hooks/useNavigateByAgentHooks';
import { Context } from '../../../../../stores/Provider';

const ToolChipExplain = styled.p`
    font-size:${fonts.fontSizeRem('xs')};
    margin-bottom:0px;
    white-space: pre-line;
    text-align:left;
`

const SiteStatusExplainTitle = styled.p`
    font-size: ${fonts.fontSizeRem('ms')};
    font-weight: bold;
    margin-bottom:0px;
    margin-top:8px;
`
const SiteStatusExplainContent = styled.p`
    font-size: ${fonts.fontSizeRem('s')};
    margin-left:20px;
    margin-bottom:0px;
`

export const EntryContentsShop = () => {
    const {state} = useContext(Context)
    const {navigateByAgent} = useNavigateByAgent()
    const {DirectAccessCheck} = useLimitDirectAccess(state.agentPath)
    const { userInfoData, merchantInfoData, agentMasterInfoData,shopState, economicInfoData, setShopStateHandler, setRadioShopStateHandler, setSelectBoxShopStateHandler, 
        radioItemsSite, radioItemsSiteMethod, radioYesNo, radioCanNot,radioBtoB, selectStartOperationYear, selectStartOperationMonth, selectChargesCycle, selectMajorContents,
        selectMediumContents, ObjToDevide, validateErrMsgs, apiErrMsg, showFlag, reverseRadioYesNo, setBtoBRadioShopStateHandler } = useEntryContentsShopHooks(navigateByAgent);
    const { pushDataLayerHandler } = useGTMHooks()

    const [directFlag, setDirectFlag] = useState<boolean>(false)
    useEffect(()=>{
      pushDataLayerHandler()
      setDirectFlag(DirectAccessCheck(state.agentPath+'/entry/shop')) 
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    if(!directFlag) return(<></>)
    
    return (
        <>
            {showFlag &&
                <>
                    <ErrorMessage ApiErrMsg={apiErrMsg}/>
                    {apiErrMsg}
                    <EntryHead icon='cart'>ショップ情報</EntryHead>
                    <h2>ショップ基本情報</h2>
                    {agentMasterInfoData.account_id_flag &&	
                        <>
                            <FormTitle required >{accountIdPhrase[userInfoData.agent_master_id]}</FormTitle>
                            <TextField id='account_id' placeholder='123456' fullwidth defaultValue={shopState.account_id} onChange={setShopStateHandler} iserror={validateErrMsgs.account_id.message} />
                        </>
                    }
                    
                    <FormTitle required toolChip={toolChipList.shop_website_url} responsiveToolChipPlacement={'right'}>決済サービスを導入するショップURL</FormTitle>
                    <TextField id='shop_website_url' placeholder='https://www.cardservice.co.jp' fullwidth defaultValue={shopState.shop_website_url} onChange={setShopStateHandler} iserror={validateErrMsgs.shop_website_url.message} />

                    <FormTitle required >お申し込み時のショップ稼働状況</FormTitle>
                    <SiteStatusExplainTitle>【オープン済み】を選択した場合</SiteStatusExplainTitle>
                    <SiteStatusExplainContent>ご申請の「決済サービスを導入するショップURL」にて、販売される商品・サービスの内容と価格、</SiteStatusExplainContent>
                    <SiteStatusExplainContent>特定商取引法に基づく表記が確認可能な状態の場合にご選択ください。</SiteStatusExplainContent>
                    <SiteStatusExplainTitle>【準備中】を選択した場合</SiteStatusExplainTitle>
                    <SiteStatusExplainContent>「決済サービスを導入するショップURL」が構築中で、販売される商品・サービスの内容と価格、</SiteStatusExplainContent>
                    <SiteStatusExplainContent>特定商取引法に基づく表記が確認出来ない場合にご選択ください。</SiteStatusExplainContent>
                    <RadioButtonGroup name='shop_addition_site_status_code' radioitems={radioItemsSite} state={shopState.shop_addition_site_status_code} setState={setRadioShopStateHandler} iserror={validateErrMsgs.shop_addition_site_status_code.message} />

                    {shopState.shop_addition_site_status_code === 0 &&
                        <>
                            <FormChildBlockskin>
                                <h4 className='mt0'>ご希望の審査方法について、⓵サンプルサイト・デモサイトもしくは、⓶資料をご選択ください。</h4>
                                <SiteStatusExplainTitle>【サンプルサイト・デモサイトによる審査】</SiteStatusExplainTitle>
                                <SiteStatusExplainContent>サンプルサイト・デモサイトにて販売される商品・サービス内容と価格、特定商取引法に</SiteStatusExplainContent>
                                <SiteStatusExplainContent>基づく表記が確認可能な場合にご選択いただき、対象のURLをご申請ください。</SiteStatusExplainContent>
                                <SiteStatusExplainTitle>【資料による審査】</SiteStatusExplainTitle>
                                <SiteStatusExplainContent>サンプルサイト・デモサイトのご準備がない場合にご選択いただき、販売される商品・サービス内容と価格、</SiteStatusExplainContent>
                                <SiteStatusExplainContent>特定商取引法に基づく表記が確認可能な資料(jpeg / png / pdf)をアップロードください。</SiteStatusExplainContent>
                                
                                <RadioButtonGroup name='shop_addition_site_status_method' radioitems={radioItemsSiteMethod} state={shopState.shop_addition_site_status_method} setState={setRadioShopStateHandler} iserror={validateErrMsgs.shop_addition_site_status_method.message}/>
                                {shopState.shop_addition_site_status_method === 0 &&
                                    <>
                                        <FormTitle required>審査用サイト</FormTitle>
                                        <TextField id='shop_addition_shop_website_check_url' placeholder='https://www.cardservice.co.jp/demo/shop/' fullwidth defaultValue={shopState.shop_addition_shop_website_check_url} onChange={setShopStateHandler} iserror={validateErrMsgs.shop_addition_shop_website_check_url.message}/>
                                        <FormTitle required>特定商取引法に基づく表示  掲載URL</FormTitle>
                                        <TextField id='shop_addition_business_deal_url' placeholder='https://www.cardservice.co.jp/guide/tokusho_houjin.html' fullwidth defaultValue={shopState.shop_addition_business_deal_url} onChange={setShopStateHandler} iserror={validateErrMsgs.shop_addition_business_deal_url.message}/>
                                        <FormTitle>ID/PW</FormTitle>
                                        <ToolChipExplain>ログインが必要な場合は、ログイン情報も併せてご登録ください。</ToolChipExplain>
                                        <TextField id='shop_addition_shop_website_id' placeholder='ID' fullwidth defaultValue={shopState.shop_addition_shop_website_id} onChange={setShopStateHandler} iserror={validateErrMsgs.shop_addition_shop_website_id.message} label={'ID'}/>
                                        <TextField id='shop_addition_shop_website_password' placeholder='PASSWORD' fullwidth defaultValue={shopState.shop_addition_shop_website_password} onChange={setShopStateHandler} iserror={validateErrMsgs.shop_addition_shop_website_password.message} label={'PASSWORD'}/>
                                    </>
                                }
                                {shopState.shop_addition_site_status_method === 1 &&
                                    <>
                                        <FormTitle required>審査資料</FormTitle>
                                        <ToolChipExplain>JPEG,PNG,PDFにてアップロードください。</ToolChipExplain>
                                        <FileInput multiple id='examination_document' name='examination_document' maxsize={30} accept={['jpeg', 'png', 'pdf', 'jpg']} iserror={validateErrMsgs.examination_document.message} page={"shop"}/>
                                    </>
                                }
                            </FormChildBlockskin>
                        </>
                    }

                    <FormTitle required>ショップ運営開始年月</FormTitle>
                    <ToolChipExplain>お申込みされるショップのオープン日（予定含む）をご入力ください。</ToolChipExplain>
                    <FlexBox>
                        <SelectMenu id='shop_addition_conduct_start_yyyy' name='shop_addition_conduct_start_yyyy' responsiveFullWidth options={selectStartOperationYear} defaultValue={shopState.shop_addition_conduct_start_yyyy} label='年' fullwidth onChange={setShopStateHandler} iserror={validateErrMsgs.shop_addition_conduct_start_yyyy.message}/>
                        <SelectMenu id='shop_addition_conduct_start_mm' name='shop_addition_conduct_start_mm' responsiveFullWidth options={selectStartOperationMonth} defaultValue={shopState.shop_addition_conduct_start_mm} label='月' fullwidth onChange={setShopStateHandler} iserror={validateErrMsgs.shop_addition_conduct_start_mm.message}/>
                    </FlexBox>

                    <FormTitle required toolChip={toolChipList.shop} toolChipWidth={390} responsiveToolChipPlacement={'center'}>ご利用店舗名称</FormTitle>
                    <TextField id='shop_name' label='店舗名' placeholder='ゼウスWEB店' fullwidth defaultValue={shopState.shop_name} onChange={setShopStateHandler} iserror={validateErrMsgs.shop_name.message} explain={toolChipList.shop_name} />
                    <TextField id='shop_name_kana' label='店舗名ﾌﾘｶﾞﾅ' placeholder='ｾﾞｳｽｳｪﾌﾞﾃﾝ' fullwidth defaultValue={shopState.shop_name_kana} onChange={setShopStateHandler} iserror={validateErrMsgs.shop_name_kana.message} explain={toolChipList.shop_name_kana}/>
                    <TextField toolChip={toolChipList.shop_name_alphabet} toolChipWidth={250} id='shop_name_alphabet' label='店舗名英字' placeholder='ZEUS WEB SHOP' fullwidth defaultValue={shopState.shop_name_alphabet} onChange={setShopStateHandler} iserror={validateErrMsgs.shop_name_alphabet.message} explain={toolChipList.shop_name_alphanumeric}/>
                    
                    {/* コンビニ選択時のみ加盟店屋号・ｶﾅ補完 */}
                    {economicInfoData.cvs.system_setting.select_flag &&
                        <>
                            <FormTitle required toolChip={toolChipList.shop_cvs_website} toolChipWidth={450} responsiveToolChipPlacement={'right'}>コンビニ払込票表示ショップ名</FormTitle>
                            <TextField id='shop_cvs_website_name' label='払込票ショップ名' placeholder='ゼウスWEB店' fullwidth defaultValue={shopState.shop_cvs_website_name} onChange={setShopStateHandler} iserror={validateErrMsgs.shop_cvs_website_name.message} explain={toolChipList.shop_cvs_website_name}/>
                            <TextField id='shop_cvs_website_name_kana' label='払込票ショップ名ﾌﾘｶﾞﾅ' placeholder='ｾﾞｳｽｳｪﾌﾞﾃﾝ' fullwidth defaultValue={shopState.shop_cvs_website_name_kana} onChange={setShopStateHandler} iserror={validateErrMsgs.shop_cvs_website_name_kana.message} explain={toolChipList.shop_cvs_website_name_kana}/>
                        </>
                    }

                    {/* 後払い追加項目 */}
                    {economicInfoData.atobarai.system_setting.select_flag &&
                        <>
                            <FormTitle required toolChip={toolChipList.shop_atobarai_customer_notice_store_name} responsiveToolChipPlacement={'right'}>コンビニあと払い　顧客通知店舗名</FormTitle>
                            <TextField id='shop_atobarai_customer_notice_store_name' placeholder='ゼウスWEB店' fullwidth value={shopState.shop_atobarai_customer_notice_store_name} onChange={setShopStateHandler} iserror={validateErrMsgs.shop_atobarai_customer_notice_store_name.message} label={'顧客通知店舗名'} explain={toolChipList.shop_atobarai_customer_notice_store_name_explain}/>
                        </>
                    }
                    
                    <h2>取扱商品</h2>
                    <FormTitle required toolChip={toolChipList.shop_addition_product_content_code} responsiveToolChipPlacement={'right'}>商品コンテンツ（複数可）</FormTitle>
                    <FlexBox responsiveColumn mt={16} gap={4} iserror={validateErrMsgs.shop_addition_product_content_code.message}>
                        <Checkbox id='shop_addition_product_content_code_sell_goods' label='物販' checked={shopState.shop_addition_product_content_code_sell_goods} onChange={setSelectBoxShopStateHandler} />
                        <Checkbox id='shop_addition_product_content_code_service' label='役務' checked={shopState.shop_addition_product_content_code_service} onChange={setSelectBoxShopStateHandler} />
                        <Checkbox id='shop_addition_product_content_code_digital_email' label='デジタルコンテンツ' checked={shopState.shop_addition_product_content_code_digital_email} onChange={setSelectBoxShopStateHandler} />
                    </FlexBox>

                    <FormTitle required>商品カテゴリ</FormTitle>
                    <ToolChipExplain>お申込みサービスの決済対象となる商品・サービスに該当するカテゴリーをご選択ください。</ToolChipExplain>
                    <SelectMenu id='shop_addition_major_content_code' name='shop_addition_major_content_code' options={selectMajorContents} value={shopState.shop_addition_major_content_code} fullwidth label={'商品カテゴリ（大分類）'} onChange={setShopStateHandler} iserror={validateErrMsgs.shop_addition_major_content_code.message} />
                    <SelectMenu id='shop_addition_medium_content_code' name='shop_addition_medium_content_code' options={selectMediumContents} value={shopState.shop_addition_medium_content_code} fullwidth label={'商品カテゴリ（小分類）'} onChange={setShopStateHandler} iserror={validateErrMsgs.shop_addition_medium_content_code.message} />
                    <TextField id='shop_addition_product_content_freetext' label={'具体的な商品内容を入力 '+toolChipList.shop_addition_product_content_freetext} fullwidth defaultValue={shopState.shop_addition_product_content_freetext} onChange={setShopStateHandler} iserror={validateErrMsgs.shop_addition_product_content_freetext.message} />

                    {economicInfoData.cvs.system_setting.select_flag &&
                        <>
                            <FormTitle required >商品価格帯</FormTitle>
                            <FlexBox childJustify iserror={validateErrMsgs.shop_addition_price_validate.message} >
                                <TextField id='shop_addition_price_range_min' placeholder='1000' fullwidth defaultValue={shopState.shop_addition_price_range_min} onChange={setShopStateHandler} iserror={validateErrMsgs.shop_addition_price_range_min.message} label={'最低価格'}/>
                                <TextField id='shop_addition_price_range_max' placeholder='20000' fullwidth defaultValue={shopState.shop_addition_price_range_max} onChange={setShopStateHandler} iserror={validateErrMsgs.shop_addition_price_range_max.message} label={'最高価格'}/>
                            </FlexBox>
                        </>
                    }

                    {ObjToDevide.antique_flag &&
                        <>
                            <FormTitle required toolChip={toolChipList.antique_select} responsiveToolChipPlacement={'right'}>中古品の取扱いの有無</FormTitle>
                            <RadioButtonGroup name='shop_addition_antique_flag' radioitems={radioYesNo} state={shopState.shop_addition_antique_flag} setState={setRadioShopStateHandler} iserror={validateErrMsgs.shop_addition_antique_flag.message} />
                            {shopState.shop_addition_antique_flag &&
                                <>
                                    <FormChildBlockskin>
                                        <FormTitle required mt={0}>古物許可証
                                        </FormTitle>
                                        <ToolChipExplain>ご契約者様名義の許可証の写しをご提出ください。</ToolChipExplain>
                                        <FileInput id='antique' name='antique' accept={['jpeg', 'png', 'pdf', 'jpg']} maxsize={30} multiple iserror={validateErrMsgs.antique.message} page={"shop"} />
                                    </FormChildBlockskin>
                                </>
                            }
                        </>
                    }

                    {ObjToDevide.subscription_flag &&
                        <>
                            <FormTitle required toolChip={toolChipList.shop_addition_subscription_flag} responsiveToolChipPlacement={'right'}>サブスク課金の有無（定期購入・月会費など）</FormTitle>
                            <RadioButtonGroup name='shop_addition_subscription_flag' radioitems={radioYesNo} state={shopState.shop_addition_subscription_flag} setState={setRadioShopStateHandler} iserror={validateErrMsgs.shop_addition_subscription_flag.message} />
                            {shopState.shop_addition_subscription_flag &&
                                <FormChildBlockskin>
                                    <FormTitle required mt={0}>課金サイクル</FormTitle>
				   
                                    <SelectMenu id='shop_addition_subscription_code' name='shop_addition_subscription_code' fullwidth defaultValue={shopState.shop_addition_subscription_code === ""?"課金サイクルを選択":shopState.shop_addition_subscription_code} options={selectChargesCycle} onChange={setShopStateHandler} iserror={validateErrMsgs.shop_addition_subscription_code.message} />
                                </FormChildBlockskin>
                            }
                        </>
                    }

                    {ObjToDevide.purchase_points_flag &&
                        <>
                            <FormTitle required toolChip={toolChipList.shop_addition_point_purchase_flag} toolChipWidth={370} responsiveToolChipPlacement={'right'}>ポイント購入の有無</FormTitle>
                            <RadioButtonGroup name='shop_addition_point_purchase_flag' radioitems={radioYesNo} setState={setRadioShopStateHandler} state={shopState.shop_addition_point_purchase_flag} iserror={validateErrMsgs.shop_addition_point_purchase_flag.message} />
                        </>
                    }

                    {ObjToDevide.prepaid_continuous_flag &&
                        <>
                            <FormTitle required toolChip={toolChipList.shop_addition_prepaid_flag} responsiveToolChipPlacement={'center'}>前払い一括回収の有無（１か月を超えるサービス）</FormTitle>
                            <RadioButtonGroup name='shop_addition_prepaid_flag' radioitems={radioYesNo} setState={setRadioShopStateHandler} state={shopState.shop_addition_prepaid_flag} iserror={validateErrMsgs.shop_addition_prepaid_flag.message} />
                        </>
                    }

                    {ObjToDevide.reserve_item_flag &&
                        <>
                            <FormTitle required >予約商品の有無</FormTitle>
                            <RadioButtonGroup name='shop_addition_reserved_product_flag' radioitems={radioYesNo} setState={setRadioShopStateHandler} state={shopState.shop_addition_reserved_product_flag} iserror={validateErrMsgs.shop_addition_reserved_product_flag.message} />
                            {shopState.shop_addition_reserved_product_flag &&
                                <FormChildBlockskin>
                                <FormTitle required mt={0} toolChip={toolChipList.shop_addition_reservation_period_flag} responsiveToolChipPlacement={'right'}>2か月以内に提供可能</FormTitle>
                                <RadioButtonGroup name='shop_addition_reservation_period_flag' radioitems={radioCanNot} state={shopState.shop_addition_reservation_period_flag} setState={setRadioShopStateHandler} iserror={validateErrMsgs.shop_addition_reservation_period_flag.message} />
                            </FormChildBlockskin>
                            }
                        </>
                    }

                    {ObjToDevide.estimate_flag &&
                        <>
                            <FormTitle required >見積商品の有無</FormTitle>
                            <RadioButtonGroup name='shop_addition_estimated_product_flag' radioitems={radioYesNo} state={shopState.shop_addition_estimated_product_flag} setState={setRadioShopStateHandler} iserror={validateErrMsgs.shop_addition_estimated_product_flag.message} />
                        </>
                    }

                    {ObjToDevide.rental_flag &&
                        <>
                            <FormTitle required >レンタル商品の有無</FormTitle>
                            <RadioButtonGroup name='shop_addition_rental_flag' radioitems={radioYesNo} setState={setRadioShopStateHandler} state={shopState.shop_addition_rental_flag} iserror={validateErrMsgs.shop_addition_rental_flag.message} />
                            {shopState.shop_addition_rental_flag && 
                                <FormChildBlockskin>
                                    <Box iserror={validateErrMsgs.shop_addition_rental_validate.message}>
                                        <FormTitle required mt={0}>レンタル規約
                                        </FormTitle>
                                        <ToolChipExplain>規約・注意事項のURLまたはファイルをご提出ください。</ToolChipExplain>
                                        <TextField id='shop_addition_rental_product_url' label='規約URLまたは規約ファイルを登録してください。' placeholder='https://www.cardservice.co.jp/zmc/kiyaku/' fullwidth defaultValue={shopState.shop_addition_rental_product_url} onChange={setShopStateHandler} iserror={validateErrMsgs.shop_addition_rental_product_url.message} />
                                        <FileInput id='rental' name='rental' multiple accept={['jpeg', 'png', 'pdf', 'jpg']} maxsize={30} iserror={validateErrMsgs.rental.message} page={"shop"}/>
                                    </Box>
                                </FormChildBlockskin>
                            }
                        </>
                    }
                    {ObjToDevide.online_local_flag && 
                        <>
                            <FormTitle required >サービスの提供場所（複数可）</FormTitle>
                            <Box mt={16} iserror={validateErrMsgs.shop_addition_offer_place.message}>
                                <Checkbox id='shop_addition_online' label='オンライン' checked={shopState.shop_addition_online === null ? false : shopState.shop_addition_online} value={shopState.shop_addition_online === null ? false : shopState.shop_addition_online} onChange={setSelectBoxShopStateHandler} />
                                <Checkbox id='shop_addition_offline' label='現地' checked={shopState.shop_addition_offline === null ? false : shopState.shop_addition_offline} value={shopState.shop_addition_offline === null ? false : shopState.shop_addition_offline} onChange={setSelectBoxShopStateHandler} />
                            </Box>
                        </>
                    }
                    {ObjToDevide.btob_flag &&
                        <>
                            <FormTitle required >BtoB取引（事業者向け販売）の取扱い</FormTitle>
                            <RadioButtonGroup name='shop_addition_btob_code' radioitems={radioBtoB} state={shopState.shop_addition_btob_code} setState={setBtoBRadioShopStateHandler} iserror={validateErrMsgs.shop_addition_btob_code.message} />
                        </>
                    }
                    {(ObjToDevide.real_store_flag && merchantInfoData.merchant_type === '2') && 
                        <>
                            <FormTitle required toolChip={toolChipList.shop_addition_physical_store_flag} responsiveToolChipPlacement={'center'}>実店舗の有無</FormTitle>
                            <RadioButtonGroup name='shop_addition_physical_store_flag' radioitems={radioYesNo} setState={setRadioShopStateHandler} state={shopState.shop_addition_physical_store_flag} iserror={validateErrMsgs.shop_addition_physical_store_flag.message} />
                            {shopState.shop_addition_physical_store_flag &&
                                <FormChildBlockskin>
                                    <FormTitle required mt={0} >実店舗が確認できるURLの有無</FormTitle>
                                    <RadioButtonGroup name='shop_addition_physical_store_url_flag' radioitems={radioYesNo} setState={setRadioShopStateHandler} state={shopState.shop_addition_physical_store_url_flag} iserror={validateErrMsgs.shop_addition_physical_store_url_flag.message} />
                                    {shopState.shop_addition_physical_store_url_flag &&
                                        <>
                                            <FormTitle required >実店舗が確認できるURL
                                            </FormTitle>
                                            <ToolChipExplain>実店舗の外観や案内等が確認できるURLをご提出ください。</ToolChipExplain>
                                            <TextField id='shop_addition_physical_store_url' placeholder='https://www.cardservice.co.jp/info/' fullwidth defaultValue={shopState.shop_addition_physical_store_url} onChange={setShopStateHandler} iserror={validateErrMsgs.shop_addition_physical_store_url.message} />
                                        </>
                                    }
                                </FormChildBlockskin>
                            }
                        </>
                    }

                    {ObjToDevide.license_flag &&
                        <>
                            <FormTitle>免許・資格情報</FormTitle>
                            <p className='mb0'>免許・許認可の必要な商品またはサービスを販売される場合は、免許証・許可証の写しを添付してください。</p>
                            <p>ご契約者様名義の下記の許可証のいずれかをご提出ください。</p>
                            {ObjToDevide.license_name !== null && ObjToDevide.license_name}
                            <FileInput multiple id='license' name='license' accept={['jpeg', 'png', 'pdf', 'jpg']} maxsize={30} iserror={validateErrMsgs.license.message} page={"shop"}/>
                        </>
                    }
                    <h2>確認項目</h2>
                    <FormTitle  required>直近5年間の行政処分歴（特商法違反）</FormTitle>
                    <RadioButtonGroup name='shop_addition_non_administrative_penalties_five_year_flag' radioitems={reverseRadioYesNo} state={shopState.shop_addition_non_administrative_penalties_five_year_flag} setState={setRadioShopStateHandler} iserror={validateErrMsgs.shop_addition_non_administrative_penalties_five_year_flag.message} />

                    <FormTitle  required>直近5年間の敗訴歴（消契法違反）</FormTitle>
                    <RadioButtonGroup name='shop_addition_non_losing_judgment_flag' radioitems={reverseRadioYesNo} state={shopState.shop_addition_non_losing_judgment_flag} setState={setRadioShopStateHandler} iserror={validateErrMsgs.shop_addition_non_losing_judgment_flag.message} />


                    <h2>同意項目</h2>
                    <FlexBox column>
                        <Checkbox id="shop_addition_available_product_flag" label={toolChipList.shop_addition_available_product_flag} checked={shopState.shop_addition_available_product_flag} onChange={setSelectBoxShopStateHandler} iserror={validateErrMsgs.shop_addition_available_product_flag.message} />
                        <Checkbox id="shop_addition_non_infringing_product_presence_absence_flag" label='第三者の知的財産権その他の権利を侵害し、又はそのおそれのあるものは取扱っていません。' checked={shopState.shop_addition_non_infringing_product_presence_absence_flag} onChange={setSelectBoxShopStateHandler} iserror={validateErrMsgs.shop_addition_non_infringing_product_presence_absence_flag.message} />
                    </FlexBox>
                </>
            }
        </>
    )
}
