import styled from "styled-components";
import { toolChipList } from "../../../../constance/constance";
import { colors } from "../../../../css/variables/colors";
import { fonts } from "../../../../css/variables/fonts";
import useEconomicEstimateTableHooks from "../../../../hooks/useEconomicEstimateTableHooks";
import { FlexBox } from "../../../layouts/FlexBox";
import { mixins } from "../../../../css/variables/mixins";
import { feeArrayType } from "../../../../constance/typeModel";
import { ToolChip } from "../../ocnk/ToolChip";

const ServiceCostTable = styled.table`
	width:100%;
	table-layout: fixed;
` 

type tableTrType = {
    responsiveDisplayNone?: boolean
}
const TableTr = styled.tr<tableTrType>`
	p{
		margin-bottom:0px;
		color:${colors.themeColor('text_main')}
	}
	.blue{
		color:${colors.themeColor('primary_dark')};
	}
	.main-color{
		color:${colors.themeColor('text_main')}
	}
	.normal-weight{
		font-weight:normal;
	}
	.cvs-settle-wrap{
		display:flex;
		flex-wrap:wrap;
		justify-content:space-between;
	}
	.cvs-settle-fee{
		padding:4px;
		display:flex;
		justify-content:space-between;
		border-bottom:solid 1px ${colors.themeColor('border_box')};
        ${mixins.screenUnder()}{
            width:100%;
        }
        ${mixins.screenOver()}{
            width:49%;
        }
	}
	.credit-settle-fee{
		padding:4px;
		width:100%;
		display:flex;
		justify-content:space-between;
		border-bottom:solid 1px ${colors.themeColor('border_box')};
	}
	.cvs-settle-fee-p{
		display:inline-block;
		font-size:14px;
	}
    ${mixins.screenUnder()}{
        ${props => props.responsiveDisplayNone ? "display:none;" : ""}
    }
`

const TableTh = styled.th`
	width:35%;
	border: solid 1px ${colors.themeColor('border_box')};
	font-size: ${fonts.fontSizeRem('ms')};
	font-weight:normal;
	background: ${colors.themeColor('background_whitegray')};
	font-weight:bold;
	padding:10px;
    position:relative;
    ${mixins.screenUnder()}{
        display: block;
        width:100%;
        text-align:left;
    }
`

type tableTd = {
    black?: boolean
}
const TableTd = styled.td<tableTd>`
	color:${props => props.black ? colors.themeColor('text_main') : colors.themeColor('primary_dark')};
	border: solid 1px ${colors.themeColor('border_box')};
	font-size: ${fonts.fontSizeRem('s')};
	font-weight:bold;
	padding:9px; 
    text-align: right;
    ${mixins.screenUnder()}{
        display: block;
        width:100%;
    }
`

const OptionWrapper = styled.div`
	width:100%;
	font-size:${fonts.fontSizeRem('s')};
    text-align: left;
`

const ServiceNameStyle = styled.div`
	margin-bottom: 0px;
	font-size: ${fonts.fontSizeRem('s')};
    font-weight:normal;
	${mixins.screenUnder()}{
		display: flex;
		justify-content: space-between;
		font-size: ${fonts.fontSizeRem('s')};
        font-weight:normal;
	}
`

const FeeP = styled.p`
    text-align: left;
    word-break: break-all;
`
const ValueFeeStyle = styled.p`
    font-size:${fonts.fontSizeRem('s')};
    color:${colors.themeColor('primary_dark')} !important;
    diplay:inline-block;
    white-space: nowrap;
    ${mixins.screenUnder()}{
        font-size:${fonts.fontSizeRem('xs')};
    }
`
const FeeSpan = styled.span`
    font-size: ${fonts.fontSizeRem('xl')};
`

const OcnkTeikiEconomicEstimateTable = ({
    contentName,
    sumSystemCost,
    options,
    salesHandleFee,
    paymentSiteFee,
    businessOperatorFee,
    page
}:{
    contentName: string,
    settlementFee?: number,
    sumSystemCost: number,
    options: feeArrayType[],
    salesHandleFee?: boolean,
    paymentSiteFee: number,
    businessOperatorFee?: number,
    page: string
}) => {
    const {economicInfoData,creditFee,demand_code,systemSettingMasterInfoData} = useEconomicEstimateTableHooks({options,page})
    
    return(
        <ServiceCostTable>
                <tbody>
                    {contentName === "atobarai" &&
                        <>
                            <TableTr>
                                <TableTh>
                                    決済手数料
                                    <ToolChip content={toolChipList.business_operator_fee} placement={'left'} responsivePlacement={'center'} width={440}/>
                                </TableTh>
                                <TableTd><span className="font-xl">{businessOperatorFee}</span> ％</TableTd>
                            </TableTr>
                        </>
                    }
                    <TableTr>
                        <TableTh>初期費用（開設契約金）</TableTh>
                        <TableTd>￥ <span className="font-xl">{economicInfoData[contentName].economic_requirement.opening_contract_fee}</span></TableTd>
                    </TableTr>
                    <TableTr>
                        <TableTh>
                            取引手数料
                        </TableTh>
                        {contentName === "credit" &&
                            <TableTd>
                                {creditFee}    
                            </TableTd>
                        }
                    </TableTr>
                    <TableTr>
                        <TableTh>
                            月額費用（システム利用料）
                        </TableTh>
                        <TableTd>
                            <p className="font-s blue">￥ <span className="font-xl">{sumSystemCost}</span> /月</p>
                        </TableTd>
                    </TableTr>  
                    {salesHandleFee &&
                        <TableTr>
                            <TableTh>
                                売上処理料
                            </TableTh>
                            <TableTd>￥ <span className="font-xl">{economicInfoData[contentName].economic_requirement.sales_processing_fee}</span></TableTd>
                        </TableTr>
                    }
                        <>
                            <TableTr>
                                <TableTh>支払いサイト(入金サイクル)</TableTh>
                                <TableTd>
                                    <FlexBox spaceBetween mt={0} >
                                        <span className="mt08 main-color font-m normal-weight">
                                            {economicInfoData[contentName].economic_requirement.payment_site}
                                        </span>
                                        <span className="mt08 font-m normal-weight">
                                            {paymentSiteFee !== 0 ? '￥'+ paymentSiteFee + ' /月' : '無料'}
                                        </span>
                                    </FlexBox>
                                </TableTd>
                            </TableTr>
                        </>
                        {(options.length !== 0 && demand_code !== 'system') &&
                        <TableTr>
                            <TableTh>
                                オプション利用料
                            </TableTh>
                            <TableTd>
                                <div>
                                    <FlexBox spaceBetween gap={8} >
                                        <FeeP>3Dセキュア（本人認証システム）利用料</FeeP>
                                        <ValueFeeStyle>￥ <FeeSpan>0</FeeSpan> /月</ValueFeeStyle>
                                    </FlexBox>
                                    <hr className="mt0 mb8" />
                                </div>
                                <div>
                                    <FlexBox spaceBetween gap={8} >
                                        <FeeP>セキュリティコード（CVV2/CVC2）利用料</FeeP>
                                        <ValueFeeStyle>￥ <FeeSpan>0</FeeSpan> /月</ValueFeeStyle>
                                    </FlexBox>
                                    <hr className="mt0 mb8" />
                                </div>
                            </TableTd>
                        </TableTr>
                        }
                        <TableTr>
                        <TableTh>
                            付帯サービス
                        </TableTh>
                        <TableTd black>
                            <FlexBox spaceBetween gap={8} column >
                                {systemSettingMasterInfoData.credit_auth_agent_flag &&
                                    <OptionWrapper>
                                        <span className="mt08 font-s normal-weight">・仮売上決済　<ToolChip content={toolChipList.auth} placement={'left'} responsivePlacement={'right'}/></span>
                                    </OptionWrapper>
                                }
                                {systemSettingMasterInfoData.credit_cgi_agent_flag &&
                                    <OptionWrapper>
                                        <ServiceNameStyle>・CGI　<ToolChip content={toolChipList.threed_secure} responsivePlacement={'right'}/></ServiceNameStyle>
                                    </OptionWrapper>
                                }
                                {/* {(systemSettingMasterInfoData.credit_3d_agent_flag && !systemSettingMasterInfoData.credit_3d_merchant_flag )&&
                                    <OptionWrapper>
                                        <ServiceNameStyle>・3Dセキュア（本人認証システム）　<ToolChip content={toolChipList.threed_secure} responsivePlacement={'right'}/></ServiceNameStyle>
                                    </OptionWrapper>
                                }
                                {(systemSettingMasterInfoData.credit_cvv_agent_flag && !systemSettingMasterInfoData.credit_cvv_merchant_flag )&&
                                    <OptionWrapper>
                                        <ServiceNameStyle>・セキュリティコード（CVV2/CVC2）　<ToolChip content={toolChipList.cvv} responsivePlacement={'right'}/></ServiceNameStyle>
                                    </OptionWrapper>
                                } */}
                                {(systemSettingMasterInfoData.credit_div2_bonus_agent_flag && !systemSettingMasterInfoData.credit_div2_bonus_merchant_flag )&&
                                    <OptionWrapper>
                                        <ServiceNameStyle>・分割払い/リボルビング（物品販売のみ利用可能）</ServiceNameStyle>
                                    </OptionWrapper>
                                }
                                {(systemSettingMasterInfoData.credit_subscription_agent_flag && !systemSettingMasterInfoData.credit_subscription_merchant_flag )&&
                                    <OptionWrapper>
                                        <span className="mt08 font-s normal-weight">・継続決済　<ToolChip content={toolChipList.subscription} responsivePlacement={'right'}/></span>
                                    </OptionWrapper>
                                }
                                {(systemSettingMasterInfoData.credit_send_mail_agent_flag && !systemSettingMasterInfoData.credit_send_mail_merchant_flag )&&
                                    <OptionWrapper>
                                        <ServiceNameStyle>・決済結果通知メール　<ToolChip content={toolChipList.send_mail} responsivePlacement={'right'}/></ServiceNameStyle>
                                    </OptionWrapper>
                                }
                                {(systemSettingMasterInfoData.credit_id_password_storage_agent_flag && !systemSettingMasterInfoData.credit_id_password_storage_merchant_flag )&&
                                    <OptionWrapper>
                                        <ServiceNameStyle>・ID・パスワード預かり　<ToolChip content={toolChipList.id_password} responsivePlacement={'right'}/></ServiceNameStyle>
                                    </OptionWrapper>
                                }
                                {(systemSettingMasterInfoData.credit_customer_support_agent_flag && !systemSettingMasterInfoData.credit_customer_support_merchant_flag )&&
                                    <OptionWrapper>
                                        <span className="mt08 font-s normal-weight">・カスタマーサポート　<ToolChip content={toolChipList.customer_support} responsivePlacement={'right'}/></span>
                                    </OptionWrapper>
                                }
                                {(systemSettingMasterInfoData.credit_payment_now_agent_flag && !systemSettingMasterInfoData.credit_payment_now_merchant_flag )&&
                                    <OptionWrapper>
                                        <ServiceNameStyle>・今すぐ決済　<ToolChip content={toolChipList.payment_now} responsivePlacement={'right'}/></ServiceNameStyle>
                                    </OptionWrapper>
                                }
                                {(systemSettingMasterInfoData.cvs_early_payment_service_agent_flag && !systemSettingMasterInfoData.cvs_early_payment_service_merchant_flag )&&
                                    <OptionWrapper>
                                        <ServiceNameStyle>・早期入金サービス　<ToolChip content={toolChipList.early_payment} responsivePlacement={'right'}/></ServiceNameStyle>
                                    </OptionWrapper>
                                } 
                            </FlexBox>
                        </TableTd>
                    </TableTr>
                </tbody>
            </ServiceCostTable>
    )
}
export default OcnkTeikiEconomicEstimateTable