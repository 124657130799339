import { useEffect, useState } from "react";
import ErrorBoundary from "../../../api/ErrorBoundary";
import { useLoginCheckHooks } from "../../../hooks/useLoginCheckHooks";
import useUserAuthHooks from "../../../hooks/useUserAuthHooks";
import { Entry } from "../renderPages/Entry";

const EntryBeforeRender = () => {
    const {LoginCheck} = useLoginCheckHooks();
    const {AuthCheck} = useUserAuthHooks();
    const [loginFlag, setLoginFlag] = useState<boolean>(false)
    
  
    //ログイン確認+URL直叩きチェックフラグをセット
    useEffect(()=>{
      setLoginFlag(LoginCheck())
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
  
    if(!AuthCheck()|| !loginFlag) return(<></>)

    return(
      <ErrorBoundary>
        <Entry/>
      </ErrorBoundary>
    )
}

export default EntryBeforeRender