import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { Context } from "../stores/Provider"

const useNavigateByAgent = () => {
    const navigate = useNavigate()
    const {state} = useContext(Context)
    const navigateByAgent = (path:string="",confirm_flag:boolean | undefined=false,email?:string | undefined) => {
        navigate(state.agentPath+path, {state:{routeTo:state.agentPath+path, confirm_flag:confirm_flag, email:email}})
    }
    return {navigateByAgent}
}

export default useNavigateByAgent