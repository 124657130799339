import styled from "styled-components"
import { colors } from "../../../../css/variables/colors"
import useEntryConfirmElementHooks from "../../../../hooks/useEntryConfirmElementHooks"
import { accountIdPhrase } from "../../../../constance/constance"
import AnotherTabLink from "../../../ui-parts/ocnk/AnotherTabLink"
import { mixins } from "../../../../css/variables/mixins"

const ConfirmFormWrap = styled.div`
    max-width:1120px;
    background: ${colors.themeColor('background_main')};
    border-radius: 8px;
    border: 1px solid ${colors.themeColor('border_box')};
    padding:40px 48px;
    ${mixins.screenUnder()}{
        padding: 28px 16px;
    }
    h3{
        font-weight:600;
        margin-top:28px;
    }
`
const TableWrapper = styled.table`
    width:100%;
`
const TableTr = styled.tr`
    color:${colors.themeColor('text-main')}
    width:100%;
    .main-color{
		color:${colors.themeColor('text_main')}
	}
	.normal-weight{
		font-weight:normal;
	}
	.cvs-settle-wrap{
		display:flex;
		flex-wrap:wrap;
		justify-content:space-between;
	}
	.cvs-settle-fee{
		padding:4px;
		display:flex;
		justify-content:space-between;
		border-bottom:solid 1px ${colors.themeColor('border_box')};
        ${mixins.screenUnder()}{
            width:100%;
        }
        ${mixins.screenOver()}{
            width:48%;
        }

	}
    .credit-settle-fee{
		padding:4px;
		display:flex;
		justify-content:space-between;
		border-bottom:solid 1px ${colors.themeColor('border_box')};
	}
	.cvs-settle-fee-p{
		display:inline-block;
		font-size:14px;
	}
`

const TableTh = styled.th`
    border: solid 1px ${colors.themeColor('border_box')};
    padding:10px;
    font-size:16px;
    font-weight:600;
    display:inlne-block;
    white-space:nowrap;
    width:34%;
    text-align:left;
    ${mixins.screenUnder()}{
        white-space:normal;
    }
`
const TableTd = styled.td`
    border: solid 1px ${colors.themeColor('border_box')};
    padding:10px;
    font-size:16px;
    overflow-wrap:break-word;
    word-break:break-all;
`
const FileWrapper = styled.div`
    display:flex;
    flex-direction:column;
    gap:12px;
`
const ItemNameAndFeeWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width:100%;
`

const CreditFee = styled.p`
    margin-bottom:8px;
    white-space:nowrap;
`

const CreditFeeTitle = styled.p`
    font-weight:normal;
    margin-bottom: 0px;
`


const EntryConfirmElement = ({ title, check, shopDevideData }:{ title : string, check : boolean, shopDevideData? : any}) => {

    const { economicInfoData, economicConditionMasterInfoData, shopInfoData, merchantInfoData, representativeInfoData, operationInfoData, bankAccountInfoData, sumFeeInfoData, systemSettingMasterInfoData, demand_code, ObjToDevide, economicCredit, economicBank, economicCVS, economicAtobarai, industryName, changePaymentMethodPhrase, cvsFee, titleDiv, siteLabel, productContentPhrase, fileInfoData, subscriptionCodePhrase, agentMasterInfoData, userInfoData, btobLabel } = useEntryConfirmElementHooks({title,shopDevideData});
    return (
        <>
            {titleDiv.merchant &&
                <ConfirmFormWrap>
                    <TableWrapper>
                        <tbody>
                            <TableTr>
                                <TableTh>法人または個人</TableTh>
                                <TableTd>{merchantInfoData.merchant_type === '1' ? '法人' : '個人'}</TableTd>
                            </TableTr>
                        </tbody>
                    </TableWrapper>
                    <h3>ご契約者情報</h3>
                    <TableWrapper>
                        <tbody>
                            {merchantInfoData.merchant_type === '1' &&
                                <>
                                    <TableTr>
                                        <TableTh>法人番号</TableTh>
                                        <TableTd>{merchantInfoData.merchant_corporate_number}</TableTd>
                                    </TableTr>
                                    <TableTr>
                                        <TableTh>法人名</TableTh>
                                        <TableTd>{merchantInfoData.merchant_name}</TableTd>
                                    </TableTr>
                                    <TableTr>
                                        <TableTh>法人名ｶﾅ</TableTh>
                                        <TableTd>{merchantInfoData.merchant_name_kana}</TableTd>
                                    </TableTr>
                                </>
                            }
                            {merchantInfoData.merchant_type === '2' &&
                                <>
                                    <TableTr>
                                        <TableTh>ご契約者名</TableTh>
                                        <TableTd>{merchantInfoData.merchant_last_name}{merchantInfoData.merchant_first_name}</TableTd>
                                    </TableTr>
                                    <TableTr>
                                        <TableTh>ご契約者名ｶﾅ</TableTh>
                                        <TableTd>{merchantInfoData.merchant_last_name_kana}{merchantInfoData.merchant_first_name_kana}</TableTd>
                                    </TableTr>
                                    <TableTr>
                                        <TableTh>性別</TableTh>
                                        <TableTd>{representativeInfoData.representative_sex === 'M' ? '男性' : '女性'}</TableTd>
                                    </TableTr>
                                    <TableTr>
                                        <TableTh>生年月日</TableTh>
                                        <TableTd>{representativeInfoData.representative_birthday_CE}年{representativeInfoData.representative_birthday_month}月{representativeInfoData.representative_birthday_day}日</TableTd>
                                    </TableTr>
                                </>
                            }
                            <TableTr>
                                <TableTh>電話番号</TableTh>
                                <TableTd>{merchantInfoData.merchant_phone_number}</TableTd>
                            </TableTr>
                            <TableTr>
                                <TableTh>郵便番号</TableTh>
                                <TableTd>{merchantInfoData.merchant_register_post_code3}-{merchantInfoData.merchant_register_post_code4}</TableTd>
                            </TableTr>
                            <TableTr>
                                <TableTh>住所　都道府県</TableTh>
                                <TableTd>{merchantInfoData.merchant_address_prefectures}</TableTd>
                            </TableTr>
                            <TableTr>
                                <TableTh>住所　都道府県ｶﾅ</TableTh>
                                <TableTd>{merchantInfoData.merchant_address_prefectures_kana}</TableTd>
                            </TableTr>
                            <TableTr>
                                <TableTh>住所　市区町村</TableTh>
                                <TableTd>{merchantInfoData.merchant_address_municipality}</TableTd>
                            </TableTr>
                            <TableTr>
                                <TableTh>住所　市区町村ｶﾅ</TableTh>
                                <TableTd>{merchantInfoData.merchant_address_municipality_kana}</TableTd>
                            </TableTr>
                            <TableTr>
                                <TableTh>住所　町名・番地</TableTh>
                                <TableTd>{merchantInfoData.merchant_address_streetbunch}</TableTd>
                            </TableTr>
                            <TableTr>
                                <TableTh>住所　町名・番地ｶﾅ</TableTh>
                                <TableTd>{merchantInfoData.merchant_address_streetbunch_kana}</TableTd>
                            </TableTr>
                            <TableTr>
                                <TableTh>住所　建物名・号室</TableTh>
                                <TableTd>{merchantInfoData.merchant_address_building}</TableTd>
                            </TableTr>
                            <TableTr>
                                <TableTh>住所　建物名・号室ｶﾅ</TableTh>
                                <TableTd>{merchantInfoData.merchant_address_building_kana}</TableTd>
                            </TableTr>
                            {merchantInfoData.merchant_type === '2' &&
                                <>
                                    <TableTr>
                                        <TableTh>本人確認書類</TableTh>
                                        <TableTd>
                                            <FileWrapper>
                                                {fileInfoData.identity_doc_images.map((file:{file_name_origin: string}, index: number) => (
                                                    <p key={index} className="mb0">{file.file_name_origin}</p>
                                                ))}
                                            </FileWrapper>
                                        </TableTd>
                                    </TableTr>
                                </>
                            }
                            {economicInfoData.cvs.system_setting.select_flag &&
                                <>
                                    <TableTr>
                                        <TableTh>会社WEBサイトURL</TableTh>
                                        <TableTd><AnotherTabLink href={merchantInfoData.merchant_website_url}>{merchantInfoData.merchant_website_url}</AnotherTabLink></TableTd>
                                    </TableTr>
                                </>
                            }
                            <TableTr>
                                <TableTh>年商</TableTh>
                                <TableTd>{merchantInfoData.merchant_annual_business}万円</TableTd>
                            </TableTr>
                            <TableTr>
                                <TableTh>業種</TableTh>
                                <TableTd>{industryName}</TableTd>
                            </TableTr>

                        </tbody>
                    </TableWrapper>
                    {merchantInfoData.merchant_type === '1' &&
                        <>
                            <h3>代表者情報</h3>
                            <TableWrapper>
                                <tbody>
                                    <TableTr>
                                        <TableTh>代表者姓</TableTh>
                                        <TableTd>{representativeInfoData.representative_last_name}</TableTd>
                                    </TableTr>
                                    <TableTr>
                                        <TableTh>代表者姓ｶﾅ</TableTh>
                                        <TableTd>{representativeInfoData.representative_last_name_kana}</TableTd>
                                    </TableTr>
                                    <TableTr>
                                        <TableTh>代表者名</TableTh>
                                        <TableTd>{representativeInfoData.representative_first_name}</TableTd>
                                    </TableTr>
                                    <TableTr>
                                        <TableTh>代表者名ｶﾅ</TableTh>
                                        <TableTd>{representativeInfoData.representative_first_name_kana}</TableTd>
                                    </TableTr>
                                    <TableTr>
                                        <TableTh>性別</TableTh>
                                        <TableTd>{representativeInfoData.representative_sex === 'M' ? '男性' : '女性'}</TableTd>
                                    </TableTr>
                                    <TableTr>
                                        <TableTh>住所</TableTh>
                                        <TableTd>商業登記簿謄本に登記されている代表者様住所にて登録いたします。</TableTd>
                                    </TableTr>
                                    <TableTr>
                                        <TableTh>生年月日</TableTh>
                                        <TableTd>{representativeInfoData.representative_birthday_CE}年{representativeInfoData.representative_birthday_month}月{representativeInfoData.representative_birthday_day}日</TableTd>
                                    </TableTr>
                                </tbody>
                            </TableWrapper>
                        </>
                    }
                    <h3>担当者情報</h3>
                    <TableWrapper>
                        <tbody>
                            <TableTr>
                                <TableTh>運営担当者姓</TableTh>
                                <TableTd>{operationInfoData.operator_last_name}</TableTd>
                            </TableTr>
                            <TableTr>
                                <TableTh>運営担当者姓ｶﾅ</TableTh>
                                <TableTd>{operationInfoData.operator_last_name_kana}</TableTd>
                            </TableTr>
                            <TableTr>
                                <TableTh>運営担当者名</TableTh>
                                <TableTd>{operationInfoData.operator_first_name}</TableTd>
                            </TableTr>
                            <TableTr>
                                <TableTh>運営担当者名ｶﾅ</TableTh>
                                <TableTd>{operationInfoData.operator_first_name_kana}</TableTd>
                            </TableTr>
                            <TableTr>
                                <TableTh>E-mail</TableTh>
                                <TableTd>{operationInfoData.operator_email}</TableTd>
                            </TableTr>
                            <TableTr>
                                <TableTh>電話番号</TableTh>
                                <TableTd>{operationInfoData.operator_phone_number}</TableTd>
                            </TableTr>
                        </tbody>
                    </TableWrapper>
                    <h3>ショップ連絡先</h3>
                    <TableWrapper>
                        <tbody>
                            <TableTr>
                                <TableTh>E-mail</TableTh>
                                <TableTd>{operationInfoData.support_operator_email}</TableTd>
                            </TableTr>
                            <TableTr>
                                <TableTh>電話番号</TableTh>
                                <TableTd>{operationInfoData.support_operator_phone_number}</TableTd>
                            </TableTr>
                        </tbody>
                    </TableWrapper>
                </ConfirmFormWrap>
            }
            {titleDiv.bank &&
                <>
                    <ConfirmFormWrap>
                        <TableWrapper>
                            <tbody>
                                <TableTr>
                                    <TableTh>金融機関名</TableTh>
                                    <TableTd>{bankAccountInfoData.bank_account_bank_name}</TableTd>
                                </TableTr>
                                <TableTr>
                                    <TableTh>支店名</TableTh>
                                    <TableTd>{bankAccountInfoData.bank_account_bank_branch}</TableTd>
                                </TableTr>
                                <TableTr>
                                    <TableTh>口座種別</TableTh>
                                    <TableTd>{bankAccountInfoData.bank_account_category === '1' ? '普通' : '当座'}</TableTd>
                                </TableTr>
                                <TableTr>
                                    <TableTh>口座番号</TableTh>
                                    <TableTd>{bankAccountInfoData.bank_account_number}</TableTd>
                                </TableTr>
                                <TableTr>
                                    <TableTh>口座名義（ｶﾅ表記）</TableTh>
                                    <TableTd>{bankAccountInfoData.bank_account_name}</TableTd>
                                </TableTr>
                                <TableTr>
                                    <TableTh>通帳画像</TableTh>
                                    <TableTd>
                                        <FileWrapper>
                                            {fileInfoData.passbook_images.map((file:{file_name_origin: string}, index: number) => (
                                                <p key={index} className="mb0">{file.file_name_origin}</p>
                                            ))}
                                        </FileWrapper>
                                    </TableTd>
                                </TableTr>
                            </tbody>
                        </TableWrapper>
                    </ConfirmFormWrap>
                </>
            }
            {titleDiv.shop &&
                <>
                    <ConfirmFormWrap>
                        <h3 className="mt0">ショップ基本情報</h3>
                        <TableWrapper>
                            <tbody>
                                {agentMasterInfoData.account_id_flag &&
                                    <>
                                        <TableTr>
                                            <TableTh>{accountIdPhrase[userInfoData.agent_master_id]}</TableTh>
                                            <TableTd>{shopInfoData.account_id}</TableTd>
                                        </TableTr>
                                    </>
                                }
                                <TableTr>
                                    <TableTh>加盟店屋号名</TableTh>
                                    <TableTd>{shopInfoData.shop_name}</TableTd>
                                </TableTr>
                                <TableTr>
                                    <TableTh>加盟店屋号名ｶﾅ</TableTh>
                                    <TableTd>{shopInfoData.shop_name_kana}</TableTd>
                                </TableTr>
                                <TableTr>
                                    <TableTh>加盟店屋号名英字</TableTh>
                                    <TableTd>{shopInfoData.shop_name_alphabet}</TableTd>
                                </TableTr>
                                <TableTr>
                                    <TableTh>ショップURL</TableTh>
                                    <TableTd><AnotherTabLink href={shopInfoData.shop_website_url}>{shopInfoData.shop_website_url}</AnotherTabLink></TableTd>
                                </TableTr>


                                <TableTr>
                                    <TableTh>お申し込み時のショップ稼働状況</TableTh>
                                    <TableTd>{siteLabel.code}</TableTd>
                                </TableTr>
                                {shopInfoData.shop_addition_site_status_code === 0 &&
                                    <>
                                        <TableTr>
                                            <TableTh>ショップ審査方法</TableTh>
                                            <TableTd>{siteLabel.method}</TableTd>
                                        </TableTr>
                                        {shopInfoData.shop_addition_site_status_method === 0 &&
                                            <>
                                                <TableTr>
                                                    <TableTh>審査用サイト</TableTh>
                                                    <TableTd><AnotherTabLink href={shopInfoData.shop_addition_shop_website_check_url}>{shopInfoData.shop_addition_shop_website_check_url}</AnotherTabLink></TableTd>
                                                </TableTr>
                                                <TableTr>
                                                    <TableTh>特定商取引法に基づく表示  掲載URL</TableTh>
                                                    <TableTd><AnotherTabLink href={shopInfoData.shop_addition_business_deal_url}>{shopInfoData.shop_addition_business_deal_url}</AnotherTabLink></TableTd>
                                                </TableTr>
                                                <TableTr>

                                                    <TableTh>ログインID</TableTh>
                                                    <TableTd>{shopInfoData.shop_addition_shop_website_id}</TableTd>
                                                </TableTr>
                                                <TableTr>
                                                    <TableTh>ログインパスワード</TableTh>
                                                    <TableTd>{shopInfoData.shop_addition_shop_website_password}</TableTd>
                                                </TableTr>
                                            </>
                                        }
                                        {shopInfoData.shop_addition_site_status_method === 1 &&
                                            <TableTr>
                                                <TableTh>審査資料</TableTh>
                                                <TableTd>
                                                    <FileWrapper>
                                                        {fileInfoData.examination_document.map((file:{file_name_origin: string}, index: number) => (
                                                            <p key={index} className="mb0">{file.file_name_origin}</p>
                                                        ))}
                                                    </FileWrapper>
                                                </TableTd>
                                            </TableTr>
                                        }
                                    </>
                                }

                                <TableTr>
                                    <TableTh>ショップ運営開始年</TableTh>
                                    <TableTd>{shopInfoData.shop_addition_conduct_start_yyyy}年</TableTd>
                                </TableTr>
                                <TableTr>
                                    <TableTh>ショップ運営開始月</TableTh>
                                    <TableTd>{shopInfoData.shop_addition_conduct_start_mm}月</TableTd>
                                </TableTr>

                                <TableTr>
                                    <TableTh>店舗名</TableTh>
                                    <TableTd>{shopInfoData.shop_name}</TableTd>
                                </TableTr>
                                <TableTr>
                                    <TableTh>店舗名ﾌﾘｶﾞﾅ</TableTh>
                                    <TableTd>{shopInfoData.shop_name_kana}</TableTd>
                                </TableTr>
                                <TableTr>
                                    <TableTh>店舗名英字</TableTh>
                                    <TableTd>{shopInfoData.shop_name_alphabet}</TableTd>
                                </TableTr>

                                {economicInfoData.cvs.system_setting.select_flag &&
                                    <>
                                        <TableTr>
                                            <TableTh>コンビニ払込票ショップ名</TableTh>
                                            <TableTd>{shopInfoData.shop_cvs_website_name}</TableTd>
                                        </TableTr>
                                        <TableTr>
                                            <TableTh>コンビニ払込票ショップ名ﾌﾘｶﾞﾅ</TableTh>
                                            <TableTd>{shopInfoData.shop_cvs_website_name_kana}</TableTd>
                                        </TableTr>
                                    </>
                                }

                                {economicAtobarai.select_flag &&
                                    <TableTr>
                                        <TableTh>コンビニあと払い　顧客通知店舗名</TableTh>
                                        <TableTd>{shopInfoData.shop_atobarai_customer_notice_store_name}</TableTd>
                                    </TableTr>
                                }

                            </tbody>
                        </TableWrapper>

                        <h3>取り扱い商品情報</h3>
                        <TableWrapper>
                            <tbody>
                                <TableTr>
                                    <TableTh>商品コンテンツ</TableTh>
                                    <TableTd>{productContentPhrase}</TableTd>
                                </TableTr>
                                <TableTr>
                                    <TableTh>商品カテゴリ（大分類）</TableTh>
                                    <TableTd>{shopInfoData.shop_addition_major_content}</TableTd>
                                </TableTr>
                                <TableTr>
                                    <TableTh>商品カテゴリ（小分類）</TableTh>
                                    <TableTd>{shopInfoData.shop_addition_medium_content}</TableTd>
                                </TableTr>
                                <TableTr>
                                    <TableTh>具体的な商品内容</TableTh>
                                    <TableTd>{shopInfoData.shop_addition_product_content_freetext}</TableTd>
                                </TableTr>
                                {economicInfoData.cvs.system_setting.select_flag &&
                                    <>
                                        <TableTr>
                                            <TableTh>商品価格帯　最低価格</TableTh>
                                            <TableTd>￥{shopInfoData.shop_addition_price_range_min}
                                            </TableTd>
                                        </TableTr>
                                        <TableTr>
                                            <TableTh>商品価格帯　最高価格</TableTh>
                                            <TableTd>￥{shopInfoData.shop_addition_price_range_max}
                                            </TableTd>
                                        </TableTr>
                                    </>
                                }
                                {ObjToDevide.antique_flag &&
                                    <>
                                        <TableTr>
                                            <TableTh>中古品の取扱い</TableTh>
                                            <TableTd>{shopInfoData.shop_addition_antique_flag ? 'あり' : 'なし'}</TableTd>
                                        </TableTr>
                                        {shopInfoData.shop_addition_antique_flag &&
                                            <>
                                                <TableTr>
                                                    <TableTh>古物許可証</TableTh>
                                                    <TableTd>
                                                        <FileWrapper>
                                                            {fileInfoData.antique.map((file:{file_name_origin: string}, index: number) => (
                                                                <p key={index} className="mb0">{file.file_name_origin}</p>
                                                            ))}
                                                        </FileWrapper>
                                                    </TableTd>
                                                </TableTr>
                                            </>
                                        }
                                    </>
                                }
                                {ObjToDevide.subscription_flag &&
                                    <>
                                        <TableTr>
                                            <TableTh>サブスク（定期購入・月会費など）</TableTh>
                                            <TableTd>{shopInfoData.shop_addition_subscription_flag ? 'あり' : 'なし'}</TableTd>
                                        </TableTr>
                                        {shopInfoData.shop_addition_subscription_flag &&
                                            <TableTr>
                                                <TableTh>課金サイクル</TableTh>
                                                <TableTd>{subscriptionCodePhrase}</TableTd>
                                            </TableTr>
                                        }
                                    </>
                                }
                                {ObjToDevide.purchase_points_flag
                                    &&
                                    <>
                                        <TableTr>
                                            <TableTh>ポイント購入</TableTh>
                                            <TableTd>{shopInfoData.shop_addition_point_purchase_flag ? 'あり' : 'なし'}</TableTd>
                                        </TableTr>
                                    </>
                                }
                                {ObjToDevide.prepaid_continuous_flag &&
                                    <>
                                        <TableTr>
                                            <TableTh>前払い一括回収（１か月を超えるサービス）</TableTh>
                                            <TableTd>{shopInfoData.shop_addition_prepaid_flag ? 'あり' : 'なし'}</TableTd>
                                        </TableTr>
                                    </>
                                }
                                {ObjToDevide.reserve_item_flag &&
                                    <>
                                        <TableTr>
                                            <TableTh>予約商品</TableTh>
                                            <TableTd>{shopInfoData.shop_addition_reserved_product_flag ? 'あり' : 'なし'}</TableTd>
                                        </TableTr>
                                        {shopInfoData.shop_addition_reserved_product_flag &&
                                            <TableTr>
                                                <TableTh>2か月以内に提供可能</TableTh>
                                                <TableTd>{shopInfoData.shop_addition_reservation_period_flag ? "可" : "不可"}</TableTd>
                                            </TableTr>
                                        }
                                    </>
                                }
                                {ObjToDevide.estimate_flag &&
                                    <>
                                        <TableTr>
                                            <TableTh>見積商品</TableTh>
                                            <TableTd>{shopInfoData.shop_addition_estimated_product_flag ? 'あり' : 'なし'}</TableTd>
                                        </TableTr>
                                    </>
                                }
                                {ObjToDevide.rental_flag &&
                                    <>
                                        <TableTr>
                                            <TableTh>レンタル商品</TableTh>
                                            <TableTd>{shopInfoData.shop_addition_rental_flag ? 'あり' : 'なし'}</TableTd>
                                        </TableTr>
                                        {shopInfoData.shop_addition_rental_flag &&
                                            <>
                                                <TableTr>
                                                    <TableTh>レンタル規約URL</TableTh>
                                                    <TableTd><AnotherTabLink href={shopInfoData.shop_addition_rental_product_url}>{shopInfoData.shop_addition_rental_product_url}</AnotherTabLink></TableTd>
                                                </TableTr>
                                                {/* あとで */}
                                                <TableTr>
                                                    <TableTh>レンタル規約ファイル</TableTh>
                                                    <TableTd>
                                                        <FileWrapper>
                                                            {fileInfoData.rental.map((file:{file_name_origin: string}, index: number) => (
                                                                <p key={index} className="mb0">{file.file_name_origin}</p>
                                                            ))}
                                                        </FileWrapper>
                                                    </TableTd>
                                                </TableTr>
                                            </>
                                        }
                                    </>
                                }
                                {ObjToDevide.online_local_flag &&
                                    <TableTr>
                                        <TableTh>サービスの提供場所（複数可）</TableTh>
                                        <TableTd>{shopInfoData.shop_addition_online ? 'オンライン' : ''}{shopInfoData.shop_addition_online && shopInfoData.shop_addition_offline ? '・' : ''}{shopInfoData.shop_addition_offline ? '現地' : ''}</TableTd>
                                    </TableTr>
                                }
                                {ObjToDevide.btob_flag &&
                                    <>
                                        <TableTr>
                                            <TableTh>BtoB取引（事業者向け販売）の取扱い</TableTh>
                                            <TableTd>{btobLabel}</TableTd>
                                        </TableTr>
                                    </>
                                }
                                {(ObjToDevide.real_store_flag && merchantInfoData.merchant_type === '2') &&
                                    <>
                                        <TableTr>
                                            <TableTh>実店舗</TableTh>
                                            <TableTd>{shopInfoData.shop_addition_physical_store_flag ? 'あり' : 'なし'}</TableTd>
                                        </TableTr>
                                        {shopInfoData.shop_addition_physical_store_flag &&
                                            <>
                                                <TableTr>
                                                    <TableTh>実店舗が確認できるURLの有無</TableTh>
                                                    <TableTd>{shopInfoData.shop_addition_physical_store_url_flag ? 'あり' : 'なし'}</TableTd>
                                                </TableTr>
                                                {shopInfoData.shop_addition_physical_store_url_flag &&
                                                    <TableTr>
                                                        <TableTh>実店舗が確認できるURL</TableTh>
                                                        <TableTd><AnotherTabLink href={shopInfoData.shop_addition_physical_store_url}>{shopInfoData.shop_addition_physical_store_url}</AnotherTabLink></TableTd>
                                                    </TableTr>
                                                }
                                            </>
                                        }
                                    </>
                                }
                                {ObjToDevide.license_flag &&
                                    <>
                                        <TableTr>
                                            <TableTh>免許・資格情報</TableTh>
                                            <TableTd>
                                                <FileWrapper>
                                                    {fileInfoData.license.map((file:{file_name_origin: string}, index: number) => (
                                                        <p key={index} className="mb0">{file.file_name_origin}</p>
                                                    ))}
                                                </FileWrapper>
                                            </TableTd>
                                        </TableTr>
                                    </>
                                }
                            </tbody>
                        </TableWrapper>
                        <h3>確認項目</h3>
                        <TableWrapper>
                            <tbody>
                                <TableTr>
                                    <TableTh>直近5年間の行政処分歴（特商法違反）</TableTh>
                                    <TableTd className={!shopInfoData.shop_addition_non_administrative_penalties_five_year_flag ? 'red_text' : ''}>{!shopInfoData.shop_addition_non_administrative_penalties_five_year_flag ? '直近５年以内の行政処分歴あり' : '直近５年以内の行政処分歴なし'}</TableTd>
                                </TableTr>
                                <TableTr>
                                    <TableTh>直近5年間の敗訴歴（消契法違反）</TableTh>
                                    <TableTd className={!shopInfoData.shop_addition_non_losing_judgment_flag ? 'red_text' : ''}>{!shopInfoData.shop_addition_non_losing_judgment_flag ? '直近５年以内の敗訴歴あり' : '直近５年以内の敗訴歴なし'}</TableTd>
                                </TableTr>
                            </tbody>
                        </TableWrapper>
                        {!check &&
                            <>
                                <h3>同意項目</h3>
                                <TableWrapper>
                                    <tbody>
                                        <TableTr>
                                            <TableTh>取扱不可商材の取扱いはありません</TableTh>
                                            <TableTd className="red_text">{shopInfoData.shop_addition_available_product_flag ? '同意しました' : '同意してください'}</TableTd>
                                        </TableTr>
                                        <TableTr>
                                            <TableTh>第三者の知的財産権その他の権利を侵害し、
                                                <p className="mb0">又はそのおそれのあるものは取扱っていません。</p></TableTh>
                                            <TableTd className="red_text">{shopInfoData.shop_addition_non_infringing_product_presence_absence_flag ? '同意しました' : '同意してください'}</TableTd>
                                        </TableTr>
                                    </tbody>
                                </TableWrapper>
                            </>
                        }
                    </ConfirmFormWrap>
                </>
            }
            {titleDiv.economic &&
                <>
                    <ConfirmFormWrap>
                        {economicCredit.select_flag &&
                            <>
                                <h3>クレジットカード決済</h3>
                                <TableWrapper>
                                    <tbody>
                                        <TableTr>
                                            <TableTh>取引手数料</TableTh>
                                            <TableTd>
                                                <div className="credit-settle-fee">
                                                    <CreditFeeTitle>VISA / MasterCard</CreditFeeTitle>
                                                    <CreditFee>{economicConditionMasterInfoData.credit.vm_fee}%</CreditFee>
                                                </div>
                                                <div className="credit-settle-fee">
                                                    <CreditFeeTitle>JCB / AMEX</CreditFeeTitle>
                                                    <CreditFee>{economicConditionMasterInfoData.credit.jcb_fee}%</CreditFee>
                                                </div>
                                                <div className="credit-settle-fee">
                                                    <CreditFeeTitle>Diners</CreditFeeTitle>
                                                    <CreditFee>{economicConditionMasterInfoData.credit.diners_fee}%</CreditFee>
                                                </div>
                                            </TableTd>
                                        </TableTr>
                                        <TableTr>
                                            <TableTh>初期費用（開設契約金）</TableTh>
                                            <TableTd>￥{economicInfoData.credit.economic_requirement.opening_contract_fee}</TableTd>
                                        </TableTr>
                                        <TableTr>
                                            <TableTh>月額費用（システム利用料）</TableTh>
                                            <TableTd>￥{sumFeeInfoData.credit}</TableTd>
                                        </TableTr>
                                        <TableTr>
                                            <TableTh>売上処理料</TableTh>
                                            <TableTd>￥{economicInfoData.credit.economic_requirement.sales_processing_fee}</TableTd>
                                        </TableTr>
                                        <TableTr>
                                            <TableTh>支払いサイト(入金サイクル)</TableTh>
                                            <TableTd>{economicInfoData.credit.economic_requirement.payment_site}</TableTd>
                                        </TableTr>
                                        {systemSettingMasterInfoData.credit_3d_agent_flag &&
                                            <TableTr>
                                                <TableTh>3Dセキュア（本人認証システム）</TableTh>
                                                <TableTd>{economicInfoData.credit.system_setting.threed_secure ? '利用する' : '利用しない'}</TableTd>
                                            </TableTr>
                                        }
                                        {systemSettingMasterInfoData.credit_cvv_agent_flag &&
                                            <TableTr>
                                                <TableTh>セキュリティコード（CVV2/CVC2）</TableTh>
                                                <TableTd>{economicInfoData.credit.system_setting.cvv ? '利用する' : '利用しない'}</TableTd>
                                            </TableTr>
                                        }
                                        {systemSettingMasterInfoData.credit_subscription_agent_flag &&
                                            <TableTr>
                                                <TableTh>継続決済</TableTh>
                                                <TableTd>{economicInfoData.credit.system_setting.subscription ? '利用する' : '利用しない'}</TableTd>
                                            </TableTr>
                                        }
                                        {systemSettingMasterInfoData.credit_div2_bonus_agent_flag &&
                                            <TableTr>
                                                <TableTh>２回払い/ボーナス一括払い</TableTh>
                                                <TableTd>
                                                    {economicInfoData.credit.system_setting.div ?
                                                        <ItemNameAndFeeWrapper>
                                                            <p className="mb0">利用する</p>
                                                            ￥{demand_code === "system" ? economicInfoData.credit.economic_requirement.system_usage_fee_div2_bonus : economicInfoData.credit.economic_requirement.monthly_option_fee_div2_bonus} /月
                                                        </ItemNameAndFeeWrapper>
                                                        :
                                                        <p className="mb0">利用しない</p>
                                                    }
                                                </TableTd>
                                            </TableTr>
                                        }
                                        {systemSettingMasterInfoData.credit_send_mail_agent_flag &&
                                            <TableTr>
                                                <TableTh>決済結果通知メール</TableTh>
                                                <TableTd>{economicInfoData.credit.system_setting.send_mail ? '利用する' : '利用しない'}</TableTd>
                                            </TableTr>
                                        }
                                        {systemSettingMasterInfoData.credit_payment_now_agent_flag &&
                                            <TableTr>
                                                <TableTh>今すぐ決済</TableTh>
                                                <TableTd>{economicInfoData.credit.system_setting.payment_now ? '利用する' : '利用しない'}</TableTd>
                                            </TableTr>
                                        }
                                        {systemSettingMasterInfoData.credit_id_password_storage_agent_flag &&
                                            <TableTr>
                                                <TableTh>ID・パスワード一時預かり</TableTh>
                                                <TableTd>{economicInfoData.credit.system_setting.id_password_storage ? '利用する' : '利用しない'}</TableTd>
                                            </TableTr>
                                        }
                                        {systemSettingMasterInfoData.credit_customer_support_agent_flag &&
                                            <TableTr>
                                                <TableTh>カスタマーサポート</TableTh>
                                                <TableTd>{economicInfoData.credit.system_setting.customer_support ? '利用する' : '利用しない'}</TableTd>
                                            </TableTr>
                                        }
                                        {systemSettingMasterInfoData.credit_early_payment_service_agent_flag &&
                                            <TableTr>
                                                <TableTh>早期入金サービス</TableTh>
                                                <TableTd>
                                                    {economicInfoData.credit.economic_requirement.early_payment_flag ?
                                                        <ItemNameAndFeeWrapper>
                                                            <p className="mb0">利用する</p>
                                                            ￥{demand_code === "system" ? economicInfoData.credit.economic_requirement.system_usage_fee_early_deposit_service : economicInfoData.credit.economic_requirement.monthly_option_fee_early_deposit_service} /月
                                                        </ItemNameAndFeeWrapper>
                                                        :
                                                        <p className="mb0">利用しない</p>
                                                    }
                                                </TableTd>
                                            </TableTr>
                                        }
                                        {systemSettingMasterInfoData.credit_auth_agent_flag &&
                                            <TableTr>
                                                <TableTh>仮売上決済</TableTh>
                                                <TableTd>{economicInfoData.credit.system_setting.auth ? '利用する' : '利用しない'}</TableTd>
                                            </TableTr>
                                        }
                                        {systemSettingMasterInfoData.credit_cgi_agent_flag &&
                                            <TableTr>
                                                <TableTh>CGI</TableTh>
                                                <TableTd>{economicInfoData.credit.system_setting.cgi ? '利用する' : '利用しない'}</TableTd>
                                            </TableTr>
                                        }
                                    </tbody>
                                </TableWrapper>
                            </>
                        }
                        {economicAtobarai.select_flag &&
                            <>
                                <h3>コンビニあと払い</h3>
                                <TableWrapper>
                                    <tbody>
                                        <TableTr>
                                            <TableTh>決済手数料</TableTh>
                                            <TableTd>{economicConditionMasterInfoData.atobarai.business_operator_fee}%</TableTd>
                                        </TableTr>
                                        <TableTr>
                                            <TableTh>取引手数料</TableTh>
                                            <TableTd>{economicConditionMasterInfoData.atobarai.fee}%</TableTd>
                                        </TableTr>
                                        <TableTr>
                                            <TableTh>初期費用（開設契約金）</TableTh>
                                            <TableTd>￥{economicInfoData.atobarai.economic_requirement.opening_contract_fee}</TableTd>
                                        </TableTr>
                                        <TableTr>
                                            <TableTh>月額費用（システム利用料）</TableTh>
                                            <TableTd>￥{sumFeeInfoData.atobarai}</TableTd>
                                        </TableTr>
                                        <TableTr>
                                            <TableTh>請求処理料</TableTh>
                                            <TableTd>￥{economicInfoData.atobarai.economic_requirement.sales_processing_fee}</TableTd>
                                        </TableTr>
                                        <TableTr>
                                            <TableTh>支払いサイト(入金サイクル)</TableTh>
                                            <TableTd>{economicInfoData.atobarai.economic_requirement.payment_site}</TableTd>
                                        </TableTr>
                                    </tbody>
                                </TableWrapper>
                            </>
                        }
                        {economicBank.select_flag &&
                            <>
                                <h3>ネットバンク決済（入金おまかせサービス）</h3>
                                <TableWrapper>
                                    <tbody>
                                        <TableTr>
                                            <TableTh>取引手数料</TableTh>
                                            <TableTd>{economicConditionMasterInfoData.bank.fee}%</TableTd>
                                        </TableTr>
                                        <TableTr>
                                            <TableTh>初期費用（開設契約金）</TableTh>
                                            <TableTd>￥{economicInfoData.bank.economic_requirement.opening_contract_fee}</TableTd>
                                        </TableTr>
                                        <TableTr>
                                            <TableTh>月額費用（システム利用料）</TableTh>
                                            <TableTd>￥{sumFeeInfoData.bank}</TableTd>
                                        </TableTr>
                                        <TableTr>
                                            <TableTh>売上処理料</TableTh>
                                            <TableTd>￥{economicInfoData.bank.economic_requirement.sales_processing_fee}</TableTd>
                                        </TableTr>
                                        <TableTr>
                                            <TableTh>支払いサイト(入金サイクル)</TableTh>
                                            <TableTd>{economicInfoData.bank.economic_requirement.payment_site}</TableTd>
                                        </TableTr>
                                        {systemSettingMasterInfoData.bank_send_mail_agent_flag &&
                                            <TableTr>
                                                <TableTh>決済結果通知メール</TableTh>
                                                <TableTd>{economicInfoData.bank.system_setting.send_mail ? '利用する' : '利用しない'}</TableTd>
                                            </TableTr>
                                        }
                                        {systemSettingMasterInfoData.bank_customer_support_agent_flag &&
                                            <TableTr>
                                                <TableTh>カスタマーサポート</TableTh>
                                                <TableTd>{economicInfoData.bank.system_setting.customer_support ? '利用する' : '利用しない'}</TableTd>
                                            </TableTr>
                                        }
                                        {systemSettingMasterInfoData.bank_early_payment_service_agent_flag &&
                                            <TableTr>
                                                <TableTh>早期入金サービス</TableTh>
                                                <TableTd>{economicInfoData.bank.economic_requirement.early_payment_flag ? '利用する' : '利用しない'}</TableTd>
                                            </TableTr>
                                        }
                                        {systemSettingMasterInfoData.bank_cgi_agent_flag &&
                                            <TableTr>
                                                <TableTh>CGI</TableTh>
                                                <TableTd>{economicInfoData.bank.system_setting.cgi ? '利用する' : '利用しない'}</TableTd>
                                            </TableTr>
                                        }
                                    </tbody>
                                </TableWrapper>
                            </>
                        }
                        {economicCVS.select_flag &&
                            <>
                                <h3>コンビニ決済</h3>
                                <TableWrapper>
                                    <tbody>
                                        <TableTr>
                                            <TableTh>取引手数料</TableTh>
                                            <TableTd>
                                                <div className="cvs-settle-wrap">
                                                    {cvsFee}
                                                </div>
                                            </TableTd>
                                        </TableTr>
                                        <TableTr>
                                            <TableTh>初期費用（開設契約金）</TableTh>
                                            <TableTd>￥{economicInfoData.cvs.economic_requirement.opening_contract_fee}</TableTd>
                                        </TableTr>
                                        <TableTr>
                                            <TableTh>月額費用（システム利用料）</TableTh>
                                            <TableTd>￥{sumFeeInfoData.cvs}</TableTd>
                                        </TableTr>
                                        <TableTr>
                                            <TableTh>売上処理料</TableTh>
                                            <TableTd>￥{economicInfoData.cvs.economic_requirement.sales_processing_fee}</TableTd>
                                        </TableTr>
                                        <TableTr>
                                            <TableTh>支払いサイト(入金サイクル)</TableTh>
                                            <TableTd>{economicInfoData.cvs.economic_requirement.payment_site}</TableTd>
                                        </TableTr>
                                        {systemSettingMasterInfoData.cvs_send_mail_agent_flag &&
                                            <TableTr>
                                                <TableTh>決済結果通知メール</TableTh>
                                                <TableTd>{economicInfoData.cvs.system_setting.send_mail ? '利用する' : '利用しない'}</TableTd>
                                            </TableTr>
                                        }
                                        {systemSettingMasterInfoData.cvs_customer_support_agent_flag &&
                                            <TableTr>
                                                <TableTh>カスタマーサポート</TableTh>
                                                <TableTd>{economicInfoData.cvs.system_setting.customer_support ? '利用する' : '利用しない'}</TableTd>
                                            </TableTr>
                                        }
                                        {systemSettingMasterInfoData.cvs_early_payment_service_agent_flag &&
                                            <TableTr>
                                                <TableTh>早期入金サービス</TableTh>
                                                <TableTd>{economicInfoData.cvs.economic_requirement.early_payment_flag ? '利用する' : '利用しない'}</TableTd>
                                            </TableTr>
                                        }
                                        {systemSettingMasterInfoData.cvs_cgi_agent_flag &&
                                            <TableTr>
                                                <TableTh>CGI</TableTh>
                                                <TableTd>{economicInfoData.cvs.system_setting.cgi ? '利用する' : '利用しない'}</TableTd>
                                            </TableTr>
                                        }
                                    </tbody>
                                </TableWrapper>
                            </>
                        }

                        {demand_code === 'system' &&
                            <>
                                <h3>支払い方法</h3>
                                <TableWrapper>
                                    <tbody>
                                        <TableTr>
                                            <TableTh>月次支払い方法</TableTh>
                                            <TableTd>{changePaymentMethodPhrase(economicInfoData.credit.economic_requirement.monthly_payment_method)}</TableTd>
                                        </TableTr>
                                    </tbody>
                                </TableWrapper>
                            </>
                        }
                    </ConfirmFormWrap>
                </>
            }
        </>
    )
}
export default EntryConfirmElement
