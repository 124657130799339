import { useState, useContext, useMemo } from "react";
import { useLocation } from 'react-router-dom';
import { Context } from '../stores/Provider';
import { PostMethod } from '../api/Api';
import { confirmPasswordValidate, mailValidate, validationPattern } from '../validators/publicValidator';
import { setIsLoading } from "../function/setIsLoading";
import { passCheck } from "../function/passCheck";
import { initApiModelType, userRegisterRequestType } from "../constance/typeModel";
import { initApiResponse } from "../constance/constance";


const useUserRegisterHooks = (navigateByAgent: Function) => {
  const { state, dispatch } = useContext(Context);
  const [email, setEmail] = useState<string>(state.user.email);
  const [password, setPassword] = useState<string>(state.user.password);
  const [confirmPassword, setConfirmPassword] = useState<string>(state.user.password);
  const [errMsgs, setErrMsgs] = useState<{email: string, confirmPassword: string, agentMasterId: string}>({ email: '', confirmPassword: '', agentMasterId: ''});
  const [passCheckState, setPassCheckState] = useState<{msg:string, score:number}>({msg:"", score:0})
  const location = useLocation();
  const paramsArray = location.search.substring(1).split('&')

  // 変数返却
  const EmailData = email;
  const PasswordData = password;
  const ConfirmPasswordData = confirmPassword;
  const ErrMsgs = errMsgs;

  //代理店IDパラメータ
  const agentMasterId = useMemo(()=>{
    let result: string | null = null
    paramsArray.forEach((param)=>{
        if(param.includes('agent_master_id=')) result = param.split('agent_master_id=')[1]
    })
    return result
  },[paramsArray])

  // API実行
  const UserRegister = async (email: string, password: string, confirmPassword: string) => {
    //バリデーション関数の使用
    let response: initApiModelType<undefined> = initApiResponse
    const emailVal = mailValidate(email)
    const confirmPasswordVal = confirmPasswordValidate(password, confirmPassword)
    const intPattern = validationPattern.int
    setErrMsgs({ email: '', confirmPassword: '', agentMasterId: ''})

    if (!emailVal.isValidate || !confirmPasswordVal.isValidate) {
      const errorMessage = {email: "", confirmPassword: ""}
      errorMessage.email = emailVal.message;
      errorMessage.confirmPassword = confirmPasswordVal.message;
      setErrMsgs({...errorMessage , agentMasterId : ""})
      return response;
    }
    // 代理店マスターIDに数字バリデーションを実施
    if (agentMasterId !== null && !intPattern.test(agentMasterId)){
      setErrMsgs({email : "", confirmPassword : "" , agentMasterId : 'エラーが発生しました。\n前の画面に戻り、「お申し込み」ボタンから再度こちらの画面に入りなおしてください。'})
      return response;
    }

    const data = {
      "email": email,
      "password": password,
      "agent_master_id": agentMasterId,
    }
    //ローディング画面開始
    setIsLoading(true, dispatch)
    response = await PostMethod<userRegisterRequestType, undefined>(data, '/user_register');

    return response;
  }

  //正常時遷移先
  const normal = () => {
    //ローディング画面非表示
    setIsLoading(false, dispatch)
    return navigateByAgent('/completed_register')
  }
  //異常時遷移先
  const abnormal = () => {
    //ローディング画面非表示
    setIsLoading(false, dispatch)
    return navigateByAgent(`/completed_register`)
  }

  const setEmailHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    return setEmail(e.target.value)
  }

  const setPasswordHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const usedByEmailStr = email !== "" ? email.split('@')[0] : ""
    setPassCheckState(passCheck(e.target.value,[email,usedByEmailStr]))
    return setPassword(e.target.value)
  }

  const setConfirmPasswordHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    return setConfirmPassword(e.target.value)
  }

  return { UserRegister, EmailData, PasswordData, ConfirmPasswordData, normal, abnormal,
     setEmailHandler, setPasswordHandler, setConfirmPasswordHandler, ErrMsgs, passCheckState };
}

export default useUserRegisterHooks