import { useEffect } from "react";
import ErrorBoundary from "../../../api/ErrorBoundary";
import useGTMHooks from "../../../hooks/useGTMHooks";
import { OcnkTeikiEntry } from "../renderPages/Entry";

const OcnkTeikiEntryRedirectBeforeRender = ({
  redirect
}: {
  redirect?: boolean
}) => {
  
  const { pushDataLayerHandler } = useGTMHooks()
  
  useEffect(()=>{
    pushDataLayerHandler()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return(
    <ErrorBoundary>
      <OcnkTeikiEntry redirect={redirect}/>
    </ErrorBoundary>
  )
}

export default OcnkTeikiEntryRedirectBeforeRender