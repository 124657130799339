import { useState } from "react";

const useMenuHooks = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const IsOpen = isOpen;

    const ChangeOpen = () => {
        setIsOpen((prev) => !prev);
    }

    return { IsOpen, ChangeOpen }
}

export default useMenuHooks