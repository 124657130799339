/**
 * ダッシュボード
 */
import { Container } from '../../../components/layouts/Container';
import { DashboardBarElement, DashboardMessageElement, DashboardLowerWrapper } from '../../../components/ui_elements';
import OcnkTeikiDashboardBoxElement from '../../../components/ui_elements/ocnkTeiki/Dashboard/DashboardBoxElement';
import useDashboardHooks from '../../../hooks/useDashboardHooks';
import ErrorBoundary from '../../../api/ErrorBoundary';
import useNavigateByAgent from '../../../hooks/useNavigateByAgentHooks';
import usePdfDownloadHooks from '../../../hooks/usePdfDownloadHooks';



export const DashBoard = () => {
  const {navigateByAgent} = useNavigateByAgent()
  const {pdfDownloadHandler, userStatus} = usePdfDownloadHooks()
  const hooksResult = useDashboardHooks(navigateByAgent);
  //ログイン確認+認証確認
  const { step, status, beforeStatus, errorStep, buttonPhrase, mainMessage, moveToExamination, moveToUseStart, moveToUseStartPrecautions, canceledFlag, ocnkTeikiMoveToStart } = hooksResult
  /*
  * containerの設定
  */
  const background = 'white';  //背景色
  const align = 'top' //コンテンツ配置
  const fullwidth = true; //幅マックス

  return (
    <ErrorBoundary>
      <Container
        background={background}
        align={align}
        fullwidth={fullwidth}
        isLogin
        isInquiry
      >
        <DashboardMessageElement mainMessage={mainMessage} step={step}/>
        <DashboardLowerWrapper>
          <OcnkTeikiDashboardBoxElement step={step} status={status} moveToStart={ocnkTeikiMoveToStart} errorStep={errorStep} buttonPhrase={buttonPhrase} moveToExamination={moveToExamination} canceledFlag={canceledFlag} moveToUseStartPrecautions={moveToUseStartPrecautions} moveToUseStart={moveToUseStart}/>
          <DashboardBarElement pdfDownloadHandler={pdfDownloadHandler} userStatus={userStatus} beforeStatus={beforeStatus}/>
        </DashboardLowerWrapper>
      </Container>
    </ErrorBoundary>
  );
};
