import { useLocation } from "react-router-dom"
import { useCallback, useContext, useEffect, useMemo } from "react"
import { Context } from "../stores/Provider"
import { getSession } from "../sessionStorage/sessionStorageMethod"
import { GetMethod } from "../api/Api"
import { setIsLoading } from "../function/setIsLoading"
import { logoutAbnormalFunction } from "../function/logoutAbnormalFunction"
import { bankDataFormatResponseDataType, initApiModelType } from "../constance/typeModel"
import { initApiResponse } from "../constance/constance"

const useEntryHooks = (navigateByAgent: Function) => {
    const location = useLocation()
    const {state, dispatch} = useContext(Context)
    const entryCheckInfoData = getSession('entry_check')
    const loginInfoData = getSession('login')

    const ErrMsgState = state.entryValidateMessage
    const snackbarIsOpen = state.is_open_modal
    const pathName = location.pathname;
    const checkStatus = {
      merchant:entryCheckInfoData !== undefined && entryCheckInfoData !== null ? entryCheckInfoData.merchant : "default",
      bank:entryCheckInfoData !== undefined && entryCheckInfoData !== null ? entryCheckInfoData.bank_account : "default",
      shop:entryCheckInfoData !== undefined && entryCheckInfoData !== null ? entryCheckInfoData.shop : "default",
    }

    //申し込みボタンに申し込みデータを渡すためにコンテキストに保存
    const setEntryContext = useCallback(() => {
      dispatch({
        type : "set-entry-merchant",
        payload : getSession('merchant')
      })
      dispatch({
          type : "set-entry-representative",
          payload : getSession('representative')
      })
      dispatch({
          type : "set-entry-operation",
          payload : getSession('operation')
      })
      dispatch({
        type : "set-entry-bank-account",
        payload : getSession("bank_account")
      })
      dispatch({
        type : "set-entry-shop",
        payload : getSession('shop')
      })
      dispatch({
        type : "set-entry-files",
        payload : getSession('files')
      })
      dispatch({
        type : "set-review-content-division",
        payload : getSession('review_contents_division')
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dispatch])

    //銀行リスト取得
    const bankGetHandler = useCallback(async() => {
      let response: initApiModelType<bankDataFormatResponseDataType> = initApiResponse
      const data = ""
      response = await GetMethod<string, bankDataFormatResponseDataType>(data,'/bank_data_format')
      if(response.code !== 200){
        if(response.code === 401) return logoutAbnormalFunction(response,dispatch,navigateByAgent,state.agentPath) 
        dispatch({
          type: 'set-context-data',
          payload: {
            auth : response.message
          }
        })
        return navigateByAgent('/system_error')
      } else {
        dispatch({
          type: 'set-context-data',
          payload:{
            entry_bank_pulldown: response.response_data
          } 
        })
      }
      setIsLoading(false,dispatch)
    },[dispatch,navigateByAgent,state.agentPath])

    //画面読み込み時処理
    useEffect(()=>{
      setIsLoading(true,dispatch)
      setEntryContext()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(()=>{
      //銀行リスト取得
      loginInfoData.code === 200 && bankGetHandler()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[loginInfoData.code])

    //ページタイトル表示
    const pageTitle = useMemo(() => {
        const entryPath = location.pathname.split('/').slice(-1)[0]
        switch(entryPath){
          case 'merchant':
            return 'お客様情報'
          case 'bank_account':
            return '口座情報'
          case 'shop':
            return 'ショップ情報'
          default:
            return ''
        }
    },[location])

    //dfashboardボタン
    const moveToDashboard = () => {
      return navigateByAgent('/dashboard')
  }

    return{ pageTitle, checkStatus, moveToDashboard, navigateByAgent, ErrMsgState, snackbarIsOpen, pathName }
}

export default useEntryHooks