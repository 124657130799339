import { useEffect, useState } from "react";
import ErrorBoundary from "../../../api/ErrorBoundary";
import useGTMHooks from "../../../hooks/useGTMHooks";
import useLimitDirectAccess from "../../../hooks/useLimitDirectAccess";
import useUserAuthHooks from "../../../hooks/useUserAuthHooks";
import { useLoginCheckHooks } from "../../../hooks/useLoginCheckHooks";
import OcnkTeikiRedirectPassword from "../renderPages/RedirectPassword";

const OcnkTeikiRedirectPasswordBeforeRender = () => {
  const {LoginCheck} = useLoginCheckHooks('/ocnk-teiki');
  const {AuthCheck} = useUserAuthHooks('/ocnk-teiki');
  const {DirectAccessCheck} = useLimitDirectAccess('/ocnk-teiki')
  const { pushDataLayerHandler } = useGTMHooks()
  const [directFlag, setDirectFlag] = useState(false)
  const [loginFlag, setLoginFlag] = useState(false)
  
  //ログイン確認+URL直叩きチェックフラグをセット
  useEffect(()=>{
    pushDataLayerHandler()
    setLoginFlag(LoginCheck())
    setDirectFlag(DirectAccessCheck('/ocnk-teiki/redirect_password'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  
  if(!AuthCheck() || !directFlag || !loginFlag) return(<></>)

    return(
      <ErrorBoundary>
        <OcnkTeikiRedirectPassword/>
      </ErrorBoundary>
    )
}

export default OcnkTeikiRedirectPasswordBeforeRender