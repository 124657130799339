import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { colors } from '../../../css/variables/colors';

const ServiceListStyle = styled.ul`
  margin: 12px 0 0 0;
  padding:0;
  display: flex;
  gap: 10px;
  justify-content: center;
`;

type serviceListItemStyleType = {
  isUsed: boolean
}

const ServiceListItemStyle = styled.li`
  width: 88px;
  height: 80px;
  background: ${(props: serviceListItemStyleType) => props.isUsed ? colors.themeColor('light_yellow') : colors.themeColor('background_disabled')};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${colors.themeColor('border_box')};
  border-radius: 4px;
  img{
    opacity: ${(props: serviceListItemStyleType) => props.isUsed?1:.3};
  }
`;

export const ServiceList = ({
  children
}: {
  children: ReactNode
}) => {
  return (
    <ServiceListStyle>
      {children}
    </ServiceListStyle>
  );
};


export const ServiceListItem = ({
  img,
  title,
  isUsed = false
}: {
  img: string,
  title: string,
  isUsed: boolean
}) => {
  return (
    <ServiceListItemStyle isUsed={isUsed}>
      <img src={img} alt={title} />
    </ServiceListItemStyle>
  );
};
