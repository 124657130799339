import { BackGround } from "../../../layouts/BackGround"
import { ExaminationContentTitle, ExaminationContent } from "../../../ui-parts/ocnk"
import styled from 'styled-components';
import { colors } from '../../../../css/variables/colors';
import { mixins } from '../../../../css/variables/mixins';
import { serviceStatusType } from "../../../../constance/typeModel";

type contentWrapperType = {
  credit?: boolean
}
const ContentWrapper = styled.div<contentWrapperType>`
  display: flex;
  justify-content: center;
  padding: ${props => props.credit ? '16px 0 36px 0;' : '32px 0 36px 0;'}
  position:relative;
  ${mixins.screenUnder()}{
    flex-direction: column;
  }
`
type examinationContentWrapperStyleType = {
  contentWidth: string
}
const ExaminationContentWrapperStyle = styled.div<examinationContentWrapperStyleType>`
  border-right: dotted 1px ${colors.themeColor('border_box')};
  width: ${props => props.contentWidth === 'credit' ? '20%' : 'calc(100% / 6)'};
  ${mixins.screenUnder()}{
    width: 100%;
    border-right: none;
    border-bottom: dotted 1px ${colors.themeColor('border_box')};
    padding-bottom: 16px;
    margin-bottom: 16px;
  }
  &:last-child {
    border-right: none;
    ${mixins.screenUnder()}{
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
`
const CancelTerminatePhraseStyle = styled.span`
  color: ${colors.themeColor('text_error')};
`

const ExaminationStatusElement = ({
  examination,
  serviceCodeList,
  creditOrderArray,
  cvsOrderArray,
  serviceStopFlag
}: {
  examination: serviceStatusType,
  serviceCodeList: string,
  creditOrderArray: string[],
  cvsOrderArray: string[],
  serviceStopFlag: {credit: boolean, bank: boolean, cvs: boolean, atobarai: boolean}
}) => {
  
  
  return (
    <BackGround>
      {serviceCodeList.includes("credit") &&
        <>
          <ExaminationContentTitle>クレジットカード決済　<CancelTerminatePhraseStyle>{examination.credit.status === 'CANCEL' && 'キャンセル済み'}{examination.credit.status === 'TERMINATION' && '解約済み'}</CancelTerminatePhraseStyle></ExaminationContentTitle>
          <ContentWrapper credit>
            {creditOrderArray.map((brand: string, index: number)=>(
              <ExaminationContentWrapperStyle contentWidth={'credit'} key={index}>
                <ExaminationContent
                  brandName={brand}
                  status={examination.credit.use_brand[brand].status}
                  serviceName={'credit'}
                  div_status={examination.credit.use_brand[brand].div_status}
                  key={index}
		  wholeStatus={examination.credit.status}
                />
              </ExaminationContentWrapperStyle>
            ))}
            {serviceStopFlag.credit && <div className="grayout"/>}
          </ContentWrapper>
        </>
      }
      {(serviceCodeList.includes("atobarai") && examination.atobarai )&& 
        <>
          <ExaminationContentTitle>コンビニあと払い　<CancelTerminatePhraseStyle>{examination.atobarai.status === 'CANCEL'　&& 'キャンセル済み'}{examination.atobarai.status === 'TERMINATION' && '解約済み'}</CancelTerminatePhraseStyle></ExaminationContentTitle>
          <ContentWrapper>
              <ExaminationContent
                serviceName={'atobarai'}
                status={examination.atobarai.status}
              />
              {serviceStopFlag.atobarai && <div className="grayout"/>}

          </ContentWrapper>
        </>
      }
      {(serviceCodeList.includes("bank") && examination.bank )&&
        <>
          <ExaminationContentTitle>ネットバンク決済（入金おまかせサービス）<CancelTerminatePhraseStyle>{examination.bank.status === 'CANCEL' && 'キャンセル済み'}{examination.bank.status === 'TERMINATION' && '解約済み'}</CancelTerminatePhraseStyle></ExaminationContentTitle>
          <ContentWrapper>
              <ExaminationContent
                serviceName={'bank'}
                status={examination.bank.status}
              />
              {serviceStopFlag.bank && <div className="grayout"/>}
          </ContentWrapper>
        </>
      }
      {(serviceCodeList.includes("cvs") && examination.cvs) && 
        <>
          <ExaminationContentTitle>コンビニ決済　<CancelTerminatePhraseStyle>{examination.cvs.status === 'CANCEL' && 'キャンセル済み'}{examination.cvs.status === 'TERMINATION' && '解約済み'}</CancelTerminatePhraseStyle></ExaminationContentTitle>
          <ContentWrapper>
            {cvsOrderArray.map((brand: string, index: number) => (
              <ExaminationContentWrapperStyle contentWidth={'cvs'} key={index}>
                <ExaminationContent
                  serviceName={'cvs'}
                  status={examination.cvs.use_cvs[brand]}
                  brandName={brand}
		  wholeStatus={examination.cvs.status}
                />
              </ExaminationContentWrapperStyle>
            ))}
            {serviceStopFlag.cvs && <div className="grayout"/>}
          </ContentWrapper>
        </>
      }
    </BackGround>
      
  )
}

export default ExaminationStatusElement
