import { useEffect, useState } from "react";
import ErrorBoundary from "../../../api/ErrorBoundary";
import useLimitDirectAccess from "../../../hooks/useLimitDirectAccess";
import { useLoginCheckHooks } from "../../../hooks/useLoginCheckHooks";
import useUserAuthHooks from "../../../hooks/useUserAuthHooks";
import useGTMHooks from "../../../hooks/useGTMHooks";
import OcnkTeikiEntryConfirm from "../renderPages/EntryConfirm";

const OcnkTeikiEntryConfirmBeforeRender = ({
  redirect
}:{
  redirect?: boolean
}) => {
    const entryPath = redirect ? '/redirect_confirm' : '/entry_confirm'
    const {LoginCheck} = useLoginCheckHooks('/ocnk-teiki');
    const {AuthCheck} = useUserAuthHooks('/ocnk-teiki');
    const {DirectAccessCheck} = useLimitDirectAccess('/ocnk-teiki')
    const { pushDataLayerHandler } = useGTMHooks()

    useEffect(()=>{
      pushDataLayerHandler()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    
    const [directFlag, setDirectFlag] = useState(false)
    const [loginFlag, setLoginFlag] = useState(false)
    
  
    //ログイン確認+URL直叩きチェックフラグをセット
    useEffect(()=>{
      setLoginFlag(LoginCheck())
      setDirectFlag(DirectAccessCheck('/ocnk-teiki'+entryPath))
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
  
    if(!AuthCheck() || !directFlag || !loginFlag) return(<></>)

    return(
      <ErrorBoundary>
        <OcnkTeikiEntryConfirm redirect={redirect}/>
      </ErrorBoundary>
    )
}

export default OcnkTeikiEntryConfirmBeforeRender