import styled from 'styled-components'
import { mixins } from '../../css/variables/mixins';
import { colors } from '../../css/variables/colors';
import { fonts } from '../../css/variables/fonts';
import { ReactNode } from 'react';

type boxStyleType = {
  sx?: string,
  contentsWidth?: boolean,
  mt?: number,
  ml?: number,
  mb?: number,
  mr?: number,
  pt?: number,
  pl?: number,
  pb?: number,
  pr?: number,
  mt_desktop?: number,
  ml_desktop?: number,
  mb_desktop?: number,
  mr_desktop?: number,
  pt_desktop?: number,
  pl_desktop?: number,
  pb_desktop?: number,
  pr_desktop?: number,
  mt_mobile?: number,
  ml_mobile?: number,
  mb_mobile?: number,
  mr_mobile?: number,
  pt_mobile?: number,
  pl_mobile?: number,
  pb_mobile?: number,
  pr_mobile?: number,
}
const BoxStyle = styled.div<boxStyleType>`
  ${props => props.sx?props.sx:null};
  ${props => props.contentsWidth ? 'margin-left: auto':null};
  ${props => props.contentsWidth ? 'margin-right: auto':null};
  ${props => props.mt ? 'margin-top:' + props.mt + 'px !important' : null};
  ${props => props.ml ? 'margin-left:' + props.ml + 'px !important' : null};
  ${props => props.mb ? 'margin-bottom:' + props.mb + 'px !important' : null};
  ${props => props.mr ? 'margin-right:' + props.mr + 'px !important' : null};
  ${props => props.pt ? 'padding-top:' + props.pt + 'px !important' : null};
  ${props => props.pl ? 'padding-left:' + props.pl + 'px !important' : null};
  ${props => props.pb ? 'padding-bottom:' + props.pb + 'px !important' : null};
  ${props => props.pr ? 'padding-right:' + props.pr + 'px !important' : null};
  ${props => props.contentsWidth ? 'max-width:' + mixins.contentsWidth() : null};
  ${props => props.contentsWidth ? 'padding:0 16px' : null};
  ${mixins.screenOver()}{
    ${props => props.mt_desktop ? 'margin-top:' + props.mt_desktop + 'px !important' : null};
    ${props => props.ml_desktop ? 'margin-left:' + props.ml_desktop + 'px !important' : null};
    ${props => props.mb_desktop ? 'margin-bottom:' + props.mb_desktop + 'px !important' : null};
    ${props => props.mr_desktop ? 'margin-right:' + props.mr_desktop + 'px !important' : null};
    ${props => props.pt_desktop ? 'padding-top:' + props.pt_desktop + 'px !important' : null};
    ${props => props.pl_desktop ? 'padding-left:' + props.pl_desktop + 'px !important' : null};
    ${props => props.pb_desktop ? 'padding-bottom:' + props.pb_desktop + 'px !important' : null};
    ${props => props.pr_desktop ? 'padding-right:' + props.pr_desktop + 'px !important' : null};
  }
  ${mixins.screenUnder()}{
    ${props => props.mt_mobile ? 'margin-top:' + props.mt_mobile + 'px !important' : null};
    ${props => props.ml_mobile ? 'margin-left:' + props.ml_mobile + 'px !important' : null};
    ${props => props.mb_mobile ? 'margin-bottom:' + props.mb_mobile + 'px !important' : null};
    ${props => props.mr_mobile ? 'margin-right:' + props.mr_mobile + 'px !important' : null};
    ${props => props.pt_mobile ? 'padding-top:' + props.pt_mobile + 'px !important' : null};
    ${props => props.pl_mobile ? 'padding-left:' + props.pl_mobile + 'px !important' : null};
    ${props => props.pb_mobile ? 'padding-bottom:' + props.pb_mobile + 'px !important' : null};
    ${props => props.pr_mobile ? 'padding-right:' + props.pr_mobile + 'px !important' : null};
  }
`
const Error = styled.span`
  display: block;
  color: ${colors.themeColor('text_error')};
  font-size: ${fonts.fontSizeRem('s')};
  margin-top: 4px;
`;

export const Box = ({
  mt,
  mt_desktop,
  mt_mobile,
  mb,
  mb_desktop,
  mb_mobile,
  mr,
  mr_desktop,
  mr_mobile,
  ml,
  ml_desktop,
  ml_mobile,
  pt,
  pt_desktop,
  pt_mobile,
  pb,
  pb_desktop,
  pb_mobile,
  pr,
  pr_desktop,
  pr_mobile,
  pl,
  pl_desktop,
  pl_mobile,
  contentsWidth,
  sx = "",
  iserror = "",
  children = <></>
}: {
  mt?: number,
  mt_desktop?: number,
  mt_mobile?: number,
  mb?: number,
  mb_desktop?: number,
  mb_mobile?: number,
  mr?: number,
  mr_desktop?: number,
  mr_mobile?: number,
  ml?: number,
  ml_desktop?: number,
  ml_mobile?: number,
  pt?: number,
  pt_desktop?: number,
  pt_mobile?: number,
  pb?: number,
  pb_desktop?: number,
  pb_mobile?: number,
  pr?: number,
  pr_desktop?: number,
  pr_mobile?: number,
  pl?: number,
  pl_desktop?: number,
  pl_mobile?: number,
  contentsWidth?: boolean,
  sx?: string,
  children?: ReactNode,
  iserror?: string,
}) => (
  <BoxStyle mt={mt} mt_desktop={mt_desktop} mt_mobile={mt_mobile} mb={mb} mb_desktop={mb_desktop} mb_mobile={mb_mobile} mr={mr} mr_desktop={mr_desktop} mr_mobile={mr_mobile} ml={ml} ml_desktop={ml_desktop} ml_mobile={ml_mobile} pt={pt} pt_desktop={pt_desktop} pt_mobile={pt_mobile} pb={pb} pb_desktop={pb_desktop} pb_mobile={pb_mobile} pr={pr} pr_desktop={pr_desktop} pr_mobile={pr_mobile} pl={pl} pl_desktop={pl_desktop} pl_mobile={pl_mobile} contentsWidth={contentsWidth} sx={sx}>
    {children}
    {iserror  !== '' ? (<Error>{iserror}</Error>) : (<></>)}
  </BoxStyle>
);

