import { useState,useMemo, useContext, useCallback  } from "react";
import { getSession, setEconomicInfoData, setShopInfoData } from "../sessionStorage/sessionStorageMethod";
import { PostMethod } from "../api/Api";
import { useEffect } from "react";
import { initApiResponse, selectItemsPaymentMethod } from "../constance/constance";
import { setIsLoading } from "../function/setIsLoading";
import { Context } from "../stores/Provider";
import { logoutAbnormalFunction } from "../function/logoutAbnormalFunction";
import { diffExtractionRegisterEconomicRequirementType, diffExtractionRegisterType, diffExtractionTablesRequestType, finalAgreementCompareDataType, diffExtractionTablesResponseType, initApiModelType } from "../constance/typeModel";

const useFinalAgreementHooks = (navigateByAgent: Function) => {
    const [checkBoxState, setCheckBoxState] = useState({agreement:false,charges_check:false});
    const [vacantCheckState, setVacantCheckState] = useState({agreement_msg:'', charges_check_msg:''})
    const economicInfoData = getSession('economic_condition')
    const economicConditionMasterInfoData = getSession('economic_condition_master')
    const agentMasterInfoData = getSession('agent_master')
    const demandCode = agentMasterInfoData.demand_code
    
    const initResponseData: diffExtractionTablesResponseType = {
        shop_addition_product_content_freetext:"",
        shop_addition_product_content_code:"",
        subscription:false,
        threed_secure:false,
        cvv:false,
        div:false
    }
    //差があった場合true
    const initCompareData = useMemo(()=>{
        const initCompareData: finalAgreementCompareDataType = {
            shop:{
                shop_addition_product_content_freetext:false,
                shop_addition_product_content_code:false,
            },
            credit:{
                subscription:false,
                threed_secure:false,
                cvv:false,
                div:false,
            }
        }
        return initCompareData
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    // Post用データtype
    
    
    //post用データ
    const initPostData: diffExtractionRegisterType = {
        entry_id: 0,
        shop_addition_product_content_freetext:"",
        shop_addition_product_content_code:"",
        services:{
            credit:{
                economic_requirement:{
                    subscription:false,
                    threed_secure:false,
                    cvv:false,
                    div:false,
                    system_usage_fee_cvv:null,
                    system_usage_fee_3d_secure:null,
                    system_usage_fee_continuous_billing:null,
                    system_usage_fee_div2_bonus:null,
                    monthly_option_fee_cvv:null,
                    monthly_option_fee_3d_secure:null,
                    monthly_option_fee_continuous_billing:null,
                    monthly_option_fee_div2_bonus:null,
                    monthly_payment_method:"",
                }
            },
            bank:{
                economic_requirement:{
                    monthly_payment_method:"",
                }
            },
            cvs:{
                economic_requirement:{
                    monthly_payment_method:"",
                }
            },
            atobarai:{
                economic_requirement:{
                    monthly_payment_method:"",
                }
            },
        },
    }
    const [responseData, setResponseData] = useState(initResponseData)
    const [compareData, setCompareData] = useState(initCompareData)
    const [newSystemUsageFeeData, setNewSystemUsageFeeData] = useState(0)
    const [postData, setPostData] = useState(initPostData)
    const [paymentMethod, setPaymentMethod] = useState("")
    const [paymentShowFlags] = useState({zeroTo:false, toZero:false})
    const [validateMessage, setValidateMessage] = useState("")
    const {state,dispatch} = useContext(Context)

    const userInfoData = getSession('user')
    const shopInfoData = getSession('shop')
    const finalAgreementResponseData = responseData
    const serviceCodeList = economicInfoData.service_code_list

    const creditDifferenceFlag = compareData.credit.cvv || compareData.credit.div || compareData.credit.subscription || compareData.credit.threed_secure

    //必要なオブジェクトを取り出す関数
    const pickupItem = useCallback((
        obj: diffExtractionTablesResponseType | diffExtractionRegisterEconomicRequirementType,
        array: string[]
        ) => {
        let newObj: {
            [key: string]: number | boolean | string | null | undefined,
            cvv?: boolean | null, 
            threed_secure?: boolean | null, 
            div?: boolean| null, 
            subscription?: boolean | null, 
            system_usage_fee_cvv?:number | null,
            system_usage_fee_3d_secure?:number | null,
            system_usage_fee_continuous_billing?:number | null,
            system_usage_fee_div2_bonus?:number | null,
            monthly_option_fee_cvv?:number | null,
            monthly_option_fee_3d_secure?:number | null,
            monthly_option_fee_continuous_billing?:number | null,
            monthly_option_fee_div2_bonus?:number | null,
        } = {}
        for(let item in obj){
            if(!array.includes(item)) continue
            newObj[item] = obj[item]
        }
        return newObj
    },[])

    const compareResponseHandler = useCallback((response: diffExtractionTablesResponseType) => {
        let insertCompareData = initCompareData
        if("shop_addition_product_content_freetext" in response) insertCompareData.shop.shop_addition_product_content_freetext = true
        if("shop_addition_product_content_code" in response) insertCompareData.shop.shop_addition_product_content_code = true
        if("subscription" in response) insertCompareData.credit.subscription = true
        if("threed_secure" in response) insertCompareData.credit.threed_secure = true
        if("cvv" in response) insertCompareData.credit.cvv = true
        if("div" in response) insertCompareData.credit.div = true
        setCompareData(insertCompareData)

        return insertCompareData
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    //元の料金を計算
    const setOriginFeeHandler = useCallback(() => {
        let systemFee = {credit:0, bank:0, cvs:0, atobarai:0}
        if(demandCode !== "system") return systemFee
        for(let costKey in economicInfoData.credit.economic_requirement){
            if(costKey.includes('system_usage_fee')){
                systemFee.credit += economicInfoData.credit.economic_requirement[costKey]
            }
        }
        for(let costKey in economicInfoData.bank.economic_requirement){
            if(costKey.includes('system_usage_fee')){
                systemFee.bank += economicInfoData.bank.economic_requirement[costKey]
            }
        }
        for(let costKey in economicInfoData.cvs.economic_requirement){
            if(costKey.includes('system_usage_fee')){
                systemFee.cvs += economicInfoData.cvs.economic_requirement[costKey]
            }
        }
        return systemFee
    },[
        demandCode,
        economicInfoData.credit.economic_requirement,
        economicInfoData.bank.economic_requirement,
        economicInfoData.cvs.economic_requirement
    ])

    //システム利用料再計算+post用データセット
    const setNewSystemUsageFeeAndPostData = useCallback((
        response: diffExtractionTablesResponseType, 
        insertCompareData: finalAgreementCompareDataType, 
        originCreditFee: number
        ) => {
        let creditData = pickupItem(response,['cvv','threed_secure','div','subscription'])
        
        let shopData: {
            shop_addition_product_content_freetext?: string, 
            shop_addition_product_content_code?: string, 
            services?: {}
        } = {}
        //変更がある場合＆＆変更後がtrueの場合＋とコスト挿入、falseの場合-
        if(insertCompareData.credit.cvv){
            let cvvMasterFee = economicConditionMasterInfoData.credit.option_fee_cvv
            let feeItemName = demandCode === "system" ? "system_usage_fee_cvv" : "monthly_option_fee_cvv"
            if(creditData.cvv){
                originCreditFee += cvvMasterFee
                creditData[feeItemName] = cvvMasterFee
            } else if (!creditData.cvv){
                originCreditFee -= cvvMasterFee
                creditData[feeItemName] = null
            } 
        }
        if(insertCompareData.credit.threed_secure){
            let threedMasterFee = economicConditionMasterInfoData.credit.option_fee_3d_secure
            let feeItemName = demandCode === "system" ? "system_usage_fee_3d_secure" : "monthly_option_fee_3d_secure"
            if(creditData.threed_secure){
                originCreditFee += threedMasterFee
                creditData[feeItemName] = threedMasterFee
            } else if (!creditData.threed_secure){
                originCreditFee -= threedMasterFee
                creditData[feeItemName] = null
            } 
        }
        if(insertCompareData.credit.div){
            let divMasterFee = economicConditionMasterInfoData.credit.option_fee_div2_bonus
            let feeItemName = demandCode === "system" ? "system_usage_fee_div2_bonus" : "monthly_option_fee_div2_bonus"
            if(creditData.div){
                originCreditFee += divMasterFee
                creditData[feeItemName] = divMasterFee
            } else if (!creditData.div){
                originCreditFee -= divMasterFee
                creditData[feeItemName] = null
            } 
        }
        if(insertCompareData.credit.subscription){
            let subscriptionMasterFee = economicConditionMasterInfoData.credit.option_fee_continuous_billing
            let feeItemName = demandCode === "system" ? "system_usage_fee_continuous_billing" : "monthly_option_fee_continuous_billing"
            if(creditData.subscription){
                originCreditFee += subscriptionMasterFee
                creditData[feeItemName] = subscriptionMasterFee
            } else if (!creditData.subscription){
                originCreditFee -= subscriptionMasterFee
                creditData[feeItemName] = null
            } 
        }
        if(insertCompareData.shop.shop_addition_product_content_freetext) shopData.shop_addition_product_content_freetext = response.shop_addition_product_content_freetext
        if(insertCompareData.shop.shop_addition_product_content_code) shopData.shop_addition_product_content_code = response.shop_addition_product_content_code
        
        if(demandCode !== "system") originCreditFee = 0
        const postTempData = { 
            entry_id:userInfoData.entry_id,
            ...shopData,
            services:{
                ...shopData.services,
                credit:{
                    economic_requirement:creditData
                }
            }
        }
        setNewSystemUsageFeeData(originCreditFee)
        return {originCreditFee, postTempData}
    },[
        demandCode,economicConditionMasterInfoData.credit.option_fee_3d_secure,
        economicConditionMasterInfoData.credit.option_fee_continuous_billing,
        economicConditionMasterInfoData.credit.option_fee_cvv,
        economicConditionMasterInfoData.credit.option_fee_div2_bonus,
        userInfoData.entry_id,
        pickupItem
    ])

    //月次支払いの表示を決めるフラグ
    // const setPaymentShowFlagsHandler = (originFee,creditFee) => {
    //     let flags = {zeroTo:false, toZero:false}
    //     let withoutCreditFee = 0
    //     let sumOriginFee = 0
    //     let sumCreditFee = 0
        
    //     if(serviceCodeList.includes('bank')) withoutCreditFee += originFee.bank
    //     if(serviceCodeList.includes('cvs')) withoutCreditFee += originFee.cvs
    //     if(serviceCodeList.includes('atobarai')) withoutCreditFee += originFee.atobarai
    //     sumOriginFee += withoutCreditFee
    //     sumCreditFee += withoutCreditFee
    //     if(serviceCodeList.includes('credit')) sumOriginFee += originFee.credit
    //     sumCreditFee += creditFee

    //     if(sumOriginFee === 0 && creditFee !== 0) flags.zeroTo = true
    //     if(sumOriginFee !== 0 && sumCreditFee === 0) flags.toZero = true
    //     setPaymentShowFlags(flags)

    //     return flags
    // }
    
    // 変更後のデータを取得（変更なしのデータは連携しない）
    const secoundExaminationDataGetHandler = useCallback(async() => {
        if(userInfoData.entry_id === null|| userInfoData.entry_id === undefined) return
        let response : initApiModelType<diffExtractionTablesResponseType> = initApiResponse
        const data = {
            entry_id:userInfoData.entry_id
        }
        response = await PostMethod<diffExtractionTablesRequestType,diffExtractionTablesResponseType>(data,'/diff_extraction_tables')
        const responseData = response.response_data
        if(response.code !== 200 || responseData === undefined){
            if(response.code === 401){
                logoutAbnormalFunction(response,dispatch,navigateByAgent,state.agentPath)
                return response
            } 
            navigateByAgent('/system_error')
            return response
        }
        if(Object.keys(responseData).length === 0) return
        //レスポンスをセット
        setResponseData(responseData)
        //変更があったもののフラグを立てる
        const insertCompareData = compareResponseHandler(responseData)
        //変更前の料金計算
        const originFee = setOriginFeeHandler()
        //クレジットの新しい合計システム料金計算＋ポストデータに新しい料金をセット
        const {postTempData} = setNewSystemUsageFeeAndPostData(responseData,insertCompareData,originFee.credit)
        //金額が0から有料になるもしくは有料から0円になる場合にフラグを立たせる
        // const paymentMethodFlags = setPaymentShowFlagsHandler(originFee,originCreditFee)
        
        //ポストデータをセット
        setPostData(postTempData)

        return response
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    
    //画面遷移時処理
    useEffect(()=>{
        //API投げる＋レスポンス加工
        secoundExaminationDataGetHandler()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    // 全サービスの支払い方法項目をセットする関数
    const setPaymentMethodPostHandler = (data: diffExtractionRegisterType) => {
        const makeObj = () => {
            if(data.services === undefined) return
            if(serviceCodeList.includes('bank')){
                data.services.bank = {}
                data.services.bank.economic_requirement = {}
            }
            if(serviceCodeList.includes('cvs')){
                data.services.cvs = {}
                data.services.cvs.economic_requirement = {}
            }
            if(serviceCodeList.includes('atobarai')){
                data.services.atobarai = {}
                data.services.atobarai.economic_requirement = {}
            }
        }
        
        //toZeroフラグがtrueの場合、月次支払い方法を"2"に設定する
        if(paymentShowFlags.toZero){
            makeObj()
            if(data.services === undefined) return
            if(serviceCodeList.includes('credit')) data.services.credit.economic_requirement.monthly_payment_method = "2"
            if(serviceCodeList.includes('cvs') && (data.services.cvs !== undefined && data.services.cvs.economic_requirement !== undefined)) data.services.cvs.economic_requirement.monthly_payment_method = "2"
            if(serviceCodeList.includes('bank') && (data.services.bank !== undefined && data.services.bank.economic_requirement !== undefined)) data.services.bank.economic_requirement.monthly_payment_method = "2"
            if(serviceCodeList.includes('atobarai') && (data.services.atobarai !== undefined && data.services.atobarai.economic_requirement !== undefined)) data.services.atobarai.economic_requirement.monthly_payment_method = "2"
        } else if(paymentShowFlags.zeroTo) {
            makeObj()
            if(data.services === undefined) return
            if(serviceCodeList.includes('credit')) data.services.credit.economic_requirement.monthly_payment_method = paymentMethod
            if(serviceCodeList.includes('bank') && (data.services.bank !== undefined && data.services.bank.economic_requirement !== undefined)) data.services.bank.economic_requirement.monthly_payment_method = paymentMethod
            if(serviceCodeList.includes('cvs') && (data.services.cvs !== undefined && data.services.cvs.economic_requirement !== undefined)) data.services.cvs.economic_requirement.monthly_payment_method = paymentMethod
            if(serviceCodeList.includes('atobarai') && (data.services.atobarai !== undefined && data.services.atobarai.economic_requirement !== undefined)) data.services.atobarai.economic_requirement.monthly_payment_method = paymentMethod
        }
        if(Object.keys(data.services !== undefined && data.services.credit.economic_requirement).length === 0) delete data.services
        return data
    }

    const paymentMethodValidater = () => {
        if(demandCode === "system" && paymentShowFlags.zeroTo && paymentMethod === ""){
            setValidateMessage('支払い方法を選択してください')
            return true
        }
        return false
    }

    //チェックバリデーション関数
    const vacantCheckAndSetMessage = () => {
        let validateFlag = false
        let vacantMsgs = {agreement_msg:'', charges_check_msg:''}
        if(checkBoxState.agreement === false) vacantMsgs.agreement_msg = '規約への同意は必須です'
        if(checkBoxState.charges_check === false) vacantMsgs.charges_check_msg = '取引手数料の確認は必須です'
        setVacantCheckState(vacantMsgs)
        //前回と今回のサービス選択を比較して差があった場合、かつ規約同意と手数料同意がされていない場合リターン
        if( !checkBoxState.agreement || !checkBoxState.charges_check) validateFlag = true
        return validateFlag
    }

    //チェックボックスステイトハンドラ
    const setCheckBoxStateHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		return setCheckBoxState({...checkBoxState, [e.target.id]:e.target.checked})
	}

    //②更新情報をPOST
    //③レスポンスをリターンする
    //①ショップ情報・経済条件の情報を更新
    const agreementHandler = async() => {
        let response : initApiModelType<diffExtractionRegisterType> = initApiResponse
        let data = {
            ...postData,
            entry_id: userInfoData.entry_id
        }
        //合意チェックバリデーション || 支払い方法ブランクバリデーション
        if(paymentMethodValidater() || vacantCheckAndSetMessage()) return response
        
        const postInsertData = setPaymentMethodPostHandler(data)
        if(postInsertData === undefined) return response
        //ローディング画面開始
        setIsLoading(true, dispatch)
        response = await PostMethod<diffExtractionRegisterType,diffExtractionRegisterType>(postInsertData,'/diff_extraction_register')
        
        return response
    }

    const finalAgreementNormal = (response: {response_data: diffExtractionRegisterType}) => {
        const registerResponseData = response.response_data
        //経済条件とショップ情報に新しいデータを挿入
        let insertEconomicData = economicInfoData
        let shopData:{
            shop_addition_product_content_freetext?: string,
            shop_addition_product_content_code?: string
        } = {}
        let creditSystemSettingData
        let creditEconomicRequirementData
        // 
        if('shop_addition_product_content_freetext' in registerResponseData){
            shopData.shop_addition_product_content_freetext = registerResponseData.shop_addition_product_content_freetext
        }
        if('shop_addition_product_content_code' in registerResponseData){
            shopData.shop_addition_product_content_code = registerResponseData.shop_addition_product_content_code
        }
        
        if(registerResponseData.services !== undefined){
            creditSystemSettingData = pickupItem(registerResponseData.services.credit.economic_requirement,['subscription','threed_secure','cvv','div'])
            creditEconomicRequirementData = pickupItem(registerResponseData.services.credit.economic_requirement,['system_usage_fee_cvv','system_usage_fee_3d_secure','system_usage_fee_continuous_billing','system_usage_fee_div2_bonus','monthly_option_fee_cvv','monthly_option_fee_3d_secure','monthly_option_fee_continuous_billing','monthly_option_fee_div2_bonus','monthly_payment_method'])
            if(registerResponseData.services.credit !== undefined){
                insertEconomicData.credit.system_setting = {...economicInfoData.credit.system_setting, ...creditSystemSettingData}
                insertEconomicData.credit.economic_requirement = {...economicInfoData.credit.economic_requirement, ...creditEconomicRequirementData}
            }
            if(registerResponseData.services.bank !== undefined){
                insertEconomicData.bank = {
                    system_setting:economicInfoData.bank.system_setting, 
                    economic_requirement:{
                        ...economicInfoData.bank.economic_requirement,
                        ...registerResponseData.services.bank.economic_requirement
                    } 
                }
            }
            if(registerResponseData.services.cvs !== undefined){
                insertEconomicData.cvs = {
                    system_setting:economicInfoData.cvs.system_setting, 
                    economic_requirement:{
                        ...economicInfoData.cvs.economic_requirement,
                        ...registerResponseData.services.cvs.economic_requirement
                    } 
                }
            }
            if(registerResponseData.services.atobarai !== undefined){
                insertEconomicData.atobarai = {
                    system_setting:economicInfoData.atobarai.system_setting, 
                    economic_requirement:{
                        ...economicInfoData.atobarai.economic_requirement,
                        ...registerResponseData.services.atobarai.economic_requirement
                    } 
                }
            }  
        }
        setShopInfoData({...shopInfoData, ...shopData})
        setEconomicInfoData(insertEconomicData)
        //ローディング画面非表示
        setIsLoading(false, dispatch)
        return navigateByAgent('/final_agreement_completed')
    }
    //API実行異常時処理
    const finalAgreementAbnormal = () => {
        //ローディング終了
        setIsLoading(false, dispatch)
        return navigateByAgent('/final_agreement')
    }

    //商品コンテンツ画面表示名
    const productContentPhrase = useMemo(()=>{
        let result = {pre:"", post:""}
        const replaceMethod = (data: string) => {
            return data.replace('SELL_GOODS','物販').replace('SERVICE','役務').replace('DIGITAL_EMAIL','デジコン')
        }
        result.pre = replaceMethod(shopInfoData.shop_addition_product_content_code)
        if("shop_addition_product_content_code" in responseData) result.post = replaceMethod(responseData.shop_addition_product_content_code!)
        return result
    },[shopInfoData,responseData])

    //支払い方法セットハンドラ
    const setMonthlyPaymentHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        return setPaymentMethod(e.target.value)
    }
    

    return {agreementHandler,finalAgreementNormal,finalAgreementResponseData,shopInfoData,productContentPhrase,compareData,newSystemUsageFeeData,selectItemsPaymentMethod,paymentMethod,setMonthlyPaymentHandler,validateMessage,paymentShowFlags,creditDifferenceFlag,finalAgreementAbnormal,setCheckBoxStateHandler,vacantCheckState}
}
export default useFinalAgreementHooks