import { useState, useContext, } from "react";
import { Context } from '../stores/Provider';
import { PostMethod } from '../api/Api';
import { mailValidate } from "../validators/publicValidator";
import { setIsLoading } from "../function/setIsLoading";
import { initApiModelType, resetPasswordMailRequestType } from "../constance/typeModel";
import { initApiResponse } from "../constance/constance";
import useNavigateByAgent from "./useNavigateByAgentHooks";

const useResetPasswordMailHooks = (agentMasterList: number[]) => {
    const {navigateByAgent} = useNavigateByAgent()
    const { dispatch } = useContext(Context);
    const [ email, setEmail ] = useState<string>('');
    const [ errMsg, setErrMsg ] = useState<string>('');

    // 変数返却
    const EmailData = email;
    const ErrMsg = errMsg;

    const sendEmail = async (email: string) => {
        let response: initApiModelType<undefined> = initApiResponse
        // バリデーション処理
        const { isValidate, message } = mailValidate(email)
        setErrMsg('')

        if (!isValidate) {
            setErrMsg(message)
            return response
        }
        const data = { 
            email: email,
            agent_master_id_list: agentMasterList
        }

        //ローディング画面開始
        setIsLoading(true, dispatch)
        //API通信
        response = await PostMethod<resetPasswordMailRequestType, undefined>(data, '/reset_password_mail');
        
        return response;
    }

    //正常時遷移先
    const normal = () => {
        //ローディング画面非表示
        setIsLoading(false, dispatch)
        return navigateByAgent('/reset_password_mail_completed',false,email)
    }
    
    //異常時遷移先
    const abnormal = () => {
        //ローディング画面非表示
        setIsLoading(false, dispatch)
        return navigateByAgent('/reset_password_mail_completed')
    }

    const setEmailHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        return setEmail(e.target.value)
    }

    return { sendEmail, EmailData, setEmailHandler, ErrMsg, normal, abnormal }
}

export default useResetPasswordMailHooks