import { useEffect, useState } from "react";
import ErrorBoundary from "../../../api/ErrorBoundary";
import { useLoginCheckHooks } from "../../../hooks/useLoginCheckHooks";
import useUserAuthHooks from "../../../hooks/useUserAuthHooks";
import { OcnkTeikiEntry } from "../renderPages/Entry";

const OcnkTeikiEntryBeforeRender = () => {
    const {LoginCheck} = useLoginCheckHooks('/ocnk-teiki');
    const {AuthCheck} = useUserAuthHooks('/ocnk-teiki');
    const [loginFlag, setLoginFlag] = useState<boolean>(false)
    
  
    //ログイン確認+URL直叩きチェックフラグをセット
    useEffect(()=>{
      setLoginFlag(LoginCheck())
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
  
    if(!AuthCheck() || !loginFlag) return(<></>)

    return(
      <ErrorBoundary>
        <OcnkTeikiEntry/>
      </ErrorBoundary>
    )
}

export default OcnkTeikiEntryBeforeRender