/**
 * ページテンプレート
 */
import React,{useContext} from 'react';
import { Container } from '../../../components/layouts/Container';
import { EntryNav } from '../../../components/ui-parts/ocnk/EntryNav';
import { EntryNavItem } from '../../../components/ui-parts/ocnk/EntryNavItem';
import { Outlet, useLocation } from 'react-router-dom';
import useEntryHooks from '../../../hooks/useEntryHooks';
import { FlexBox } from '../../../components/layouts/FlexBox';
import { BackButton } from '../../../components/ui-parts/ocnk/BackButton';
import { ArrowLink, Button } from '../../../components/ui-parts/ocnk';
import styled from 'styled-components';
import { colors } from '../../../css/variables/colors';
import { fonts } from '../../../css/variables/fonts';
import useEntryAPIHooks from '../../../hooks/useEntryAPIHooks';
import { Context } from '../../../stores/Provider';
import { Snackbar } from '../../../components/ui-parts/ocnk/Snackbar';
import { mixins } from '../../../css/variables/mixins';
import useNavigateByAgent from '../../../hooks/useNavigateByAgentHooks';

const Error = styled.span`
  display: block;
  color: ${colors.themeColor('text_error')};
  font-size: ${fonts.fontSizeRem('s')};
  margin-top: 4px;
`;

const BottomButtonWrapper = styled.div`
  width:100%;
  margin-top: 32px;
  ${mixins.screenUnder()}{
    display: none;
  }
`

const ButtonWrapper = styled.div`
  width:270px;
  text-align: center;
  margin-right:auto;
  margin-left:auto;
`

export const Entry = () => {
  const {navigateByAgent} = useNavigateByAgent()
  const { pageTitle, checkStatus, moveToDashboard } = useEntryHooks(navigateByAgent);
  const { EntryPostMethod, fromConfirmFlag, path } = useEntryAPIHooks(navigateByAgent);
  const {state} = useContext(Context)
  const location = useLocation();
  const pathName = location.pathname;

  const ErrMsgState = state.entryValidateMessage
  const snackbarIsOpen = state.is_open_modal
  
  return (
    <Container isLogin={true} background={'default'} align={'top'} isInquiry>
      <Snackbar open={snackbarIsOpen.flag} message={snackbarIsOpen.message} type={snackbarIsOpen.type} />
      <FlexBox sx={ 'alignItems: center' } spaceBetween>
        <h1>{pageTitle}</h1>
        <div className='display-desktop'>
          <BackButton onClick={moveToDashboard}>ダッシュボードに戻る</BackButton>
        </div>
      </FlexBox>
        <div className='display-mobile align_right mb12'>
            <BackButton onClick={moveToDashboard}>ダッシュボードに戻る</BackButton>
        </div>
      <p className='mb20'>全ての情報を入力するとお申し込みが可能になります。</p>

      <ArrowLink link='/entry_top'>お申し込み情報TOPへ</ArrowLink>
      
      <EntryNav>
        <EntryNavItem title="お客様情報" icon='shop' status={checkStatus.merchant} isCurrent={pathName === state.agentPath+'/entry/merchant' ? true : false} onClick={() => navigateByAgent('/entry/merchant')}/>
        <EntryNavItem title="口座情報" icon='bank' status={checkStatus.bank} isCurrent={pathName === state.agentPath+'/entry/bank_account' ? true : false} onClick={() => navigateByAgent('/entry/bank_account')}/>
        <EntryNavItem title="ショップ情報" icon='cart' status={checkStatus.shop} isCurrent={pathName === state.agentPath+'/entry/shop' ? true : false} onClick={() => navigateByAgent('/entry/shop')}/>
      </EntryNav>
      {ErrMsgState !== '' && <Error>{ErrMsgState}</Error>}
      <hr />
      <Outlet />
      <BottomButtonWrapper>
        {fromConfirmFlag ?
          <ButtonWrapper>
            <Button sizeDesktop='large' fullwidth onClick={EntryPostMethod}>確認画面へ戻る</Button>
          </ButtonWrapper>
          :
          <ButtonWrapper>
            <Button sizeDesktop='large' fullwidth onClick={EntryPostMethod}>{path === 'shop' ? "保存して確認画面へ" : "保存して次へ"}</Button>
          </ButtonWrapper>
        }
      </BottomButtonWrapper>
    </Container>
  );
};
