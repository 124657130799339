import styled from "styled-components";
import { Box } from "../../../components/layouts/Box";
import { Container } from "../../../components/layouts/Container"
import { EntryIcons } from "../../../components/ui-parts/ocnk";
import { BackButton } from "../../../components/ui-parts/ocnk/BackButton";
import { colors } from "../../../css/variables/colors";
import { mixins } from "../../../css/variables/mixins";
import LinkByAgent from "../../../components/ui-parts/ocnk/LinkByAgent";
import OcnkTeikiEntryConfirmElement from "../../../components/ui_elements/ocnkTeiki/EntryConfirm/EntryConfirmElement";
import { getSession } from "../../../sessionStorage/sessionStorageMethod";
import PdfDownloadButton from "../../../components/ui-parts/ocnk/PdfDownloadButton";
import { FlexBox } from "../../../components/layouts/FlexBox";
import usePdfDownloadHooks from "../../../hooks/usePdfDownloadHooks";
import { useEntryCheckHooks } from "../../../hooks/useEntryCheckHooks";
import useNavigateByAgent from "../../../hooks/useNavigateByAgentHooks";
import OcnkBankExplainBox from "../../../components/ui_elements/ocnkTeiki/EntryCheck/OcnkBankExplainBox";

const TitleWrapper = styled.div`
    p {
        color:${colors.themeColor('text-main')};
        font-size:26px;
        font-weight:600;
        margin-bottom:0px;
    }
    display:flex;
    margin-top:28px;
    ${mixins.screenUnder()}{
        margin-top: 28px;
        margin-bottom: 20px;
    }
`
const TopTitleWrapper = styled.div`
    p {
        color:${colors.themeColor('text-main')};
        font-size:26px;
        font-weight:600;
        margin-bottom:0px;
    }
    display:flex;
    ${mixins.screenUnder()}{
        margin-top: 28px;
        margin-bottom: 20px;
    }
`
const WholeWrapper = styled.div`
    padding:0px 90px 0px;
    ${mixins.screenUnder()}{
        padding:0px 16px 0px;
    }
`
const MainBorder = styled.hr`
    ${mixins.screenUnder()}{
        margin-top: 4px;
        margin-bottom: 16px;
    }
`

const OcnkTeikiEntryCheck = () => {
    const userInfoData = getSession('user') 
    const {pdfDownloadHandler, userStatus} = usePdfDownloadHooks()
    const {navigateByAgent} = useNavigateByAgent()
    const {ObjToDevide} = useEntryCheckHooks(navigateByAgent)

    return(
        <Container isLogin={true} background={'default'} align={'top'} fullwidth>
            <WholeWrapper>
                <h1 className="mb0">お申し込み情報の確認</h1>
                <MainBorder/>
                <FlexBox gap={12} column flexEnd>
                    <BackButton><LinkByAgent to={'/dashboard'}>ダッシュボードへ戻る</LinkByAgent></BackButton>
                    {(userStatus === "CUTOVER" || userStatus === "CLOSING") &&
                        <PdfDownloadButton pdfDownloadHandler={pdfDownloadHandler}/>
                    }
                </FlexBox>
                <TopTitleWrapper>
                    <EntryIcons icon={'shop'} /><p>お客様情報</p>
                </TopTitleWrapper>
                <OcnkTeikiEntryConfirmElement title={'merchant'} check/>
                {!userInfoData.ip_flag ?
                    <>
                        <TitleWrapper>
                            <EntryIcons icon={'bank'} /><p>口座情報</p>
                        </TitleWrapper>
                        <OcnkTeikiEntryConfirmElement title={'bank'} check/>
                    </>
                    :
                    <>
                        <TitleWrapper>
                            <EntryIcons icon={'bank'} /><p>口座情報</p>
                        </TitleWrapper>
                        <OcnkBankExplainBox/>
                    </>
                }
                <TitleWrapper>
                    <EntryIcons icon={'cart'} /><p>ショップ情報</p>
                </TitleWrapper>
                <OcnkTeikiEntryConfirmElement title={'shop'} check shopDevideData={ObjToDevide}/>
                <TitleWrapper>
                    <EntryIcons icon={'setting'}/><p>お見積内容の確認</p>
                </TitleWrapper>
                <OcnkTeikiEntryConfirmElement title={'economic'} check/>
                <Box mt={46}>
                    <BackButton><LinkByAgent to={'/dashboard'}>ダッシュボードへ戻る</LinkByAgent></BackButton>
                </Box>
            </WholeWrapper>
        </Container>
    )
}
export default OcnkTeikiEntryCheck
