import { Container } from "../../../components/layouts/Container"
import { Button } from "../../../components/ui-parts/ocnk"
import { FormWrap } from "../../../components/layouts/FormWrap"
import VectorImg from "../../../images/Vector.svg"
import LinkByAgent from "../../../components/ui-parts/ocnk/LinkByAgent"

const FinalAgreementCompleted = () => {
  return (
    <Container>
      <FormWrap center>
        <img src={VectorImg} alt="finalAgreementImg" />
        <h3>合意を確認いたしました</h3>
        <div className="mb32">
          <p className="font-m">ご利用の準備完了まで今しばらくお待ちください。</p>
        </div>
        <Button variant="secoundary">
          <LinkByAgent to={'/dashboard'}>ダッシュボードに戻る</LinkByAgent>
        </Button>
      </FormWrap>
    </Container>
  )
}

export default FinalAgreementCompleted