import React, { useState, useContext, useEffect, useCallback, useMemo } from "react";
import { Context } from "../stores/Provider";
import { GetMethod } from "../api/Api";
import { getSession } from "../sessionStorage/sessionStorageMethod";
import { setIsLoading } from "../function/setIsLoading";
import { logoutAbnormalFunction } from "../function/logoutAbnormalFunction";
import { initApiResponse } from "../constance/constance";
import { ToolChipDiv, bankPaymentPattern, cvsPaymentPattern } from "../constance/constance";
import AnotherTabLink from "../components/ui-parts/ocnk/AnotherTabLink";
import { creditServiceStatusType, creditStatusType, initApiModelType, serviceStatusType, statusRequestType, statusResponseType } from "../constance/typeModel";

const useStartHooks = (navigateByAgent: Function) => {
    const { state, dispatch } = useContext(Context);
    const [openExamiantionFlagState, setOpenExamiantionFlagState] = useState<{[key: string]: boolean, credit: true, bank: false, cvs: false}>({credit: true, bank: false, cvs: false})
    const useStartData = state.use_start_data
    const serviceStatusData = state.service_status
    //セッションデータ抽出
	const userInfoData = getSession('user')
    const operatorInfoData = getSession('operation')
    const economicInfoData = getSession('economic_condition')
    const [serviceCodeList, setServiceCodeList] = useState<string>("")
    const [creditBrandData, setCreditBrandData] = useState<creditStatusType>([])
    const phraseByAgent = {
        bank: bankPaymentPattern[userInfoData.agent_master_id],
        cvs: cvsPaymentPattern[userInfoData.agent_master_id]
    }

    // クレカブランド表示
    const setCreditBrandsPaymentData = useCallback((creditData: creditServiceStatusType) => {
        const creditAllBrandData = creditData.use_brand
        const creditBrandName: { [key: string]: string, visa: "VISA", master: "Master Card", jcb: "JCB", diners: "Diners", amex: "AMEX"} = { visa: "VISA", master: "Master Card", jcb: "JCB", diners: "Diners", amex: "AMEX"}
        const brandArray: creditStatusType = []

        // 審査OKのブランドを抽出
        for(let brand in creditAllBrandData){
            brandArray.push({ label: creditBrandName[brand], status: ContentStatus(creditAllBrandData[brand].status), div_status: creditAllBrandData[brand].div_status})
        }
        // クレジットブランドと分割・リボの文言をセット
        setCreditBrandData(brandArray)
    },[])

    const serviceStopFlag = useMemo(()=>{
        const serviceStopFlag = {
            credit: (serviceStatusData.credit.status === 'CANCEL' || serviceStatusData.credit.status === 'TERMINATION'),
            cvs: (serviceStatusData.cvs.status === 'CANCEL' || serviceStatusData.cvs.status === 'TERMINATION'),
            bank: (serviceStatusData.bank.status === 'CANCEL' || serviceStatusData.bank.status === 'TERMINATION'),
            atobarai: (serviceStatusData.atobarai.status === 'CANCEL' || serviceStatusData.atobarai.status === 'TERMINATION')
        } 
        return serviceStopFlag
    },[serviceStatusData])

    const getUseStart = useCallback((data:{
        service_code_list: string,
        merchant_id: string,
        service: serviceStatusType
    }) => {
        setServiceCodeList(data.service_code_list)
        dispatch({
            type: "set-context-data",
            payload: {
                use_start_data: {
                    serviceData : {
                        credit: [["IPコード", data.service.credit.ip_code], ["Zキー（認証キー）", data.service.credit.zkey]],
                        cvs: [["IPコード", data.service.cvs.ip_code]],
                        bank: [["IPコード", data.service.bank.ip_code]],
                        atobarai: [["IPコード", data.service.atobarai.ip_code],["APIキー", data.service.atobarai.api_key]]
                    },
                    managementData : [
                        ["管理画面URL", <AnotherTabLink href="https://linkpt.cardservice.co.jp/merchant/">https://linkpt.cardservice.co.jp/merchant/</AnotherTabLink>],
                        ["ログインID", data.merchant_id],
                        ["ログインパスワード", <ToolChipDiv>{"初回ログイン時は、ゼウスからの受信メールに記載の「仮パスワード」を入力してください。\n「仮パスワード」でログイン後、パスワード変更画面が表示されますので、ご希望のパスワードへ変更の上、再ログインをお願いいたします。"}</ToolChipDiv>]
                        
                    ],
                    atobaraiManagementData : [
                        ["あと払い決済サービス管理画面URL", "https://atobarai.sbi-finsol.co.jp/admin/"],
                        ["ログインID", operatorInfoData.operator_email],
                        ["ログインパスワード", <ToolChipDiv>{"初回ログイン時にご自身で設定いただけます。\n（ログイン画面の「初回ログイン・パスワードの再設定を希望の方」よりご登録ください。）"}</ToolChipDiv>]
                    ]
                },
                service_status:{
                    credit:data.service.credit,
                    bank:data.service.bank,
                    cvs:data.service.cvs,
                    atobarai:data.service.atobarai,
                }
            }
        })
        setCreditBrandsPaymentData(data.service.credit)
    },[dispatch,operatorInfoData,setCreditBrandsPaymentData])

    useEffect(() => {
        let response: initApiModelType<statusResponseType> = initApiResponse
        const entryId = {entry_id: userInfoData.entry_id}
        const getResponse = async () => {
            setIsLoading(true, dispatch)
            response = await GetMethod<statusRequestType, statusResponseType>(entryId, '/status') 
            if(response.code !== 200){
                if(response.code === 401){
                    return logoutAbnormalFunction(response,dispatch,navigateByAgent,state.agentPath)
                }
                setIsLoading(false, dispatch)
                return navigateByAgent('/system_error')
            } 
            // 型ガード用条件式
            if(response.response_data === undefined) return
            
            getUseStart(response.response_data.entry)
            setIsLoading(false, dispatch)
        }
        getResponse()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    

    // 審査ステータス文言表示
    const ContentStatus = (status: string) => {
        let statusPhrase = <></>
        switch (status) {
          case 'BEFORE_SUBMITTING':
          case 'SUBMITTED':
            statusPhrase = <>審査中</>
            break;
          case 'OK':
            statusPhrase = <>審査OK</>
            break;
          case 'NG':
            statusPhrase = <>審査NG</>
            break;
          default:
            statusPhrase = <></>
            break;
        }
        return statusPhrase
    }

    // 詳細情報の表示変更ハンドラ
    const examinationOpenHandler = (service: string) => {
        return setOpenExamiantionFlagState({...openExamiantionFlagState, [service]: !openExamiantionFlagState[service]})
    }

    return { useStartData, serviceCodeList, navigateByAgent, dispatch, serviceStatusData, phraseByAgent, 
        openExamiantionFlagState, examinationOpenHandler, ContentStatus,economicInfoData, creditBrandData, serviceStopFlag }
}

export default useStartHooks
