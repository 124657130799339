import { useState, useRef } from "react";
import { sessionFileType } from "../constance/typeModel";

const useInquiryFileHooks = (FileDatas: sessionFileType[]|null, setFileData: Function) => {
    // ツールチップState
    const[tip, setTip] = useState(false);
    const FileRef = useRef<HTMLInputElement>(null);
    // ツールチップState変更関数
    const tipChangeHandler = () => {
        setTip(prev => !prev);
    };
    // ファイルの登録
    const loadFile = (e: {target:{value: string | null}}) => {
        const inputFiles = FileRef.current !== null ? FileRef.current.files : null;
        if(inputFiles === null) return
        const target = e.target
        for (let file of inputFiles) {
            var fr = new FileReader();
            fr.onload = async () => {
                let sumFileSize = 0
                const fileArray: sessionFileType[] = FileDatas !== null ? FileDatas : []
                const insertFileData = fr.result !== null && typeof fr.result === 'string' ? fr.result.split(',')[1] : ""
                const tempFileData = { file_name_origin: file.name, file_data: insertFileData, file_size:file.size}
                fileArray.push(tempFileData)
                setFileData(fileArray)
                //ファイルデータが30Mb以上ないかチェック
                fileArray.forEach((file: {file_size: number}) => {
                    sumFileSize += file.file_size
                })
                if(sumFileSize > 31457281) return alert('ファイルを登録できません。ファイルの上限は合計30MBです。')
                 
            }
            fr.readAsDataURL(file);
        }

        const setNullValue = () => {
            target.value = null
        }
        //同じファイルを登録->消去->登録ができないバグを解消する
        setTimeout(()=>setNullValue(), 1000)
    }

    // アップロードしたファイルの削除
    const deleteFile = (num: number) => {
        if(FileDatas === null) return
        const newFileArray = FileDatas.filter((file: sessionFileType, index: number) => (index !== num))
        setFileData(newFileArray)
    }

    return {
        tip,
        tipChangeHandler,
        FileRef,
        loadFile,
        deleteFile,
    }
}

export default useInquiryFileHooks;