import styled from 'styled-components';
import { colors } from '../../../../css/variables/colors';
import { fonts } from '../../../../css/variables/fonts';
import UserIcon from '../../../../images/icon_user.svg';
import ZeusIcon from '../../../../images/icon_zeus.svg';
import { Box } from '../../../layouts/Box';
import InquiryFileItem from './InquiryFileItem';
import { FlexBox } from '../../../layouts/FlexBox';
import { sessionFileType } from '../../../../constance/typeModel';
import { inquiryCommentArrayType, inquiryCommentDataType } from '../../../../constance/providerTypeModel';
type inquiryCommentStyleType = {
  navigation?: boolean,
  index?: number,
}

const InquiryCommentStyle = styled.div`
  padding: ${(props: inquiryCommentStyleType) => props.navigation ? '8px 0 0 0;' : '28px 0;'} 
  ${(props: inquiryCommentStyleType) => props.index === 0 ? 'padding-top:0px;' : ''}
  ${(props: inquiryCommentStyleType) => props.index === 0 ? 'padding-bottom:0px;' : ''}
  ${(props: inquiryCommentStyleType) => props.navigation ? '' : `border-bottom: solid 1px ${colors.themeColor('border_box')};`}
  width:100%;
`

const UserDataStyle = styled.div`
  display: flex;
  .right {
    margin-top: 7px;
  }
`
type iconWrapperStyleType = {
  navigation?: boolean,
}

const IconWrapperStyle = styled.div`
  width:  ${(props: iconWrapperStyleType) => props.navigation ? '36px' : '60px'};
  height:  ${(props: iconWrapperStyleType) => props.navigation ? '36px' : '60px'} ;
  border-radius: ${(props: iconWrapperStyleType) => props.navigation ? '18px':'30px'};
  background: ${colors.themeColor('background_whitegray')};
  border: solid 1px ${colors.themeColor('border_box')};
  display: flex;
  justify-content: center;
  align-items: center;
`

const ZeusIconStyle = styled.img`
  width: 30px;
  height: 24px;
  overflow: visible;
`

const UserIconStyle = styled.img`
  width: 30px;
  height: 37.49px;
  overflow: visible;
`
type dateStyleType = {
  navigation?: boolean,
}
const DateStyle = styled.p`
  margin-top: ${(props: dateStyleType) => props.navigation ? '20' : '0'}px;
  margin-bottom: 0px;
  color: ${colors.themeColor('text_gray')};
  font-size: ${(props: dateStyleType) => props.navigation ? fonts.fontSizeRem('xxs') : fonts.fontSizeRem('s') };
`

type textStyleType = {
  navigation?: boolean,
}
const TextStyle = styled.p`
  margin-top: 6px;
  ${(props: textStyleType) => props.navigation ? '' : 'margin-bottom: 0px;'}
  font-size: ${(props: textStyleType) => props.navigation ? fonts.fontSizeRem('s') : fonts.fontSizeRem('ms')};
  white-space: pre-line;
  word-wrap: break-word;
`
type commentWrapperType = {
  navigation?: boolean,
}
const CommentWrapper = styled.div`
  margin-left: ${(props: commentWrapperType) => props.navigation ?  '12px' : '76px'};
`
const LeftFlexBox = styled.div`
  display:flex;
  gap: 0px;
  flex-direction: column;
  height:100%;
`
type heightLineType = {
  titleProps?: boolean,
}
const HeightLine = styled.div`
  margin-top:${(props: heightLineType) => props.titleProps ? '0' : '8'}px;
  height: 100%;
  width: 36px;
  padding-left: 16.5px;
`
const HeightLineStyle = styled.div`
  height:100%;
  width:1px;
  border: 1px solid ${colors.themeColor('border_box')};
`
const UserDataWrapper = styled.div`
  margin-left:16px;
`
const UserNameStyle = styled.p`
  margin-top: 8px;
  margin-bottom: 4px;
  font-weight: bold;
  font-size : ${fonts.fontSizeRem('ms')};
  word-break: break-all;
`
const InquiryCommentItems = ({
  commentData,
  navigation
}: {
  commentData: inquiryCommentArrayType,
  navigation?: boolean
}) => {

  // 日付の抽出関数
  const createdDate = (date: string) => {
    const tSplitDate = date.split('T')
    const dateText = tSplitDate[0].replace(/-/g, '/');
    const timeText = tSplitDate[1].substring(0,5);
    return dateText + ' ' + timeText
  };
  return <>
    {!navigation && commentData.map((comment: inquiryCommentDataType, i: number) => (
      <InquiryCommentStyle key={'nonNav'+i}>
        <UserDataStyle>
          <div className='left'>
            <IconWrapperStyle>
              {comment.created_by.name === 'merchant' ? <UserIconStyle src={UserIcon}/> : <ZeusIconStyle src={ZeusIcon} />}
            </IconWrapperStyle>
          </div>
          <UserDataWrapper>
            <UserNameStyle>{comment.created_by.name === 'merchant' ? comment.created_by.email : 'ZEUS'}</UserNameStyle>
            <DateStyle>{createdDate(comment.created_at)}</DateStyle>
          </UserDataWrapper>
          
        </UserDataStyle>
        <CommentWrapper navigation={navigation}>
          {/* アップロードしたファイルを表示する部分 */}
          {comment.files.map((value: sessionFileType, index: number) => (
            <InquiryFileItem
              key={'navFile'+index}
              file={value}
            />
          ))}
          <Box mt={20}/>
          <TextStyle>{comment.comment}</TextStyle>
        </CommentWrapper>
      </InquiryCommentStyle>
    ))}
    {navigation && commentData.map((comment: inquiryCommentDataType, i: number) => (
      <InquiryCommentStyle key={'nav'+i} navigation={navigation} index={i}>
        <UserDataStyle>
          <FlexBox gap={0}>
            <LeftFlexBox>
              {i !== 0 &&
                <>
                  <div className='left'>
                    <IconWrapperStyle navigation={navigation}>
                      {comment.created_by.name === 'merchant' ? <UserIconStyle src={UserIcon}/> : <ZeusIconStyle src={ZeusIcon}/>}
                    </IconWrapperStyle>
                  </div>
                  <HeightLine>
                    <HeightLineStyle/>
                  </HeightLine>
                </>
              }
              {i === 0 &&
                <HeightLine titleProps>
                  <HeightLineStyle/>
                </HeightLine>
              }
            </LeftFlexBox>
            <CommentWrapper navigation={navigation}>
                <TextStyle>{comment.comment}</TextStyle>
                {/* アップロードしたファイルを表示する部分 */}
                <Box mt={10}>
                  {comment.files.map((value: sessionFileType, index: number) => (
                    <InquiryFileItem
                      key={'file'+index}
                      file={value}
                    />
                  ))}
                </Box>
                <DateStyle navigation={navigation}>{createdDate(comment.created_at)}</DateStyle>
            </CommentWrapper>
          </FlexBox>
        </UserDataStyle>
        
      </InquiryCommentStyle>
    ))}
  </>;
};

export default InquiryCommentItems;