import React from 'react'
import { Container } from "../../../components/layouts/Container";
import { BackButton } from '../../../components/ui-parts/ocnk/BackButton';
import styled from 'styled-components';
import { colors } from '../../../css/variables/colors';
import { BackGround } from '../../../components/layouts/BackGround';
import AnotherTabLink from '../../../components/ui-parts/ocnk/AnotherTabLink';
import { FlexBox } from '../../../components/layouts/FlexBox';
import { mixins } from '../../../css/variables/mixins';
import { fonts } from '../../../css/variables/fonts';
import LinkByAgent from '../../../components/ui-parts/ocnk/LinkByAgent';

const TitlePhraseStyle = styled.h4`
  border-top: 1px solid ${colors.themeColor('border_box')};
  border-bottom: 1px solid ${colors.themeColor('border_box')};
  padding: 16px;
  font-size: ${fonts.fontSizeRem('l')};
  margin-bottom: 0px;
  margin-top: 0px;
`

const WholeWrap = styled.div`
  ${mixins.screenOver()}{
    padding-right: 240px;
  }
`
const ContentsWrap = styled.div`
  margin: 16px 0px 40px 32px;
  ${mixins.screenUnder()}{
    margin: 16px 0px 40px 0px;
  }
`
const MbZeroPhrase = styled.p`
  margin-bottom: 0px;
`

const UseStartPrecautions = () => {
  return (
    <Container isLogin={true}>
        <div className="mb32 align_right">
          <BackButton><LinkByAgent to={'/dashboard'}>ダッシュボードへ戻る</LinkByAgent></BackButton>
        </div>
        <h1>ご利用にあたってのご注意事項</h1>
        <h3 className='mb0 mt8'>この度はゼウス決済サービスにお申込みいただきましてありがとうございました。</h3>
        <h3 className='mb12 mt0'>サービス開始のご案内と、運用上の注意点についてご案内させていただきます。</h3>
        <BackGround>
          <WholeWrap>
            <TitlePhraseStyle>
                1. 「サービス開始のお知らせ」をご覧ください。
            </TitlePhraseStyle>
            <ContentsWrap>
            <FlexBox mt={0} gap={4}>
              <p>〇</p>
              <p className='newline'>{'売上管理画面の「WEB明細ダウンロード」よりサービス開始を示す\n「決済サービス開始のお知らせ」というPDFをダウンロードください。\n※数営業日後にご覧いただけるようになります。'}</p>
            </FlexBox>
            </ContentsWrap>
            <TitlePhraseStyle>
              ２．手数料・処理料のご請求と「明細書」についてご確認ください。
            </TitlePhraseStyle>
            <ContentsWrap>
              <FlexBox mt={0} gap={4}>
                <p>〇</p>
                <p className='newline'>{'毎月の売上金から相殺いたします。\n詳しくは明細書でご覧ください。'}</p>
              </FlexBox>
              <FlexBox mt={0} gap={4}>
                <p>〇</p>
                <p>締め毎のお振込金額は、明細書にてご確認ください。</p>
              </FlexBox>
              <FlexBox mt={0} gap={4}>
                <p>〇</p>
                <p>明細書は、売上管理画面の『WEB明細ダウンロード』よりPDFファイルをダウンロードください。</p>
              </FlexBox>
              <FlexBox mt={0} gap={4}>
                <p>〇</p>
                <p className='newline'>{'また、明細書の対象となる決済データにつきましては『明細データダウンロード』よりダウンロードください。\n締め日後、【　5営業日目　】にご確認いただけます。'}</p>
              </FlexBox>
            </ContentsWrap>
            <TitlePhraseStyle>
              ３．チャージバックリスクについてご確認ください。
            </TitlePhraseStyle>
            <ContentsWrap>
              <FlexBox mt={0} gap={4}>
                <p>〇</p>
                <p className='newline'>{'インターネットを利用したカード決済において、他人のカード情報を不正に入手し利用する等の不正使用によりカードホルダーがクレジットカードでの支払いに「同意」しない場合、クレジットカード会社から加盟店様への売上金のお支払いがされません。\nこれを「チャージバック」といいます。'}</p>
              </FlexBox>
              <FlexBox mt={0} gap={4}>
                <p>〇</p>
                <p>チャージバックが発生した場合、ゼウス「クレジット決済サービス利用規約」（第12条　債権譲渡又は立替払いの取消又は解除）に基づき、チャージバック対象の債権（売上金）は加盟店様のご負担となります。</p>
              </FlexBox>
            </ContentsWrap>
            <TitlePhraseStyle>
              ４．3Dセキュアの注意事項 
            </TitlePhraseStyle>
            <ContentsWrap>
              <FlexBox mt={0} gap={4}>
                <p>〇</p>
                <div>
                  <p className='newline'>{'「EMV３-Dセキュア」を導入いただくことで、チャージバックリスクを回避でき、\n原則そのリスク負担はカード会社になります。'}</p>
                  <p>{'ただし、以下の場合は対象外となり、加盟店様負担となりますのでご注意ください。'}</p>
                  <MbZeroPhrase>{'＜対象外の取引＞ '}</MbZeroPhrase>
                  <MbZeroPhrase>{'・加盟店様にて金額変更や再決済処理を行った場合 '}</MbZeroPhrase>
                  <MbZeroPhrase>{'・加盟店様にて継続決済を行った場合 '}</MbZeroPhrase>
                  <MbZeroPhrase>{'・加盟店様からユーザーへ商品'}</MbZeroPhrase>
                  <MbZeroPhrase>{'・サービス提供が適切にされていない場合'}</MbZeroPhrase>
                  <MbZeroPhrase>{'・カード会社からの利用内容調査に加盟店様が未回答の場合'}</MbZeroPhrase>
                  <MbZeroPhrase>{' 　ゼウスよりご連絡する利用調査依頼に加盟店様が期日内に対応されなかった場合 '}</MbZeroPhrase>
                  <p>{'・カード会社がチャージバックと判断した場合'}</p>
                  <MbZeroPhrase>{'※チャージバックリスクとは：'}<AnotherTabLink href={'https://www.cardservice.co.jp/support/beginner/begin_33.html'}>https://www.cardservice.co.jp/support/beginner/begin_33.html</AnotherTabLink></MbZeroPhrase>
                  <p>{'※対象外の取引はゼウスサポートページでもご確認ください：'}<AnotherTabLink href={'https://support.cardservice.co.jp/hc/ja/articles/24206837679001'}>https://support.cardservice.co.jp/hc/ja/articles/24206837679001</AnotherTabLink></p>
                </div>
              </FlexBox>
            </ContentsWrap>
            <TitlePhraseStyle>
              ５．サービス開始後のお問い合わせ先
            </TitlePhraseStyle>
            <ContentsWrap>
              <p>今後、ご不明点が発生した場合には、下記をご確認ください。</p>
              <p className='mb0'>●加盟店様サポートページ</p>
              <AnotherTabLink href={'https://support.cardservice.co.jp/hc/ja'}>https://support.cardservice.co.jp/hc/ja</AnotherTabLink>
              <FlexBox mt={0} gap={4}>
                <p>⇒</p>
                <p>{'マニュアルや運用上のよくあるご質問を記載させていただいております。'}</p>
              </FlexBox>
              <p className='mb0'>●お問合せフォーム</p>
              <AnotherTabLink href={'https://www.cardservice.co.jp/contactus/#anchor-02'}>https://www.cardservice.co.jp/contactus/#anchor-02</AnotherTabLink>
              <FlexBox mt={0} gap={4}>
                <p>⇒</p>
                <p>{'追加お申込みのご相談・決済に関するお問い合わせを受け付けております。\n登録加盟店名とご担当者様名、IPコードを必ずお伝えの上でお問い合わせください。\n電話でのお問合せも承っておりますので、ご不明点ございましたらマニュアル等よりお電話番号をご確認くださいませ。'}</p>
              </FlexBox>
            </ContentsWrap>
          </WholeWrap>
        </BackGround>
    </Container>
  );
};

export default UseStartPrecautions