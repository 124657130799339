import React from 'react'
import { Container } from "../../../components/layouts/Container";
import { Box } from '../../../components/layouts/Box';
import { TitleElement, ExaminationStatusElement, ExminationCancelBarElement } from '../../../components/ui_elements';
import useExaminationStatusHooks from '../../../hooks/useExaminationStatusHooks';
import { BackButton } from '../../../components/ui-parts/ocnk/BackButton';
import useNavigateByAgent from '../../../hooks/useNavigateByAgentHooks';

const ExaminationStatus = () => {
  const {navigateByAgent} = useNavigateByAgent()
  const { allServiceData, serviceCodeList, creditOrderArray, cvsOrderArray, serviceStopFlag } = useExaminationStatusHooks(navigateByAgent) 
  return (
    <Container
      isLogin
      isInquiry
    >
      <TitleElement />
      <div className="mb8 align_right display-mobile">
        <BackButton onClick={()=>navigateByAgent('/dashboard')}>ダッシュボードに戻る</BackButton>
      </div>
      <ExminationCancelBarElement/>
      <Box mt={18}/>
      <ExaminationStatusElement
        examination={allServiceData}
        serviceCodeList={serviceCodeList}
        creditOrderArray={creditOrderArray}
        cvsOrderArray={cvsOrderArray}
        serviceStopFlag={serviceStopFlag}
      />
    </Container>
  );
};

export default ExaminationStatus