import { useContext,useEffect, useState } from "react";
import { Context } from '../stores/Provider';
import { PostMethod } from '../api/Api';
import { getSession } from "../sessionStorage/sessionStorageMethod";
import { logoutAbnormalFunction } from "../function/logoutAbnormalFunction";
import { initApiModelType, userAuthRequestType, userAuthResponseType } from "../constance/typeModel";
import { initApiResponse } from "../constance/constance";
import useNavigateByAgent from "./useNavigateByAgentHooks";
import { useNavigate } from "react-router-dom";

const useUserAuthHooks = (agentPath: string|undefined = "") => {
    const navigate = useNavigate()
    const {navigateByAgent} = useNavigateByAgent()
    const {state,dispatch} = useContext(Context)
    const userInfoData = getSession('user')
    const [flagState, setFlagState] = useState<boolean | null>(null)
    // 認証に成功したらそのままページを表示する
    const AuthCheck = () => {
        useEffect(()=>{
            const authAPIHandler = async() => {
                let response: initApiModelType<userAuthResponseType> = initApiResponse
                const data = { user_id : userInfoData !== null ? userInfoData.user_id : ""}
                response = await PostMethod<userAuthRequestType, userAuthResponseType>(data,'/user_auth')
                if(response.code !== 200){
                    if(response.code === 401){
                        //ログインした情報があればログアウト
                        if(userInfoData !== null && userInfoData.code === 200) return logoutAbnormalFunction(response,dispatch,navigateByAgent,state.agentPath)
                        dispatch({
                            type: 'set-context-data',
                            payload: {
                                auth: {
                                    err_message: response.message
                                }
                            }
                        })
                        setFlagState(false)
                        return navigate(agentPath+'/login')
                    }
                    return navigate(agentPath+'/system_error')
                } else if (response.code === 200 && response.response_data){
                    let closeScheduleDate: null | string = null
                    if (response.response_data.confirm_date !== null){
                        const confirmDateData = response.response_data.confirm_date.split(" ")[0].split('-')
                        const confirmYear = Number(confirmDateData[0])
                        const confirmMonth = Number(confirmDateData[1])
                        const confirmDate = Number(confirmDateData[2])
                        const confirmDay = new Date(confirmYear,confirmMonth - 1, confirmDate)
                        confirmDay.setDate(confirmDay.getDate() + 30)
                        closeScheduleDate = confirmDay.getFullYear() + "年" + (confirmDay.getMonth() + 1) + "月" + confirmDay.getDate() + "日"
                    }
                    dispatch({
                        type: 'set-context-data',
                        payload: {
                            user_status: {
                                status : response.response_data.status,
                                before_status : response.response_data.before_status,
                                close_schedule_date: closeScheduleDate,
                                confirm_flag: response.response_data.confirm_flag,
                                cancel_flag : response.response_data.cancel_flag
                            }
                        }
                    })
                    setFlagState(true)
                }
            }
            authAPIHandler()
        },[])
        if(flagState === null) return false
        return flagState
    }
    return { AuthCheck }
}

export default useUserAuthHooks