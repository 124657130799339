import { Container } from "../../../components/layouts/Container"
import EconomicEstimateElement from "../../../components/ui_elements/ocnk/Economic/EconomicEstimateElement"

const EconomicEstimate = () => {

	return (
		<Container isLogin={true} background={'default'} align={'top'}>
			<EconomicEstimateElement/>
		</Container>
	)
}

export default EconomicEstimate