import { PostMethod } from '../api/Api';
import { useContext } from 'react';
import { getSession, setSession, setUserInfoData } from '../sessionStorage/sessionStorageMethod';
import { Context } from '../stores/Provider';
import { logoutAbnormalFunction, refreshMethods } from '../function/logoutAbnormalFunction';

const useLogoutHooks = (navigateByAgent: Function) => {
    const {state,dispatch} = useContext(Context)

    const user = getSession('user')
    // ログアウトに成功したら、login画面に遷移させる
    const Logout = async() => {
        setUserInfoData({...user, code:""})
        const data = ""
        const response = await PostMethod<string, undefined>(data, '/logout');
        return response
    }

    //正常時処理
	const normal = (response: {
        code: number,
        message: string,
        response_data: {
            message: string
        }
    }) => {
        let loginData: {
            code: string,
            err_message: string,
        } = {
            code: "",
            err_message: response.message,
        }
        refreshMethods(dispatch,state.agentPath)
        // フロント側のセッションにアクセス状況を登録
        setSession('login', loginData)
		return navigateByAgent('/login')
	}

	// 異常時処理
	const abnormal = (response: {
        code: number,
        message: string,
        response_data: {
            message: string
        }
    }) => {
        return logoutAbnormalFunction(response,dispatch,navigateByAgent,state.agentPath)
	}

    return { Logout, normal, abnormal }
}

export default useLogoutHooks;