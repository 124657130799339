import { useContext,useEffect, useMemo, useState } from "react";
import { Context } from '../stores/Provider';
import { initApiModelType, loginResponseDataType, useRedirectAuthRequestType } from "../constance/typeModel"
import { initApiResponse } from "../constance/constance"
import { getSession, setAgentMasterInfoData, setBankAccountInfoData, setEconomicConditionMasterInfoData, setEconomicDevidedInfoData, setEconomicInfoData, setEntryCheckInfoData, setFileInfoData, setLoginInfoData, setMerchantInfoData, setOperationInfoData, setRepresentativeInfoData, setReviewContentMasterInfoData, setReviewContentsDivisionInfoData, setShopInfoData, setSumFeeInfoData, setSystemSettingMasterInfoData, setUserInfoData } from "../sessionStorage/sessionStorageMethod";
import { PostMethod } from "../api/Api";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { initUserSessionData, initMerchantSessionData, initOperationSessionData, initRepresentativeSessionData, initShopSessionData, initFileSessionData, initBankAccountSessionData, initReviewContentsMasterSessionData, initAgentMasterSessionData, initReviewContentsDivisionSessionData, initEconomicSessionData, initEntryCheckData, initEconomicConditionMasterSessionData, initSumFeeSessionData } from "../constance/sessionData";
import { setIsLoading } from "../function/setIsLoading";
import { economicConditionMasterInsertHandler, economicInsertHandler, entryDataSuitHandler, reviewContentInsertHandler, setEconomicFlagBySystemSetting, setServiceShowFlagHandler, systemSettingMasterSetHandler } from "../function/loginFunction";

const useRedirectAuthHooks = (agentList: string[]) => {
    const location = useLocation()
    const navigate = useNavigate()
    const {state,dispatch} = useContext(Context)
    const paramsArray = location.search.substring(1).split('&')
    const [flagState, setFlagState] = useState<boolean | null>(null)
    //セッションデータ抽出
	const userInfoData = getSession('user')
    //URLパラメータからauth_hashの値を取り出す
    const authHash = useMemo(()=>{
        let result: string  = ""
        paramsArray.forEach((param)=>{
            if(param.includes('auth_hash=')) result = param.split('auth_hash=')[1]
        })
        return result
    },[paramsArray])
    
    //リダイレクト認証APIを叩き、レスポンスを受け取る
    const RedirectAuthCheck = () => {
        useEffect(()=>{
            // レンダリング時１一度のみ実行
            const redirectAuthAPIHandler = async() => {
                let response: initApiModelType<loginResponseDataType> = initApiResponse
                // dataにはauth_hash,agent_master_idをいれる
                const data = {
                    //agent_master_idの取得の仕方は課題
                    agent_master_id_list : agentList,
                    auth_hash : authHash,
                }
                response = await PostMethod<useRedirectAuthRequestType, loginResponseDataType>(data,'/ocnk_user_redirect_auth')
                
                if(response.code !== 200){
                    if(response.code === 401){
                        //認証失敗
                        setFlagState(false)
                        return navigate(state.agentPath+'/system_error')
                    }
                    //システムエラー
                    setFlagState(false)
                    return navigate(state.agentPath+'/system_error')
                } else if (response.code === 200 && response.response_data){
                    //レスポンスデータをsessionにセット
                    normal(response)
                    setFlagState(true)
                }
            }
            // 再読み込み時に再度認証を叩かないようにする処理
            if(!userInfoData || !userInfoData.redirect_auth_flag){
                redirectAuthAPIHandler()
            } else {
                setFlagState(true)
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps  
        },[])
        if(flagState === null) return false
        return flagState
    }

    //正常時処理
    const normal = (response: initApiModelType<loginResponseDataType>) => {
        const responseData = response.response_data
        if(responseData === undefined) return
        const systemSettingData = systemSettingMasterSetHandler(responseData)
        const {merchantData,representativeData,operatorData,bankObj,reviewContentInsert} = entryDataSuitHandler(responseData)
        const economicConditionMasterData = economicConditionMasterInsertHandler(responseData, initEconomicConditionMasterSessionData)
        let preEconomicData = economicInsertHandler(responseData,initEconomicSessionData,responseData.masters.agent_master.demand_code)
        //経済条件マスタに項目がない場合表示しない制御をするフラグ
        const economicDividedData = setServiceShowFlagHandler(economicConditionMasterData) 
        const reviewContentMasterPlusShopAddition = reviewContentInsertHandler(responseData,reviewContentInsert)
        const economicData = setEconomicFlagBySystemSetting(systemSettingData,preEconomicData)

        //ユーザリコイルセット
	if(typeof responseData.status === "string" ) return
        setUserInfoData({
            ...initUserSessionData, 
            email:responseData.email,
            user_id:responseData.user_id, 
            entry_id:responseData.entry_id, 
            agent_master_id:responseData.agent_master_id,
            ip_flag:responseData.ip_flag,
            redirect_auth_flag: true,
            ip_code:responseData.status.ip_code
        })
        //エージェントマスターリコイルセット
        setAgentMasterInfoData({
            ...initAgentMasterSessionData,
            ...responseData.masters.agent_master
        })
        //システム設定情報マスタリコイルセット
        setSystemSettingMasterInfoData({
            ...setSystemSettingMasterInfoData,
            ...systemSettingData
        })
        //ショップ情報マスタリコイルセット
        setReviewContentMasterInfoData({
            ...initReviewContentsMasterSessionData,
            ...responseData.masters.shop_addition_masters
        })
        // 経済条件マスタ初期値リコイルセット
        setEconomicConditionMasterInfoData(economicConditionMasterData)
        //ショップ情報の格納
        setShopInfoData({
            ...initShopSessionData,
            ...reviewContentMasterPlusShopAddition
        })
        // 経済条件初期値リコイルセット
        setEconomicInfoData(economicData)
        //お客様情報リコイルセット
        setMerchantInfoData({
            ...initMerchantSessionData,
            ...merchantData
        })
        //代表者情報リコイルセット
        setRepresentativeInfoData({
            ...initRepresentativeSessionData,
            ...representativeData
        })
        //担当者情報リコイルセット
        setOperationInfoData({ 
            ...initOperationSessionData,
            ...operatorData 
        })
        //口座情報リコイルセット
        setBankAccountInfoData({ 
            ...initBankAccountSessionData,
            ...bankObj 
        })
        //ファイル情報リコイルセット
        setFileInfoData({
            ...initFileSessionData, 
            ...responseData.entry_info_file
        })
        
        // 審査コンテンツ出し分け初期値リコイルセット
        setReviewContentsDivisionInfoData(initReviewContentsDivisionSessionData)
        // 申込完了フラグ初期値リコイルセット
        setEntryCheckInfoData(initEntryCheckData)
        // 合計料金初期値セット
        setSumFeeInfoData(initSumFeeSessionData)
        //マスタによる経済条件だしわけ情報
        setEconomicDevidedInfoData(economicDividedData)

        let loginInfoData = {
            err_message: '',
            code: response.code
        }
        
        //ログインチェックで使用
        setLoginInfoData(loginInfoData)

        //ローディング画面を非表示
        setIsLoading(false,dispatch)

        return 
    }

    return { RedirectAuthCheck }

}

export default useRedirectAuthHooks
