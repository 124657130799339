import { useEffect, useState } from "react";
import ErrorBoundary from "../../../api/ErrorBoundary";
import useLimitDirectAccess from "../../../hooks/useLimitDirectAccess";
import { useLoginCheckHooks } from "../../../hooks/useLoginCheckHooks";
import useUserAuthHooks from "../../../hooks/useUserAuthHooks";
import { DashBoard } from "../renderPages/DashBoard";
import useGTMHooks from "../../../hooks/useGTMHooks";

const DashBoardBeforeRender = () => {

  const { LoginCheck } = useLoginCheckHooks();
  const { AuthCheck } = useUserAuthHooks();
  const { DirectAccessCheck } = useLimitDirectAccess()
  const [directFlag, setDirectFlag] = useState<boolean>(false)
  const [loginFlag, setLoginFlag] = useState<boolean>(false)
  const { pushDataLayerHandler } = useGTMHooks()
  

  //ログイン確認+URL直叩きチェックフラグをセット
  useEffect(()=>{
    pushDataLayerHandler()
    setLoginFlag(LoginCheck())
    setDirectFlag(DirectAccessCheck('/dashboard')) 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  if(!AuthCheck() || !directFlag || !loginFlag) return(<></>)

  return (
    <ErrorBoundary>
      <DashBoard />
    </ErrorBoundary>
  )
}

export default DashBoardBeforeRender