import styled from "styled-components"
import { colors } from "../../../../css/variables/colors"
import { mixins } from "../../../../css/variables/mixins"

const ConfirmFormWrap = styled.div`
    max-width:1120px;
    background: ${colors.themeColor('background_main')};
    border-radius: 8px;
    border: 1px solid ${colors.themeColor('border_box')};
    padding:40px 48px;
    ${mixins.screenUnder()}{
        padding: 28px 16px;
    }
    h3{
        font-weight:600;
        margin-top:28px;
    }
`

const OcnkBankExplainBox = () => (
    <ConfirmFormWrap>
        <h3 className='mt0'>
            ご利用中のゼウスIPコードと同一の口座情報が設定されます
        </h3>
        <p>※IPコードは、ショップ情報「ご利用中IPコード」に表示されています。</p>
        <p>※口座情報は、定期購入プランIPコード稼働後に、ゼウス売上管理画面に公開されます 「決済サービス開始のお知らせ」(pdf)でご確認いただけます。</p>
        <p className='mb0'>※口座情報を変更されたい場合は、定期購入プランIPコード稼働後に変更のお手続きが可能となりますので、ゼウスまでお問い合わせください。</p>
    </ConfirmFormWrap>
)

export default OcnkBankExplainBox