import ErrorBoundary from "../../../api/ErrorBoundary";
import { ocnkTeikiPlanLists } from "../../../constance/constance";
import useRedirectAuthHooks from "../../../hooks/useRedirectAuthHooks";
import OcnkTeikiRedirectEconomic from "../renderPages/RedirectEconomic";

const OcnkTeikiRedirectEconomicBeforeRender = ({
  redirect
}:{
  redirect?: boolean
}) => {
    // 代理店設定
    const agentList = ocnkTeikiPlanLists
    // リダイレクト認証APIを実行
    const {RedirectAuthCheck} = useRedirectAuthHooks(agentList);

    // 認証系判定
    if(!RedirectAuthCheck()) return(<></>)

    return(
      <ErrorBoundary>
        <OcnkTeikiRedirectEconomic redirect={redirect}/>
      </ErrorBoundary>
    )
}

export default OcnkTeikiRedirectEconomicBeforeRender