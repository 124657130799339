import React from 'react';
import styled from 'styled-components'
import { colors } from '../../css/variables/colors';

const FooterWrapper = styled.footer`
`;
const FooterBottom = styled.div`
  border-top: 1px solid ${colors.themeColor('border_box')};
  font-size:10px;
  color: ${colors.themeColor('text_gray')};
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Footer = () => (
  <FooterWrapper>
    <FooterBottom>
      Copyright（C） ZEUS CO.,LTD.All Rights Reserved.
    </FooterBottom>
  </FooterWrapper>
);
