import styled from 'styled-components'
import { colors } from '../../../css/variables/colors';
import AnotherTabLink from './AnotherTabLink';
import { ReactNode } from 'react';
import LinkByAgent from './LinkByAgent';

const Wrapper = styled.div`
  padding-left: 16px;
  position: relative;
  color: ${colors.themeColor('text_link')};
  &:before {
    content: '';
    width: 0px;
    height: 0px;
    border-left: solid 10px ${colors.themeColor('text_link')};
    border-right: solid 6px transparent;
    border-top: solid 6px transparent;
    border-bottom: solid 6px transparent;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`

export const ArrowLink = ({
  children,
  link,
  href
}: {
  children?: ReactNode,
  link: string,
  href?: boolean
}) => {
  return (
    <Wrapper>
      {href ?
        <AnotherTabLink href={link}>{children}</AnotherTabLink>
      :
        <LinkByAgent to={link}>{children}</LinkByAgent>
      }
    </Wrapper>
  );
};