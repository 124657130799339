import React, { ReactNode } from 'react';

export const EntryStepBox = ({
  content_number = 1,
  canceledFlag = false,
  children,
  step,
  errorStep,
}: {
  children: ReactNode,
  step: number,
  content_number: number,
  errorStep?: number,
  canceledFlag?: boolean
}) => {
  return (
    <li className={`${content_number === step ? 'current' : ''} ${content_number < step ? 'checked' : ''} ${errorStep === content_number ? 'error' : ''} step`}>
      <div className='box'>
        <div className={`box_inner ${canceledFlag ? 'relative' : '' }`}>
          {children}
          <div className={canceledFlag ? 'grayout' : '' }/>
        </div>
      </div>
    </li>
  )
}
