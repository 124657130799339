import styled from 'styled-components'
import { colors } from '../../../css/variables/colors'
import { mixins } from '../../../css/variables/mixins'
import { fonts } from '../../../css/variables/fonts'
import EntryGuideWhiteIcon from '../../../images/EntryGuideWhite.svg'

type entryGuideWrapperType = { path: string }
const EntryGuideWrapper = styled.button<entryGuideWrapperType>`
  z-index: 990;
  box-sizing: border-box;
  position: fixed;
  bottom: 60px;
  right: 20px;
  height: 48px;
  background-color: ${colors.themeColor('background_button_primary')};
  border: 1px solid ${colors.themeColor('border_box')};
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  padding: 12px 16px;
  gap: 8px;
  cursor: pointer;
  ${mixins.screenUnder()}{
    bottom: 16px;
    right: 8px;
    ${props => props.path.includes('entry/') || props.path.includes('redirect/') ? "bottom: 68px;" : ""}
  }
`

const EntryGuideText = styled.p`
  font-size: ${fonts.fontSizeRem('s')};
  color: ${colors.themeColor('text_button_primary')};
  margin-bottom: 0px;
`

const EntryGuide = ({
    path
}: {
    path: string
}) => {
    // 申し込みガイドの遷移
    const navigateEntryGuide = () => {
        window.open("https://www.cardservice.co.jp/partner/ocnk02/guide/", '_blank', 'noopener=yes')
    }
    return (
        <EntryGuideWrapper onClick={navigateEntryGuide} path={path}>
            <img src={EntryGuideWhiteIcon} alt='EntryGuideIcon' />
            <EntryGuideText>申し込みガイド</EntryGuideText>
        </EntryGuideWrapper>
    )
}

export default EntryGuide
