import styled from "styled-components";
import { colors } from "../../../../css/variables/colors";
import { FlexBox } from "../../../layouts/FlexBox";
import { FormWrap } from "../../../layouts/FormWrap";
import { Checkbox } from "../../../ui-parts/ocnk/Checkbox";
import { Button } from "../../../ui-parts/ocnk";
import useEconomicEstimateElementHooks from "../../../../hooks/useEconomicEstimateElementHooks";
import AnotherTabLink from "../../../ui-parts/ocnk/AnotherTabLink";
import useNavigateByAgent from "../../../../hooks/useNavigateByAgentHooks";
import { fonts } from "../../../../css/variables/fonts";
import { mixins } from "../../../../css/variables/mixins";
import OcnkTeikiEconomicEstimateTable from "../../../ui-parts/ocnkTeiki/EconomicEstimate/EconomicEstimateTable";

const BlueWraper = styled.div`
	background-color:${colors.themeColor('background_whitegray')};
	border: solid 1px ${colors.themeColor('border_box')};
	border-radius: 8px;
	padding:20px;
`

const UL = styled.ul`
  list-style: inherit;
  padding-left:20px;
  li {
	font-size:${fonts.fontSizeRem('ms')};
	${mixins.screenUnder()}{
		font-size:${fonts.fontSizeRem('xs')}
	}
  }
`
const OcnkTeikiEconomicEstimateElement = ({
	redirect
}:{
	redirect?: boolean
}) => {
	const {navigateByAgent} = useNavigateByAgent()
    const {feeArray,EconomicInfoData,sumFeeInfoData,vacantCheckState,creditPaymentSiteFeeState,agentMasterInfoData,setCheckBoxStateHandler,navigateBack,ocnkTeikiNavigateHandler
	} = useEconomicEstimateElementHooks(navigateByAgent,redirect)

    return(
        <FormWrap width={700} padding={36}>
			<>
				<h2 className="mt0">このページ以降はZEUSのWeb申込システム画面です</h2>
				<h1 className="mt0">ご利用料金</h1>
				<p>ご利用料金は以下の通りです。</p>
				<h2 className="mt40 mb20">クレジットカード決済({agentMasterInfoData.agent_name})</h2>
				<OcnkTeikiEconomicEstimateTable 
					contentName={'credit'} 
					sumSystemCost={sumFeeInfoData.credit} 
					options={feeArray.credit}
					salesHandleFee
					paymentSiteFee={creditPaymentSiteFeeState}
					page={'economic_estimate'}
				/>

				<UL>
					<li>上記料金はすべて税別表記となります。</li>
					<li>支払サイト：支払日が金融機関休業日の場合、翌営業日の支払いとさせていただきます。</li>
					<li>支払サイトの<span className="bold">振込手数料はゼウス負担</span>となります。</li>
					<li>手数料の支払い方法は、各月の売上金から相殺となります。</li>
				</UL>
				
				<h2 className="mt40 mb20">お申し込みの前にご確認ください</h2>
				<BlueWraper>
					<FlexBox column gap={4} mt={0}>
						<AnotherTabLink href={"https://www.cardservice.co.jp/guide/preparation.html#anchor-08"}>取扱不可商材について</AnotherTabLink>
						{(EconomicInfoData.cvs.system_setting.select_flag) && 
							<AnotherTabLink href={"https://www.cardservice.co.jp/guide/cvs.html"}>コンビニサービス導入基準</AnotherTabLink>
						}
					</FlexBox>
				</BlueWraper>

				<h2 className="mt40 mb0">規約等のご確認と同意</h2>
				<p className="font-ms mt16">お申し込みには各種利用規約等への同意が必要です。</p>
				<BlueWraper>
					<FlexBox column gap={4} mt={0}>
					<AnotherTabLink href={"https://www.cardservice.co.jp/zmc/kiyaku/pdf/notation_privacy.pdf"}>個人情報の取扱いについて</AnotherTabLink>
						<AnotherTabLink href={"https://www.cardservice.co.jp/zmc/kiyaku/pdf/notation_kyotsu.pdf"}>ゼウス決済サービス共通規約</AnotherTabLink>
						{EconomicInfoData.credit.system_setting.select_flag &&
							<AnotherTabLink href={"https://www.cardservice.co.jp/zmc/kiyaku/pdf/notation_creditcard.pdf"}>クレジット決済サービス利用規約（包括加盟型 ）</AnotherTabLink>
						}
					</FlexBox>
					<FlexBox column gap={4} mt={30}>
						<Checkbox id='agreement' label={'規約等へ同意します'} onChange={setCheckBoxStateHandler} iserror={vacantCheckState.agreement_msg}/>
						<Checkbox id='charges_check' label="料金及び取引手数料を確認しました"  onChange={setCheckBoxStateHandler} iserror={vacantCheckState.charges_check_msg}/>
					</FlexBox>
				</BlueWraper>
				<FlexBox mt={24} gap={16}>
					{!redirect &&
						<Button variant="secoundary" onClick={navigateBack}>戻る</Button>
					}
					<Button onClick={ocnkTeikiNavigateHandler} >申し込み情報入力に進む</Button>
				</FlexBox>
			</>
		</FormWrap>
    )
}

export default OcnkTeikiEconomicEstimateElement
