import { createGlobalStyle } from 'styled-components';
import { colors } from './variables/colors';
import { fonts } from './variables/fonts';
import { mixins } from './variables/mixins';

const GlobalStyle = createGlobalStyle`

  /*
  *reset 
  */
  
  *,
  *::before,
  *::after{
      box-sizing: border-box;
  }
  ol, ul {
      list-style: none;
  }
  img {
      max-width: 100%;
  }
  table{
      border-collapse: collapse;
  }

  /*
  * site
  */
  body,html{
    height: 100%;
  }
  body {
    margin:0;
    padding:0;
    font-family: ${fonts.fontFamily};
    color:${colors.themeColor('text_main')};
    background: #333;
    background-color: ${colors.themeColor('background_main')};
  }
  a{
    color:${colors.themeColor('text_link')};
    text-decoration: none;
  }


  h1{
    font-size: ${fonts.fontSizeRem('h1_desktop')};
    ${mixins.screenUnder()}{
      font-size:${fonts.fontSizeRem('h1_mobile')}; ;
    }
    margin: 10px 0;
  }

  h2{
    font-size: ${(22 / fonts.fontSizeBase()) + 'rem'};
    margin-top: 36px;
    margin-bottom: 4px;
    border-bottom: 1px solid ${colors.themeColor('border_box')};
    padding-bottom: 10px;
    ${mixins.screenUnder()}{
      font-size: ${(20 / fonts.fontSizeBase()) + 'rem'};
      margin-top: 32px;
    }

  }

  p{
    margin: 0 0 16px 0;
  }

  hr{
    margin: 28px 0;
    border: none;
    height: 1px;
    width: 100%;
    background: ${colors.themeColor('border_box')};
  }

  br{
    ${mixins.screenOver()}{
      display:none;}
  }

  br.all{
    display:inline;
  }


  //レイアウト
  #root{
    height: 100%;
  }

  /*
  *　ユーティリティ
  */
  /*
  * margin/padding
  */
  .mt0 {
  margin-top: 0px !important;
  }

  .mb0 {
  margin-bottom: 0px !important;
  }

  .ml0 {
  margin-left: 0px !important;
  }

  .mr0 {
  margin-right: 0px !important;
  }

  .pt0 {
  padding-top: 0px !important;
  }

  .pb0 {
  padding-bottom: 0px !important;
  }

  .pl0 {
  padding-left: 0px !important;
  }

  .pr0 {
  padding-right: 0px !important;
  }

  .mt4 {
  margin-top: 4px !important;
  }

  .mb4 {
  margin-bottom: 4px !important;
  }

  .ml4 {
  margin-left: 4px !important;
  }

  .mr4 {
  margin-right: 4px !important;
  }

  .pt4 {
  padding-top: 4px !important;
  }

  .pb4 {
  padding-bottom: 4px !important;
  }

  .pl4 {
  padding-left: 4px !important;
  }

  .pr4 {
  padding-right: 4px !important;
  }

  .mt8 {
  margin-top: 8px !important;
  }

  .mb8 {
  margin-bottom: 8px !important;
  }

  .ml8 {
  margin-left: 8px !important;
  }

  .mr8 {
  margin-right: 8px !important;
  }

  .pt8 {
  padding-top: 8px !important;
  }

  .pb8 {
  padding-bottom: 8px !important;
  }

  .pl8 {
  padding-left: 8px !important;
  }

  .pr8 {
  padding-right: 8px !important;
  }

  .mt12 {
  margin-top: 12px !important;
  }

  .mb12 {
  margin-bottom: 12px !important;
  }

  .ml12 {
  margin-left: 12px !important;
  }

  .mr12 {
  margin-right: 12px !important;
  }

  .pt12 {
  padding-top: 12px !important;
  }

  .pb12 {
  padding-bottom: 12px !important;
  }

  .pl12 {
  padding-left: 12px !important;
  }

  .pr12 {
  padding-right: 12px !important;
  }

  .mt16 {
  margin-top: 16px !important;
  }

  .mb16 {
  margin-bottom: 16px !important;
  }

  .ml16 {
  margin-left: 16px !important;
  }

  .mr16 {
  margin-right: 16px !important;
  }

  .pt16 {
  padding-top: 16px !important;
  }

  .pb16 {
  padding-bottom: 16px !important;
  }

  .pl16 {
  padding-left: 16px !important;
  }

  .pr16 {
  padding-right: 16px !important;
  }

  .mt20 {
  margin-top: 20px !important;
  }

  .mb20 {
  margin-bottom: 20px !important;
  }

  .ml20 {
  margin-left: 20px !important;
  }

  .mr20 {
  margin-right: 20px !important;
  }

  .pt20 {
  padding-top: 20px !important;
  }

  .pb20 {
  padding-bottom: 20px !important;
  }

  .pl20 {
  padding-left: 20px !important;
  }

  .pr20 {
  padding-right: 20px !important;
  }

  .mt24 {
  margin-top: 24px !important;
  }

  .mb24 {
  margin-bottom: 24px !important;
  }

  .ml24 {
  margin-left: 24px !important;
  }

  .mr24 {
  margin-right: 24px !important;
  }

  .pt24 {
  padding-top: 24px !important;
  }

  .pb24 {
  padding-bottom: 24px !important;
  }

  .pl24 {
  padding-left: 24px !important;
  }

  .pr24 {
  padding-right: 24px !important;
  }

  .mt28 {
  margin-top: 28px !important;
  }

  .mb28 {
  margin-bottom: 28px !important;
  }

  .ml28 {
  margin-left: 28px !important;
  }

  .mr28 {
  margin-right: 28px !important;
  }

  .pt28 {
  padding-top: 28px !important;
  }

  .pb28 {
  padding-bottom: 28px !important;
  }

  .pl28 {
  padding-left: 28px !important;
  }

  .pr28 {
  padding-right: 28px !important;
  }

  .mt32 {
  margin-top: 32px !important;
  }

  .mb32 {
  margin-bottom: 32px !important;
  }

  .ml32 {
  margin-left: 32px !important;
  }

  .mr32 {
  margin-right: 32px !important;
  }

  .pt32 {
  padding-top: 32px !important;
  }

  .pb32 {
  padding-bottom: 32px !important;
  }

  .pl32 {
  padding-left: 32px !important;
  }

  .pr32 {
  padding-right: 32px !important;
  }

  .mt36 {
  margin-top: 36px !important;
  }

  .mb36 {
  margin-bottom: 36px !important;
  }

  .ml36 {
  margin-left: 36px !important;
  }

  .mr36 {
  margin-right: 36px !important;
  }

  .pt36 {
  padding-top: 36px !important;
  }

  .pb36 {
  padding-bottom: 36px !important;
  }

  .pl36 {
  padding-left: 36px !important;
  }

  .pr36 {
  padding-right: 36px !important;
  }

  .mt40 {
  margin-top: 40px !important;
  }

  .mb40 {
  margin-bottom: 40px !important;
  }

  .ml40 {
  margin-left: 40px !important;
  }

  .mr40 {
  margin-right: 40px !important;
  }

  .pt40 {
  padding-top: 40px !important;
  }

  .pb40 {
  padding-bottom: 40px !important;
  }

  .pl40 {
  padding-left: 40px !important;
  }

  .pr40 {
  padding-right: 40px !important;
  }

  .mt44 {
  margin-top: 44px !important;
  }

  .mb44 {
  margin-bottom: 44px !important;
  }

  .ml44 {
  margin-left: 44px !important;
  }

  .mr44 {
  margin-right: 44px !important;
  }

  .pt44 {
  padding-top: 44px !important;
  }

  .pb44 {
  padding-bottom: 44px !important;
  }

  .pl44 {
  padding-left: 44px !important;
  }

  .pr44 {
  padding-right: 44px !important;
  }

  .mt48 {
  margin-top: 48px !important;
  }

  .mb48 {
  margin-bottom: 48px !important;
  }

  .ml48 {
  margin-left: 48px !important;
  }

  .mr48 {
  margin-right: 48px !important;
  }

  .pt48 {
  padding-top: 48px !important;
  }

  .pb48 {
  padding-bottom: 48px !important;
  }

  .pl48 {
  padding-left: 48px !important;
  }

  .pr48 {
  padding-right: 48px !important;
  }

  .mt52 {
  margin-top: 52px !important;
  }

  .mb52 {
  margin-bottom: 52px !important;
  }

  .ml52 {
  margin-left: 52px !important;
  }

  .mr52 {
  margin-right: 52px !important;
  }

  .pt52 {
  padding-top: 52px !important;
  }

  .pb52 {
  padding-bottom: 52px !important;
  }

  .pl52 {
  padding-left: 52px !important;
  }

  .pr52 {
  padding-right: 52px !important;
  }

  .mt56 {
  margin-top: 56px !important;
  }

  .mb56 {
  margin-bottom: 56px !important;
  }

  .ml56 {
  margin-left: 56px !important;
  }

  .mr56 {
  margin-right: 56px !important;
  }

  .pt56 {
  padding-top: 56px !important;
  }

  .pb56 {
  padding-bottom: 56px !important;
  }

  .pl56 {
  padding-left: 56px !important;
  }

  .pr56 {
  padding-right: 56px !important;
  }

  .mt60 {
  margin-top: 60px !important;
  }

  .mb60 {
  margin-bottom: 60px !important;
  }

  .ml60 {
  margin-left: 60px !important;
  }

  .mr60 {
  margin-right: 60px !important;
  }

  .pt60 {
  padding-top: 60px !important;
  }

  .pb60 {
  padding-bottom: 60px !important;
  }

  .pl60 {
  padding-left: 60px !important;
  }

  .pr60 {
  padding-right: 60px !important;
  }




  //align
  .align{
    &_center{
        text-align: center !important; 
        &_desktop{
          ${mixins.screenOver()}{
            text-align: center !important; 
          }
        }
        &_mobile{
          ${mixins.screenUnder()}{
            text-align: center !important; 
          }
        }
    }
    &_left{
        text-align: left !important;
        &_desktop{
          ${mixins.screenOver()}{
            text-align: left !important; 
          }
        }
        &_mobile{
          ${mixins.screenUnder()}{
            text-align: left !important; 
          }
        }
    }
    &_right{
        text-align: right !important;
        &_desktop{
          ${mixins.screenOver()}{
            text-align: right !important; 
          }
        }
        &_mobile{
          ${mixins.screenUnder()}{
            text-align: right !important; 
          }
        }
    }
  }
  //font
  .font-xxs {
    font-size: ${fonts.fontSizeRem('xxs')};
  }
  .font-xs {
      font-size: ${fonts.fontSizeRem('xs')};
  }
  .font-s {
      font-size: ${fonts.fontSizeRem('s')};
  }
  .font-m {
      font-size: ${fonts.fontSizeRem('m')};
  }
  .font-ms {
      font-size: ${fonts.fontSizeRem('ms')};
  }
  .font-l {
      font-size: ${fonts.fontSizeRem('l')};
  }
  .font-xl {
      font-size: ${fonts.fontSizeRem('xl')};
  }
  .font-xxl {
      font-size: ${fonts.fontSizeRem('xxl')};
  }
  .font-xxxl {
      font-size: ${fonts.fontSizeRem('xxxl')};
  }

  .bold{
    font-weight: bold;
  }

  .normal{
    font-weight: normal;
  }

  .flex{
    display:flex;
    justify-content:flex-end;
  }
  
  .display{
    &-desktop{
      ${mixins.screenUnder()}{
        display: none;
      }
    }
    &-mobile{
      ${mixins.screenOver()}{
        display: none;
      }
    }
  }
  .approval {
    color: ${colors.themeColor('text_white')};
    background-color: #3AC772;
  }

  .denial {
    color: ${colors.themeColor('text_white')};
    background-color: ${colors.themeColor('background_reject')};
  }

  .examination {
    color: #35373A;
    background-color: ${colors.themeColor('border_disabled')};
  }

  .red_text {
    color: ${colors.themeColor('text_error')} !important;
  }

  .blue_text {
    color: ${colors.themeColor('primary_dark')};
  }

  .opacity {
    opacity: 0.5;
  }

  .border{
    text-decoration: line-through;
  }

  .align-center{
    text-align:center;
  }

  .relative{
    position:relative;
  }

  .absolute{
    position:absolute;
  }

  .fullWidth{
    width:100%;
  }

  .nowrap{
    white-space:nowrap;
  }

  .newline{
    white-space: pre-line;
  }

  .grayout{
    width:100%;
    height:100%;
    position: absolute;
    background-color: ${colors.themeColor('border_box')};
    opacity: 0.7;
    top:0px;
    left:0px;
  }

  .item_center{
    align-item: center;
  }

`

export default GlobalStyle;
