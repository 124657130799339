import { Box } from "../../../components/layouts/Box"
import { Container } from "../../../components/layouts/Container"
import { FormWrap } from "../../../components/layouts/FormWrap"
import { Button, TextField } from "../../../components/ui-parts/ocnk"
import useRedirectPasswordHooks from "../../../hooks/useRedirectPasswordHooks"
import useNavigateByAgentHooks from "../../../hooks/useNavigateByAgentHooks"
import { BackButton } from "../../../components/ui-parts/ocnk/BackButton"
import LinkByAgent from "../../../components/ui-parts/ocnk/LinkByAgent"
import { ToolChipDiv, passPhrase, toolChipList } from "../../../constance/constance"
import styled from 'styled-components';
import { colors } from '../../../css/variables/colors';
import { ErrorHandler } from "../../../api/ErrorHandler"
import { initApiModelType } from "../../../constance/typeModel"

type passCheckMsgStyleType = {
  score: number
}

const PassCheckMsgStyle = styled.p<passCheckMsgStyleType>`
    color:${props => (props.score === 0 || props.score === 1) ? colors.themeColor('text_error') : colors.themeColor('success') };
    margin-top: 4px;
    `
const OcnkTeikiRedirectPassword = () => {
  const {navigateByAgent} = useNavigateByAgentHooks()
  const { ErrorHandle } = ErrorHandler(navigateByAgent)
	const { ErrwebEntryMsg, ErrConfirmMsg, navigateConfirm, setPassHandler, setConfirmPassHandler, userInfoData, passCheckState, normal, abnormal} = useRedirectPasswordHooks(navigateByAgent)
	
  //パスワードリセット実行
  const navigateConfirmHandler = async () => {
    const response = await navigateConfirm()
    // バリデーションに引っかかった場合リターン
    if(response === undefined) return 
    //エラーハンドリング実行
    ErrorHandle<initApiModelType<undefined>>(response, normal, abnormal, false)
  }
  
  return (
		<Container isLogin={true} background={'default'} align={'top'}>
      <div className="mb32 align_right">
        <BackButton><LinkByAgent to={'/redirect/shop'}>ショップ情報入力画面へ戻る</LinkByAgent></BackButton>
      </div>
			<FormWrap>
        <div className='align_center'>
          <h3 className="mt0 mb0 align_left">ZEUS Web申込システム　ログイン情報設定</h3>
          <hr className="mt4"/>
          <ToolChipDiv className="align_left">{`こちらのWeb申込システムは申込サービスの利用開始まで使用いたします。\nログインの際に使用するパスワードを以下で入力してください。`}</ToolChipDiv>
          <div className="mt24">
            <p className="align_left mb0">メールアドレス：</p>
            <p className="bold align_left">{userInfoData.email}</p>
          </div>
        </div>
        <p className="mb0">パスワード：</p>
        <TextField
          type={'password'}
          mt={0}
          onChange={setPassHandler}
          iserror={ErrwebEntryMsg}
          id='textfield1'
          placeholder='Example1234@'
          toolChip={toolChipList.user_regist_usabled_symbol}
          toolChipPlacement={'right'}
          toolChipWidth={280}
          fullwidth
          label={passPhrase}
          labelMt={4}
        />
        <PassCheckMsgStyle score={passCheckState.score}>{passCheckState.msg}</PassCheckMsgStyle>
          <p className='mb0 mt12 font-ms'>パスワードを再度入力してください</p>
          <TextField
            onChange={setConfirmPassHandler}
            iserror={ErrConfirmMsg}
            placeholder='Example1234@'
            type='password'
            id='textfield3'
            fullwidth
            mt={4}
          />
        <Box mt={32}>
          <Button variant='primary' fullwidth onClick={navigateConfirmHandler}>確認画面へ遷移する</Button>
        </Box>
      </FormWrap>
		</Container>
	)
}

export default OcnkTeikiRedirectPassword