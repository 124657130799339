import { useEffect, useState } from "react";
import useLimitDirectAccess from "../../../hooks/useLimitDirectAccess";
import { useLoginCheckHooks } from "../../../hooks/useLoginCheckHooks";
import useUserAuthHooks from "../../../hooks/useUserAuthHooks";
import UseStartPrecautions from "../../ocnk/renderPages/UseStartPrecautions";
import useGTMHooks from "../../../hooks/useGTMHooks";

const OcnkTeikiUseStartPrecautionsBeforeRender = () => {
    const {LoginCheck} = useLoginCheckHooks('/ocnk-teiki');
    const {AuthCheck} = useUserAuthHooks('/ocnk-teiki');
    const {DirectAccessCheck} = useLimitDirectAccess('/ocnk-teiki')
    const [directFlag, setDirectFlag] = useState(false)
    const [loginFlag, setLoginFlag] = useState(false)
    const { pushDataLayerHandler } = useGTMHooks()
  
    //ログイン確認+URL直叩きチェックフラグをセット
    useEffect(()=>{
      pushDataLayerHandler()
      setLoginFlag(LoginCheck())
      setDirectFlag(DirectAccessCheck('/ocnk-teiki/use_start_precautions')) 
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
  
    if(!AuthCheck() || !directFlag || !loginFlag) return <></>
    return(
        <UseStartPrecautions/>
    )
}

export default OcnkTeikiUseStartPrecautionsBeforeRender