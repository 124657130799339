import { useEffect, useState } from "react";
import ErrorBoundary from "../../../api/ErrorBoundary";
import useLimitDirectAccess from "../../../hooks/useLimitDirectAccess";
import { useLoginCheckHooks } from "../../../hooks/useLoginCheckHooks";
import useUserAuthHooks from "../../../hooks/useUserAuthHooks";
import useGTMHooks from "../../../hooks/useGTMHooks";
import OcnkTeikiEntryCompleted from "../renderPages/EntryCompleted";

const OcnkTeikiEntryCompletedBeforeRender = ({
  redirect
}:{
  redirect?: boolean
}) => {
    const entryPath = redirect ? '/redirect_completed' : '/entry_completed'
    const {LoginCheck} = useLoginCheckHooks('/ocnk-teiki');
    const {AuthCheck} = useUserAuthHooks('/ocnk-teiki');
    const {DirectAccessCheck} = useLimitDirectAccess('/ocnk-teiki')
    const { pushDataLayerHandler } = useGTMHooks()
    
    useEffect(()=>{
      pushDataLayerHandler()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const [directFlag, setDirectFlag] = useState<boolean>(false)
    const [loginFlag, setLoginFlag] = useState<boolean>(false)
    
  
    //ログイン確認+URL直叩きチェックフラグをセット
    useEffect(()=>{
      setLoginFlag(LoginCheck())
      setDirectFlag(DirectAccessCheck('/ocnk-teiki'+entryPath))
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
  
    if(!AuthCheck() || !directFlag || !loginFlag) return(<></>)
    return(
      <ErrorBoundary>
        <OcnkTeikiEntryCompleted redirect={redirect}/>
      </ErrorBoundary>
    )
}

export default OcnkTeikiEntryCompletedBeforeRender