import { useEffect, useRef, useState, useContext } from "react";
import { Context } from "../stores/Provider";
import { GetMethod, PostMethod } from "../api/Api";
import { slideToggle } from "../function/accordion";
import { entryValidator } from "../validators/publicValidator";
import { getSession } from "../sessionStorage/sessionStorageMethod";
import { setIsLoading } from "../function/setIsLoading";
import { logoutAbnormalFunction } from "../function/logoutAbnormalFunction";
import { initApiModelType, ticketsRequestType,commentResponseType, sessionFileType } from "../constance/typeModel";
import { inquiryCommentDataType } from "../constance/providerTypeModel";
import { initApiResponse } from "../constance/constance";
const useInquiryItemHooks = (
    accordionOpen: number | null, 
    id: number, 
    navigation: boolean | undefined,
    navigateByAgent: Function
    ) => {
    const { state, dispatch } = useContext(Context);
    const userInfoData = getSession('user')
    const operationInfoData = getSession('operation')

    const emailData = userInfoData.email
    // アコーディオン開閉State
    const[itemToggle, setItemToggle] = useState(false);
    const ItemToggle = itemToggle;
    const ToggleRef = useRef<null | HTMLParagraphElement>(null);
    const NavToggleRef = useRef<null | HTMLParagraphElement>(null)
    const canceledFlag = state.user_status.cancel_flag

    // アコーディオンState変更関数
    const itemToggleChangeHandler = () => {
        let slideTime = 500
        if(navigation) slideTime = 0
        setItemToggle(prev => !prev);
        if(!navigation) slideToggle(ToggleRef.current, slideTime)
        if(navigation) slideToggle(NavToggleRef.current, slideTime)
    };
    // ファイルState
    const[fileData, setFileData] = useState<sessionFileType[] | null>(null)
    const FileDatas = fileData

    // textareaのheight自動調整State
    const[scrollHeight, setScrollHeight] = useState(118);
    const ScrollHeight = scrollHeight;
    const TextareaRef = useRef<HTMLTextAreaElement>(null);

    // コメント登録用State
    const [commentRegister, setCommentRegister] = useState('');

    // コメントバリデーションState
    const [commentRegisterErrMsg, setCommentRegisterErrMsg] = useState<JSX.Element | string>('');
    const CommentRegisterErrMsg = commentRegisterErrMsg;

    // height自動調整State変更関数
    const scrollHeightChangeHandler = () => {
        if(TextareaRef.current === null) return
        setScrollHeight(TextareaRef.current.scrollHeight);
        setCommentRegister(TextareaRef.current.value)
    };
    
    // コメント登録処理
    const commentRegisterHandler = async (ticket_id: number,files: sessionFileType[]|null) => {
        let ticketsResponse: initApiModelType<undefined> = initApiResponse
        if(commentRegister === '') return setCommentRegisterErrMsg('コメントを入力してください');
        const fileValidateMessage = entryValidator(['File'],files,0).message
        if(fileValidateMessage !== "") return setCommentRegisterErrMsg(fileValidateMessage)
        setCommentRegisterErrMsg('');
        const reqData: ticketsRequestType = {
            entry_id: userInfoData.entry_id,
            ticket_id: ticket_id,
            comment: commentRegister,
            created_by: {
                name : 'merchant', // ユーザー側からの登録のため固定
                email : operationInfoData.operator_email
            }             
        }
        if(files !== null && files.length !== 0){
            reqData.files = {}
            reqData.files.comment = files
        }
        //ローディング画面表示
        setIsLoading(true, dispatch)
        ticketsResponse = await PostMethod<ticketsRequestType, undefined>(reqData, '/tickets')
        if(ticketsResponse.code !== 200){
            if(ticketsResponse.code === 401){
                logoutAbnormalFunction(ticketsResponse,dispatch,navigateByAgent,state.agentPath)
            } else {
                // setCommentRegisterErrMsg(ticketsResponse.message)
                navigateByAgent('/system_error')
            }
            return setIsLoading(false, dispatch)
        } 
        setCommentRegister('')
        setCommentRegisterErrMsg('')
        if(TextareaRef.current !== null){
            TextareaRef.current.value = '';
        }
        //ファイル消去
        setFileData([])
        //コメント再取得
        const commentsArray: inquiryCommentDataType[][] = []
        const inquiryData = state.inquiry.inquiry_all_data
        await Promise.all(inquiryData.map( async (data: {ticket_id: number}) => {
            const getReqData = { 
                entry_id: userInfoData.entry_id,
                ticket_id: data.ticket_id 
            }
            let response: initApiModelType<commentResponseType> = initApiResponse
            response = await GetMethod<typeof getReqData,commentResponseType>(getReqData, '/tickets/comments');
            if(response.code === 200 && response.response_data !== undefined){
                commentsArray.push(response.response_data.comments)
            } else if(response.code === 401) {
                return logoutAbnormalFunction(response,dispatch,navigateByAgent,state.agentPath)
            } else {
                return navigateByAgent('/system_error')
            }
            commentsArray.sort((
                a:{created_at: string}[],
            
                b:{created_at: string}[]
                )=>{
                if(a[0].created_at < b[0].created_at){
                    return 1
                } else {
                    return -1
                }
            })
        }));
        dispatch({
            type: "set-inquiry-comment-data",
            payload: commentsArray
        });
        //ローディング画面非表示
        setIsLoading(false, dispatch)
    };

    // モーダルの自動オープン
    const[isFlag, setIsFlag] = useState(false)

    useEffect(() => {
        if(id === accordionOpen) {
            setIsFlag(true)
            setItemToggle(true)
        }else {
            setIsFlag(false)
            setItemToggle(false)
        }
    }, [accordionOpen,id])

    return { 
        ItemToggle,
        itemToggleChangeHandler,
        ToggleRef,
        NavToggleRef,
        ScrollHeight,
        scrollHeightChangeHandler,
        TextareaRef,
        commentRegisterHandler,
        isFlag,
        emailData,
        CommentRegisterErrMsg,
        FileDatas,
        setFileData,
        canceledFlag
    }
};

export default useInquiryItemHooks;