import React, { MouseEventHandler } from 'react';
import styled from 'styled-components'
import { colors } from '../../../css/variables/colors';

const ToggleButtonWrapStyle = styled.div`
  position: relative;
  margin: 0 2px;
  width: 56px;
  display: inline-block;
`;
type toggleButtonStyleType = {
  toggle: boolean,
  disabled?: boolean
}
const ToggleButtonStyle = styled.button`
  width: 52px;
  height: 30px;
  border-radius:30px;
  background: ${(props: toggleButtonStyleType) => (props.toggle && !props.disabled) ? colors.themeColor('primary') : colors.themeColor('background_whitegray')};
  border-width: 1px;
  border-style: solid;
  border-color: ${(props: toggleButtonStyleType) => (props.toggle && !props.disabled) ? colors.themeColor('primary') : colors.themeColor('border_box')};
  &::after{
    content: '';
    width: 30px;
    height: 30px;
    border-radius:30px;
    display: block;
    background: ${colors.themeColor('background_main')};
    position: absolute;
    left: ${(props: toggleButtonStyleType) => props.toggle ? '22px' : 0};
    top: 50%;
    transform: translateY(-50%);
    border-width: 2px;
    border-style: solid;
    border-color: ${(props: toggleButtonStyleType) => (props.toggle && !props.disabled) ? colors.themeColor('primary') : colors.themeColor('border_box')};
    transition: all .5s;
  }
`;


export const ToggleButton = ({
  toggle = false,
  onClick,
  disabled
}: {
  toggle: boolean,
  onClick?: MouseEventHandler<HTMLElement>,
  disabled?: boolean
}) => {
  return (
    <ToggleButtonWrapStyle onClick={onClick}>
      <ToggleButtonStyle toggle={toggle} disabled={disabled} />
    </ToggleButtonWrapStyle>
  );
};
