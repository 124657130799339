import styled from "styled-components"
import { ErrorHandler } from "../../../../api/ErrorHandler"
import useFinalAgreementHooks from "../../../../hooks/useFinalAgreementHooks"
import FinalAgreementTable from "../../../ui-parts/ocnk/FinalAgreement/FinalAgreementTable"
import { colors } from "../../../../css/variables/colors"
import { mixins } from "../../../../css/variables/mixins"
import { fonts } from "../../../../css/variables/fonts"
import { FormWrap } from "../../../layouts/FormWrap"
import { Button, SelectMenu } from "../../../ui-parts/ocnk"
import { FlexBox } from "../../../layouts/FlexBox"
import AnotherTabLink from "../../../ui-parts/ocnk/AnotherTabLink"
import { Checkbox } from "../../../ui-parts/ocnk/Checkbox"
import { Box } from "../../../layouts/Box"
import useEconomicEstimateElementHooks from "../../../../hooks/useEconomicEstimateElementHooks"
import { diffExtractionRegisterType, initApiModelType } from "../../../../constance/typeModel"

const FinalAgreementAttention = styled.div`
	width:100%;
	border: solid 1px ${colors.themeColor('pale_pink')};
	background-color:${colors.themeColor('background_pink')};
	color:${colors.themeColor('text_error')};
	padding:10px 16px;
	border-radius: 4px;
	${mixins.screenUnder()}{
		font-size: ${fonts.fontSizeRem('s')}
	}
`
type titleWrapperType = {
	toggle?:boolean
}
const TitleWrapper = styled.div<titleWrapperType>`
	display:flex;
	justify-content:space-between;
	align-items: center;
  .toggleArrow {
    width: 60px;
    height: 28px;
    position: relative;
    cursor: pointer;
    transition: all 0.3s linear;
    ${props => props.toggle ? 'transform: rotate(180deg)' : null};
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -15%) rotate(-45deg);
      width: 12px;
      height: 12px;
      border-top: solid 2px ${colors.themeColor('text_lightgray')};
      border-right: solid 2px ${colors.themeColor('text_lightgray')};
    }
  }
`
const ContentWrapper = styled.div`
  transition: all 0.3s linear;
  .inner {
    padding: 28px;
  }
`
const RequiredStyle = styled.span`
  font-size:${fonts.fontSizeRem('xxs')};
  color: ${colors.themeColor('text_white')};
  background: ${colors.themeColor('background_required')};
  padding: 2px 6px;
  border-radius: 4px;
  margin-left: 8px;
  vertical-align: text-bottom;
`
const BlueWraper = styled.div`
	background-color:${colors.themeColor('background_whitegray')};
	border: solid 1px ${colors.themeColor('border_box')};
	border-radius: 8px;
	padding:20px;
`
const Guidance = styled.p`
	margin-top:24px;
	font-size:${fonts.fontSizeRem('xs')}
`
const FinalAgreementElement = ({
    navigateByAgent
}:{
    navigateByAgent: Function
}) => {
    const {feeArray,EconomicInfoData,sumFeeInfoData,creditPaymentSiteFeeState,demandCode,originFeeArrayHandler} = useEconomicEstimateElementHooks(navigateByAgent)
    const {finalAgreementNormal, agreementHandler,finalAgreementResponseData,shopInfoData,productContentPhrase,compareData,newSystemUsageFeeData,selectItemsPaymentMethod,paymentMethod,setMonthlyPaymentHandler,validateMessage,paymentShowFlags,creditDifferenceFlag,finalAgreementAbnormal,setCheckBoxStateHandler,vacantCheckState} = useFinalAgreementHooks(navigateByAgent)
    const {ErrorHandle} = ErrorHandler(navigateByAgent)

    //二次審査合意画面のエラーハンドラー
	const navigateFinalAgreementCompletedHandler = async() => {
		const response = await agreementHandler()
		ErrorHandle<initApiModelType<diffExtractionRegisterType>>(response,finalAgreementNormal,finalAgreementAbnormal,false)
	}

    return(
        <FormWrap width={1050} padding={36}>
            <>
                <FinalAgreementAttention>
                    ・審査結果に伴い、お申し込み時のお見積り内容を次のように変更させていただきます。
                    <br className="all"/>
                    変更箇所は、「変更後」の欄に赤字で表示されます。
                    <br className="all"/>
                    <br className="all"/>
                    ・「変更後」のお見積り内容がご契約条件となります。
                    <br className="all"/>
                    ご確認の上、よろしければ「確認しました」ボタンを押してください。
                    <br className="all"/>
                    ご確認後は、システム設定を行いサービス利用開始となります。
                </FinalAgreementAttention>
                {(compareData.shop.shop_addition_product_content_freetext||compareData.shop.shop_addition_product_content_code) &&
                    <h2 className="mt40 mb20">ショップ情報</h2>
                }
                <FinalAgreementTable
                    options={feeArray.credit}
                    contentName={'shop'}
                    shopData={shopInfoData}
                    productContentPhrase={productContentPhrase}
                    responseData={finalAgreementResponseData}
                    compareData={compareData}
                    page={"final_agreement"}
                    originFeeArrayHandler={originFeeArrayHandler}
                />
                <h2 className="mt40 mb20">クレジットカード決済</h2>
                <FinalAgreementTable 
                    contentName={'credit'}
                    options={feeArray.credit}
                    shopData={shopInfoData}
                    originPaymentSiteFee={sumFeeInfoData.credit}
                    paymentSiteFee={creditPaymentSiteFeeState}
                    sumSystemCost={newSystemUsageFeeData}
                    compareData={compareData}
                    responseData={finalAgreementResponseData}
                    page={"final_agreement"}
                    creditDifferenceFlag={creditDifferenceFlag}
                    productContentPhrase={productContentPhrase}
                    originFeeArrayHandler={originFeeArrayHandler}
                />
                { (demandCode === "system" && (paymentShowFlags.zeroTo || paymentShowFlags.toZero)) && 
                    <FormWrap margin_tb={12} padding={16} width={594}>
                        <TitleWrapper>
                            <h3 className='mt0 mb0 font-m'>支払い方法{paymentShowFlags.zeroTo && <RequiredStyle>必須</RequiredStyle>}</h3>
                        </TitleWrapper>
                        <ContentWrapper>
                            {paymentShowFlags.toZero && 
                                <p className="mb0 font-m">無料</p>
                            }
                            {paymentShowFlags.zeroTo &&
                                <SelectMenu id='monthly_payment_method' name='monthly_payment_method' options={selectItemsPaymentMethod} defaultValue={paymentMethod} onChange={setMonthlyPaymentHandler} iserror={validateMessage}></SelectMenu>
                            }
                        </ContentWrapper>
                    </FormWrap>
                }
                <h2 className="mt40 mb20">お申し込みの前にご確認ください</h2>
                <BlueWraper>
                    <FlexBox column gap={4} mt={0}>
                        <AnotherTabLink href={"https://www.cardservice.co.jp/guide/preparation.html#anchor-08"}>取扱不可商材について</AnotherTabLink>
                    </FlexBox>
                </BlueWraper>

                <h2 className="mt40 mb0">規約等のご確認と合意</h2>
                <p className="font-ms mt16">お申し込みには各種利用規約等への合意が必要です。</p>
                <BlueWraper>
                    <FlexBox column gap={4} mt={0}>
                    <AnotherTabLink href={"https://www.cardservice.co.jp/zmc/kiyaku/pdf/notation_privacy.pdf"}>個人情報の取扱いについて</AnotherTabLink>
                        <AnotherTabLink href={"https://www.cardservice.co.jp/zmc/kiyaku/pdf/notation_kyotsu.pdf"}>ゼウス決済サービス共通規約</AnotherTabLink>
                        {EconomicInfoData.credit.system_setting.select_flag &&
                            <AnotherTabLink href={"https://www.cardservice.co.jp/zmc/kiyaku/pdf/notation_creditcard.pdf"}>クレジット決済サービス利用規約（包括加盟型 ）</AnotherTabLink>
                        }
                    </FlexBox>
                    <FlexBox column gap={4} mt={30}>
                        <Checkbox id='agreement' label={'規約等へ合意します'} onChange={setCheckBoxStateHandler} iserror={vacantCheckState.agreement_msg}/>
                        <Checkbox id='charges_check' label="料金及び取引手数料を確認しました"  onChange={setCheckBoxStateHandler} iserror={vacantCheckState.charges_check_msg}/>
                    </FlexBox>
                </BlueWraper>
                <Box mt={40}/>
                <Button size={'large'} onClick={navigateFinalAgreementCompletedHandler}>確認しました</Button>
                <Guidance>ご利用料金についてご質問等ございましたら ゼウスまでお問い合わせください</Guidance>

            </>
        </FormWrap>
    )
}

export default FinalAgreementElement