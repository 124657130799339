import { useContext, useState } from "react"
import {  webEntryPasswordValidate,confirmPasswordValidate} from "../validators/publicValidator"
import { Context } from "../stores/Provider"
import { getSession } from "../sessionStorage/sessionStorageMethod"
import { passCheck } from "../function/passCheck";
import { PostMethod } from "../api/Api";
import { initApiResponse } from "../constance/constance";
import { initApiModelType, redirectUpdatePasswordRequestType } from "../constance/typeModel";
import { setIsLoading } from "../function/setIsLoading";

const useRedirectPasswordHooks = (navigateByAgent:Function) => {
    const [pass, setPass] = useState("")
    const [confirmPass, setConfirmPass] = useState("")
    const [ErrwebEntryMsg, setwebEntryErrMsg] = useState("")
    const [ErrConfirmMsg, setConfirmErrMsg] = useState("")
    const {dispatch} = useContext(Context)
    const userInfoData = getSession('user')
    const [passCheckState, setPassCheckState] = useState<{msg:string, score:number}>({msg:"", score:0})

    const setPassHandler = (e:React.ChangeEvent<HTMLInputElement>) => {
        setPassCheckState(passCheck(e.target.value,[]))
        setPass(e.target.value)
    }
    const setConfirmPassHandler = (e:React.ChangeEvent<HTMLInputElement>) => {
        setConfirmPass(e.target.value)
    }
    const navigateConfirm = async() => {
        let response: initApiModelType<undefined> = initApiResponse
        const confirmPasswordVal = confirmPasswordValidate(pass, confirmPass)

        // バリデーション処理
        setwebEntryErrMsg('')
        setConfirmErrMsg('')
        const webEntryPasswordVal = webEntryPasswordValidate(pass)
        
        if (webEntryPasswordVal.message !== "") {
            return setwebEntryErrMsg(webEntryPasswordVal.message)
        } 
        if (confirmPasswordVal.message !== "") {
            return setConfirmErrMsg(confirmPasswordVal.message)
        }
        const data = {
            "email": userInfoData.email,
            "agent_master_id": userInfoData.agent_master_id,
            "password": pass
        }
        //ローディング画面開始
        setIsLoading(true, dispatch)
        //API通信
        response = await PostMethod<redirectUpdatePasswordRequestType, undefined>(data, '/redirect_update_password');

        return response
    }

    const normal = () => {
        dispatch({
            type: 'set-user-data',
            payload: {
                web_entry_password: pass
            }
        })
        setIsLoading(false, dispatch)
        navigateByAgent('/redirect_confirm')
    }

    const abnormal = () => {
        setIsLoading(false, dispatch)
        return navigateByAgent('/redirect_password')
    }
    
    return{setPassHandler,setConfirmPassHandler,ErrwebEntryMsg, ErrConfirmMsg, navigateConfirm, userInfoData, passCheckState, normal, abnormal}
}

export default useRedirectPasswordHooks