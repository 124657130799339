import { useState, useContext, useMemo } from "react";
import {useLocation} from "react-router-dom"
import { Context } from '../stores/Provider';
import { PostMethod } from '../api/Api';
import { mailValidate } from "../validators/publicValidator";
import { setIsLoading } from "../function/setIsLoading";
import { emailSettingRequestType, emailSettingResponseType, initApiModelType } from "../constance/typeModel";
import { initApiResponse } from "../constance/constance";
import useNavigateByAgent from "./useNavigateByAgentHooks";

const useEmailSettingHooks = (agentMasterList: string[]) => {
    const {navigateByAgent} = useNavigateByAgent()
    const { state, dispatch } = useContext(Context);
    const [ email, setEmail ] = useState<string>('');
    const [ errMsg, setErrMsg ] = useState<string>('');
    const location = useLocation();
    const paramsArray = location.search.substring(1).split('&')

    // 変数返却
    const EmailData = email;
    const ErrMsg = errMsg;
    const apiErrMsg = state.auth.err_message

    //代理店IDパラメータ
    const authHash = useMemo(()=>{
        let result: string | null = null
        paramsArray.forEach((param)=>{
            if(param.includes('auth_hash=')) result = param.split('auth_hash=')[1]
        })
        return result
    },[paramsArray])

    const sendEmail = async (email: string) => {
        let response: initApiModelType<emailSettingResponseType> = initApiResponse
        // バリデーション処理
        const { isValidate, message } = mailValidate(email)
        setErrMsg('')

        if (!isValidate) {
            setErrMsg(message)
            return response
        }
        const data = { 
            email: email,
            plan_code_list: agentMasterList,
            auth_hash: authHash
        }

        //ローディング画面開始
        setIsLoading(true, dispatch)
        //API通信
        response = await PostMethod<emailSettingRequestType, emailSettingResponseType>(data, '/email_setting');
        return response;
    }

    //正常時遷移先
    const normal = (response:initApiModelType<emailSettingResponseType>) => {
        //ローディング画面非表示
        setIsLoading(false, dispatch)
	return navigateByAgent('/email_setting_completed')
    }
    
    //異常時遷移先
    const abnormal = () => {
        //ローディング画面非表示
        setIsLoading(false, dispatch)
        return navigateByAgent('/email_setting'+'?auth_hash='+authHash)
    }

    const setEmailHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        return setEmail(e.target.value)
    }

    return { sendEmail, EmailData, setEmailHandler, ErrMsg, apiErrMsg, normal, abnormal }
}

export default useEmailSettingHooks
