import { ReactNode, useContext } from "react"
import { Link } from "react-router-dom"
import { Context } from "../../../stores/Provider"

const LinkByAgent = ({
    children,
    to,
    confirm_flag,
    accordionNum,
    agentMasterList
}:{
    children: ReactNode,
    to: string,
    confirm_flag?: boolean,
    accordionNum?: number
    agentMasterList?: string[] | number[]
}) => {
    const {state} = useContext(Context)
    return(
        <Link 
            to={state.agentPath+to} 
            state={{
                routeTo: state.agentPath+to, 
                confirm_flag: confirm_flag, 
                accordionNum: accordionNum, 
                agentMasterList: agentMasterList
            }}
        >
            {children}
        </Link>
    )
}

export default LinkByAgent