import { ReactNode } from 'react';
import { Box } from '../../../layouts/Box';
import { EntryStep, EntryStepList } from '../../../style/DashboardStyle';

const DashboardLowerWrapper = ({
  children = <></>
}: {
  children : ReactNode
}) => {
  return (
    <section>
      <Box mt_desktop={32} mt_mobile={24}>
        <EntryStep>
          <div>
            <EntryStepList>
              {children}
            </EntryStepList>
          </div>
        </EntryStep>
      </Box>
    </section>
  )
}

export default DashboardLowerWrapper