import styled from "styled-components";
import { colors } from "../../../../css/variables/colors";
import { fonts } from "../../../../css/variables/fonts";
import { FlexBox } from "../../../layouts/FlexBox";
import { FormWrap } from "../../../layouts/FormWrap";
import { Checkbox } from "../../../ui-parts/ocnk/Checkbox";
import { Button } from "../../../ui-parts/ocnk";
import useEconomicEstimateElementHooks from "../../../../hooks/useEconomicEstimateElementHooks";
import EconomicEstimateTable from "../../../ui-parts/ocnk/EconomicEstimate/EconomicEstimateTable";
import { ErrorHandler } from "../../../../api/ErrorHandler";
import AnotherTabLink from "../../../ui-parts/ocnk/AnotherTabLink";
import { initApiModelType, entryInfoPostResponseDataType } from "../../../../constance/typeModel";
import useNavigateByAgent from "../../../../hooks/useNavigateByAgentHooks";

const ServiceCostTable = styled.table`
	width:100%;
	table-layout: fixed;
` 
const TableTr = styled.tr`
	p{
		margin-bottom:0px;
		font-size:13px;
		color:${colors.themeColor('text_main')}
	}
	.blue{
		color:${colors.themeColor('primary_dark')};
	}
	.main-color{
		color:${colors.themeColor('text_main')}
	}
	.normal-weight{
		font-weight:normal;
	}
	.cvs-settle-wrap{
		display:flex;
		flex-wrap:wrap;
		justify-content:space-between;
	}
	.credit-settle-fee{
		width:300px;
		padding:4px;
		display:flex;
		justify-content:space-between;
		border-bottom:solid 1px ${colors.themeColor('border_box')};
	}
	.cvs-settle-fee{
		padding:4px;
		width:48%;
		display:flex;
		justify-content:space-between;
		border-bottom:solid 1px ${colors.themeColor('border_box')};
	}
	.cvs-settle-fee-p{
		display:inline-block;
		font-size:14px;
	}
`

const TableTh = styled.th`
	width:220px;
	border: solid 1px ${colors.themeColor('border_box')};
	font-size: ${fonts.fontSizeRem('ms')};
	font-weight:normal;
	background: ${colors.themeColor('background_whitegray')};
	font-weight:bold;
	padding:10px;
    position:relative;
`

type tableTdType = {
	black:boolean
}
const TableTd = styled.td<tableTdType>`
	color:${props => props.black ? colors.themeColor('text_main') : colors.themeColor('primary_dark')};
	border: solid 1px ${colors.themeColor('border_box')};
	font-size: ${fonts.fontSizeRem('ms')};
	font-weight:bold;
	padding:9px;
    
`
const BlueWraper = styled.div`
	background-color:${colors.themeColor('background_whitegray')};
	border: solid 1px ${colors.themeColor('border_box')};
	border-radius: 8px;
	padding:20px;
`
const EconomicEstimateElement = () => {
	const {navigateByAgent} = useNavigateByAgent()
    const {economicConditionMasterInfoData,feeArray,EconomicInfoData,systemPaymentMethodPhrase,sumFeeInfoData,vacantCheckState,creditPaymentSiteFeeState,normal,abnormal,setCheckBoxStateHandler,navigateBack,entryEconomicData,demandCode} = useEconomicEstimateElementHooks(navigateByAgent)

	const {ErrorHandle} = ErrorHandler(navigateByAgent)
	
	//経済条件画面のエラーハンドラー
	const navigateEntryMerchantHandler = async() => {
		const response = await entryEconomicData()
		//チェックボックスバリデーションに引っかかった時はリターン
		if(response === null) return
		ErrorHandle<initApiModelType<entryInfoPostResponseDataType>>(response,normal,abnormal,false)
	}

    return(
        <FormWrap width={700} padding={36}>
			<>
				<h1 className="mt0">ご利用料金</h1>
				<p>ご利用料金は以下の通りです。</p>
				<h2 className="mt40 mb20">クレジットカード決済</h2>
				<EconomicEstimateTable 
					contentName={'credit'} 
					sumSystemCost={sumFeeInfoData.credit} 
					options={feeArray.credit}
					salesHandleFee
					paymentSiteFee={creditPaymentSiteFeeState}
					page={'economic_estimate'}
				/>
				{EconomicInfoData.atobarai.system_setting.select_flag && 
					<>
						<h2 className="mt40 mb20">コンビニあと払い</h2>
						<EconomicEstimateTable 
							contentName={'atobarai'}
							settlementFee={economicConditionMasterInfoData.atobarai.fee} 
							businessOperatorFee={economicConditionMasterInfoData.atobarai.business_operator_fee} 
							sumSystemCost={sumFeeInfoData.atobarai} 
							options={feeArray.atobarai}
							salesHandleFee
							paymentSiteFee={0}
							page={'economic_estimate'}
						/>
					</>
				}
				{EconomicInfoData.bank.system_setting.select_flag &&
					<>
						<h2 className="mt40 mb20">ネットバンク決済（入金おまかせサービス）</h2>
						<EconomicEstimateTable 
							contentName={'bank'}
							settlementFee={economicConditionMasterInfoData.bank.fee} 
							sumSystemCost={sumFeeInfoData.bank} 
							options={feeArray.bank}
							salesHandleFee
							paymentSiteFee={0}
							page={'economic_estimate'}
						/>
					</>
				}
				{EconomicInfoData.cvs.system_setting.select_flag && 
					<>
						<h2 className="mt40 mb20">コンビニ決済</h2>
						<EconomicEstimateTable 
							contentName={'cvs'} 
							sumSystemCost={sumFeeInfoData.cvs} 
							options={feeArray.cvs}
							paymentSiteFee={0}
							page={'economic_estimate'}
						/>
					</>
				}
				
				{demandCode === "system" &&
					<>
						<h3 className="mt40 mb20">支払い方法</h3>
							<ServiceCostTable>
								<tbody>
									<TableTr>
										<TableTh>月次支払い方法</TableTh>
										<TableTd black>{systemPaymentMethodPhrase.credit}</TableTd>
									</TableTr>
								</tbody>
							</ServiceCostTable>
					</>
				}

				<h2 className="mt40 mb20">お申し込みの前にご確認ください</h2>
				<BlueWraper>
					<FlexBox column gap={4} mt={0}>
						<AnotherTabLink href={"https://www.cardservice.co.jp/guide/preparation.html#anchor-08"}>取扱不可商材について</AnotherTabLink>
						{(EconomicInfoData.cvs.system_setting.select_flag) && 
							<AnotherTabLink href={"https://www.cardservice.co.jp/guide/cvs.html"}>コンビニサービス導入基準</AnotherTabLink>
						}
					</FlexBox>
				</BlueWraper>

				<h2 className="mt40 mb0">規約等のご確認と同意</h2>
				<p className="font-ms mt16">お申し込みには各種利用規約等への同意が必要です。</p>
				<BlueWraper>
					<FlexBox column gap={4} mt={0}>
					<AnotherTabLink href={"https://www.cardservice.co.jp/zmc/kiyaku/pdf/notation_privacy.pdf"}>個人情報の取扱いについて</AnotherTabLink>
						<AnotherTabLink href={"https://www.cardservice.co.jp/zmc/kiyaku/pdf/notation_kyotsu.pdf"}>ゼウス決済サービス共通規約</AnotherTabLink>
						{EconomicInfoData.credit.system_setting.select_flag &&
							<AnotherTabLink href={"https://www.cardservice.co.jp/zmc/kiyaku/pdf/notation_creditcard.pdf"}>クレジット決済サービス利用規約（包括加盟型 ）</AnotherTabLink>
						}
						{EconomicInfoData.atobarai.system_setting.select_flag &&
							<AnotherTabLink href={"https://www.cardservice.co.jp/zmc/kiyaku/pdf/notation_atobarai.pdf"}>後払い決済サービス加盟店規約・後払い決済サービス利用規約</AnotherTabLink>
						}
						{EconomicInfoData.bank.system_setting.select_flag &&
							<AnotherTabLink href={"https://www.cardservice.co.jp/zmc/kiyaku/pdf/notation_omakase.pdf"}>入金おまかせサービスゼウス加盟店規約</AnotherTabLink>
						}
						{EconomicInfoData.cvs.system_setting.select_flag &&
							<AnotherTabLink href={"https://www.cardservice.co.jp/zmc/kiyaku/pdf/notation_cvs.pdf"}>コンビニ決済サービス利用規約</AnotherTabLink>
						}
					</FlexBox>
					<FlexBox column gap={4} mt={30}>
						<Checkbox id='agreement' label={'規約等へ同意します'} onChange={setCheckBoxStateHandler} iserror={vacantCheckState.agreement_msg}/>
						<Checkbox id='charges_check' label="料金及び取引手数料を確認しました"  onChange={setCheckBoxStateHandler} iserror={vacantCheckState.charges_check_msg}/>
					</FlexBox>
				</BlueWraper>
				<FlexBox mt={24} gap={16}>
					<Button variant="secoundary" onClick={navigateBack}>戻る</Button>
					<Button onClick={navigateEntryMerchantHandler} >申し込み情報入力に進む</Button>
				</FlexBox>
			</>
		</FormWrap>
    )
}

export default EconomicEstimateElement