// ./Provider
// データの初期設定やデータの受け渡しの設定を行う。

import React, { PropsWithChildren } from "react";
import reducer from "./Reducer";
import { RecoilRoot } from 'recoil';
import { initBankAccountSessionData,  initMerchantSessionData, initOperationSessionData, initRepresentativeSessionData, initReviewContentsDivisionSessionData, initShopSessionData, initFileSessionData  } from "../constance/sessionData";
import { inquiryContextAllDataType, validateMessageDataType, inquiryDataArrayType, serviceStatusCreditType, serviceStatusCvsType, serviceStatusBankType, serviceStatusAtobaraiType } from "../constance/providerTypeModel";
import { initBankAccountSessionDataType, initFileSessionDataType, initMerchantSessionDataType, initOperationSessionDataType, initRepresentativeSessionDataType, initReviewContentsDivisionSessionDataType, initShopSessionDataType } from "../constance/typeModel";


// Reducer関数の初期値
// ここでデータ構造を定義する。
let initialState : {
    login: {
        email: string,
        password: string,
    },
    auth: {
        err_message: string,
        code: number
    },
    user: {
        name: string,
        email: string,
        password: string,
        tempPassword: string,
        access_token: string,
        reflesh_token: string,
        agent_service_id: string,
        web_entry_password: string
    },
    user_status: {
        status: string,
        before_status: null | string,
        cancel_flag: boolean,
        close_schedule_date: null | string,
        confirm_flag: boolean
    },
    examination_status: {},
    use_start_data: {
        serviceData: {
            credit: string[][],
            cvs: string[][],
            bank: string[][],
            atobarai: string[][]
        },
        managementData: [],
        atobaraiManagementData: [],
    },
    service_code_list: string,
    service_status: {
        [key: string]: any,
        credit: serviceStatusCreditType,
        cvs: serviceStatusCvsType,
        bank: serviceStatusBankType,
        atobarai: serviceStatusAtobaraiType
    },
    inquiry: {
        inquiry_all_data: inquiryContextAllDataType,
        inquiry_comment_data: inquiryDataArrayType 
    },
    is_open_modal : {
        path : string,
        type : string,
        flag : boolean,
        message : string
        merchant : {
            path: string;
            type: string;
            flag: boolean;
            message: string;
        }
    },
    entryValidateMessage:string,
    validateMessages: validateMessageDataType,
    entry : {
        merchant : initMerchantSessionDataType,
        representative : initRepresentativeSessionDataType,
        operation : initOperationSessionDataType,
        bank_account : initBankAccountSessionDataType,
        shop : initShopSessionDataType,
        files : initFileSessionDataType,
        review_contents_division : initReviewContentsDivisionSessionDataType,
    },
    entry_check_status:{
        merchant:string,
        bank:string,
        shop:string,
    },
    entry_bank_pulldown: {
        [key:string]: {
            bank_name: string,
            bank_branch: { [key: string]: { branch_code: string; branch_name: string; }}
        },
    },
    isLoading: boolean,
    agentPath: string
} = {
    // これ多分いらない
    login: {
        email: "",
        password: "",
    },
    auth: {
        err_message: "",
        code: 0
    },
    user: {
        name: "",
        email: "",
        password: "",
        tempPassword: "",
        access_token: "",
        reflesh_token: "",
        agent_service_id: "",
        web_entry_password: ""
    },
    user_status: {
        status: "",
        before_status: "",
        cancel_flag: false,
        close_schedule_date: null,
        confirm_flag: false
    },
    examination_status: {},
    use_start_data: {
        serviceData: {
            credit: [[]],
            cvs: [[]],
            bank: [[]],
            atobarai: [[]]
        },
        managementData: [],
        atobaraiManagementData: [],
    },
    service_code_list: "credit",
    service_status:{
        credit: {
            status: "",
            ip_code: 0,
            zkey: "",
            use_brand: {
                visa: {
                    status: "",
                    div_status: {
                        one: false,
                        div: false,
                        rivo: false,
                        div2_bonus: false
                    }
                },
                master: {
                    status: "",
                    div_status: {
                        one: false,
                        div: false,
                        rivo: false,
                        div2_bonus: false
                    }
                },
                jcb: {
                    status: "",
                    div_status: {
                        one: false,
                        div: false,
                        rivo: false,
                        div2_bonus: false
                    }
                },
                amex: {
                    status: "",
                    div_status: {
                        one: false,
                        div: false,
                        rivo: false,
                        div2_bonus: false
                    }
                },
                diners: {
                    status: "",
                    div_status: {
                        one: false,
                        div: false,
                        rivo: false,
                        div2_bonus: false
                    }
                }
            }
        },
        cvs: {
            status: "",
            ip_code: 0,
            zkey: "",
            use_cvs: {
                seven: "",
                lawson: "",
                seicomart: "",
                familymart: "",
                yamazaki: "",
                ministop: "",
            }
        },
        bank: {
            status: "",
            ip_code: 0
        },
        atobarai: {
            status: "",
            ip_code: 0,
            api_key: ""
        }
    },
    inquiry: {
        inquiry_all_data: [],
        inquiry_comment_data:[]
    },
    is_open_modal : {
        path : "",
        type : "",
        flag : false,
        message : "",
        merchant : {
            path : "",
            type : "",
            flag : false,
            message : ""
        }
    },
    entryValidateMessage:"",
    validateMessages:{
        merchant:{
            merchant_type:{isValidate:false,message:''},
            merchant_name:{isValidate:false,message:''},
            merchant_name_kana:{isValidate:false,message:''},
            merchant_last_name:{isValidate:false,message:''},
            merchant_first_name:{isValidate:false,message:''},
            merchant_last_name_kana:{isValidate:false,message:''},
            merchant_first_name_kana:{isValidate:false,message:''},
            merchant_phone_number_before:{isValidate:false,message:''},
            merchant_phone_number_middle:{isValidate:false,message:''},
            merchant_phone_number_after:{isValidate:false,message:''},
            merchant_register_post_code3:{isValidate:false,message:''},
            merchant_register_post_code4:{isValidate:false,message:''},
            merchant_address_prefectures:{isValidate:false,message:''},
            merchant_address_prefectures_kana:{isValidate:false,message:''},
            merchant_address_municipality:{isValidate:false,message:''},
            merchant_address_municipality_kana:{isValidate:false,message:''},
            merchant_address_streetbunch:{isValidate:false,message:''},
            merchant_address_streetbunch_kana:{isValidate:false,message:''},
            merchant_address_building:{isValidate:false,message:''},
            merchant_address_building_kana:{isValidate:false,message:''},
            merchant_website_url:{isValidate:false,message:''},
            merchant_annual_business:{isValidate:false,message:''},
            merchant_shop_category:{isValidate:false,message:''},
            merchant_corporate_number:{isValidate:false,message:''},
            representative_last_name:{isValidate:false,message:''},
            representative_first_name:{isValidate:false,message:''},
            representative_last_name_kana:{isValidate:false,message:''},
            representative_first_name_kana:{isValidate:false,message:''},
            representative_sex:{isValidate:false,message:''},
            representative_birthday_CE:{isValidate:false,message:''},
            representative_birthday_month:{isValidate:false,message:''},
            representative_birthday_day:{isValidate:false,message:''},
            representative_position_name:{isValidate:false,message:''},
            operator_last_name:{isValidate:false,message:''},
            operator_first_name:{isValidate:false,message:''},
            operator_last_name_kana:{isValidate:false,message:''},
            operator_first_name_kana:{isValidate:false,message:''},
            operator_email:{isValidate:false,message:''},
            operator_phone_number_before:{isValidate:false,message:''},
            operator_phone_number_middle:{isValidate:false,message:''},
            operator_phone_number_after:{isValidate:false,message:''},
            support_operator_email:{isValidate:false,message:''},
            support_operator_phone_number_before:{isValidate:false,message:''},
            support_operator_phone_number_middle:{isValidate:false,message:''},
            support_operator_phone_number_after:{isValidate:false,message:''},
            identity_doc_images:{isValidate:false,message:''},

        },
        bank_account: {
            bank_account_bank_name: { isValidate: false, message: '' },
            bank_account_bank_branch: { isValidate: false, message: '' },
            bank_account_category: { isValidate: false, message: '' },
            bank_account_number: { isValidate: false, message: '' },
            bank_account_name: { isValidate: false, message: '' },
            passbook_images: { isValidate: false, message: '' },
        },
        shop: {
            account_id: { isValidate: false, message: '' },
            shop_name: { isValidate: false, message: '' },
            shop_name_kana: { isValidate: false, message: '' },
            shop_name_alphabet: { isValidate: false, message: '' },
            shop_website_url: { isValidate: false, message: '' },
            shop_addition_site_status_code: { isValidate: false, message: '' },
            shop_addition_site_status_method: { isValidate: false, message: '' },
            shop_cvs_website_name: { isValidate: false, message: '' },
            shop_cvs_website_name_kana: { isValidate: false, message: '' },
            shop_addition_shop_website_check_url: { isValidate: false, message: '' },
            shop_addition_business_deal_url: { isValidate: false, message: '' },
            shop_addition_shop_website_id: { isValidate: false, message: '' },
            shop_addition_shop_website_password: { isValidate: false, message: '' },
            shop_addition_conduct_start_yyyy: { isValidate: false, message: '' },
            shop_addition_conduct_start_mm: { isValidate: false, message: '' },
            shop_addition_price_range_min: { isValidate: false, message: '' },
            shop_addition_price_range_max: { isValidate: false, message: '' },
            shop_addition_price_validate: { isValidate: false, message: '' },
            shop_addition_product_content_code: { isValidate: false, message: '' },
            shop_addition_major_content_code: { isValidate: false, message: '' },
            shop_addition_medium_content_code: { isValidate: false, message: '' },
            shop_addition_product_content_freetext: { isValidate: false, message: '' },
            shop_addition_antique_flag: { isValidate: false, message: '' },
            shop_addition_subscription_flag: { isValidate: false, message: '' },
            shop_addition_subscription_code: { isValidate: false, message: '' },
            shop_addition_point_purchase_flag: { isValidate: false, message: '' },
            shop_addition_prepaid_flag: { isValidate: false, message: '' },
            shop_addition_reserved_product_flag: { isValidate: false, message: '' },
            shop_addition_reservation_period_flag: { isValidate: false, message: '' },
            shop_addition_estimated_product_flag: { isValidate: false, message: '' },
            shop_addition_rental_flag: { isValidate: false, message: '' },
            shop_addition_rental_product_url: { isValidate: false, message: '' },
            //レンタル規約のURLもしくは画像の有無判定
            shop_addition_rental_validate: { isValidate: false, message: '' },
            //online/offlineの有無判定
            shop_addition_offer_place: { isValidate: false, message: '' },
            shop_addition_btob_code: { isValidate: false, message: '' },
            shop_addition_physical_store_flag: { isValidate: false, message: '' },
            shop_addition_physical_store_url_flag: { isValidate: false, message: '' },
            shop_addition_physical_store_url: { isValidate: false, message: '' },
            shop_addition_non_administrative_penalties_five_year_flag: { isValidate: false, message: '' },
            shop_addition_non_losing_judgment_flag: { isValidate: false, message: '' },
            shop_addition_available_product_flag: { isValidate: false, message: '' },
            shop_addition_non_infringing_product_presence_absence_flag: { isValidate: false, message: '' },
            shop_atobarai_customer_notice_store_name: { isValidate: false, message: '' },
            antique: { isValidate: false, message: '' },
            license: { isValidate: false, message: '' },
            rental: { isValidate: false, message: '' },
            examination_document: { isValidate: false, message: '' },
        },
    },
    entry : {
        merchant : initMerchantSessionData,
        representative : initRepresentativeSessionData,
        operation : initOperationSessionData,
        bank_account : initBankAccountSessionData,
        shop : initShopSessionData,
        files : initFileSessionData,
        review_contents_division : initReviewContentsDivisionSessionData,
    },
    entry_check_status:{
        merchant:"default",
        bank:"default",
        shop:"default",
    },
    entry_bank_pulldown: {},
    isLoading: false,
    agentPath: ""
};
// React Contextの作成
const Context = React.createContext<{state:typeof initialState, dispatch:Function}>({state:initialState, dispatch:()=>{}});

// コンテキストプロバイダーコンポーネント※基本いじらない
const ContextProvider = (props: PropsWithChildren) => {
    // useReducerでreducer関数と初期値をセット
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const value = { state, dispatch };

    return (
        // コンテキストプロバイダーとしてuseReducerのstateとdispatchをコンテキストに設定
        <RecoilRoot>
            <Context.Provider value={value}>
                {props.children}
            </Context.Provider>
        </RecoilRoot>
    );
}


export {
    initialState,
    Context,
    ContextProvider
};