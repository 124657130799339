import { useEffect, useState } from "react";
import { Container } from "../../../components/layouts/Container"
import OcnkTeikiEconomicEstimateElement from "../../../components/ui_elements/ocnkTeiki/Economic/EconomicEstimateElement"
import useGTMHooks from "../../../hooks/useGTMHooks";
import { useLoginCheckHooks } from "../../../hooks/useLoginCheckHooks";
import useUserAuthHooks from "../../../hooks/useUserAuthHooks";

const OcnkTeikiRedirectEconomic = ({
  redirect
}:{
  redirect?: boolean
}) => {
	const {LoginCheck} = useLoginCheckHooks('/ocnk-teiki');
    const {AuthCheck} = useUserAuthHooks('/ocnk-teiki');
    const { pushDataLayerHandler } = useGTMHooks()
    
    const [loginFlag, setLoginFlag] = useState(false)
    
  
    //ログイン確認+URL直叩きチェックフラグをセット
    useEffect(()=>{
      pushDataLayerHandler()
      setLoginFlag(LoginCheck())
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
	// 認証系判定
    if(!AuthCheck() || !loginFlag) return(<></>)
	
	return (
		<Container isLogin={true} background={'default'} align={'top'} redirect={redirect}>
			<OcnkTeikiEconomicEstimateElement redirect={redirect}/>
		</Container>
	)
}

export default OcnkTeikiRedirectEconomic