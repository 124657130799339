import styled from "styled-components";
import { Box } from "../../../components/layouts/Box";
import { Container } from "../../../components/layouts/Container"
import { EntryIcons } from "../../../components/ui-parts/ocnk";
import { BackButton } from "../../../components/ui-parts/ocnk/BackButton";
import EntryConfirmElement from "../../../components/ui_elements/ocnk/EntryConfirm/EntryConfirmElement";
import { colors } from "../../../css/variables/colors";
import { mixins } from "../../../css/variables/mixins";
import LinkByAgent from "../../../components/ui-parts/ocnk/LinkByAgent";
import usePdfDownloadHooks from "../../../hooks/usePdfDownloadHooks";
import PdfDownloadButton from "../../../components/ui-parts/ocnk/PdfDownloadButton";
import { FlexBox } from "../../../components/layouts/FlexBox";
import useNavigateByAgent from "../../../hooks/useNavigateByAgentHooks";
import { useEntryCheckHooks } from "../../../hooks/useEntryCheckHooks";

const TitleWrapper = styled.div`
    p {
        color:${colors.themeColor('text-main')};
        font-size:26px;
        font-weight:600;
        margin-bottom:0px;
    }
    display:flex;
    margin-top:28px;
    ${mixins.screenUnder()}{
        margin-top: 28px;
        margin-bottom: 20px;
    }
`
const TopTitleWrapper = styled.div`
    p {
        color:${colors.themeColor('text-main')};
        font-size:26px;
        font-weight:600;
        margin-bottom:0px;
    }
    display:flex;
    ${mixins.screenUnder()}{
        margin-top: 28px;
        margin-bottom: 20px;
    }
`
const WholeWrapper = styled.div`
    padding:0px 90px 0px;
    ${mixins.screenUnder()}{
        padding:0px 16px 0px;
    }
`
const MainBorder = styled.hr`
    ${mixins.screenUnder()}{
        margin-top: 4px;
        margin-bottom: 16px;
    }
`

const EntryCheck = () => {
    const {pdfDownloadHandler, userStatus} = usePdfDownloadHooks()
    const {navigateByAgent} = useNavigateByAgent()
    const {ObjToDevide} = useEntryCheckHooks(navigateByAgent)
    return(
        <Container isLogin={true} background={'default'} align={'top'} fullwidth>
            <WholeWrapper>
                <h1 className="mb0">お申し込み情報の確認</h1>
                <MainBorder/>
                <FlexBox gap={12} column flexEnd>
                    <BackButton><LinkByAgent to={'/dashboard'}>ダッシュボードへ戻る</LinkByAgent></BackButton>
                    {(userStatus === "CUTOVER" || userStatus === "CLOSING") &&
                        <PdfDownloadButton pdfDownloadHandler={pdfDownloadHandler}/>
                    }
                </FlexBox>
                <TopTitleWrapper>
                    <EntryIcons icon={'shop'} /><p>お客様情報</p>
                </TopTitleWrapper>
                <EntryConfirmElement title={'merchant'} check/>
                <TitleWrapper>
                    <EntryIcons icon={'bank'} /><p>口座情報</p>
                </TitleWrapper>
                <EntryConfirmElement title={'bank'} check/>
                <TitleWrapper>
                    <EntryIcons icon={'cart'} /><p>ショップ情報</p>
                </TitleWrapper>
                <EntryConfirmElement title={'shop'} check shopDevideData={ObjToDevide}/>
                <TitleWrapper>
                    <EntryIcons icon={'setting'}/><p>お見積内容の確認</p>
                </TitleWrapper>
                <EntryConfirmElement title={'economic'} check/>
                <Box mt={46}>
                    <BackButton><LinkByAgent to={'/dashboard'}>ダッシュボードへ戻る</LinkByAgent></BackButton>
                </Box>
            </WholeWrapper>
        </Container>
    )
}
export default EntryCheck
