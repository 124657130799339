import { useMemo } from "react"
import styled from "styled-components"
import { FlexBox } from "../components/layouts/FlexBox"
import { setCreditFeeData, setCvsFeeData } from "../function/setFeeData"
import { getSession } from "../sessionStorage/sessionStorageMethod"
import { fonts } from "../css/variables/fonts"
import { colors } from "../css/variables/colors"
import { mixins } from "../css/variables/mixins"
import { feeArrayType } from "../constance/typeModel"

const FeeP = styled.p`
    ${(props: {page: string}) => props.page === 'final_agreement' ? 'max-width:280px;' : ''}
    ${(props: {page: string}) => props.page === 'final_agreement' ? `font-size:${fonts.fontSizeRem('s')};` : ''}
    text-align: left;
    word-break: break-all;
`
const ValueFeeStyle = styled.p`
    font-size:${fonts.fontSizeRem('s')};
    color:${colors.themeColor('primary_dark')} !important;
    diplay:inline-block;
    white-space: nowrap;
    ${mixins.screenUnder()}{
        font-size:${fonts.fontSizeRem('xs')};
    }
`
const FeeSpan = styled.span`
    font-size: ${fonts.fontSizeRem('xl')};
`


const useEconomicEstimateTableHooks = ({
    options,
    page
}: {
    options: feeArrayType[],
    page: string
}) => {
    //セッションデータ抽出
    const agentMasterInfoData = getSession('agent_master')
    const economicInfoData = getSession('economic_condition')
    const economicConditionMasterInfoData = getSession('economic_condition_master')
    const systemSettingMasterInfoData = getSession('system_setting_master')

    const demand_code = agentMasterInfoData.demand_code
    
    //コンビニ用取引手数料
    const cvsFee = useMemo(()=>{
        return setCvsFeeData(economicConditionMasterInfoData) 
    },[economicConditionMasterInfoData])
    //クレジット用取引手数料
    const creditFee = useMemo(()=>{
        return setCreditFeeData(economicConditionMasterInfoData)
    },[economicConditionMasterInfoData])

    // オプション費用リスト
    const systemOptionFee = useMemo(()=>{
        if(!options) return <></>
        const systemOptionFee = options.map((value: {name: string, fee: number},index: number)=>{
            return(
                <div key={value.name + index}>
                        <FlexBox spaceBetween gap={8} responsiveColumn={page === 'final_agreement' ? true : false} >
                            <FeeP page={page}>{value.name}</FeeP>
                            <ValueFeeStyle>￥ <FeeSpan>{value.fee}</FeeSpan> /月</ValueFeeStyle>
                        </FlexBox>
                    <hr className="mt0 mb8" />
                </div>
            )
        })
        return systemOptionFee
    },[options,page])

    return{economicInfoData,creditFee,cvsFee,systemOptionFee,demand_code,systemSettingMasterInfoData}
}
export default useEconomicEstimateTableHooks