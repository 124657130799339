import { useEffect } from "react";
import ErrorBoundary from "../../../api/ErrorBoundary";
import useGTMHooks from "../../../hooks/useGTMHooks";
import SystemError from "../../ocnk/renderPages/SystemError";

const OcnkTeikiSystemErrorBeforeRender = () => {
  const { pushDataLayerHandler } = useGTMHooks()
  
  useEffect(()=>{
    pushDataLayerHandler()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  
  return(
    <ErrorBoundary>
      <SystemError/>
    </ErrorBoundary>
  )
}

export default OcnkTeikiSystemErrorBeforeRender