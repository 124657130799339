import React from "react";
import SystemError from "../pages/ocnk/renderPages/SystemError";

export default class ErrorBoundary extends React.Component<{ children: React.ReactNode }, { errorInfo : boolean }> {
    constructor(props:any){
        super(props);
        this.state = { errorInfo: false }
    }

    componentDidCatch(errorInfo: any) {
        this.setState({
            errorInfo: errorInfo
        })
    }

    render(){
        if(this.state.errorInfo) {
            return(
                <SystemError/>
            )
        }
        return <>{this.props.children}</> 
    }
}