import { useEffect } from "react";
import ErrorBoundary from "../../../api/ErrorBoundary";
import useGTMHooks from "../../../hooks/useGTMHooks";
import ResetPassword from "../renderPages/ResetPassword";

const ResetPasswordBeforeRender = () => {
    const { pushDataLayerHandler } = useGTMHooks()

    useEffect(()=>{
        pushDataLayerHandler()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    
    return(
        <ErrorBoundary>
            <ResetPassword/>
        </ErrorBoundary>
    )
}

export default ResetPasswordBeforeRender