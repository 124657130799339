import styled from 'styled-components';
import { colors } from '../../../css/variables/colors';
import { ReactNode } from 'react';

type cardStyleType = {
  sx? :string
}
const CardStyle = styled.div<cardStyleType>`
  ${props => props.sx};
  background: ${colors.themeColor('background_main')};
  border-radius: 8px;
  border: 1px solid ${colors.themeColor('border_box')};
`;
const CardContentStyle = styled.div`
  padding: 20px;
  min-height:260px;
` ;
const CardBottomStyle = styled.div`
  padding:16px 20px;
  border-top: 1px solid ${colors.themeColor('border_box')};
`;


export const Card = ({
  sx,
  children
}: {
  sx?: string,
  children: ReactNode
}) => {

  return (
    <CardStyle sx={sx}>
      {children}
    </CardStyle>
  );
};

export const CardContent = ({
  children
}: {
  children: ReactNode
}) => {
  return (
    <CardContentStyle>
      {children}
    </CardContentStyle>
  );
}

export const CardBottom = ({
  children
}: {
  children: ReactNode
}) => {
  return (
    <CardBottomStyle>
      {children}
    </CardBottomStyle>
  );
}

