import styled from "styled-components"
import { Container } from "../../../components/layouts/Container"
import { Button } from "../../../components/ui-parts/ocnk"
import { colors } from "../../../css/variables/colors"
import LinkByAgent from "../../../components/ui-parts/ocnk/LinkByAgent"

const EntryCompleteWrap = styled.div`
background: ${colors.themeColor('background_main')};
border-radius: 8px;
border: 1px solid ${colors.themeColor('border_box')};
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding : 105px;
width:100%;
height:424px;
`
const ContentWrap = styled.div`
width:272px;
text-align: center;
`
const OcnkTeikiEntryCompleted = ({
  redirect
}:{
  redirect?: boolean
}) => {
  return (
    <Container isLogin={true} background={'default'} align={'top'} redirect={redirect}>
      <h1>お申し込み完了</h1>
      <EntryCompleteWrap>
        <ContentWrap>
          <div className="font-xxl bold align_center">
            <p className="mb0">お申し込み</p>
            <p>ありがとうございました</p>
          </div>
          <div className="mb32">
            <p className="font-m">お申し込みから審査完了までの状況は ダッシュボードにてご確認ください。</p>
          </div>
          <Button variant={"secondary"}>
            <LinkByAgent to={'/dashboard'}>ダッシュボードに戻る</LinkByAgent>
          </Button>
        </ContentWrap>
      </EntryCompleteWrap>

    </Container>
  )
}

export default OcnkTeikiEntryCompleted
