import React, { useEffect, useState } from 'react';
import { EntryHead } from '../../../../ui-parts/ocnk/EntryHead';
import { FormTitle } from '../../../../ui-parts/ocnk/FormTitle';
import { FlexBox } from '../../../../layouts/FlexBox';
import { RadioButtonGroup } from '../../../../ui-parts/ocnk/RadioButtonGroup';
import { TextField } from '../../../../ui-parts/ocnk/TextField';
import { FileInput } from '../../../../ui-parts/ocnk/FileInput';
import useEntryContentsBankAccountHooks from '../../../../../hooks/useEntryContentsBankAccountHooks';
import { toolChipList } from '../../../../../constance/constance';
import { ErrorMessage } from '../../../../ui-parts/ocnk';
import { DataList } from '../../../../ui-parts/ocnk/DataList';
import AnotherTabLink from '../../../../ui-parts/ocnk/AnotherTabLink';
import useGTMHooks from '../../../../../hooks/useGTMHooks';
import useLimitDirectAccess from '../../../../../hooks/useLimitDirectAccess';
import { useLoginCheckHooks } from '../../../../../hooks/useLoginCheckHooks';
import useUserAuthHooks from '../../../../../hooks/useUserAuthHooks';
import { FC } from 'react'
import styled from 'styled-components';
import { fonts } from '../../../../../css/variables/fonts';

export const OcnkTeikiEntryContentsBankAccount = ({
  redirect
}:{
  redirect?: boolean
}) => {
  const {LoginCheck} = useLoginCheckHooks('/ocnk-teiki');
  const {AuthCheck} = useUserAuthHooks('/ocnk-teiki');
  const {DirectAccessCheck} = useLimitDirectAccess('/ocnk-teiki')
  const { radioItemsAccount, bankAccountState, merchantInfoData, bankPulldownState, bankBranchPulldownState, showFlag, disableFlag,ip_flag, errMsg, setBankAccountBankStateHandler, setRadioBankAccountBankStateHandler, setPulldownBankStateHandler, validateErrMsgs } = useEntryContentsBankAccountHooks(); 
  const { pushDataLayerHandler } = useGTMHooks()
  const [directFlag, setDirectFlag] = useState<boolean>(false)
  const [loginFlag, setLoginFlag] = useState<boolean>(false)
  type SpacerProps = {
    size: number;
    horizontal?: boolean;
  }
  const ToolChipExplain = styled.p`
    font-size:${fonts.fontSizeRem('xs')};
    margin-bottom:0px;
    white-space: pre-line;
    text-align:left;
`
  const Spacer: FC<SpacerProps> = ({ size, horizontal }) => {
    return (
      <div
        style={
          horizontal
            ? { width: size, height: 'auto', display: 'inline-block', flexShrink: 0 }
            : { width: 'auto', height: size, flexShrink: 0  }
        }
      />
    )
  }
  useEffect(()=>{
    const accessCheckPath = redirect ? '/redirect/bank_account' : '/entry/bank_account'
		pushDataLayerHandler()
    setLoginFlag(LoginCheck())
		setDirectFlag(DirectAccessCheck('/ocnk-teiki'+accessCheckPath)) 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  if(!AuthCheck() || !directFlag || !loginFlag) return(<></>)
  
  return (
    <>
      <section>
        <EntryHead icon='bank'>口座情報（売上代金振込先）</EntryHead>
        {showFlag && 
              <>
              {!ip_flag ?
              <>
                <p>【ご注意】ご指定いただける<AnotherTabLink href={"https://www.cardservice.co.jp/info/guide/kouza.html"}>口座情報</AnotherTabLink>について事前にご確認ください。</p>
                <FormTitle required >金融機関
                </FormTitle>
                <ErrorMessage ApiErrMsg={errMsg} />
                <FlexBox childJustify responsiveColumn>
                  <DataList id="bank_account_bank_name" fullwidth label={"金融機関名"} options={bankPulldownState} onChange={setPulldownBankStateHandler} value={bankAccountState.bank_account_bank_name} iserror={validateErrMsgs.bank_account_bank_name.message} name={"bank_account_bank_name"} explain={toolChipList.bank_account_name_pulldown} autocomplete={"off"}/>
                  <DataList id="bank_account_bank_branch" fullwidth label={"支店名"} options={bankBranchPulldownState} value={bankAccountState.bank_account_bank_branch} name={"bank_account_bank_branch"} onChange={setPulldownBankStateHandler} disabled={disableFlag} iserror={validateErrMsgs.bank_account_bank_branch.message} explain={toolChipList.bank_account_branch_pulldown} autocomplete={"off"}/>
                </FlexBox>

                <FormTitle required >口座種別</FormTitle>
                <RadioButtonGroup name='bank_account_category' radioitems={radioItemsAccount} state={bankAccountState.bank_account_category} setState={setRadioBankAccountBankStateHandler} iserror={validateErrMsgs.bank_account_category.message} />

                <FormTitle required>口座番号</FormTitle>
                <TextField id='bank_account_number' placeholder='1234567' fullwidth onChange={setBankAccountBankStateHandler} defaultValue={bankAccountState.bank_account_number} iserror={validateErrMsgs.bank_account_number.message} explain={toolChipList.bank_account_number}/>

                <FormTitle required >口座名義（ｶﾅ表記）</FormTitle>
                <TextField id='bank_account_name' placeholder={merchantInfoData.merchant_type === "1" ? 'ｶ)ｾﾞｳｽ' : 'ﾔﾏﾀﾞﾀﾛｳ'} fullwidth onChange={setBankAccountBankStateHandler} value={bankAccountState.bank_account_name} iserror={validateErrMsgs.bank_account_name.message} explain={toolChipList.bank_account_name}/>

                <FormTitle required >通帳画像</FormTitle>
                <FileInput id='passbook_images' name='passbook_images' maxsize={30} multiple iserror={validateErrMsgs.passbook_images.message} page={'bank_account'}/>
              </>
              :
              <>
                <h4>ご利用中のゼウスIPコードと同一の口座情報が設定されます</h4>
                <div>
                  <ToolChipExplain>※IPコードは、次のショップ情報入力画面で表示されます。</ToolChipExplain>
                  <Spacer size={10} />
                  <ToolChipExplain>※口座情報は、定期購入プランIPコード稼働後に、ゼウス売上管理画面に公開</ToolChipExplain>
                  <ToolChipExplain>されます「決済サービス開始のお知らせ」(pdf)でご確認いただけます。</ToolChipExplain>
                  <Spacer size={10} />
                  <ToolChipExplain>※口座情報を変更されたい場合は、定期購入プランIPコード稼働後に変更のお手続きが</ToolChipExplain>
                  <ToolChipExplain>可能となりますので、ゼウスまでお問い合わせください。</ToolChipExplain>
                </div>
              </>
              }
              </>
          }
        </section>
      </>
  )
}

