import { useContext, useState } from "react";
import { PostMethod } from '../api/Api';
import { confirmPasswordValidate, mailValidate } from '../validators/publicValidator';
import { setIsLoading } from "../function/setIsLoading";
import { Context } from "../stores/Provider";
import { passCheck } from "../function/passCheck";
import { initApiResponse } from "../constance/constance";
import { initApiModelType, resetPasswordRequestType } from "../constance/typeModel";
import useNavigateByAgent from "./useNavigateByAgentHooks";

const useResetPasswordHooks = () => {
    const {navigateByAgent} = useNavigateByAgent()
    const [authentication, setAuthentication] = useState<string>('');
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [errMsg, setErrMsg] = useState<{email:string, password:string, confirm:boolean}>({email:"", password:"", confirm:false});
    const [passCheckState, setPassCheckState] = useState<{msg:string, score:number}>({msg:"", score:0})
    const {dispatch} = useContext(Context)

    // 変数返却
    const AuthenticationData = authentication;
    const PasswordData = password;
    const ConfirmPasswordData = confirmPassword;
    const ErrMsg = errMsg;

    const sendPassword = async (email: string, password: string, confirmPassword: string) => {
        let response: initApiModelType<undefined> = initApiResponse
        //バリデーションメッセージリセット
        setErrMsg({email:"", password:"", confirm:false})

        const passValidate = confirmPasswordValidate(password, confirmPassword);
        const emailValidate = mailValidate(email)
        if (!passValidate.isValidate || !emailValidate.isValidate) {
            let confirmFlag = false
            if(passValidate.message === "両方のパスワードを入力してください" || passValidate.message === "再入力されたパスワードと一致しません") confirmFlag = true
            setErrMsg({email:emailValidate.message, password:passValidate.message,confirm:confirmFlag})
            return response
        }

        //データセット
        const data = { 
            email: email,
            confirmation_code: AuthenticationData,
            password: password 
        }
        //ローディング画面開始
        setIsLoading(true, dispatch)

        //API通信
        response = await PostMethod<resetPasswordRequestType, undefined>(data, '/reset_password');
        //認証失敗時システムエラーになってしまうためエラーメッセージをセット
        if(response.code === undefined){
            response.message = '認証コード、またはメールアドレスが間違っています。'
            response.code = 401
        }
        //レスポンスリターン
        return response;
    }

    //正常時遷移先
    const normal = () => {
        //ローディング画面非表示
        setIsLoading(false, dispatch)
        return navigateByAgent('/login')
    }
    //異常時遷移先
    const abnormal = () => {
        //ローディング画面非表示
        setIsLoading(false, dispatch)
        return navigateByAgent('/reset_password')
    }

    const setPasswordHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const usedByEmailStr = email !== "" ? email.split('@')[0] : ""
        setPassCheckState(passCheck(e.target.value,[email,usedByEmailStr]))
        return setPassword(e.target.value)
    }
    const setConfirmPasswordHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        return setConfirmPassword(e.target.value)
    }
    const setAuthenticationHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        //スペースがあれば自動で消去
        e.target.value = e.target.value.replace(' ','').replace('　','')
        return setAuthentication(e.target.value)
    }
    const setEmailHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        return setEmail(e.target.value)
    }

    return { email, setEmailHandler, sendPassword, PasswordData, ConfirmPasswordData, setPasswordHandler, setConfirmPasswordHandler, ErrMsg, normal, abnormal, setAuthenticationHandler, passCheckState }
}

export default useResetPasswordHooks