import React from 'react'
import { Container } from "../../../components/layouts/Container";
import { FormWrap } from '../../../components/layouts/FormWrap';
import styled from 'styled-components';
import { colors } from '../../../css/variables/colors';
import { fonts } from '../../../css/variables/fonts';
import { mixins } from '../../../css/variables/mixins';

const PhraseWrapper = styled.div`
    background-color:${colors.themeColor('background_whitegray')};
    padding: 16px 40px;
    margin-bottom: 24px;
    p{
        margin-bottom:0px;
        font-weight:bold;
    }
`
const TitleStyle = styled.p`
    font-weight: bold;
    font-size: ${fonts.fontSizeRem('xxl')};
    ${mixins.screenUnder()}{
      font-size:${fonts.fontSizeRem('m')};
    }
`
const ExplainPhrase = styled.p`
    margin-bottom: 0px;
    font-size:${fonts.fontSizeRem('ms')};
    ${mixins.screenUnder()}{
      font-size:${fonts.fontSizeRem('xxs')};
    }
`

const OcnkTeikiCompletedEmailSetting = () => {
  return (
    <Container>
      <FormWrap center>
        <TitleStyle>メールをご確認ください</TitleStyle>
        <PhraseWrapper>
          <ExplainPhrase>メールに記載のURLにアクセスして</ExplainPhrase>
          <ExplainPhrase>申し込みを完了させてください。</ExplainPhrase>
        </PhraseWrapper>
        <ExplainPhrase>メールが届かない場合は、</ExplainPhrase>
        <ExplainPhrase>メールアドレスをご確認の上再度お送りいただくか、</ExplainPhrase>
        <ExplainPhrase>迷惑メールフォルダをご確認頂きますようお願いいたします。</ExplainPhrase>
      </FormWrap>
    </Container>
  )
}

export default OcnkTeikiCompletedEmailSetting
