const screenOver = (px = 768) => {
  return `@media (min-width: ${px}px)`;
}

const screenUnder = (px = 767) => {
  return `@media (max-width: ${px}px)`;
}

const contentsWidth = () => {
  return 'calc(1200px + 16px*2)';
}

export const mixins = {
  screenOver,
  screenUnder,
  contentsWidth,
}
