import zxcvbn from "zxcvbn";

export const passCheck = (pass: string, dangerArray: string[]) => {
    const dangerArrayOrigin = ['zeus', 'cardservice', 'sbi']
    const dangerData = dangerArrayOrigin.concat(dangerArray)
    // パスワードチェック文言
    const returnCheckPassMessage = (data: number) =>{
        let message = ""
        switch(data){
            case 0:
                message = '非常に脆弱なパスワードです。';
                break;
            case 1:
                message = '脆弱なパスワードです。';
                break;
            case 2:
                message = '中程度の強度のパスワードです。';
                break;
            case 3:
                message = '強固なパスワードです。';
                break;
            case 4:
                message = '非常に強固なパスワードです。';
                break;
            default:
                message = '';
        }
        return message
    }
    const passScore = zxcvbn(pass,dangerData).score
    return { msg : returnCheckPassMessage(passScore), score : passScore }
}