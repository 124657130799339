import React, { useContext } from "react";
import useLoginHooks from '../../../hooks/useLoginHooks';
import { Context } from '../../../stores/Provider';
import { LoginElement } from '../../../components/ui_elements';
import { Container } from "../../../components/layouts/Container";
import { ErrorHandler } from "../../../api/ErrorHandler";
import { initApiModelType, loginResponseDataType } from "../../../constance/typeModel";
import useNavigateByAgent from "../../../hooks/useNavigateByAgentHooks";

const Login = ({
  agentMasterList
}:{
  agentMasterList: string[] | number[]
}) => {
  const {navigateByAgent} = useNavigateByAgent()
  const { LoginMethod, EmailData, PasswordData, isClosed, normal, abnormal, setPasswordHandler, setEmailHandler, ErrMsgs, agentName } = useLoginHooks(agentMasterList);
  const { ErrorHandle } = ErrorHandler(navigateByAgent)
  const { state } = useContext(Context);
  const ApiErrMsg = state.auth
  //Login実行
  const LoginHandler = async () => {
    const response = await LoginMethod(EmailData, PasswordData);
    //エラーハンドリング
    ErrorHandle<initApiModelType<loginResponseDataType>>(response, normal, abnormal, true)
  }

  return (
    <Container>
      <LoginElement
        LoginHandler={LoginHandler}
        setEmailHandler={setEmailHandler}
        setPasswordHandler={setPasswordHandler}
        ErrMsgs={ErrMsgs}
        ApiErrMsg={ApiErrMsg}
        IsClosed={isClosed}
        agentMasterList={agentMasterList}
        agentName={agentName}
      />
    </Container>
  )
}

export default Login