import { useEffect, useState } from "react";
import ErrorBoundary from "../../../api/ErrorBoundary";
import useLimitDirectAccess from "../../../hooks/useLimitDirectAccess";
import { useLoginCheckHooks } from "../../../hooks/useLoginCheckHooks";
import useUserAuthHooks from "../../../hooks/useUserAuthHooks";
import EconomicSelect from "../renderPages/EconomicSelect";
import useGTMHooks from "../../../hooks/useGTMHooks";

const EconomicSelectBeforeRender = () => {
    const {LoginCheck} = useLoginCheckHooks();
    const {AuthCheck} = useUserAuthHooks();
    const {DirectAccessCheck} = useLimitDirectAccess()
    const { pushDataLayerHandler } = useGTMHooks()
    
    useEffect(()=>{
      pushDataLayerHandler()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    
    const [directFlag, setDirectFlag] = useState(false)
    const [loginFlag, setLoginFlag] = useState(false)
    
  
    //ログイン確認+URL直叩きチェックフラグをセット
    useEffect(()=>{
      setLoginFlag(LoginCheck())
      setDirectFlag(DirectAccessCheck('/economic_select')) 
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
  
    if(!AuthCheck() || !directFlag || !loginFlag) return(<></>)

    return(
      <ErrorBoundary>
        <EconomicSelect/>
      </ErrorBoundary>
    )
}

export default EconomicSelectBeforeRender