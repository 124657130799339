import ResetPasswordMailCompleted from "../renderPages/ResetPasswordMailCompleted"
import ErrorBoundary from '../../../api/ErrorBoundary';
import useGTMHooks from "../../../hooks/useGTMHooks";
import { useEffect } from "react";

const ResetPasswordMailCompletedBeforeRender = () => {
    const { pushDataLayerHandler } = useGTMHooks()
    
    useEffect(()=>{
        pushDataLayerHandler()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    
    return(
        <ErrorBoundary>
            <ResetPasswordMailCompleted/>
        </ErrorBoundary>
    )

}

export default ResetPasswordMailCompletedBeforeRender