import { useLocation } from "react-router-dom";
import { getSession } from "../sessionStorage/sessionStorageMethod";

const useGTMHooks = () => {
    
    const location = useLocation()
    const baseUrl = "https://entry.cardservice.co.jp"
    type dataLayerType = {
        event: string,
        pagePath: string,
        pageLocation: string,
        agentMaster: string | null
    }
    //セッションデータからagent_master_id抽出
    const agentMasterInfoData = getSession('agent_master')
    const agentMasterName = agentMasterInfoData && agentMasterInfoData.agent_name !== "" ? agentMasterInfoData.agent_name : null

    const pushDataLayer = (data: dataLayerType) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(data);
    };
    const pushDataLayerHandler = (notFoundFlag = false) => {
        // NotFoundページに遷移時はpagePathの値は'/not_found'が定義される
        let pathName = notFoundFlag ? '/not_found' : location.pathname
        pushDataLayer({
            event: 'page_view',
            pagePath: pathName,
            pageLocation: baseUrl + location.pathname,
            agentMaster: agentMasterName
        })
    }

    return { pushDataLayerHandler }
}

export default useGTMHooks