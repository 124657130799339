import styled from 'styled-components'
import { colors } from '../../css/variables/colors';
import { mixins } from '../../css/variables/mixins';
import { ReactNode } from 'react';

const Wrapper = styled.div<{
  widthPercent?: number
}>`
  background: ${colors.themeColor('background_main')};
  border-radius: 8px;
  border: 1px solid ${colors.themeColor('border_box')};
  padding: 36px;
  ${props => props.widthPercent ? "width:" + props.widthPercent + "%;" : "width: 100%;" }
  ${mixins.screenUnder()}{
    padding: 28px 16px;
  }
`

export const BackGround = ({
  children = <></>, 
  widthPercent
}:{ 
  children : ReactNode, 
  widthPercent?: number
}) => (
		<Wrapper widthPercent={widthPercent}>
			{children}
		</Wrapper>
	)
