import { Container } from "../../../components/layouts/Container"
import OcnkTeikiEconomicEstimateElement from "../../../components/ui_elements/ocnkTeiki/Economic/EconomicEstimateElement"

const OcnkTeikiEconomicEstimate = ({
	redirect
}:{
	redirect?: boolean
}) => {
	
	return (
		<Container isLogin={true} background={'default'} align={'top'}>
			<OcnkTeikiEconomicEstimateElement redirect={redirect}/>
		</Container>
	)
}

export default OcnkTeikiEconomicEstimate