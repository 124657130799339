import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../../css/variables/colors';
import { ServiceList, ServiceListItem } from '../../../ui-parts/ocnk/ServiceList';
import { Typography } from '../../../ui-parts/ocnk/Typography';
import ServiceImgCard from '../../../../images/pct_service_card.svg';
import useServiceTypeElmHooks from '../../../../hooks/useServiceTypeElmHooks';
import useNavigateByAgent from '../../../../hooks/useNavigateByAgentHooks';

const ServiceTypeElmStyle = styled.div`
  background:${colors.themeColor('background_main')};
  padding: 12px;
  border:1px solid ${colors.themeColor('border_box')};
  border-radius: 8px;
`;
const HeadStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
`;


export const OcnkTeikiServiceTypeElm = () => {
  const {navigateByAgent} = useNavigateByAgent()
  const { economicInfoData,economicDevidedInfoData} = useServiceTypeElmHooks(navigateByAgent);
  return (
    <ServiceTypeElmStyle>
      <HeadStyle>
        <Typography size='ms' bold>ご利用サービス</Typography>
      </HeadStyle>
      <ServiceList>
        {economicDevidedInfoData.credit &&
          <ServiceListItem img={ServiceImgCard} title={'クレジットカード決済'} isUsed={economicInfoData.credit.system_setting.select_flag} />
        }
      </ServiceList>

    </ServiceTypeElmStyle>
  )
}
