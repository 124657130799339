import { useEffect } from "react";
import ErrorBoundary from "../../../api/ErrorBoundary";
import useGTMHooks from "../../../hooks/useGTMHooks";
import Login from "../renderPages/Login";

const LoginBeforeRender = () => {
    const { pushDataLayerHandler } = useGTMHooks()

    useEffect(()=>{
      pushDataLayerHandler()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    
    return(
      <ErrorBoundary>
        <Login agentMasterList={[1]}/>
      </ErrorBoundary>
    )
}

export default LoginBeforeRender