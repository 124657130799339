import styled from "styled-components"
import { mixins } from "../../../css/variables/mixins"
import { ReactNode } from "react"

const LinkStyled = styled.a`
    font-size: 15px;
    // サービス選択画面のキャンペーン用リンクの場合黄色にする。
    // それ以外の画面はデフォルトの色、"#0855C9"を設定する。
    color: ${props => (props.onClick && typeof props.onClick === 'function')  ? "#ffff00" : "#0855C9"};
    ${mixins.screenUnder()}{
        font-size: 12px;
        color: ${props => (props.onClick && typeof props.onClick === 'function')  ? "#ffff00" : "#0855C9"};
    }
`


const AnotherTabLink = ({
    children,
    href,
    onClick
}: {
    children: ReactNode,
    href: string,
    onClick?: React.MouseEventHandler<HTMLAnchorElement>
}) => {
    
    return(
        <LinkStyled target="_blank" rel="noopener noreferrer" href={href} onClick={onClick} >{children}</LinkStyled>
    )
}
export default AnotherTabLink