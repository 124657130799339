import React, { ReactNode } from 'react';
import styled from 'styled-components'
import { mixins } from '../../../../css/variables/mixins';
import { colors } from '../../../../css/variables/colors';
import { OcnkTeikiEntryContentsMerchant } from './EntryContents/EntryContentsMerchant';
import { OcnkTeikiEntryContentsBankAccount } from './EntryContents/EntryContentsBankAccount';
import { OcnkTeikiEntryContentsShop } from './EntryContents/EntryContentsShop';
import { OcnkTeikiEntrySide } from './EntrySide';

const EntryColumnStyle = styled.div`
  display: flex;
  ${mixins.screenOver()}{
    gap: 32px;
  }
  ${mixins.screenUnder()}{
    flex-direction: column;
  }
  &>main{
    flex: 1;
    background: ${colors.themeColor('background_main')};
    border-radius:8px;
    border:1px solid ${colors.themeColor('border_box')};
    ${mixins.screenOver()}{
      padding: 36px;
    }
    ${mixins.screenUnder()}{
      padding: 28px 16px;
    }
  }
  &>div:last-child{
    ${mixins.screenOver()}{
    width: 270px;
  }
  }
`

export const OcnkTeikiEntryColumn = ({
  path,
  redirect = false
}: {
  path : string,
  redirect?: boolean
}) => {

  let showPage: ReactNode = <></>;
  switch(path){
    case 'merchant': showPage = <OcnkTeikiEntryContentsMerchant redirect={redirect}/> 
      break;
    case 'bank_account': showPage = <OcnkTeikiEntryContentsBankAccount redirect={redirect}/>
      break;
    case 'shop': showPage = <OcnkTeikiEntryContentsShop redirect={redirect}/>
      break;
    default: showPage = '表示する内容がありません'
  }
  return(
  <EntryColumnStyle>
    <main>
      {showPage}
    </main>
    <div>
      <OcnkTeikiEntrySide path={path} redirect={redirect}/>
    </div>
  </EntryColumnStyle>
  )
  };


