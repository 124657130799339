import { getSession } from "../sessionStorage/sessionStorageMethod";

const useServiceTypeElmHooks = (navigateByAgent: Function) => {
    //セッションデータ抽出
    const economicInfoData = getSession('economic_condition')
    const economicDevidedInfoData = getSession('economic_devided')
    const navigateEconomicSelect = () => {
        return navigateByAgent('/economic_select')
    }
    const ocnkTeikiNavigateEconomic = () => {
        return navigateByAgent('/economic_estimate')
    }
    return {navigateEconomicSelect,economicInfoData,economicDevidedInfoData,ocnkTeikiNavigateEconomic}
}
export default useServiceTypeElmHooks