import { FormWrap } from '../../../layouts/FormWrap';
import { TextField, Button, ErrorMessage } from '../../../ui-parts/ocnk';
import { Box } from '../../../layouts/Box';
import styled from 'styled-components';
import { colors } from '../../../../css/variables/colors';
import { ChangeEventHandler, MouseEventHandler } from 'react';
import { passPhrase } from '../../../../constance/constance';

type passCheckMsgStyleType = {
  score: number
}
const PassCheckMsgStyle = styled.p<passCheckMsgStyleType>`
  color:${props => (props.score === 0 || props.score === 1) ? colors.themeColor('text_error') : colors.themeColor('success') };
  margin-top: 4px;
`
const ResetPasswordElement = ({
  setEmailHandler,
  setPasswordHandler,
  setConfirmPasswordHandler,
  sendPasswordHandler,
  ApiErrMsg,
  ErrMsg,
  setAuthenticationHandler,
  passCheckState
}: {
  setEmailHandler: ChangeEventHandler<HTMLInputElement>,
  setPasswordHandler: ChangeEventHandler<HTMLInputElement>,
  setConfirmPasswordHandler: ChangeEventHandler<HTMLInputElement>,
  sendPasswordHandler: MouseEventHandler<HTMLButtonElement>,
  ApiErrMsg: string,
  ErrMsg: {
    email: string,
    password: string,
    confirm: boolean
  },
  setAuthenticationHandler: ChangeEventHandler<HTMLInputElement>,
  passCheckState: {
    score: number,
    msg: string
  }
}) => {

  return (
    <FormWrap>
      <div className='align_center'>
        <h1 className='mt0 mb0'>パスワード再設定</h1>
      </div>
      <ErrorMessage ApiErrMsg={ApiErrMsg} />
      <p className="mt8 mb0 ml4 align_left bold">確認コードを入力</p>
        <TextField
        id='authentication'
        onChange={setAuthenticationHandler}
      />
      <p className="mt32 ml4 align_left bold">メールアドレスを入力</p>
      <TextField
        iserror={ErrMsg.email}
        id='textfield0'
        fullwidth
        onChange={setEmailHandler} />
      <p className="mt32 ml4 align_left bold">新しいパスワードを設定</p>
      <TextField
        iserror={ErrMsg.password}
        id='textfield1'
        label={passPhrase}
        type='password'
        fullwidth
        onChange={setPasswordHandler} />
      <PassCheckMsgStyle score={passCheckState.score}>{passCheckState.msg}</PassCheckMsgStyle>
      <TextField
        iserror={ErrMsg.confirm}
        id='textfield2'
        label='もう一度入力してください'
        type='password'
        fullwidth
        onChange={setConfirmPasswordHandler} />
      <Box mt={32}>
        <Button
          variant='primary'
          fullwidth
          onClick={sendPasswordHandler}>
          パスワードを再設定する</Button>
      </Box>
    </FormWrap>
  )
}
export default ResetPasswordElement;