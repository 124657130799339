import { useContext, useMemo } from "react";
import { getSession } from "../sessionStorage/sessionStorageMethod";
import { Context } from "../stores/Provider";
import { Button } from "../components/ui-parts/ocnk";
import styled from "styled-components";

const ButtonWrapper = styled.div`
  padding: auto;
  width:100%;
  text-align:center;
`

const useDashboardHooks = (navigateByAgent: Function) => {
    const userInfoData = getSession('user')
    const {state} = useContext(Context)
    const status = state.user_status.status
    const beforeStatus = state.user_status.before_status ?? ""
    const canceledFlag = state.user_status.cancel_flag

    // ステータスをステップごとに分ける
    const step = useMemo(()=>{
        let step: number = 1;
        switch(status){
            case "ENTRING":
            case "WAITING":
                step = 1;
                break;
            case "ENTRY_OK":
            case "CHECKING":
            case "FIXED":
                step = 2;
                break;
            case "INHOUSE_REVIEW":
                step = 3;
                break;
            case "EXTERNAL_REVIEW":
            case "WAITING_FINAL_AGREEMENT":
            case "FIXED_FINAL_AGREEMENT":
                step = 4;
                break;
            case "CUTOVER":
                step = 5;
                break;
            case "CLOSING":
                switch(beforeStatus){
                    case "CHECK_NG":
                    case "CHECKING":  
                        step = 2;
                        break;
                    case "INHOUSE_REVIEW_NG":
                    case "INHOUSE_REVIEW": 
                        step = 3;
                        break;
                    case "EXTERNAL_REVIEW_NG":
                        step = 4;
                        break;
                    case "CUTOVER":
                        step = 5;
                        break;
                }
                break;
            default:
                step = 1;
                break;
        }
        return step
    },[status,beforeStatus])

    //始めるボタンの遷移先
    const moveToStart = () => {
        //entry_idがあればentry_topへ遷移
        if(userInfoData.entry_id === null){
            return navigateByAgent('/economic_select')
        }else if((status === "ENTRING" || status === "WAITING") && userInfoData.entry_id !== null){
            return navigateByAgent('/entry_top')
        }
        return navigateByAgent('/entry_check')
    }
    //始めるボタンの遷移先(おちゃ定期)
    const ocnkTeikiMoveToStart = () => {
        //加盟店申込情報があればentry_topへ遷移
        if(status === "ENTRING" && userInfoData.is_merchant_entry === false){
            return navigateByAgent('/economic_estimate')
        }else if(status === "ENTRING" || status === "WAITING"){
            return navigateByAgent('/entry_top')
        }
        return navigateByAgent('/entry_check')
    }

    // dashboard表示文言
    const mainMessage = useMemo(()=>{
        let mainMessage = <></>;
        switch (status) {
            case "ENTRING":
                mainMessage = <>まずは必要な情報を入力しましょう。すべての情報を入力するとお申し込みが可能になります。</>
                break;
            case "ENTRY_OK":
                mainMessage = <>申し込みが完了しました。次にゼウスにてお申込内容の項目チェックとなります。</>
                break;
            case "CHECKING":
                mainMessage = <>ただいまゼウスにてお申込内容の項目チェック中です。</>
                break;
            case "WAITING":
                mainMessage = <>お申込み内容に不備があります。編集するボタンから編集いただき、再申し込みをお願いいたします。</>
                break;
            case "FIXED":
                mainMessage = <>再申し込みが完了しました。審査完了までしばらくお待ちください。</>
                break;
            case "INHOUSE_REVIEW":
                mainMessage = <>一次審査中です。審査完了までしばらくお待ちください。</>
                break;
            case "EXTERNAL_REVIEW":
                mainMessage = <>二次審査中です。審査完了までしばらくお待ちください。</>
                break;
            case "WAITING_FINAL_AGREEMENT":
                mainMessage = <>二次審査中です。お申込内容から変更があるため、こちらから内容の確認・合意をお願いします。<ButtonWrapper><Button onClick={()=>navigateByAgent('/final_agreement')}>変更内容の確認</Button></ButtonWrapper></>
                break;
            case "FIXED_FINAL_AGREEMENT":
                mainMessage = <>お申し込みの内容変更の合意を受け付けました。ご利用開始のお知らせまでしばらくお待ちください。</>
                break;
            case "CUTOVER":
                mainMessage = <>利用開始が可能となりました。「ご利用開始の手順」で手順をご確認ください。</>
                break;
            case "CLOSING":
                switch (beforeStatus) {
                    case "CHECK_NG":
                        mainMessage = <div className='red_text newline'>{'取扱商品が、取扱不可商材などに該当いたしました。\nお取り扱いが出来ず、決済サービスのご提供ができませんので、申込み手続きを終了とさせていただきます。'}</div>
                        break;
                    case "INHOUSE_REVIEW_NG":
                        mainMessage = <div className='red_text newline'>{'一次審査で、審査NGとなりました。「審査状況のご確認」をご覧ください。\nこの一次審査の結果により、決済サービスのご提供ができませんので、申込み手続きを終了とさせていただきます。'}</div>
                        break;
                    case "EXTERNAL_REVIEW_NG":
                        mainMessage = <div className='red_text newline'>{'二次審査で、審査NGとなりました。「審査状況のご確認」をご覧ください。\nこの二次審査の結果により、決済サービスのご提供ができませんので、申込み手続きを終了とさせていただきます。'}</div>
                        break;
                    case "CUTOVER":
                        mainMessage = <>利用開始が可能となりました。「ご利用開始の手順」で手順をご確認ください。</>
                        break;
                }
                break;
            default:
                mainMessage = <></>
        }

        if(canceledFlag) mainMessage = <div className='red_text newline'>{'キャンセルのご依頼を受け付けました。\nお申し込みのキャンセル手続きは完了しています。'}</div>
    
        return mainMessage
      },[status,canceledFlag,navigateByAgent,beforeStatus])

    //１つ目のボックスボタンの文言
    const buttonPhrase = useMemo(()=>{
        switch(status){
            case 'ENTRING':
                return '始める'
            case 'WAITING':
                return '編集する'
            default:
                return '確認する'
        }
    },[status])

    //NG時の赤枠発現
    const errorStep = useMemo(()=>{
        let errorStep = 0;
        switch(beforeStatus){
            case "CHECK_NG":
                errorStep = 2;
                break
            case "INHOUSE_REVIEW_NG":
                errorStep = 3;
                break
            case "EXTERNAL_REVIEW_NG":
                errorStep = 4;
                break
            default:
                errorStep = 0;
                break
        }
        return errorStep
    },[beforeStatus])

    //利用開始遷移ボタン
    const moveToUseStart = () => {
        return navigateByAgent('/use_start')
    }
    //利用開始注意事項遷移ボタン
    const moveToUseStartPrecautions = () => {
      return navigateByAgent('/use_start_precautions')
  }

    //審査状況確認画面遷移
    const moveToExamination = () => {
        return navigateByAgent('/examination_status')
    }

    return { step, status, beforeStatus, errorStep, buttonPhrase, mainMessage, moveToStart, moveToUseStart, moveToExamination, canceledFlag, moveToUseStartPrecautions, ocnkTeikiMoveToStart }
}

export default useDashboardHooks;

