import styled from "styled-components"
import { colors } from "../../../css/variables/colors"
import { fonts } from "../../../css/variables/fonts"
import { ReactNode } from "react"

const IconBottomStyle = styled.div`
  min-width: 22px;
  min-height: 22px;
  background: ${colors.themeColor('background_error')};
  color: ${colors.themeColor('text_white')};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  p {
    margin: 0;
  }font-size: ${fonts.fontSizeRem('ms')};
`

const ExclamationIcon = ({
	children
}: {
	children: ReactNode
}) => (
	<IconBottomStyle>
		{children}
	</IconBottomStyle>
)

export default ExclamationIcon