const themeColors: {
  [key: string]: string,
  //color
  primary:'#50A0FE',
  primary_light:'#82BCFF',
  primary_dark:'#1C84FE',
  primary_gradetion:'linear-gradient(143.94deg, #82BCFF 0%, #82BCFF 15.89%, #4FA0FF 34.11%)',
  error_gradetion:'linear-gradient(143.94deg, #ff4d4d 0%, #ff4d4d 15.89%, #ff3333 34.11%)',
  success:'#3AC772',
  gray:'#5E5E5E',
  light_yellow:'#FFFDEE',
  pale_pink:'#FFD5D7',
  //text
  text_main: '#313131',
  text_gray: '#7d7d7d',
  text_lightgray:'#9b9b9b',
  text_link: '#0855c9',
  text_disabled: '#b7b7b7',
  text_white: '#fff',
  text_error: '#ff1321',
  text_placeholder: '#9e9e9e',
  text_button_primary: '#fff',
  text_button_secoundary: '#50a0fe',
  text_button_secoundary_active: '#1c84fe',
  //background
  background_main: '#fff',
  background_whitegray: '#f1f4fc',
  background_button_primary: '#1c84fe',
  background_button_primary_active: '#0075ff',
  background_button_secoundary: '#fff',
  background_button_sub: '#565656',
  background_disabled: '#f0f0f0',
  background_reject: '#f87f87',
  background_input: '#f6f8fd',
  background_error: '#ff1321',
  background_pink: '#FFF6F7;',
  background_menu_hover:'#FAFBFF',
  background_required:'#F87F87',
  background_gray: '#EAEAEA',
  background_black: '#000',
  //border
  border_box: '#d9e0f0',
  border_disabled: '#e3e3e3',
  border_input: '#d9e0f0',
  border_error: '#ff1321',
} = {
    //color
    primary:'#50A0FE',
    primary_light:'#82BCFF',
    primary_dark:'#1C84FE',
    primary_gradetion:'linear-gradient(143.94deg, #82BCFF 0%, #82BCFF 15.89%, #4FA0FF 34.11%)',
    error_gradetion:'linear-gradient(143.94deg, #ff4d4d 0%, #ff4d4d 15.89%, #ff3333 34.11%)',
    success:'#3AC772',
    gray:'#5E5E5E',
    light_yellow:'#FFFDEE',
    pale_pink:'#FFD5D7',
    //text
    text_main: '#313131',
    text_gray: '#7d7d7d',
    text_lightgray:'#9b9b9b',
    text_link: '#0855c9',
    text_disabled: '#b7b7b7',
    text_white: '#fff',
    text_error: '#ff1321',
    text_placeholder: '#9e9e9e',
    text_button_primary: '#fff',
    text_button_secoundary: '#50a0fe',
    text_button_secoundary_active: '#1c84fe',
    //background
    background_main: '#fff',
    background_whitegray: '#f1f4fc',
    background_button_primary: '#1c84fe',
    background_button_primary_active: '#0075ff',
    background_button_secoundary: '#fff',
    background_button_sub: '#565656',
    background_disabled: '#f0f0f0',
    background_reject: '#f87f87',
    background_input: '#f6f8fd',
    background_error: '#ff1321',
    background_pink: '#FFF6F7;',
    background_menu_hover:'#FAFBFF',
    background_required:'#F87F87',
    background_gray: '#EAEAEA',
    background_black: '#000',
    //border
    border_box: '#d9e0f0',
    border_disabled: '#e3e3e3',
    border_input: '#d9e0f0',
    border_error: '#ff1321',
  }
  
  const themeColor=(color: string) => {
    return themeColors[color];
  }
  
  export const colors = {
    themeColor
  }
  
  
  