import styled from 'styled-components'
import { colors } from '../../../css/variables/colors';
import { fonts } from '../../../css/variables/fonts';

const Error = styled.span`
  display: block;
  color: ${colors.themeColor('text_error')};
  font-size: ${fonts.fontSizeRem('s')};
  margin-top: 4px;
  white-space: pre-line;
`


export const ErrorMessage = ({
  ApiErrMsg = ""
}: {
  ApiErrMsg: JSX.Element | string | undefined
}) => {
  return (
    <Error>
      {ApiErrMsg ? (<span>{ApiErrMsg}</span>) : (<></>)}
    </Error>
  );
};
