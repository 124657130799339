import React, { MouseEventHandler } from 'react';
import styled from 'styled-components';
import { fonts } from '../../../../css/variables/fonts';
import { colors } from '../../../../css/variables/colors';
import attachedIcon from "../../../../images/icon_attached.svg";
import deleteIcon from "../../../../images/icon_file_delete_btn.svg";
import { sessionFileType } from '../../../../constance/typeModel';

const InquiryFileItemStyle = styled.div`
  display: flex;
  width:100%;
  justify-content: left;
`

const FileInnerStyle = styled.div`
  width:88%;
  max-width: 440px;
  height: 35px;
  border-radius: 4px;
  display: flex;
  gap: 16px;
  align-items: center;
  background: ${colors.themeColor('background_main')};
  border: solid 1px ${colors.themeColor('border_box')};
  :not(:first-child) {
    margin-top: 4px;
  }
`

const AttachedIconStyle = styled.div`
  width: 10.5px;
  height: 18px;
  margin-left: 15.5px;
  background: url(${attachedIcon}) no-repeat center;
`

const FileNameStyle = styled.p`
  margin: 0 auto 0 0;
  font-size:${fonts.fontSizeRem('ms')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const CapacityStyle = styled.p`
  margin: 0 12px 0 0;
  color: ${colors.themeColor('text_lightgray')};
  font-size:${fonts.fontSizeRem('ms')};
`

const DeleteBtnStyle =styled.div`
  width:10%;
  min-width: 35px;
  height: 35px;
  cursor: pointer;
  background: url(${deleteIcon}) no-repeat center;
`

const InquiryFileItem = ({
  file,
  deleteFile,
  upload,
  entry
}: {
  file: sessionFileType,
  deleteFile?: MouseEventHandler<HTMLElement>,
  upload?: boolean,
  entry?: boolean
}) => {
  const fileSize = entry ? Math.ceil(file.file_size / 1024) : Math.ceil(file.file_size / 1024)
  return (
    <InquiryFileItemStyle>
      <FileInnerStyle>
        <AttachedIconStyle />
        <FileNameStyle>{file.file_name_origin}</FileNameStyle>
        {upload && <CapacityStyle>{fileSize}KB</CapacityStyle>}
      </FileInnerStyle>
      {upload && <DeleteBtnStyle onClick={deleteFile}/>}
    </InquiryFileItemStyle>
  );
};

export default InquiryFileItem;