/**
 * ダッシュボード
 */
import { Container } from '../../../components/layouts/Container';
import { DashboardBarElement, DashboardMessageElement, DashboardBoxElement, DashboardLowerWrapper } from '../../../components/ui_elements';
import useDashboardHooks from '../../../hooks/useDashboardHooks';
import ErrorBoundary from '../../../api/ErrorBoundary';
import useNavigateByAgent from '../../../hooks/useNavigateByAgentHooks';
import usePdfDownloadHooks from '../../../hooks/usePdfDownloadHooks';

export const DashBoard = () => {
  const {navigateByAgent} = useNavigateByAgent()
  const hooksResult = useDashboardHooks(navigateByAgent);
  const {pdfDownloadHandler, userStatus} = usePdfDownloadHooks()
  //ログイン確認+認証確認
  const { step, status, beforeStatus, errorStep, buttonPhrase, mainMessage, moveToStart, moveToUseStart, moveToExamination, canceledFlag, moveToUseStartPrecautions } = hooksResult
  /*
  * containerの設定
  */
  const background = 'white';  //背景色
  const align = 'top' //コンテンツ配置
  const fullwidth = true; //幅マックス

  return (
    <ErrorBoundary>
      <Container
        background={background}
        align={align}
        fullwidth={fullwidth}
        isLogin
        isInquiry
      >
        <DashboardMessageElement mainMessage={mainMessage} step={step}/>
        <DashboardLowerWrapper>
          <DashboardBoxElement step={step} status={status} moveToStart={moveToStart} moveToUseStart={moveToUseStart} errorStep={errorStep} buttonPhrase={buttonPhrase} moveToExamination={moveToExamination} canceledFlag={canceledFlag} moveToUseStartPrecautions={moveToUseStartPrecautions}/>
          <DashboardBarElement pdfDownloadHandler={pdfDownloadHandler} userStatus={userStatus} beforeStatus={beforeStatus}/>
        </DashboardLowerWrapper>
      </Container>
    </ErrorBoundary>
  );
};
