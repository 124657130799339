import styled from 'styled-components';
import { colors } from '../../../../css/variables/colors';
import { fonts } from '../../../../css/variables/fonts';

type wrapperType = {
  navigation?: boolean,
  status: string
}

const Wrapper = styled.div`
  width: ${(props: wrapperType) => props.navigation ? "8px" : "72px"};
  padding: 4px 0;
  text-align: center;
  border-radius: 20px;
  font-size: ${fonts.fontSizeRem('xs')};
  color: ${colors.themeColor('text_white')};
  background: ${(props: wrapperType) => props.status === 'ACTIVE' ? colors.themeColor('success') : colors.themeColor('background_reject')}
`

const InquiryStatusIcon = ({
  status = '',
  navigation
}: {
  status: string,
  navigation?: boolean
}) => {
  const statusText = (status: string) => {
    switch (status) {
      case 'ACTIVE':
        return '要返信'
      case 'CLOSED':
        return '完了'
      default :
        return '不正'
    }
  };
  
  return (
    <Wrapper status={status} navigation={navigation}>
      {!navigation && statusText(status)}
    </Wrapper>
  );
};

export default InquiryStatusIcon;