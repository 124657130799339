import { initEconomicConditionMasterSessionDataType } from "../../../constance/typeModel";
import { FlexBox } from "../../layouts/FlexBox"

const OptionList = ({
    service,
    option,
    masterData
}: {
    service: string,
    option: string,
    masterData: initEconomicConditionMasterSessionDataType
}) => {
    let optionPhrase: string = "";
    let optionFeeName: string = "";
    switch(option){
        case "send_mail":
            optionPhrase = "決済結果通知メール"
            optionFeeName = "option_fee_confirmation_email"
            break
        case "customer_support":
            optionPhrase = "カスタマーサポート"
            optionFeeName = "option_fee_customer_support"
            break
        case "early_payment_flag":
            optionPhrase = "早期入金サービス"
            optionFeeName = "option_fee_early_deposit_service"
            break
        case "threed_secure":
            optionPhrase = "3Dセキュア（本人認証システム）"
            optionFeeName = "option_fee_3d_secure"
            break
        case "cvv":
            optionPhrase = "セキュリティコード（CVV2/CVC2）"
            optionFeeName = "option_fee_cvv"
            break
        case "subscription":
            optionPhrase = "継続決済"
            optionFeeName = "option_fee_continuous_billing"
            break
        case "div":
            optionPhrase = "２回払い/ボーナス一括払い"
            optionFeeName = "option_fee_div2_bonus"
            break
        case "id_password_storage":
            optionPhrase = "ID・パスワード預かり"
            optionFeeName = "option_fee_id_password_storage"
            break
        case "payment_now":
            optionPhrase = "今すぐ決済"
            optionFeeName = "option_fee_payment_now"
            break
        default :
            optionPhrase = "error"
            optionFeeName = "error"
            break
    }
    return(
        <>
            <FlexBox spaceBetween>
                <p className="mb0 font-s">{optionPhrase}</p>
                <p className="mb0 font-s bold blue_text">￥<span className="font-m">{masterData[service][optionFeeName]}</span>/月</p>
            </FlexBox>
            <hr className="mb4 mt0"/>
        </>
    )
}

export default OptionList
