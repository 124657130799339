import { ErrorHandler } from "../../../api/ErrorHandler"
import { Container } from "../../../components/layouts/Container"
import { FormWrap } from "../../../components/layouts/FormWrap"
import { Button } from "../../../components/ui-parts/ocnk"
import { initApiModelType } from "../../../constance/typeModel"
import useNavigateByAgent from "../../../hooks/useNavigateByAgentHooks"
import useUserRegisterAuthenticationHooks from "../../../hooks/useUserRegisterAuthenticationHooks"

const UserRegisterAuthentication = () => {
	const {navigateByAgent} = useNavigateByAgent()
	const { authButtonHandler, normal, abnormal } = useUserRegisterAuthenticationHooks(navigateByAgent)
	const {ErrorHandle} = ErrorHandler(navigateByAgent)
	
	const AuthButtonHandler = async() => {
		const response = await authButtonHandler()
		ErrorHandle<initApiModelType<undefined>>(response, normal, abnormal, false)
	}
	return (
		<Container>
			<FormWrap center>
				<h3 className="bold">ボタンを押すと認証が完了します。</h3>
				<Button variant="secondary" onClick={AuthButtonHandler}>認証する</Button>
			</FormWrap>
		</Container>
	)
}

export default UserRegisterAuthentication