// reducers/Reducer
import React from "react";
import { initialState } from "./Provider";

// 必要なアクションをimportして関数呼び出しにする。
// 主にAPI操作かな

// reducer関数
const Reducer : React.Reducer<any, any> = (state: any, action: any) => {
  switch (action.type) {
    case "set-context-data":
      // ここでaction（関数）を呼び出す
      return { ...state, ...action.payload };
    case "reset-context":
      return initialState;
    case "set-user-data":
      const userData = {...state.user, ...action.payload}
      return { ...state,user:{...userData }};
    case "set-inquiry-data":
      const inquiryData = {...state.inquiry, inquiry_all_data:action.payload}
      return { ...state,inquiry:{...inquiryData }};
    case "set-inquiry-comment-data":
      const inquiryCommentAllData = {...state.inquiry, inquiry_comment_data: action.payload}
      return {...state, inquiry:{...inquiryCommentAllData}}
    case "set-validate-data":
      return { ...state, validateMessages:action.payload };
    case "set-entry-validate":
      return { ...state, ...action.payload };
    case "set-validate-merchant":
      const merchantData = {...state.validateMessages ,merchant:action.payload}
      return { ...state, validateMessages:{...merchantData} };
    case "set-validate-bank_account":
      const bankAccountData = {...state.validateMessages ,bank_account:action.payload}
      return { ...state, validateMessages:{...bankAccountData} };
    case "set-validate-shop":
      const shopData = {...state.validateMessages ,shop:action.payload}
      return { ...state, validateMessages:{...shopData} };
    case "set-entry-data":
      const entryData = {...state.entry ,...action.payload}
      return { ...state, entry: entryData };
    case "set-entry-merchant":
      const entryMerchantData = {...state.entry ,merchant:{...state.entry.merchant,...action.payload}}
      return { ...state, entry: entryMerchantData };
    case "set-entry-representative":
      const entryRepresentativeData = {...state.entry ,representative:{...state.entry.representative,...action.payload}}
      return { ...state, entry: entryRepresentativeData };
    case "set-entry-operation":
      const entryOperationData = {...state.entry ,operation:{...state.entry.operation,...action.payload}}
      return { ...state, entry: entryOperationData };
    case "set-entry-bank-account":
      const entryBankAccountData = {...state.entry ,bank_account:{...state.entry.bank_account,...action.payload}}
      return { ...state, entry: entryBankAccountData };
    case "set-entry-shop":
      const entryShopAccountData = {...state.entry ,shop:{...state.entry.shop,...action.payload}}
      return { ...state, entry: entryShopAccountData };
    case "set-entry-files":
      const entryFilesData = {...state.entry ,files:{...state.entry.files,...action.payload}}
      return { ...state, entry: entryFilesData };
    case "set-review-content-division":
      const entryReviewContentsDivisionData = {...state.entry ,review_contents_division:{...state.entry.review_contents_division,...action.payload}}
      return { ...state, entry: entryReviewContentsDivisionData };
    case "set-modal-flag":
      return { ...state, is_open_modal: action.payload };
    case "set-bank-pulldown":
      return { ...state, entry_bank_pulldown: action.payload };
    default:
      return state;
  }
};

export default Reducer;