import axios from 'axios';

const baseURL : string = '/api'

// axiosのインスタンス作成
const api = axios.create({
    baseURL: baseURL,
    headers:{
        'Content-Type': 'application/json',
    }
})


// ログイン時やユーザー登録時にFlaskAPIと通信する
export const PostMethod = async <T,U>(data: T, uri: string): Promise<{ code: number, message: string, response_data: U}> => {
    let code : number, message : string, response_data : U
    return await api.post(
        uri,
        data,
        { withCredentials: true },
    )
        .then(({data}) => {
            code = data.code
            message = data.message
            response_data = data.response_data
            return { code, message, response_data }
        })
        .catch((err) => {
            code = err.response.data.code
            message = err.response.data.message
            response_data = err.response.data.result_code
            return { code, message, response_data }
        })
}

// ゲットメソッド（データ受受信）
export const GetMethod = async <T,U>(param_Data: T, uri: string): Promise<{ code: number, message: string, response_data?: U}> => {
    let code : number, message : string, response_data : U
    return await api.get(
        uri,
        { params: param_Data },
    )
        .then(({data}) => {
            code = data.code
            message = data.message
            response_data = data.response_data
            return { code, message, response_data }
        })
        .catch((err) => {
            code = err.request.status
            message = err.message
            return { code, message }
        })
}
