import { useContext, useEffect, useState, useMemo, useCallback } from "react"
import { Context } from "../stores/Provider"
import { getSession } from "../sessionStorage/sessionStorageMethod"
import { setIsLoading } from "../function/setIsLoading"
import { logoutAbnormalFunction } from "../function/logoutAbnormalFunction"
import { initApiModelType, inquirySendRequestType, statusRequestType, statusResponseEntryType, statusResponseType } from "../constance/typeModel"
import { initApiResponse, ToolChipDiv } from "../constance/constance"
import { GetMethod,PostMethod } from "../api/Api";
import styled from "styled-components";
import { fonts } from "../css/variables/fonts";
import { colors } from "../css/variables/colors";

const AnavailableDaySpan = styled.span`
    font-weight: bold;
    font-size: ${fonts.fontSizeRem('s')};
    margin-bottom: 32px;
    color: ${colors.themeColor('text_error')};
`

const useContainerHooks = (
    isLogin: boolean,
    navigateByAgent: Function
) => {
    const {state, dispatch} = useContext(Context)
    const isLoading = state.isLoading
    const canceledFlag = state.user_status.cancel_flag
    //セッションデータ抽出
	const userInfoData = getSession('user')
    const serviceStatusData = state.service_status
    const confirmDate = state.user_status.close_schedule_date
    const serviceCodeList = state.service_code_list

    //エラーメッセージステイト
    const [registerApiErrMsg, setRegisterApiErrMsg] = useState("")
    // モーダル用のState
    const[modalFlag, setModalFlag] = useState(false);
    const ModalFlag = modalFlag;
    // モーダルの送信状態State
    const[modalSendFlag, setModalSendFlag] = useState(false);
    const ModalSendFlag = modalSendFlag;

    const modalOpenHandler = () => {
        setModalFlag(prev => !prev);
        setModalSendFlag(false);
    };

    // お問い合わせ作成用のState
    const[inquiryRegister, setInquiryRegister] = useState({title: '', content: '', name: '', email: ''});

    // お問い合わせバリデーションState
    const[inquiryRegisterErrMsg, setInquiryRegisterErrMsg] = useState({text:"", select:""});
    const InquiryRegisterErrMsg = inquiryRegisterErrMsg;
    

    // お問い合わせState変更関数
    const setInquiryRegisterHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const itemName = e.target.id.replace('send_mail_','')
        setInquiryRegister({...inquiryRegister, [itemName]: e.target.value})
    };

    //お問い合わせブランクバリデーション
    const setInquiryRegisterErrMsgHandler = (inquiryName: string, inquiryEmail: string, inquiryContent: string,inquiryTitle: string) => {
        let insertErrMsg = {text:"", select:""}
        if (inquiryName === '' || inquiryEmail === "" || inquiryContent === '') {
            insertErrMsg = {...insertErrMsg, text:'お名前、メールアドレス、お問い合わせ内容(詳細)をすべてを入力してください。'};
        };
        if (inquiryTitle === '') {
            insertErrMsg = {...insertErrMsg, select:'お問い合わせ内容を選択してください。'};
        };
        return insertErrMsg
    }

    //コンテンツ選択セレクトメニュー
    const selectTitle = [
        { label: 'コンテンツを選択', value: '', disabled: false },
        { label: '申し込みについて', value: '申し込みについて', disabled: false },
        { label: '審査について', value: '審査について', disabled: false },
        { label: '申し込みのキャンセル', value: '申し込みのキャンセル', disabled: false },
        { label: 'その他', value: 'その他', disabled: false },
    ]

    const closeState = useMemo(()=>{
        let returnState:{
            confirm_date: null | string, 
            confirm_flag: boolean
        } = {
            confirm_date: null, 
            confirm_flag: false
        }
        returnState = {confirm_date: state.user_status.close_schedule_date, confirm_flag: state.user_status.confirm_flag}
        return returnState
    },[state.user_status.close_schedule_date, state.user_status.confirm_flag])

    const closingNoticeText = useMemo(()=>{
        const closeServiceCodeList = serviceCodeList.split('/')
        const allText = closeServiceCodeList.length > 1 ? 'すべて' : '';

        // 各テキスト格納
        const allGoText = <ToolChipDiv>{`・お申込みの決済サービスが${allText}ご利用開始となりましたので、Web申込システムのご利用が終了となります。\n  `}<AnavailableDaySpan>ご利用終了日：{confirmDate}</AnavailableDaySpan>{'\n・なお、お申込み内容は、ダッシュボード画面右下の「お申し込み情報ダウンロード」ボタンよりPDFでダウンロードが可能です。\n 必要に応じて、ご利用終了日までにダウンロードを行ってください。'}</ToolChipDiv>
        const cvsCancelText = <ToolChipDiv>{'・コンビニ決済サービスのキャンセル手続きが完了しましたので、Web申込システムのご利用が終了となります。\n  '}<AnavailableDaySpan>ご利用終了日：{confirmDate}</AnavailableDaySpan>{'\n・なお、お申込み内容は、ダッシュボード画面右下の「お申し込み情報ダウンロード」ボタンよりPDFでダウンロードが可能です。\n 必要に応じて、ご利用終了日までにダウンロードを行ってください。'}</ToolChipDiv>
        const cvsNgText = <ToolChipDiv>{'・コンビニ決済サービスは審査の結果ご提供不可となりました。審査終了に伴い、Web申込システムのご利用が終了となります。\n  '}<AnavailableDaySpan>ご利用終了日：{confirmDate}</AnavailableDaySpan>{'\n・なお、お申込み内容は、ダッシュボード画面右下の「お申し込み情報ダウンロード」ボタンよりPDFでダウンロードが可能です。\n 必要に応じて、ご利用終了日までにダウンロードを行ってください。'}</ToolChipDiv>
        const allNgText = <ToolChipDiv>{'・お申込みの決済サービスは審査の結果、ご提供不可となりました。審査終了に伴い、Web申込システムのご利用が終了となります。\n  '}<AnavailableDaySpan>ご利用終了日：{confirmDate}</AnavailableDaySpan></ToolChipDiv>
        const allChancelText = <ToolChipDiv>{`・お申込みの決済サービスは、${allText}キャンセル手続きが完了いたしました。キャンセルに伴い、Web申込システムのご利用が終了となります。\n  `}<AnavailableDaySpan>ご利用終了日：{confirmDate}</AnavailableDaySpan></ToolChipDiv>

        // 選択サービス全NGパターン
        let allNgFlag = true
        closeServiceCodeList.forEach((selectedService) => {
            if(serviceStatusData[selectedService].status !== 'NG') allNgFlag = false           
        })
        if(allNgFlag || state.user_status.status === 'CHECK_NG') return allNgText

        // 選択サービス全キャンセルパターン
        let allChancelFlag = true
        closeServiceCodeList.forEach((selectedService) => {
            if(serviceStatusData[selectedService].status !== 'CANCEL') allChancelFlag = false           
        })
        // 全サービスキャンセルか申込全体がキャンセルの時
        if(allChancelFlag || canceledFlag) return allChancelText

        //  選択サービスにコンビニがあるかチェック
        if(serviceCodeList.includes("cvs")){
            // クレカがキャンセル以外＆＆コンビニがキャンセルの時
            if(serviceStatusData.credit.status !== 'CANCEL' && serviceStatusData.cvs.status === 'CANCEL'){
                return cvsCancelText
            }
            // クレカが審査NG以外＆＆コンビニが審査NGの時
            if(serviceStatusData.credit.status !== 'NG' && serviceStatusData.cvs.status === 'NG'){
                return cvsNgText
            }
        }

        // 上記の条件以外
        return allGoText
        
    },[state, serviceCodeList, serviceStatusData, confirmDate, canceledFlag])
    
    //画面読み込み時ローディング表示、ページトップ移動後ローディングを消去
    useEffect(()=>{
        const getResponse = async () => {
            setIsLoading(true, dispatch)
            const entryId = {entry_id: userInfoData.entry_id}
            let response: initApiModelType<statusResponseType> = initApiResponse
            response = await GetMethod<statusRequestType,statusResponseType>(entryId, '/status') 
            if(response.code !== 200 || response.response_data === undefined){
                if(response.code === 401){
                    return logoutAbnormalFunction(response,dispatch,navigateByAgent,state.agentPath)
                }
                setIsLoading(false, dispatch)
                return navigateByAgent('/system_error')
            } 
            getServiceStatus(response.response_data.entry)
            setIsLoading(false, dispatch)
        }
        if(isLogin && state.user_status.status !== "" && state.user_status.status !== 'ENTRING') getResponse()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[state.user_status.status])

        // 各サービス情報
    const getServiceStatus = useCallback((data:statusResponseEntryType) => {
        //初期表示ページトップと画面読み込みローディング非表示
        const scrollWindow = () => {
            window.scrollTo(0,0)
            dispatch({
                type: "set-context-data",
                payload: {
                    service_code_list:data.service_code_list,
                    service_status:{
                        credit:data.service.credit,
                        bank:data.service.bank,
                        cvs:data.service.cvs,
                        atobarai:data.service.atobarai,
                    },
                    user_status:{
                        ...state.user_status,
                        cancel_flag:data.cancel_flag ,
                    }
                }
            })
        }
        setTimeout(scrollWindow,1500)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    // お問い合わせ作成関数
    const inquiryRegisterHandler = async () => {
        const inquiryTitle = inquiryRegister.title;
        const inquiryContent = inquiryRegister.content;
        const inquiryName = inquiryRegister.name;
        const inquiryEmail = inquiryRegister.email;
        let insertErrMsg = {text:"", select:""}
        let response: initApiModelType<undefined> = initApiResponse
        
        //エラーメッセージ設定
        if (inquiryName === '' || inquiryEmail === "" || inquiryContent === '' || inquiryTitle === '') {
            insertErrMsg = setInquiryRegisterErrMsgHandler(inquiryName,inquiryEmail,inquiryContent,inquiryTitle)
            setInquiryRegisterErrMsg(insertErrMsg)
            return response
        }
        
        setInquiryRegisterErrMsg({text:"", select:""});
        const reqData = {
            "name" : inquiryName,
            "user_id": userInfoData.user_id,
            "email": inquiryEmail,
            "content": inquiryTitle,
            "detail": inquiryContent,
        };
        //ローディング開始
        setIsLoading(true, dispatch)
        response = await PostMethod<inquirySendRequestType, undefined>(reqData, '/inquiry_send');
        if (response.code === 200) {
            setRegisterApiErrMsg('')
            setModalSendFlag(true)
            setInquiryRegister({title: '', content: '', name: '', email: ''})
        } else if (response.code === 401) {
            return logoutAbnormalFunction(response,dispatch,navigateByAgent,state.agentPath)
        } else {
            // setRegisterApiErrMsg('メール送信に失敗しました。')
            setModalSendFlag(false)
            return navigateByAgent('/system_error')
        }
        //ローディング終了
        setIsLoading(false, dispatch)
    };

    const agentName = useMemo(()=>{
        let result = ''
        if(state.agentPath === '/ocnk-teiki'){
            result = '：おちゃのこネット定期購入決済'
        }
        return result
    },[state.agentPath])

    return{isLoading, canceledFlag, modalOpenHandler, inquiryRegisterHandler, setInquiryRegisterHandler, 
        ModalSendFlag, registerApiErrMsg, InquiryRegisterErrMsg, selectTitle, ModalFlag, closeState, closingNoticeText, agentName}
}

export default useContainerHooks