import React, { useContext, useEffect, useState } from 'react';
import { EntryHead } from '../../../../ui-parts/ocnk/EntryHead';
import { FormTitle } from '../../../../ui-parts/ocnk/FormTitle';
import { FlexBox } from '../../../../layouts/FlexBox';
import { RadioButtonGroup } from '../../../../ui-parts/ocnk/RadioButtonGroup';
import { TextField } from '../../../../ui-parts/ocnk/TextField';
import { FileInput } from '../../../../ui-parts/ocnk/FileInput';
import useEntryContentsBankAccountHooks from '../../../../../hooks/useEntryContentsBankAccountHooks';
import { toolChipList } from '../../../../../constance/constance';
import { ErrorMessage } from '../../../../ui-parts/ocnk';
import { DataList } from '../../../../ui-parts/ocnk/DataList';
import AnotherTabLink from '../../../../ui-parts/ocnk/AnotherTabLink';
import useGTMHooks from '../../../../../hooks/useGTMHooks';
import useLimitDirectAccess from '../../../../../hooks/useLimitDirectAccess';
import { Context } from '../../../../../stores/Provider';


export const EntryContentsBankAccount = () => {
  const {state} = useContext(Context)
  const {DirectAccessCheck} = useLimitDirectAccess(state.agentPath)
  const { radioItemsAccount, bankAccountState, merchantInfoData, bankPulldownState, bankBranchPulldownState, showFlag, disableFlag, errMsg, setBankAccountBankStateHandler, setRadioBankAccountBankStateHandler, setPulldownBankStateHandler, validateErrMsgs } = useEntryContentsBankAccountHooks(); 
  const { pushDataLayerHandler } = useGTMHooks()

  const [directFlag, setDirectFlag] = useState<boolean>(false)
  useEffect(()=>{
    pushDataLayerHandler()
    setDirectFlag(DirectAccessCheck(state.agentPath+'/entry/bank_account')) 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  if(!directFlag) return(<></>)

  return (
    <>
      <section>
        <EntryHead icon='bank'>口座情報（売上代金振込先）</EntryHead>
        <p>【ご注意】ご指定いただける<AnotherTabLink href={"https://www.cardservice.co.jp/info/guide/kouza.html"}>口座情報</AnotherTabLink>について事前にご確認ください。</p>
        {showFlag &&
          <>
            <FormTitle required >金融機関
            </FormTitle>
            <ErrorMessage ApiErrMsg={errMsg} />
            <FlexBox childJustify responsiveColumn>
              <DataList id="bank_account_bank_name" fullwidth label={"金融機関名"} options={bankPulldownState} onChange={setPulldownBankStateHandler} value={bankAccountState.bank_account_bank_name} iserror={validateErrMsgs.bank_account_bank_name.message} name={"bank_account_bank_name"} explain={toolChipList.bank_account_name_pulldown} autocomplete={"off"}/>
              <DataList id="bank_account_bank_branch" fullwidth label={"支店名"} options={bankBranchPulldownState} value={bankAccountState.bank_account_bank_branch} name={"bank_account_bank_branch"} onChange={setPulldownBankStateHandler} disabled={disableFlag} iserror={validateErrMsgs.bank_account_bank_branch.message} explain={toolChipList.bank_account_branch_pulldown} autocomplete={"off"}/>
            </FlexBox>

            <FormTitle required >口座種別</FormTitle>
            <RadioButtonGroup name='bank_account_category' radioitems={radioItemsAccount} state={bankAccountState.bank_account_category} setState={setRadioBankAccountBankStateHandler} iserror={validateErrMsgs.bank_account_category.message} />

            <FormTitle required>口座番号</FormTitle>
            <TextField id='bank_account_number' placeholder='1234567' fullwidth onChange={setBankAccountBankStateHandler} defaultValue={bankAccountState.bank_account_number} iserror={validateErrMsgs.bank_account_number.message} explain={toolChipList.bank_account_number}/>

            <FormTitle required >口座名義（ｶﾅ表記）</FormTitle>
            <TextField id='bank_account_name' placeholder={merchantInfoData.merchant_type === "1" ? 'ｶ)ｾﾞｳｽ' : 'ﾔﾏﾀﾞﾀﾛｳ'} fullwidth onChange={setBankAccountBankStateHandler} value={bankAccountState.bank_account_name} iserror={validateErrMsgs.bank_account_name.message} explain={toolChipList.bank_account_name}/>

            <FormTitle required >通帳画像</FormTitle>
            <FileInput id='passbook_images' name='passbook_images' maxsize={30} multiple iserror={validateErrMsgs.passbook_images.message} page={'bank_account'} toolChip={toolChipList.passbook_images}/>
          </>
        }
        </section>
      </>
  )
}

