import { useEffect } from "react";
import ErrorBoundary from "../../../api/ErrorBoundary";
import useGTMHooks from "../../../hooks/useGTMHooks";
import CompletedRegister from "../renderPages/CompletedRegister";

const CompletedRegisterBeforeRender = () => {
  const { pushDataLayerHandler } = useGTMHooks()

  useEffect(()=>{
    pushDataLayerHandler()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
    return(
      <ErrorBoundary>
        <CompletedRegister/>
      </ErrorBoundary>
    )
}

export default CompletedRegisterBeforeRender