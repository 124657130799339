import React, { useEffect, useState } from 'react';
import { EntryHead } from '../../../../ui-parts/ocnk/EntryHead';
import { FormTitle } from '../../../../ui-parts/ocnk/FormTitle';
import { Box } from '../../../../layouts/Box';
import { FlexBox } from '../../../../layouts/FlexBox';
import { RadioButtonGroup } from '../../../../ui-parts/ocnk/RadioButtonGroup';
import { TextField } from '../../../../ui-parts/ocnk/TextField';
import { SelectMenu } from '../../../../ui-parts/ocnk/SelectMenu';
import { FileInput } from '../../../../ui-parts/ocnk/FileInput';
import { Checkbox } from '../../../../ui-parts/ocnk/Checkbox';
import { FormChildBlockskin } from '../../../../layouts/FormChildBlockskin';
import { useEntryContentsShopHooks } from '../../../../../hooks/useEntryContentsShopHooks';
import { ErrorMessage } from '../../../../ui-parts/ocnk/ErrorMessage';
import { toolChipList } from '../../../../../constance/constance';
import styled from 'styled-components';
import { fonts } from '../../../../../css/variables/fonts';
import useGTMHooks from '../../../../../hooks/useGTMHooks';
import useLimitDirectAccess from '../../../../../hooks/useLimitDirectAccess';
import useNavigateByAgent from '../../../../../hooks/useNavigateByAgentHooks';
import useUserAuthHooks from '../../../../../hooks/useUserAuthHooks';
import { useLoginCheckHooks } from '../../../../../hooks/useLoginCheckHooks';

const ToolChipExplain = styled.p`
    font-size:${fonts.fontSizeRem('xs')};
    margin-bottom:0px;
    white-space: pre-line;
    text-align:left;
`

export const OcnkTeikiEntryContentsShop = ({
    redirect
}:{
    redirect?: boolean
}) => {
    const {navigateByAgent} = useNavigateByAgent()
    const {LoginCheck} = useLoginCheckHooks('/ocnk-teiki');
    const {DirectAccessCheck} = useLimitDirectAccess('/ocnk-teiki')
    const {AuthCheck} = useUserAuthHooks('/ocnk-teiki');
    const { pushDataLayerHandler } = useGTMHooks()
    const { shopState, setShopStateHandler, setRadioShopStateHandler, setSelectBoxShopStateHandler, 
        radioYesNo, radioCanNot,radioBtoB, selectChargesCycle, selectMajorContents,
        selectMediumContents, ObjToDevide, validateErrMsgs, apiErrMsg, showFlag, reverseRadioYesNo, setBtoBRadioShopStateHandler, ipFlag, userInfoData
    } = useEntryContentsShopHooks(navigateByAgent);
    
    const [directFlag, setDirectFlag] = useState<boolean>(false)
    const [loginFlag, setLoginFlag] = useState<boolean>(false)
    
    useEffect(()=>{
        const accessCheckPath = redirect ? '/redirect/shop' : '/entry/shop'
		pushDataLayerHandler()
        setLoginFlag(LoginCheck())
		setDirectFlag(DirectAccessCheck('/ocnk-teiki'+accessCheckPath)) 
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    if(!AuthCheck() || !directFlag || !loginFlag) return(<></>)
    
    return (
        <>
            {showFlag &&
                <>
                    <ErrorMessage ApiErrMsg={apiErrMsg}/>
                    {apiErrMsg}
                    <EntryHead icon='cart'>ショップ情報</EntryHead>
                    <h2>ショップ基本情報</h2>
                {!ipFlag ?
                    <>   
                        <FormTitle required toolChip={toolChipList.shop_website_url} responsiveToolChipPlacement={'right'}>決済サービスを導入するショップURL</FormTitle>
                        <TextField id='shop_website_url' placeholder='https://www.cardservice.co.jp' fullwidth defaultValue={shopState.shop_website_url} onChange={setShopStateHandler} iserror={validateErrMsgs.shop_website_url.message} disabled/>
                    </>
                    :
                    <>   
                        <FormTitle toolChip={toolChipList.shop_use_ip_code} responsiveToolChipPlacement={'right'}>ご利用中IPコード</FormTitle>
                        <TextField id='shop_used_IPcode' placeholder={"1234567890"} fullwidth defaultValue={userInfoData.ip_code} disabled={true} onChange={setShopStateHandler} />
                        <FormTitle required toolChip={toolChipList.shop_website_url} responsiveToolChipPlacement={'right'}>決済サービスを導入するショップURL</FormTitle>
                        <TextField id='shop_website_url' placeholder='https://www.cardservice.co.jp' fullwidth defaultValue={shopState.shop_website_url} onChange={setShopStateHandler} iserror={validateErrMsgs.shop_website_url.message} disabled/>
                    </>
                }
                    <>
                        
                                <>
                                    <FormTitle required>審査用サイト</FormTitle>
                                    <TextField id='shop_addition_shop_website_check_url' placeholder='https://www.cardservice.co.jp/demo/shop/' fullwidth defaultValue={shopState.shop_addition_shop_website_check_url} onChange={setShopStateHandler} iserror={validateErrMsgs.shop_addition_shop_website_check_url.message} disabled/>
                                    <ToolChipExplain>カード会社審査用に、定期購入対象商品を掲載しているURLです。</ToolChipExplain>
                                    <FormTitle required>特定商取引法に基づく表示  掲載URL</FormTitle>
                                    <TextField id='shop_addition_business_deal_url' placeholder='https://www.cardservice.co.jp/guide/tokusho_houjin.html' fullwidth defaultValue={shopState.shop_addition_business_deal_url} onChange={setShopStateHandler} iserror={validateErrMsgs.shop_addition_business_deal_url.message} disabled/>
                                    <ToolChipExplain>定期購入プランを利用された場合にショップ内で掲載される特商法の内容です。</ToolChipExplain>
                                </>
                        
                    </>
                    {!ipFlag ?
                    <>   
                        <FormTitle required toolChip={toolChipList.shop} toolChipWidth={390} responsiveToolChipPlacement={'center'}>ご利用店舗名称</FormTitle>
                        <TextField id='shop_name' label='店舗名' placeholder='ゼウスWEB店' fullwidth defaultValue={shopState.shop_name} onChange={setShopStateHandler} iserror={validateErrMsgs.shop_name.message} explain={toolChipList.shop_name} />
                        <TextField id='shop_name_kana' label='店舗名ﾌﾘｶﾞﾅ' placeholder='ｾﾞｳｽｳｪﾌﾞﾃﾝ' fullwidth defaultValue={shopState.shop_name_kana} onChange={setShopStateHandler} iserror={validateErrMsgs.shop_name_kana.message} explain={toolChipList.shop_name_kana}/>
                        <TextField toolChip={toolChipList.shop_name_alphabet} toolChipWidth={250} id='shop_name_alphabet' label='店舗名英字' placeholder='ZEUS WEB SHOP' fullwidth defaultValue={shopState.shop_name_alphabet} onChange={setShopStateHandler} iserror={validateErrMsgs.shop_name_alphabet.message} explain={toolChipList.shop_name_alphanumeric}/>
                    </>
                    :
                    <>   
                    </>
                }
                    

                    <h2>取扱商品</h2>
                    <FormTitle required toolChip={toolChipList.shop_addition_product_content_code} responsiveToolChipPlacement={'right'}>商品コンテンツ（複数可）</FormTitle>
                    <FlexBox responsiveColumn mt={16} gap={4} iserror={validateErrMsgs.shop_addition_product_content_code.message}>
                        <Checkbox id='shop_addition_product_content_code_sell_goods' label='物販' checked={shopState.shop_addition_product_content_code_sell_goods} onChange={setSelectBoxShopStateHandler} />
                        <Checkbox id='shop_addition_product_content_code_service' label='役務' checked={shopState.shop_addition_product_content_code_service} onChange={setSelectBoxShopStateHandler} />
                        <Checkbox id='shop_addition_product_content_code_digital_email' label='デジタルコンテンツ' checked={shopState.shop_addition_product_content_code_digital_email} onChange={setSelectBoxShopStateHandler} />
                    </FlexBox>

                    <FormTitle required>商品カテゴリ</FormTitle>
                    <ToolChipExplain>お申込みサービスの決済対象となる商品・サービスに該当するカテゴリーをご選択ください。</ToolChipExplain>
                    <SelectMenu id='shop_addition_major_content_code' name='shop_addition_major_content_code' options={selectMajorContents} value={shopState.shop_addition_major_content_code} fullwidth label={'商品カテゴリ（大分類）'} onChange={setShopStateHandler} iserror={validateErrMsgs.shop_addition_major_content_code.message} />
                    <SelectMenu id='shop_addition_medium_content_code' name='shop_addition_medium_content_code' options={selectMediumContents} value={shopState.shop_addition_medium_content_code} fullwidth label={'商品カテゴリ（小分類）'} onChange={setShopStateHandler} iserror={validateErrMsgs.shop_addition_medium_content_code.message} />
                    <TextField id='shop_addition_product_content_freetext' label={'具体的な商品内容を入力 '+toolChipList.shop_addition_product_content_freetext} fullwidth defaultValue={shopState.shop_addition_product_content_freetext} onChange={setShopStateHandler} iserror={validateErrMsgs.shop_addition_product_content_freetext.message} />

                    {ObjToDevide.antique_flag &&
                        <>
                            <FormTitle required toolChip={toolChipList.antique_select} responsiveToolChipPlacement={'right'}>中古品の取扱いの有無</FormTitle>
                            <RadioButtonGroup name='shop_addition_antique_flag' radioitems={radioYesNo} state={shopState.shop_addition_antique_flag} setState={setRadioShopStateHandler} iserror={validateErrMsgs.shop_addition_antique_flag.message} />
                            {shopState.shop_addition_antique_flag &&
                                <>
                                    <FormChildBlockskin>
                                        <FormTitle required mt={0}>古物許可証
                                        </FormTitle>
                                        <ToolChipExplain>ご契約者様名義の許可証の写しをご提出ください。</ToolChipExplain>
                                        <FileInput id='antique' name='antique' accept={['jpeg', 'png', 'pdf', 'jpg']} maxsize={30} multiple iserror={validateErrMsgs.antique.message} page={"shop"} />
                                    </FormChildBlockskin>
                                </>
                            }
                        </>
                    }

                    {ObjToDevide.subscription_flag &&
                        <>
                            <FormTitle required toolChip={toolChipList.shop_addition_subscription_flag} responsiveToolChipPlacement={'right'}>サブスク課金の有無（定期購入・月会費など）</FormTitle>
                            <ToolChipExplain>定期購入サービスのご利用のため、「あり」で固定となります。</ToolChipExplain>
                            <RadioButtonGroup name='shop_addition_subscription_flag' radioitems={radioYesNo} state={shopState.shop_addition_subscription_flag} setState={setRadioShopStateHandler} disabled={true} iserror={validateErrMsgs.shop_addition_subscription_flag.message} />
                            {shopState.shop_addition_subscription_flag &&
                                <FormChildBlockskin>
                                    <FormTitle required mt={0}>課金サイクル</FormTitle>
				    <p className="mt0 font-xs">複数パターンがある場合は、最も長いサイクルを選択してください。</p>
                                    <SelectMenu id='shop_addition_subscription_code' name='shop_addition_subscription_code' fullwidth defaultValue={shopState.shop_addition_subscription_code === ""?"課金サイクルを選択":shopState.shop_addition_subscription_code} options={selectChargesCycle} onChange={setShopStateHandler} iserror={validateErrMsgs.shop_addition_subscription_code.message} />
                                </FormChildBlockskin>
                            }
                        </>
                    }

                    {ObjToDevide.purchase_points_flag &&
                        <>
                            <FormTitle required toolChip={toolChipList.shop_addition_point_purchase_flag} toolChipWidth={370} responsiveToolChipPlacement={'right'}>ポイント購入の有無</FormTitle>
                            <RadioButtonGroup name='shop_addition_point_purchase_flag' radioitems={radioYesNo} setState={setRadioShopStateHandler} state={shopState.shop_addition_point_purchase_flag} iserror={validateErrMsgs.shop_addition_point_purchase_flag.message} />
                        </>
                    }

                    {ObjToDevide.prepaid_continuous_flag &&
                        <>
                            <FormTitle required toolChip={toolChipList.shop_addition_prepaid_flag} responsiveToolChipPlacement={'center'}>前払い一括回収の有無（１か月を超えるサービス）</FormTitle>
                            <RadioButtonGroup name='shop_addition_prepaid_flag' radioitems={radioYesNo} setState={setRadioShopStateHandler} state={shopState.shop_addition_prepaid_flag} iserror={validateErrMsgs.shop_addition_prepaid_flag.message} />
                        </>
                    }

                    {ObjToDevide.reserve_item_flag &&
                        <>
                            <FormTitle required >予約商品の有無</FormTitle>
                            <RadioButtonGroup name='shop_addition_reserved_product_flag' radioitems={radioYesNo} setState={setRadioShopStateHandler} state={shopState.shop_addition_reserved_product_flag} iserror={validateErrMsgs.shop_addition_reserved_product_flag.message} />
                            {shopState.shop_addition_reserved_product_flag &&
                                <FormChildBlockskin>
                                <FormTitle required mt={0} toolChip={toolChipList.shop_addition_reservation_period_flag} responsiveToolChipPlacement={'right'}>2か月以内に提供可能</FormTitle>
                                <RadioButtonGroup name='shop_addition_reservation_period_flag' radioitems={radioCanNot} state={shopState.shop_addition_reservation_period_flag} setState={setRadioShopStateHandler} iserror={validateErrMsgs.shop_addition_reservation_period_flag.message} />
                            </FormChildBlockskin>
                            }
                        </>
                    }

                    {ObjToDevide.estimate_flag &&
                        <>
                            <FormTitle required >見積商品の有無</FormTitle>
                            <RadioButtonGroup name='shop_addition_estimated_product_flag' radioitems={radioYesNo} state={shopState.shop_addition_estimated_product_flag} setState={setRadioShopStateHandler} iserror={validateErrMsgs.shop_addition_estimated_product_flag.message} />
                        </>
                    }

                    {ObjToDevide.rental_flag &&
                        <>
                            <FormTitle required >レンタル商品の有無</FormTitle>
                            <RadioButtonGroup name='shop_addition_rental_flag' radioitems={radioYesNo} setState={setRadioShopStateHandler} state={shopState.shop_addition_rental_flag} iserror={validateErrMsgs.shop_addition_rental_flag.message} />
                            {shopState.shop_addition_rental_flag && 
                                <FormChildBlockskin>
                                    <Box iserror={validateErrMsgs.shop_addition_rental_validate.message}>
                                        <FormTitle required mt={0}>レンタル規約
                                        </FormTitle>
                                        <ToolChipExplain>規約・注意事項のURLまたはファイルをご提出ください。</ToolChipExplain>
                                        <TextField id='shop_addition_rental_product_url' label='規約URLまたは規約ファイルを登録してください。' placeholder='https://www.cardservice.co.jp/zmc/kiyaku/' fullwidth defaultValue={shopState.shop_addition_rental_product_url} onChange={setShopStateHandler} iserror={validateErrMsgs.shop_addition_rental_product_url.message} />
                                        <FileInput id='rental' name='rental' multiple accept={['jpeg', 'png', 'pdf', 'jpg']} maxsize={30} iserror={validateErrMsgs.rental.message} page={"shop"}/>
                                    </Box>
                                </FormChildBlockskin>
                            }
                        </>
                    }
                    {ObjToDevide.online_local_flag && 
                        <>
                            <FormTitle required >サービスの提供場所（複数可）</FormTitle>
                            <Box mt={16} iserror={validateErrMsgs.shop_addition_offer_place.message}>
                                <Checkbox id='shop_addition_online' label='オンライン' checked={shopState.shop_addition_online === null ? false : shopState.shop_addition_online} value={shopState.shop_addition_online === null ? false : shopState.shop_addition_online} onChange={setSelectBoxShopStateHandler} />
                                <Checkbox id='shop_addition_offline' label='現地' checked={shopState.shop_addition_offline === null ? false : shopState.shop_addition_offline} value={shopState.shop_addition_offline === null ? false : shopState.shop_addition_offline} onChange={setSelectBoxShopStateHandler} />
                            </Box>
                        </>
                    }
                    {ObjToDevide.btob_flag &&
                        <>
                            <FormTitle required >BtoB取引（事業者向け販売）の取扱い</FormTitle>
                            <RadioButtonGroup name='shop_addition_btob_code' radioitems={radioBtoB} state={shopState.shop_addition_btob_code} setState={setBtoBRadioShopStateHandler} iserror={validateErrMsgs.shop_addition_btob_code.message} />
                        </>
                    }

                    {ObjToDevide.license_flag &&
                        <>
                            <FormTitle>免許・資格情報</FormTitle>
                            <p className='mb0'>免許・許認可の必要な商品またはサービスを販売される場合は、免許証・許可証の写しを添付してください。</p>
                            <p>ご契約者様名義の下記の許可証のいずれかをご提出ください。</p>
                            {ObjToDevide.license_name !== null && ObjToDevide.license_name}
                            <FileInput multiple id='license' name='license' accept={['jpeg', 'png', 'pdf', 'jpg']} maxsize={30} iserror={validateErrMsgs.license.message} page={"shop"}/>
                        </>
                    }
                    <h2>確認項目</h2>
                    <FormTitle required>直近5年間の行政処分歴（特商法違反）</FormTitle>
                    <RadioButtonGroup name='shop_addition_non_administrative_penalties_five_year_flag' radioitems={reverseRadioYesNo} state={shopState.shop_addition_non_administrative_penalties_five_year_flag} setState={setRadioShopStateHandler} iserror={validateErrMsgs.shop_addition_non_administrative_penalties_five_year_flag.message} />

                    <FormTitle required>直近5年間の敗訴歴（消契法違反）</FormTitle>
                    <RadioButtonGroup name='shop_addition_non_losing_judgment_flag' radioitems={reverseRadioYesNo} state={shopState.shop_addition_non_losing_judgment_flag} setState={setRadioShopStateHandler} iserror={validateErrMsgs.shop_addition_non_losing_judgment_flag.message} />


                    <h2>同意項目</h2>
                    <FlexBox column>
                        <Checkbox id="shop_addition_available_product_flag" label={toolChipList.shop_addition_available_product_flag} checked={shopState.shop_addition_available_product_flag} onChange={setSelectBoxShopStateHandler} iserror={validateErrMsgs.shop_addition_available_product_flag.message} />
                        <Checkbox id="shop_addition_non_infringing_product_presence_absence_flag" label='第三者の知的財産権その他の権利を侵害し、又はそのおそれのあるものは取扱っていません。' checked={shopState.shop_addition_non_infringing_product_presence_absence_flag} onChange={setSelectBoxShopStateHandler} iserror={validateErrMsgs.shop_addition_non_infringing_product_presence_absence_flag.message} />
                    </FlexBox>
                </>
            }
        </>
    )
}
